import {
  ADD_TO_SHORT_LIST_UNIVERSITY_COURSE_ERR,
  ADD_TO_SHORT_LIST_UNIVERSITY_COURSE_REQUEST,
  ADD_TO_SHORT_LIST_UNIVERSITY_COURSE_SUCCESS,
  APPLICATION_VERIFIED_LEAD_FIND_ERR,
  APPLICATION_VERIFIED_LEAD_FIND_REQUEST,
  APPLICATION_VERIFIED_LEAD_FIND_SUCCESS,
  A_LEAD_DETAIL_FETCH_ERR,
  A_LEAD_DETAIL_FETCH_REQUEST,
  A_LEAD_DETAIL_FETCH_SUCCESS,
  CHANGE_APPROVING_LEAD_STATUS_ERR,
  CHANGE_APPROVING_LEAD_STATUS_REQUEST,
  CHANGE_APPROVING_LEAD_STATUS_SUCCESS,
  CHAT_WITH_COUNSELOR_ERR,
  CHAT_WITH_COUNSELOR_REQUEST,
  CHAT_WITH_COUNSELOR_SUCCESS,
  COUNTRY_ADMISSION_DOCS_FIND_ERR,
  COUNTRY_ADMISSION_DOCS_FIND_REQUEST,
  COUNTRY_ADMISSION_DOCS_FIND_SUCCESS,
  FIND_APPROVING_LEAD_STATUS_ERR,
  FIND_APPROVING_LEAD_STATUS_REQUEST,
  FIND_APPROVING_LEAD_STATUS_SUCCESS,
  FIND_CHAT_WITH_COUNSELOR_ERR,
  FIND_CHAT_WITH_COUNSELOR_REQUEST,
  FIND_CHAT_WITH_COUNSELOR_SUCCESS,
  FIND_LEAD_PENDING_STATUS_CHANGE_ERR,
  FIND_LEAD_PENDING_STATUS_CHANGE_REQUEST,
  FIND_LEAD_PENDING_STATUS_CHANGE_SUCCESS,
  FIND_SHORT_LIST_UNIVERSITY_COURSE_ERR,
  FIND_SHORT_LIST_UNIVERSITY_COURSE_REQUEST,
  FIND_SHORT_LIST_UNIVERSITY_COURSE_SUCCESS,
  GET_CHAT_WITH_COUNSELOR_ERR,
  GET_CHAT_WITH_COUNSELOR_REQUEST,
  GET_CHAT_WITH_COUNSELOR_SUCCESS,
  LEAD_APPROVAL_REQUEST_APPROVE_ERR,
  LEAD_APPROVAL_REQUEST_APPROVE_REQUEST,
  LEAD_APPROVAL_REQUEST_APPROVE_SUCCESS,
  LEAD_APPROVAL_REQUEST_FIND_ERR,
  LEAD_APPROVAL_REQUEST_FIND_REQUEST,
  LEAD_APPROVAL_REQUEST_FIND_SUCCESS,
  LEAD_APPROVAL_REQUEST_REJECT_ERR,
  LEAD_APPROVAL_REQUEST_REJECT_REQUEST,
  LEAD_APPROVAL_REQUEST_REJECT_SUCCESS,
  LEAD_BULKUPLOAD_ERR,
  LEAD_BULKUPLOAD_REQUEST,
  LEAD_BULKUPLOAD_SUCCESS,
  LEAD_COUNSILORNOTE_CREATE_ERR,
  LEAD_COUNSILORNOTE_CREATE_REQUEST,
  LEAD_COUNSILORNOTE_CREATE_SUCCESS,
  LEAD_COUNTRY_COMMON_DOCS_FINDING_ERR,
  LEAD_COUNTRY_COMMON_DOCS_FINDING_REQUEST,
  LEAD_COUNTRY_COMMON_DOCS_FINDING_SUCCESS,
  LEAD_COUNTRY_COMMON_DOCS_UPLOADING_ERR,
  LEAD_COUNTRY_COMMON_DOCS_UPLOADING_REQUEST,
  LEAD_COUNTRY_COMMON_DOCS_UPLOADING_SUCCESS,
  LEAD_COUNTRY_SPECIFIC_DOCS_FINDING_ERR,
  LEAD_COUNTRY_SPECIFIC_DOCS_FINDING_REQUEST,
  LEAD_COUNTRY_SPECIFIC_DOCS_FINDING_SUCCESS,
  LEAD_COUNTRY_SPECIFIC_DOCS_UPLOADING_ERR,
  LEAD_COUNTRY_SPECIFIC_DOCS_UPLOADING_REQUEST,
  LEAD_COUNTRY_SPECIFIC_DOCS_UPLOADING_SUCCESS,
  LEAD_CREATE_SUBMIT_ERR,
  LEAD_CREATE_SUBMIT_REQUEST,
  LEAD_CREATE_SUBMIT_SUCCESS,
  LEAD_CREDENTIAL_RESEND_ERR,
  LEAD_CREDENTIAL_RESEND_REQUEST,
  LEAD_CREDENTIAL_RESEND_SUCCESS,
  LEAD_CREDENTIAL_SETPASSWORD_ERR,
  LEAD_CREDENTIAL_SETPASSWORD_REQUEST,
  LEAD_CREDENTIAL_SETPASSWORD_SUCCESS,
  LEAD_DELETE_ERR,
  LEAD_DELETE_REQUEST,
  LEAD_DELETE_SUCCESS,
  LEAD_DOCUMENT_REUPLOADING_ERR,
  LEAD_DOCUMENT_REUPLOADING_REQUEST,
  LEAD_DOCUMENT_REUPLOADING_SUCCESS,
  LEAD_DUPLICATE_APPROVAL_APPROVE_ERR,
  LEAD_DUPLICATE_APPROVAL_APPROVE_REQUEST,
  LEAD_DUPLICATE_APPROVAL_APPROVE_SUCCESS,
  LEAD_DUPLICATE_APPROVAL_FINDONE_ERR,
  LEAD_DUPLICATE_APPROVAL_FINDONE_REQUEST,
  LEAD_DUPLICATE_APPROVAL_FINDONE_SUCCESS,
  LEAD_DUPLICATE_APPROVAL_FIND_ERR,
  LEAD_DUPLICATE_APPROVAL_FIND_REQUEST,
  LEAD_DUPLICATE_APPROVAL_FIND_SUCCESS,
  LEAD_DUPLICATE_APPROVAL_REJECT_ERR,
  LEAD_DUPLICATE_APPROVAL_REJECT_REQUEST,
  LEAD_DUPLICATE_APPROVAL_REJECT_SUCCESS,
  LEAD_EDIT_SUBMIT_ERR,
  LEAD_EDIT_SUBMIT_REQUEST,
  LEAD_EDIT_SUBMIT_SUCCESS,
  LEAD_EDUCATION_DETAILS_DELETE_ERR,
  LEAD_EDUCATION_DETAILS_DELETE_REQUEST,
  LEAD_EDUCATION_DETAILS_DELETE_SUCCESS,
  LEAD_EDUCATION_DETAILS_FIND_ERR,
  LEAD_EDUCATION_DETAILS_FIND_REQUEST,
  LEAD_EDUCATION_DETAILS_FIND_SUCCESS,
  LEAD_EDUCATION_ENGLISH_TEST_DETAILS_DELETE_ERR,
  LEAD_EDUCATION_ENGLISH_TEST_DETAILS_DELETE_REQUEST,
  LEAD_EDUCATION_ENGLISH_TEST_DETAILS_DELETE_SUCCESS,
  LEAD_EMAIL_VERIFIED_ERR,
  LEAD_EMAIL_VERIFIED_REQUEST,
  LEAD_EMAIL_VERIFIED_SUCCESS,
  LEAD_FIND_ERR,
  LEAD_FIND_REQUEST,
  LEAD_FIND_SUCCESS,
  LEAD_MARKETINGNOTE_CREATE_ERR,
  LEAD_MARKETINGNOTE_CREATE_REQUEST,
  LEAD_MARKETINGNOTE_CREATE_SUCCESS,
  LEAD_OTP_VERIFY_ERR,
  LEAD_OTP_VERIFY_REQUEST,
  LEAD_OTP_VERIFY_SUCCESS,
  LEAD_OWNERCHANGE_APPROVAL_REQUEST_APPROVE_ERR,
  LEAD_OWNERCHANGE_APPROVAL_REQUEST_APPROVE_REQUEST,
  LEAD_OWNERCHANGE_APPROVAL_REQUEST_APPROVE_SUCCESS,
  LEAD_OWNERCHANGE_APPROVAL_REQUEST_FIND_ERR,
  LEAD_OWNERCHANGE_APPROVAL_REQUEST_FIND_REQUEST,
  LEAD_OWNERCHANGE_APPROVAL_REQUEST_FIND_SUCCESS,
  LEAD_OWNERCHANGE_APPROVAL_REQUEST_REJECT_ERR,
  LEAD_OWNERCHANGE_APPROVAL_REQUEST_REJECT_REQUEST,
  LEAD_OWNERCHANGE_APPROVAL_REQUEST_REJECT_SUCCESS,
  LEAD_OWNER_CHANGE_ERR,
  LEAD_OWNER_CHANGE_REQUEST,
  LEAD_OWNER_CHANGE_SUCCESS,
  LEAD_PENDING_STATUS_CHANGE_ERR,
  LEAD_PENDING_STATUS_CHANGE_REQUEST,
  LEAD_PENDING_STATUS_CHANGE_SUCCESS,
  LEAD_PERSONAL_DETAILS_ADD_ERR,
  LEAD_PERSONAL_DETAILS_ADD_ERR_MESSAGE,
  LEAD_PERSONAL_DETAILS_ADD_REQUEST,
  LEAD_PERSONAL_DETAILS_ADD_SUCCESS,
  LEAD_PERSONAL_DETAILS_ADD_SUCCESS_MESSAGE,
  LEAD_PERSONAL_DETAILS_SHOW_ERR,
  LEAD_PERSONAL_DETAILS_SHOW_REQUEST,
  LEAD_PERSONAL_DETAILS_SHOW_SUCCESS,
  LEAD_PHONENUMBER_VERIFIED_ERR,
  LEAD_PHONENUMBER_VERIFIED_REQUEST,
  LEAD_PHONENUMBER_VERIFIED_SUCCESS,
  LEAD_REFUND_APPROVAL_ERR,
  LEAD_REFUND_APPROVAL_REQUEST,
  LEAD_REFUND_APPROVAL_SUCCESS,
  LEAD_REFUND_CREATE_ERR,
  LEAD_REFUND_CREATE_REQUEST,
  LEAD_REFUND_CREATE_SUCCESS,
  LEAD_REFUND_FIND_ERR,
  LEAD_REFUND_FIND_REQUEST,
  LEAD_REFUND_FIND_SUCCESS,
  LEAD_REFUND_UPDATE_ERR,
  LEAD_REFUND_UPDATE_REQUEST,
  LEAD_REFUND_UPDATE_SUCCESS,
  LEAD_REGISTRATIONFEE_CREATE_ERR,
  LEAD_REGISTRATIONFEE_CREATE_REQUEST,
  LEAD_REGISTRATIONFEE_CREATE_SUCCESS,
  LEAD_REGISTRATIONFEE_FIND_ERR,
  LEAD_REGISTRATIONFEE_FIND_REQUEST,
  LEAD_REGISTRATIONFEE_FIND_SUCCESS,
  LEAD_RESTART_APPLICATION_ERR,
  LEAD_RESTART_APPLICATION_REQUEST,
  LEAD_RESTART_APPLICATION_SUCCESS,
  LEAD_SOP_PDF_FINDING_ERR,
  LEAD_SOP_PDF_FINDING_REQUEST,
  LEAD_SOP_PDF_FINDING_SUCCESS,
  LEAD_SOP_PDF_STATUS_CHANGE_ERR,
  LEAD_SOP_PDF_STATUS_CHANGE_REQUEST,
  LEAD_SOP_PDF_STATUS_CHANGE_SUCCESS,
  LEAD_SOP_PDF_UPLOAD_ERR,
  LEAD_SOP_PDF_UPLOAD_REQUEST,
  LEAD_SOP_PDF_UPLOAD_SUCCESS,
  LEAD_STATUS_SHOW_ERR,
  LEAD_STATUS_SHOW_REQUEST,
  LEAD_STATUS_SHOW_SUCCESS,
  LEAD_TELECALLERNOTE_CREATE_ERR,
  LEAD_TELECALLERNOTE_CREATE_REQUEST,
  LEAD_TELECALLERNOTE_CREATE_SUCCESS,
  LEAD_TIME_LINE_FIND_ERR,
  LEAD_TIME_LINE_FIND_REQUEST,
  LEAD_TIME_LINE_FIND_SUCCESS,
  LEAD_TIME_LINE_STATUS_ADD_ERR,
  LEAD_TIME_LINE_STATUS_ADD_REQUEST,
  LEAD_TIME_LINE_STATUS_ADD_SUCCESS,
  LEAD_TRAVELIMMIGRATION_PDF_FINDING_ERR,
  LEAD_TRAVELIMMIGRATION_PDF_FINDING_REQUEST,
  LEAD_TRAVELIMMIGRATION_PDF_FINDING_SUCCESS,
  LEAD_TRAVELIMMIGRATION_PDF_UPLOAD_ERR,
  LEAD_TRAVELIMMIGRATION_PDF_UPLOAD_REQUEST,
  LEAD_TRAVELIMMIGRATION_PDF_UPLOAD_SUCCESS,
  LEAD_WORK_DETAILS_ADD_ERR,
  LEAD_WORK_DETAILS_ADD_REQUEST,
  LEAD_WORK_DETAILS_ADD_SUCCESS,
  LEAD_WORK_DETAILS_DELETE_ERR,
  LEAD_WORK_DETAILS_DELETE_REQUEST,
  LEAD_WORK_DETAILS_DELETE_SUCCESS,
  LEAD_WORK_DETAILS_FIND_ERR,
  LEAD_WORK_DETAILS_FIND_REQUEST,
  LEAD_WORK_DETAILS_FIND_SUCCESS,
  PAYMENT_STATUS_UPDATE_SHORTLIST_ERR,
  PAYMENT_STATUS_UPDATE_SHORTLIST_REQUEST,
  PAYMENT_STATUS_UPDATE_SHORTLIST_SUCCESS,
  SHORTLIST_VALIDATION_FIND_ERR,
  SHORTLIST_VALIDATION_FIND_REQUEST,
  SHORTLIST_VALIDATION_FIND_SUCCESS,
  SHORT_LIST_METHOD_APPLY_ERR,
  SHORT_LIST_METHOD_APPLY_REQUEST,
  SHORT_LIST_METHOD_APPLY_SUCCESS,
  SHORT_LIST_METHOD_DELETE_ERR,
  SHORT_LIST_METHOD_DELETE_REQUEST,
  SHORT_LIST_METHOD_DELETE_SUCCESS,
  SHORT_LIST_METHOD_FIND_ERR,
  SHORT_LIST_METHOD_FIND_REQUEST,
  SHORT_LIST_METHOD_FIND_SUCCESS,
  SHORT_LIST_UNIVERSITY_COURSE_FINDING_ERR,
  SHORT_LIST_UNIVERSITY_COURSE_FINDING_REQUEST,
  SHORT_LIST_UNIVERSITY_COURSE_FINDING_SUCCESS
} from "../../constants/leadConstant";

//leadFindReducer
export const leadFindReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_FIND_REQUEST:
      return {
        ...state,
        leadFindLoading: true
      };
    case LEAD_FIND_SUCCESS:
      return {
        ...state,
        leadFindLoading: false,
        leadFindSuccess: action.payload
      };
    case LEAD_FIND_ERR:
      return {
        ...state,
        leadFindLoading: false,
        leadFinderror: action.payload
      };
    default:
      return state;
  }
};

// leadAddReducer
export const leadAddReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_CREATE_SUBMIT_REQUEST:
      return {
        ...state,
        leadAddLoading: true
      };
    case LEAD_CREATE_SUBMIT_SUCCESS:
      return {
        ...state,
        leadAddLoading: false,
        leadAddSuccess: action.payload
      };
    case LEAD_CREATE_SUBMIT_ERR:
      return {
        ...state,
        leadAddLoading: false,
        leadAdderror: action.payload
      };
    default:
      return state;
  }
};

// leadEditReducer
export const leadEditReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_EDIT_SUBMIT_REQUEST:
      return {
        ...state,
        leadEditLoading: true
      };
    case LEAD_EDIT_SUBMIT_SUCCESS:
      return {
        ...state,
        leadEditLoading: false,
        leadEditSuccess: action.payload
      };
    case LEAD_EDIT_SUBMIT_ERR:
      return {
        ...state,
        leadEditLoading: false,
        leadEditError: action.payload
      };
    default:
      return state;
  }
};

// leadDetailFetchReducer
export const leadDetailFetchReducer = (state = {}, action) => {
  switch (action.type) {
    case A_LEAD_DETAIL_FETCH_REQUEST:
      return {
        ...state,
        leadDetailLoading: true
      };
    case A_LEAD_DETAIL_FETCH_SUCCESS:
      return {
        ...state,
        leadDetailLoading: false,
        leadDetailSuccess: action.payload
      };
    case A_LEAD_DETAIL_FETCH_ERR:
      return {
        ...state,
        leadDetailLoading: false,
        leadDetailerror: action.payload
      };
    default:
      return state;
  }
};

// deleteLeadReducer
export const deleteLeadReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_DELETE_REQUEST:
      return {
        ...state,
        leadDeleteLoading: true
      };
    case LEAD_DELETE_SUCCESS:
      return {
        ...state,
        leadDeleteLoading: false,
        leadDeleteSuccess: action.payload
      };
    case LEAD_DELETE_ERR:
      return {
        ...state,
        leadDeleteLoading: false,
        leadDeleteerror: action.payload
      };
    default:
      return state;
  }
};

// leadChangeStatusReducer
export const leadChangeStatusReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_STATUS_SHOW_REQUEST:
      return {
        ...state,
        leadStatusChangeLoading: true
      };
    case LEAD_STATUS_SHOW_SUCCESS:
      return {
        ...state,
        leadStatusChangeLoading: false,
        leadStatusChangeSuccess: action.payload
      };
    case LEAD_STATUS_SHOW_ERR:
      return {
        ...state,
        leadStatusChangeLoading: false,
        leadStatusChangeError: action.payload
      };
    default:
      return state;
  }
};

// leadPersonalDetailsAddReducer
export const leadPersonalDetailsAddReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_PERSONAL_DETAILS_ADD_REQUEST:
      return {
        ...state,
        leadPersonalDetailAddLoading: true
      };
    case LEAD_PERSONAL_DETAILS_ADD_SUCCESS:
      return {
        ...state,
        leadPersonalDetailAddLoading: false,
        leadPersonalDetailAddSuccess: action.payload
      };
    case LEAD_PERSONAL_DETAILS_ADD_SUCCESS_MESSAGE:
      return {
        ...state,
        leadPersonalDetailAddSuccessMsgLoading: false,
        leadPersonalDetailAddSuccessMsgSuccess: action.payload
      };
    case LEAD_PERSONAL_DETAILS_ADD_ERR:
      return {
        ...state,
        leadPersonalDetailAddLoading: false,
        leadPersonalDetailAdderror: action.payload
      };

    case LEAD_PERSONAL_DETAILS_ADD_ERR_MESSAGE:
      return {
        ...state,
        leadPersonalDetailAddErrorMsgLoading: false,
        leadPersonalDetailAddErrorMsgerror: action.payload
      };
    default:
      return state;
  }
};
// leadPersonalDetailsShowReducer
export const leadPersonalDetailsShowReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_PERSONAL_DETAILS_SHOW_REQUEST:
      return {
        ...state,
        leadPersonalDetailFindLoading: true
      };
    case LEAD_PERSONAL_DETAILS_SHOW_SUCCESS:
      return {
        ...state,
        leadPersonalDetailFindLoading: false,
        leadPersonalDetailFindSuccess: action.payload
      };
    case LEAD_PERSONAL_DETAILS_SHOW_ERR:
      return {
        ...state,
        leadPersonalDetailFindLoading: false,
        leadPersonalDetailFinderror: action.payload
      };
    default:
      return state;
  }
};

// deleteEnglishTestReducer
export const deleteEnglishTestReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_EDUCATION_ENGLISH_TEST_DETAILS_DELETE_REQUEST:
      return {
        ...state,
        leadEducationEnglishTestDetailDeleteLoading: true
      };
    case LEAD_EDUCATION_ENGLISH_TEST_DETAILS_DELETE_SUCCESS:
      return {
        ...state,
        leadEducationEnglishTestDetailDeleteLoading: false,
        leadEducationEnglishTestDetailDeleteSuccess: action.payload
      };
    case LEAD_EDUCATION_ENGLISH_TEST_DETAILS_DELETE_ERR:
      return {
        ...state,
        leadEducationEnglishTestDetailDeleteLoading: false,
        leadEducationEnglishTestDetailDeleteerror: action.payload
      };
    default:
      return state;
  }
};

// leadEducationDetailsShowReducer
export const leadEducationDetailsShowReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_EDUCATION_DETAILS_FIND_REQUEST:
      return {
        ...state,
        leadEducationDetailFindLoading: true
      };
    case LEAD_EDUCATION_DETAILS_FIND_SUCCESS:
      return {
        ...state,
        leadEducationDetailFindLoading: false,
        leadEducationDetailFindSuccess: action.payload
      };
    case LEAD_EDUCATION_DETAILS_FIND_ERR:
      return {
        ...state,
        leadEducationDetailFindLoading: false,
        leadEducationDetailFinderror: action.payload
      };
    default:
      return state;
  }
};

// deleteAcademicDetailsReducer
export const deleteAcademicDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_EDUCATION_DETAILS_DELETE_REQUEST:
      return {
        ...state,
        leadEducationDetailDeleteLoading: true
      };
    case LEAD_EDUCATION_DETAILS_DELETE_SUCCESS:
      return {
        ...state,
        leadEducationDetailDeleteLoading: false,
        leadEducationDetailDeleteSuccess: action.payload
      };
    case LEAD_EDUCATION_DETAILS_DELETE_ERR:
      return {
        ...state,
        leadEducationDetailDeleteLoading: false,
        leadEducationDetailDeleteerror: action.payload
      };
    default:
      return state;
  }
};

// leadOwnerChangeReducer
export const leadOwnerChangeReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_OWNER_CHANGE_REQUEST:
      return {
        ...state,
        leadOwnerLoading: true
      };
    case LEAD_OWNER_CHANGE_SUCCESS:
      return {
        ...state,
        leadOwnerLoading: false,
        leadOwnerSuccess: action.payload
      };
    case LEAD_OWNER_CHANGE_ERR:
      return {
        ...state,
        leadOwnerLoading: false,
        leadOwnererror: action.payload
      };
    default:
      return state;
  }
};

// lead sop reducer

// leadSopFindingReducer
export const leadSopFindingReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_SOP_PDF_FINDING_REQUEST:
      return {
        ...state,
        leadSopFindingLoading: true
      };
    case LEAD_SOP_PDF_FINDING_SUCCESS:
      return {
        ...state,
        leadSopFindingLoading: false,
        leadSopFindingSuccess: action.payload
      };
    case LEAD_SOP_PDF_FINDING_ERR:
      return {
        ...state,
        leadSopFindingLoading: false,
        leadSopFindingerror: action.payload
      };
    default:
      return state;
  }
};

// changeSopApplicationStatusReducer
export const changeSopApplicationStatusReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_SOP_PDF_STATUS_CHANGE_REQUEST:
      return {
        ...state,
        leadSopStatusChangeLoading: true
      };
    case LEAD_SOP_PDF_STATUS_CHANGE_SUCCESS:
      return {
        ...state,
        leadSopStatusChangeLoading: false,
        leadSopStatusChangeSuccess: action.payload
      };
    case LEAD_SOP_PDF_STATUS_CHANGE_ERR:
      return {
        ...state,
        leadSopStatusChangeLoading: false,
        leadSopStatusChangeError: action.payload
      };
    default:
      return state;
  }
};

// leadSopUploadingReducer
export const leadSopUploadingReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_SOP_PDF_UPLOAD_REQUEST:
      return {
        ...state,
        leadSopUploadingLoading: true
      };
    case LEAD_SOP_PDF_UPLOAD_SUCCESS:
      return {
        ...state,
        leadSopUploadingLoading: false,
        leadSopUploadingSuccess: action.payload
      };
    case LEAD_SOP_PDF_UPLOAD_ERR:
      return {
        ...state,
        leadSopUploadingLoading: false,
        leadSopUploadingerror: action.payload
      };
    default:
      return state;
  }
};

// LEAD TRAVEL & IMMIGRATION

// leadTravelAndImmigrationUploadingReducer
export const leadTravelAndImmigrationUploadingReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case LEAD_TRAVELIMMIGRATION_PDF_UPLOAD_REQUEST:
      return {
        ...state,
        leadTravelAndImmigrationUploadingLoading: true
      };
    case LEAD_TRAVELIMMIGRATION_PDF_UPLOAD_SUCCESS:
      return {
        ...state,
        leadTravelAndImmigrationUploadingLoading: false,
        leadTravelAndImmigrationUploadingSuccess: action.payload
      };
    case LEAD_TRAVELIMMIGRATION_PDF_UPLOAD_ERR:
      return {
        ...state,
        leadTravelAndImmigrationUploadingLoading: false,
        leadTravelAndImmigrationUploadingerror: action.payload
      };
    default:
      return state;
  }
};

// leadTraveAndImmigrationFindingReducer
export const leadTraveAndImmigrationFindingReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_TRAVELIMMIGRATION_PDF_FINDING_REQUEST:
      return {
        ...state,
        leadTravelAndImmigrationFindingLoading: true
      };
    case LEAD_TRAVELIMMIGRATION_PDF_FINDING_SUCCESS:
      return {
        ...state,
        leadTravelAndImmigrationFindingLoading: false,
        leadTravelAndImmigrationFindingSuccess: action.payload
      };
    case LEAD_TRAVELIMMIGRATION_PDF_FINDING_ERR:
      return {
        ...state,
        leadTravelAndImmigrationFindingLoading: false,
        leadTravelAndImmigrationFindingerror: action.payload
      };
    default:
      return state;
  }
};

// lead work Details
// leadWorkDetailsFindReducer
export const leadWorkDetailsFindReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_WORK_DETAILS_FIND_REQUEST:
      return {
        ...state,
        leadWorkDetailsFindingLoading: true
      };
    case LEAD_WORK_DETAILS_FIND_SUCCESS:
      return {
        ...state,
        leadWorkDetailsFindingLoading: false,
        leadWorkDetailsFindingSuccess: action.payload
      };
    case LEAD_WORK_DETAILS_FIND_ERR:
      return {
        ...state,
        leadWorkDetailsFindingLoading: false,
        leadWorkDetailsFindingerror: action.payload
      };
    default:
      return state;
  }
};

// leadWorkDetailsAddReducer
export const leadWorkDetailsAddReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_WORK_DETAILS_ADD_REQUEST:
      return {
        ...state,
        leadWorkDetailsAddingLoading: true
      };
    case LEAD_WORK_DETAILS_ADD_SUCCESS:
      return {
        ...state,
        leadWorkDetailsAddingLoading: false,
        leadWorkDetailsAddingSuccess: action.payload
      };
    case LEAD_WORK_DETAILS_ADD_ERR:
      return {
        ...state,
        leadWorkDetailsAddingLoading: false,
        leadWorkDetailsAddingerror: action.payload
      };
    default:
      return state;
  }
};

// leadWorkDetailsDeleteReducer
export const leadWorkDetailsDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_WORK_DETAILS_DELETE_REQUEST:
      return {
        ...state,
        leadWorkDetailsDeletingLoading: true
      };
    case LEAD_WORK_DETAILS_DELETE_SUCCESS:
      return {
        ...state,
        leadWorkDetailsDeletingLoading: false,
        leadWorkDetailsDeletingSuccess: action.payload
      };
    case LEAD_WORK_DETAILS_DELETE_ERR:
      return {
        ...state,
        leadWorkDetailsDeletingLoading: false,
        leadWorkDetailsDeletingerror: action.payload
      };
    default:
      return state;
  }
};

// country Admission Docs

// countryAllAdmissionDocsFindReducer
export const countryAllAdmissionDocsFindReducer = (state = {}, action) => {
  switch (action.type) {
    case COUNTRY_ADMISSION_DOCS_FIND_REQUEST:
      return {
        ...state,
        countryAdmissionFindLoading: true
      };
    case COUNTRY_ADMISSION_DOCS_FIND_SUCCESS:
      return {
        ...state,
        countryAdmissionFindLoading: false,
        countryAdmissionFindSuccess: action.payload
      };
    case COUNTRY_ADMISSION_DOCS_FIND_ERR:
      return {
        ...state,
        countryAdmissionFindLoading: false,
        countryAdmissionFinderror: action.payload
      };
    default:
      return state;
  }
};

// leadCountryCommonDocsFindDocsReducer
export const leadCountryCommonDocsFindDocsReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_COUNTRY_COMMON_DOCS_FINDING_REQUEST:
      return {
        ...state,
        leadCountryCommonDocsFindLoading: true
      };
    case LEAD_COUNTRY_COMMON_DOCS_FINDING_SUCCESS:
      return {
        ...state,
        leadCountryCommonDocsFindLoading: false,
        leadCountryCommonDocsFindSuccess: action.payload
      };
    case LEAD_COUNTRY_COMMON_DOCS_FINDING_ERR:
      return {
        ...state,
        leadCountryCommonDocsFindLoading: false,
        leadCountryCommonDocsFinderror: action.payload
      };
    default:
      return state;
  }
};

// leadCountrycommonDocUploadingReducer
export const leadCountrycommonDocUploadingReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_COUNTRY_COMMON_DOCS_UPLOADING_REQUEST:
      return {
        ...state,
        leadCountryCommonDocsUploadingLoading: true
      };
    case LEAD_COUNTRY_COMMON_DOCS_UPLOADING_SUCCESS:
      return {
        ...state,
        leadCountryCommonDocsUploadingLoading: false,
        leadCountryCommonDocsUploadingSuccess: action.payload
      };
    case LEAD_COUNTRY_COMMON_DOCS_UPLOADING_ERR:
      return {
        ...state,
        leadCountryCommonDocsUploadingLoading: false,
        leadCountryCommonDocsUploadingerror: action.payload
      };
    default:
      return state;
  }
};

// leadCountrySpecificDocsFindingDocsReducer
export const leadCountrySpecificDocsFindingDocsReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case LEAD_COUNTRY_SPECIFIC_DOCS_FINDING_REQUEST:
      return {
        ...state,
        leadCountrySpecificDocsFindingLoading: true
      };
    case LEAD_COUNTRY_SPECIFIC_DOCS_FINDING_SUCCESS:
      return {
        ...state,
        leadCountrySpecificDocsFindingLoading: false,
        leadCountrySpecificDocsFindingSuccess: action.payload
      };
    case LEAD_COUNTRY_SPECIFIC_DOCS_FINDING_ERR:
      return {
        ...state,
        leadCountrySpecificDocsFindingLoading: false,
        leadCountrySpecificDocsFindingerror: action.payload
      };
    default:
      return state;
  }
};

// leadCountrySpecificDocUploadingReducer
export const leadCountrySpecificDocUploadingReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_COUNTRY_SPECIFIC_DOCS_UPLOADING_REQUEST:
      return {
        ...state,
        leadCountrySpecificDocsUploadingLoading: true
      };
    case LEAD_COUNTRY_SPECIFIC_DOCS_UPLOADING_SUCCESS:
      return {
        ...state,
        leadCountrySpecificDocsUploadingLoading: false,
        leadCountrySpecificDocsUploadingSuccess: action.payload
      };
    case LEAD_COUNTRY_SPECIFIC_DOCS_UPLOADING_ERR:
      return {
        ...state,
        leadCountrySpecificDocsUploadingLoading: false,
        leadCountrySpecificDocsUploadingerror: action.payload
      };
    default:
      return state;
  }
};

// leadDocument ReUpload Reducer

export const leadDocumentReUploadingReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_DOCUMENT_REUPLOADING_REQUEST:
      return {
        ...state,
        leadDocumentReUploadingLoading: true
      };
    case LEAD_DOCUMENT_REUPLOADING_SUCCESS:
      return {
        ...state,
        leadDocumentReUploadingLoading: false,
        leadDocumentReUploadingSuccess: action.payload
      };
    case LEAD_DOCUMENT_REUPLOADING_ERR:
      return {
        ...state,
        leadDocumentReUploadingLoading: false,
        leadDocumentReUploadingerror: action.payload
      };
    default:
      return state;
  }
};

// short list lead

// universityCourseFindForShortListReducer
export const universityCourseFindForShortListReducer = (state = {}, action) => {
  switch (action.type) {
    case SHORT_LIST_UNIVERSITY_COURSE_FINDING_REQUEST:
      return {
        ...state,
        universityCourseFindLoading: true
      };
    case SHORT_LIST_UNIVERSITY_COURSE_FINDING_SUCCESS:
      return {
        ...state,
        universityCourseFindLoading: false,
        universityCourseFindSuccess: action.payload
      };
    case SHORT_LIST_UNIVERSITY_COURSE_FINDING_ERR:
      return {
        ...state,
        universityCourseFindLoading: false,
        universityCourseFinderror: action.payload
      };
    default:
      return state;
  }
};

// findShortListUniversityCourseReducer
export const findShortListUniversityCourseReducer = (state = {}, action) => {
  switch (action.type) {
    case FIND_SHORT_LIST_UNIVERSITY_COURSE_REQUEST:
      return {
        ...state,
        leadShortListFindLoading: true
      };
    case FIND_SHORT_LIST_UNIVERSITY_COURSE_SUCCESS:
      return {
        ...state,
        leadShortListFindLoading: false,
        leadShortListFindSuccess: action.payload
      };
    case FIND_SHORT_LIST_UNIVERSITY_COURSE_ERR:
      return {
        ...state,
        leadShortListFindLoading: false,
        leadShortListFinderror: action.payload
      };
    default:
      return state;
  }
};

// courseAddShortListReducer
export const courseAddShortListReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_TO_SHORT_LIST_UNIVERSITY_COURSE_REQUEST:
      return {
        ...state,
        leadShortListAddLoading: true
      };
    case ADD_TO_SHORT_LIST_UNIVERSITY_COURSE_SUCCESS:
      return {
        ...state,
        leadShortListAddLoading: false,
        leadShortListAddSuccess: action.payload
      };
    case ADD_TO_SHORT_LIST_UNIVERSITY_COURSE_ERR:
      return {
        ...state,
        leadShortListAddLoading: false,
        leadShortListAdderror: action.payload
      };
    default:
      return state;
  }
};

// shortListDeleteReducer
export const shortListDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case SHORT_LIST_METHOD_DELETE_REQUEST:
      return {
        ...state,
        leadShortListDeleteLoading: true
      };
    case SHORT_LIST_METHOD_DELETE_SUCCESS:
      return {
        ...state,
        leadShortListDeleteLoading: false,
        leadShortListDeleteSuccess: action.payload
      };
    case SHORT_LIST_METHOD_DELETE_ERR:
      return {
        ...state,
        leadShortListDeleteLoading: false,
        leadShortListDeleteerror: action.payload
      };
    default:
      return state;
  }
};

// Short List Method Applied Find

// shortListMethodFindReducer
export const shortListMethodFindReducer = (state = {}, action) => {
  switch (action.type) {
    case SHORT_LIST_METHOD_FIND_REQUEST:
      return {
        ...state,
        leadShortListAppliedFindLoading: true
      };
    case SHORT_LIST_METHOD_FIND_SUCCESS:
      return {
        ...state,
        leadShortListAppliedFindLoading: false,
        leadShortListAppliedFindSuccess: action.payload
      };
    case SHORT_LIST_METHOD_FIND_ERR:
      return {
        ...state,
        leadShortListAppliedFindLoading: false,
        leadShortListAppliedFinderror: action.payload
      };
    default:
      return state;
  }
};

// paymentUpdationInShortListReducer
export const paymentUpdationInShortListReducer = (state = {}, action) => {
  switch (action.type) {
    case PAYMENT_STATUS_UPDATE_SHORTLIST_REQUEST:
      return {
        ...state,
        shortListPaymentStatusLoading: true
      };
    case PAYMENT_STATUS_UPDATE_SHORTLIST_SUCCESS:
      return {
        ...state,
        shortListPaymentStatusLoading: false,
        shortListPaymentStatusSuccess: action.payload
      };
    case PAYMENT_STATUS_UPDATE_SHORTLIST_ERR:
      return {
        ...state,
        shortListPaymentStatusLoading: false,
        shortListPaymentStatuserror: action.payload
      };
    default:
      return state;
  }
};

// shortListMethodApplyReducer
export const shortListMethodApplyReducer = (state = {}, action) => {
  switch (action.type) {
    case SHORT_LIST_METHOD_APPLY_REQUEST:
      return {
        ...state,
        leadShortListApplyAddToAppliedLoading: true
      };
    case SHORT_LIST_METHOD_APPLY_SUCCESS:
      return {
        ...state,
        leadShortListApplyAddToAppliedLoading: false,
        leadShortListApplyAddToAppliedSuccess: action.payload
      };
    case SHORT_LIST_METHOD_APPLY_ERR:
      return {
        ...state,
        leadShortListApplyAddToAppliedLoading: false,
        leadShortListApplyAddToAppliederror: action.payload
      };
    default:
      return state;
  }
};

// verifiedApplicationLeadFindReducer
export const verifiedApplicationLeadFindReducer = (state = {}, action) => {
  switch (action.type) {
    case APPLICATION_VERIFIED_LEAD_FIND_REQUEST:
      return {
        ...state,
        applicationVerifiedLeadsLoading: true
      };
    case APPLICATION_VERIFIED_LEAD_FIND_SUCCESS:
      return {
        ...state,
        applicationVerifiedLeadsLoading: false,
        applicationVerifiedLeadsSuccess: action.payload
      };
    case APPLICATION_VERIFIED_LEAD_FIND_ERR:
      return {
        ...state,
        applicationVerifiedLeadsLoading: false,
        applicationVerifiedLeadsError: action.payload
      };
    default:
      return state;
  }
};

// findTimeLineReducer
export const findTimeLineReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_TIME_LINE_FIND_REQUEST:
      return {
        ...state,
        leadTimeLineFindLoading: true
      };
    case LEAD_TIME_LINE_FIND_SUCCESS:
      return {
        ...state,
        leadTimeLineFindLoading: false,
        leadTimeLineFindSuccess: action.payload
      };
    case LEAD_TIME_LINE_FIND_ERR:
      return {
        ...state,
        leadTimeLineFindLoading: false,
        leadTimeLineFindError: action.payload
      };
    default:
      return state;
  }
};

// leadStatusTimeLineReducer
export const leadStatusTimeLineReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_TIME_LINE_STATUS_ADD_REQUEST:
      return {
        ...state,
        leadTimeLineStatusAddLoading: true
      };
    case LEAD_TIME_LINE_STATUS_ADD_SUCCESS:
      return {
        ...state,
        leadTimeLineStatusAddLoading: false,
        leadTimeLineStatusAddSuccess: action.payload
      };
    case LEAD_TIME_LINE_STATUS_ADD_ERR:
      return {
        ...state,
        leadTimeLineStatusAddLoading: false,
        leadTimeLineStatusAddError: action.payload
      };
    default:
      return state;
  }
};

// LEAD PENDING STATUS CHANGE
export const pendingLeadStatusDocsReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_PENDING_STATUS_CHANGE_REQUEST:
      return {
        ...state,
        leadPendingStatusDocsAddLoading: true
      };
    case LEAD_PENDING_STATUS_CHANGE_SUCCESS:
      return {
        ...state,
        leadPendingStatusDocsAddLoading: false,
        leadPendingStatusDocsAddSuccess: action.payload
      };
    case LEAD_PENDING_STATUS_CHANGE_ERR:
      return {
        ...state,
        leadPendingStatusDocsAddLoading: false,
        findleadPendingStatusDocsAddError: action.payload
      };
    default:
      return state;
  }
};

// findPendingStatusLeadChangeReducer
export const findPendingStatusLeadChangeReducer = (state = {}, action) => {
  switch (action.type) {
    case FIND_LEAD_PENDING_STATUS_CHANGE_REQUEST:
      return {
        ...state,
        findleadPendingStatusDocsAddLoading: true
      };
    case FIND_LEAD_PENDING_STATUS_CHANGE_SUCCESS:
      return {
        ...state,
        findleadPendingStatusDocsAddLoading: false,
        findleadPendingStatusDocsAddSuccess: action.payload
      };
    case FIND_LEAD_PENDING_STATUS_CHANGE_ERR:
      return {
        ...state,
        findleadPendingStatusDocsAddLoading: false,
        findleadPendingStatusDocsAddError: action.payload
      };
    default:
      return state;
  }
};

// findApprovingStatusLeadReducer
export const findApprovingStatusLeadReducer = (state = {}, action) => {
  switch (action.type) {
    case FIND_APPROVING_LEAD_STATUS_REQUEST:
      return {
        ...state,
        findleadApprovingStatusLoading: true
      };
    case FIND_APPROVING_LEAD_STATUS_SUCCESS:
      return {
        ...state,
        findleadApprovingStatusLoading: false,
        findleadApprovingStatusSuccess: action.payload
      };
    case FIND_APPROVING_LEAD_STATUS_ERR:
      return {
        ...state,
        findleadApprovingStatusLoading: false,
        findleadApprovingStatusError: action.payload
      };
    default:
      return state;
  }
};

// leadStatusApprovingChangeReducer
export const leadStatusApprovingChangeReducer = (state = {}, action) => {
  switch (action.type) {
    case CHANGE_APPROVING_LEAD_STATUS_REQUEST:
      return {
        ...state,
        changeleadApprovingStatusLoading: true
      };
    case CHANGE_APPROVING_LEAD_STATUS_SUCCESS:
      return {
        ...state,
        changeleadApprovingStatusLoading: false,
        changeleadApprovingStatusSuccess: action.payload
      };
    case CHANGE_APPROVING_LEAD_STATUS_ERR:
      return {
        ...state,
        changeleadApprovingStatusLoading: false,
        changeleadApprovingStatusError: action.payload
      };
    default:
      return state;
  }
};

// leadRestartApplicationReducer
export const leadRestartApplicationReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_RESTART_APPLICATION_REQUEST:
      return {
        ...state,
        restartApplicationLoading: true
      };
    case LEAD_RESTART_APPLICATION_SUCCESS:
      return {
        ...state,
        restartApplicationLoading: false,
        restartApplicationSuccess: action.payload
      };
    case LEAD_RESTART_APPLICATION_ERR:
      return {
        ...state,
        restartApplicationLoading: false,
        restartApplicationError: action.payload
      };
    default:
      return state;
  }
};

// Lead registration fee create reducer

export const leadRegistrationFeeCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_REGISTRATIONFEE_CREATE_REQUEST:
      return {
        ...state,
        leadRegistrationFeeCreateLoading: true
      };
    case LEAD_REGISTRATIONFEE_CREATE_SUCCESS:
      return {
        ...state,
        leadRegistrationFeeCreateLoading: false,
        leadRegistrationFeeCreateSuccess: action.payload
      };
    case LEAD_REGISTRATIONFEE_CREATE_ERR:
      return {
        ...state,
        leadRegistrationFeeCreateLoading: false,
        leadRegistrationFeeCreateError: action.payload
      };
    default:
      return state;
  }
};

// Lead registration fee find reducer

export const leadRegistrationFeeFindReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_REGISTRATIONFEE_FIND_REQUEST:
      return {
        ...state,
        leadRegistrationFeeFindLoading: true
      };
    case LEAD_REGISTRATIONFEE_FIND_SUCCESS:
      return {
        ...state,
        leadRegistrationFeeFindLoading: false,
        leadRegistrationFeeFindSuccess: action.payload
      };
    case LEAD_REGISTRATIONFEE_FIND_ERR:
      return {
        ...state,
        leadRegistrationFeeFindLoading: false,
        leadRegistrationFeeFindError: action.payload
      };
    default:
      return state;
  }
};

// leadBulkUploadReducer

export const leadBulkUploadReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_BULKUPLOAD_REQUEST:
      return {
        ...state,
        leadBulkUploadLoading: true
      };
    case LEAD_BULKUPLOAD_SUCCESS:
      return {
        ...state,
        leadBulkUploadLoading: false,
        leadBulkUploadSuccess: action.payload
      };
    case LEAD_BULKUPLOAD_ERR:
      return {
        ...state,
        leadBulkUploadLoading: false,
        leadBulkUploadError: action.payload
      };
    default:
      return state;
  }
};

// ShortList valiation find reducer

// leadBulkUploadReducer

export const shortListValidationFindReducer = (state = {}, action) => {
  switch (action.type) {
    case SHORTLIST_VALIDATION_FIND_REQUEST:
      return {
        ...state,
        shortListValidationFindLoading: true
      };
    case SHORTLIST_VALIDATION_FIND_SUCCESS:
      return {
        ...state,
        shortListValidationFindLoading: false,
        shortListValidationFindSuccess: action.payload
      };
    case SHORTLIST_VALIDATION_FIND_ERR:
      return {
        ...state,
        shortListValidationFindLoading: false,
        shortListValidationFindError: action.payload
      };
    default:
      return state;
  }
};

// ::::::::::::::::::::::::::::::::LEAD APPROVAL REDUCER ::::::::::::::::::::::::::::::::::::::::::

// leadApprovalFindReducer

export const leadApprovalRequestFindReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_APPROVAL_REQUEST_FIND_REQUEST:
      return {
        ...state,
        leadApprovalFindLoading: true
      };
    case LEAD_APPROVAL_REQUEST_FIND_SUCCESS:
      return {
        ...state,
        leadApprovalFindLoading: false,
        leadApprovalFindSuccess: action.payload
      };
    case LEAD_APPROVAL_REQUEST_FIND_ERR:
      return {
        ...state,
        leadApprovalFindLoading: false,
        leadApprovalFindError: action.payload
      };
    default:
      return state;
  }
};

// leadApprovalApprove reducer

export const leadApprovalRequestApproveReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_APPROVAL_REQUEST_APPROVE_REQUEST:
      return {
        ...state,
        leadApprovalApproveLoading: true
      };
    case LEAD_APPROVAL_REQUEST_APPROVE_SUCCESS:
      return {
        ...state,
        leadApprovalApproveLoading: false,
        leadApprovalApproveSuccess: action.payload
      };
    case LEAD_APPROVAL_REQUEST_APPROVE_ERR:
      return {
        ...state,
        leadApprovalApproveLoading: false,
        leadApprovalApproveError: action.payload
      };
    default:
      return state;
  }
};

// leadApprovalApprove reducer

export const leadApprovalRequestRejectReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_APPROVAL_REQUEST_REJECT_REQUEST:
      return {
        ...state,
        leadApprovalRejectLoading: true
      };
    case LEAD_APPROVAL_REQUEST_REJECT_SUCCESS:
      return {
        ...state,
        leadApprovalRejectLoading: false,
        leadApprovalRejectSuccess: action.payload
      };
    case LEAD_APPROVAL_REQUEST_REJECT_ERR:
      return {
        ...state,
        leadApprovalRejectLoading: false,
        leadApprovalRejectError: action.payload
      };
    default:
      return state;
  }
};

// ::::::::::::::::::::::::::::::::LEAD OWNERCHANGE APPROVAL REDUCER ::::::::::::::::::::::::::::::::::::::::::

// leadOwnerChangeApprovalFindReducer

export const leadOwnerChangeApprovalFindReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_OWNERCHANGE_APPROVAL_REQUEST_FIND_REQUEST:
      return {
        ...state,
        leadOwnerChangeApprovalFindLoading: true
      };
    case LEAD_OWNERCHANGE_APPROVAL_REQUEST_FIND_SUCCESS:
      return {
        ...state,
        leadOwnerChangeApprovalFindLoading: false,
        leadOwnerChangeApprovalFindSuccess: action.payload
      };
    case LEAD_OWNERCHANGE_APPROVAL_REQUEST_FIND_ERR:
      return {
        ...state,
        leadOwnerChangeApprovalFindLoading: false,
        leadOwnerChangeApprovalFindError: action.payload
      };
    default:
      return state;
  }
};

// leadApprovalApprove reducer

export const leadOwnerChangeApproveReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_OWNERCHANGE_APPROVAL_REQUEST_APPROVE_REQUEST:
      return {
        ...state,
        leadOwnerChangeApprovalApproveLoading: true
      };
    case LEAD_OWNERCHANGE_APPROVAL_REQUEST_APPROVE_SUCCESS:
      return {
        ...state,
        leadOwnerChangeApprovalApproveLoading: false,
        leadOwnerChangeApprovalApproveSuccess: action.payload
      };
    case LEAD_OWNERCHANGE_APPROVAL_REQUEST_APPROVE_ERR:
      return {
        ...state,
        leadOwnerChangeApprovalApproveLoading: false,
        leadOwnerChangeApprovalApproveError: action.payload
      };
    default:
      return state;
  }
};

// leadApprovalApprove reducer

export const leadOwnerChangeRequestRejectReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_OWNERCHANGE_APPROVAL_REQUEST_REJECT_REQUEST:
      return {
        ...state,
        leadOwnerChnageApprovalRejectLoading: true
      };
    case LEAD_OWNERCHANGE_APPROVAL_REQUEST_REJECT_SUCCESS:
      return {
        ...state,
        leadOwnerChnageApprovalRejectLoading: false,
        leadOwnerChnageApprovalRejectSuccess: action.payload
      };
    case LEAD_OWNERCHANGE_APPROVAL_REQUEST_REJECT_ERR:
      return {
        ...state,
        leadOwnerChnageApprovalRejectLoading: false,
        leadOwnerChnageApprovalRejectError: action.payload
      };
    default:
      return state;
  }
};

// LeadResend credential reducer

export const leadResendCredentialsReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_CREDENTIAL_RESEND_REQUEST:
      return {
        ...state,
        leadResendCredentialsLoading: true
      };
    case LEAD_CREDENTIAL_RESEND_SUCCESS:
      return {
        ...state,
        leadResendCredentialsLoading: false,
        leadResendCredentialsSuccess: action.payload
      };
    case LEAD_CREDENTIAL_RESEND_ERR:
      return {
        ...state,
        leadResendCredentialsLoading: false,
        leadResendCredentialsError: action.payload
      };
    default:
      return state;
  }
};

// Lead password set reducer

export const leadPasswordSetReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_CREDENTIAL_SETPASSWORD_REQUEST:
      return {
        ...state,
        leadCredentialSetLoading: true
      };
    case LEAD_CREDENTIAL_SETPASSWORD_SUCCESS:
      return {
        ...state,
        leadCredentialSetLoading: false,
        leadCredentialSetSuccess: action.payload
      };
    case LEAD_CREDENTIAL_SETPASSWORD_ERR:
      return {
        ...state,
        leadCredentialSetLoading: false,
        leadCredentialSetError: action.payload
      };
    default:
      return state;
  }
};

// Lead CounsilorNote create reducer

export const leadCounsilorNoteCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_COUNSILORNOTE_CREATE_REQUEST:
      return {
        ...state,
        leadCounsilorNoteCreateLoading: true
      };
    case LEAD_COUNSILORNOTE_CREATE_SUCCESS:
      return {
        ...state,
        leadCounsilorNoteCreateLoading: false,
        leadCounsilorNoteCreateSuccess: action.payload
      };
    case LEAD_COUNSILORNOTE_CREATE_ERR:
      return {
        ...state,
        leadCounsilorNoteCreateLoading: false,
        leadCounsilorNoteCreateError: action.payload
      };
    default:
      return state;
  }
};

// Lead TelecallerNote create reducer

export const leadTelecallerNoteCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_TELECALLERNOTE_CREATE_REQUEST:
      return {
        ...state,
        leadTelecallerNoteLoading: true
      };
    case LEAD_TELECALLERNOTE_CREATE_SUCCESS:
      return {
        ...state,
        leadTelecallerNoteLoading: false,
        leadTelecallerNoteSuccess: action.payload
      };
    case LEAD_TELECALLERNOTE_CREATE_ERR:
      return {
        ...state,
        leadTelecallerNoteLoading: false,
        leadTelecallerNoteError: action.payload
      };
    default:
      return state;
  }
};



// Lead MarketingNote create reducer

export const leadMarketingNoteCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_MARKETINGNOTE_CREATE_REQUEST:
      return {
        ...state,
        leadMarketingNoteLoading: true
      };
    case LEAD_MARKETINGNOTE_CREATE_SUCCESS:
      return {
        ...state,
        leadMarketingNoteLoading: false,
        leadMarketingNoteSuccess: action.payload
      };
    case LEAD_MARKETINGNOTE_CREATE_ERR:
      return {
        ...state,
        leadMarketingNoteLoading: false,
        leadMarketingNoteError: action.payload
      };
    default:
      return state;
  }
};


// Lead duplicate approval find reducer

export const leadDuplicateApprovalFindReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_DUPLICATE_APPROVAL_FIND_REQUEST:
      return {
        ...state,
        leadDuplicateApprovalFindLoading: true
      };
    case LEAD_DUPLICATE_APPROVAL_FIND_SUCCESS:
      return {
        ...state,
        leadDuplicateApprovalFindLoading: false,
        leadDuplicateApprovalFindSuccess: action.payload
      };
    case LEAD_DUPLICATE_APPROVAL_FIND_ERR:
      return {
        ...state,
        leadDuplicateApprovalFindLoading: false,
        leadDuplicateApprovalFindError: action.payload
      };
    default:
      return state;
  }
};


// Lead duplicate approval findOne reducer

export const leadDuplicateApprovalFindOneReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_DUPLICATE_APPROVAL_FINDONE_REQUEST:
      return {
        ...state,
        leadDuplicateApprovalFindOneLoading: true
      };
    case LEAD_DUPLICATE_APPROVAL_FINDONE_SUCCESS:
      return {
        ...state,
        leadDuplicateApprovalFindOneLoading: false,
        leadDuplicateApprovalFindOneSuccess: action.payload
      };
    case LEAD_DUPLICATE_APPROVAL_FINDONE_ERR:
      return {
        ...state,
        leadDuplicateApprovalFindOneLoading: false,
        leadDuplicateApprovalFindOneError: action.payload
      };
    default:
      return state;
  }
};



// Lead duplicate approval approve reducer

export const leadDuplicateApproveReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_DUPLICATE_APPROVAL_APPROVE_REQUEST:
      return {
        ...state,
        leadDuplicateApproveLoading: true
      };
    case LEAD_DUPLICATE_APPROVAL_APPROVE_SUCCESS:
      return {
        ...state,
        leadDuplicateApproveLoading: false,
        leadDuplicateApproveSuccess: action.payload
      };
    case LEAD_DUPLICATE_APPROVAL_APPROVE_ERR:
      return {
        ...state,
        leadDuplicateApproveLoading: false,
        leadDuplicateApproveError: action.payload
      };
    default:
      return state;
  }
};


// Lead duplicate approval reject reducer

export const leadDuplicateRejectReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_DUPLICATE_APPROVAL_REJECT_REQUEST:
      return {
        ...state,
        leadDuplicateApprovalRejectLoading: true
      };
    case LEAD_DUPLICATE_APPROVAL_REJECT_SUCCESS:
      return {
        ...state,
        leadDuplicateApprovalRejectLoading: false,
        leadDuplicateApprovalRejectSuccess: action.payload
      };
    case LEAD_DUPLICATE_APPROVAL_REJECT_ERR:
      return {
        ...state,
        leadDuplicateApprovalRejectLoading: false,
        leadDuplicateApprovalRejectError: action.payload
      };
    default:
      return state;
  }
};


// Lead refund create reducer

export const leadRefundCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_REFUND_CREATE_REQUEST:
      return {
        ...state,
        leadRefundCreateLoading: true
      };
    case LEAD_REFUND_CREATE_SUCCESS:
      return {
        ...state,
        leadRefundCreateLoading: false,
        leadRefundCreateSuccess: action.payload
      };
    case LEAD_REFUND_CREATE_ERR:
      return {
        ...state,
        leadRefundCreateLoading: false,
        leadRefundCreateError: action.payload
      };
    default:
      return state;
  }
};


// Lead refund find reducer

export const leadRefundFindReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_REFUND_FIND_REQUEST:
      return {
        ...state,
        leadRefundFindLoading: true
      };
    case LEAD_REFUND_FIND_SUCCESS:
      return {
        ...state,
        leadRefundFindLoading: false,
        leadRefundFindSuccess: action.payload
      };
    case LEAD_REFUND_FIND_ERR:
      return {
        ...state,
        leadRefundFindLoading: false,
        leadRefundFindError: action.payload
      };
    default:
      return state;
  }
};


// Lead refund approval reducer

export const leadRefundApprovalReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_REFUND_APPROVAL_REQUEST:
      return {
        ...state,
        leadRefundApprovalLoading: true
      };
    case LEAD_REFUND_APPROVAL_SUCCESS:
      return {
        ...state,
        leadRefundApprovalLoading: false,
        leadRefundApprovalSuccess: action.payload
      };
    case LEAD_REFUND_APPROVAL_ERR:
      return {
        ...state,
        leadRefundApprovalLoading: false,
        leadRefundApprovalError: action.payload
      };
    default:
      return state;
  }
};


// Lead refund update reducer

export const leadRefundUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_REFUND_UPDATE_REQUEST:
      return {
        ...state,
        leadRefundUpdateLoading: true
      };
    case LEAD_REFUND_UPDATE_SUCCESS:
      return {
        ...state,
        leadRefundUpdateLoading: false,
        leadRefundUpdateSuccess: action.payload
      };
    case LEAD_REFUND_UPDATE_ERR:
      return {
        ...state,
        leadRefundUpdateLoading: false,
        leadRefundUpdateError: action.payload
      };
    default:
      return state;
  }
};


// Lead email verified reducer

export const leadEmailVerifiedReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_EMAIL_VERIFIED_REQUEST:
      return {
        ...state,
        leadEmailVerifiedLoading: true
      };
    case LEAD_EMAIL_VERIFIED_SUCCESS:
      return {
        ...state,
        leadEmailVerifiedLoading: false,
        leadEmailVerifiedSuccess: action.payload
      };
    case LEAD_EMAIL_VERIFIED_ERR:
      return {
        ...state,
        leadEmailVerifiedLoading: false,
        leadEmailVerifiedError: action.payload
      };
    default:
      return state;
  }
};


export const leadPhoneNumberVerifiedReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_PHONENUMBER_VERIFIED_REQUEST:
      return {
        ...state,
        leadPhoneNumberVerifiedLoading: true
      };
    case LEAD_PHONENUMBER_VERIFIED_SUCCESS:
      return {
        ...state,
        leadPhoneNumberVerifiedLoading: false,
        leadPhoneNumberVerifiedSuccess: action.payload
      };
    case LEAD_PHONENUMBER_VERIFIED_ERR:
      return {
        ...state,
        leadPhoneNumberVerifiedLoading: false,
        leadPhoneNumberVerifiedError: action.payload
      };
    default:
      return state;
  }
};


export const leadOtpVerifiedReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_OTP_VERIFY_REQUEST:
      return {
        ...state,
        leadOtpVerifyLoading: true
      };
    case LEAD_OTP_VERIFY_SUCCESS:
      return {
        ...state,
        leadOtpVerifyLoading: false,
        leadOtpVerifySuccess: action.payload
      };
    case LEAD_OTP_VERIFY_ERR:
      return {
        ...state,
        leadOtpVerifyLoading: false,
        leadOtpVerifyError: action.payload
      };
    default:
      return state;
  }
};