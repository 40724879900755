import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import { findcountryMasterAddAction } from "../../../../actions/countryMasterAction.js";
import { findStateMasterAction } from "../../../../actions/StateMasterAction.js";
import { findCityMasterAction } from "../../../../actions/cityManagmentAction.js";
import { findMasterbyTypeIDAction } from "../../../../actions/universityActions/universityMasterManagmentAction.js";
import { TypographyText } from "../../../customComponent/Typography.js";
import {
  SubmitButton,
  SwitchButton
} from "../../../customComponent/Buttons.js";
import InputField, {
  SelectInputField
} from "../../../customComponent/InputField.js";
import {
  Autocomplete,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from "@mui/material";
import { universityViewAction } from "../../../../actions/universityActions/universityManagmentAction.js";
import { useParams } from "react-router-dom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { locationFindAction } from "../../../../actions/locationAction.js";
import { branchManagerFetchAction } from "../../../../actions/employeeManagmentAction.js";

const ranks = Array.from({ length: 10 }, (_, i) => i + 1);

function EditbasicInformation({ handleComplete }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [universityName, setUniversityName] = useState("");
  const [country, setCountry] = useState([]);
  const [city, setCity] = useState([]);
  const [state, setState] = useState([]);
  const [rank, setRank] = useState("");
  const [establishedYear, setEstablishedYear] = useState(dayjs());
  const [website, setWebsite] = useState("");

  const [internationalStudent, setInternationalStudent] = useState("");
  const [internationalFee, setInternationalFee] = useState("");

  const [universityNameError, setUniversityNameError] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [stateError, setStateError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [rankError, setRankError] = useState(false);
  const [affiliationError, setAffiliationError] = useState(false);
  const [facilitiesError, setFacilitiesError] = useState(false);
  const [typesError, setTypesError] = useState(false);

  const [establishedYearError, setEstablishedYearError] = useState(false);
  const [internationalStudentError, setInternationalStudentError] =
    useState(false);
  const [internationalFeeError, setInternationalFeeError] = useState(false);

  const [countries, setCountries] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [ranksData, setRanksData] = useState([]);
  const [affiliationsData, setAffiliationsData] = useState([]);
  const [facilitiesData, setFacilitiesData] = useState([]);
  const [typesData, setTypesData] = useState([]);

  const [ranking, setRanking] = useState([]);
  const [affiliation, setAffiliation] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [applicationFee, setApplicationFee] = useState("");
  const [types, setTypes] = useState('');

  const [isTopUniversity, setIsTopUniversity] = useState(false);

  const [location, setLocation] = useState("");
  const [locationData, setLocationData] = useState([]);
  const [universityHandler, setUniversityHandler] = useState("");
  const [universityHandlerData, setUniversityHandlerData] = useState([]);

  // selectors
  let { countryFindLoading, countryFindSuccess, countryFindErr } = useSelector(
    (state) => {
      return state.findcountryMasterAdd;
    }
  );
  // state selector
  const { stateFindLoading, stateFindSuccess, stateFindErr } = useSelector(
    (state) => {
      return state.findStateMaster;
    }
  );

  let { cityFindLoading, cityFindSuccess, cityFindErr } = useSelector(
    (state) => {
      return state.findCityMaster;
    }
  );

  let { locationFindLoading, locationFindSuccess } = useSelector((state) => {
    return state.LocationFind;
  });

  let { branchManagerLoading, branchManagerSuccess, branchManagerError } =
    useSelector((state) => {
      return state.branchManagerFetch;
    });

  let {
    universityMasterFindByIdLoading,
    universityMasterFindByIdSuccess,
    universityMasterFindByIdErr
  } = useSelector((state) => {
    return state.findMasterbyTypeID;
  });

  const {
    universityViewByIdLoading,
    universityViewByIdSuccess,
    universityViewByIdErr
  } = useSelector((state) => {
    return state.universityView;
  });

  useEffect(() => {
    dispatch(findCityMasterAction());
    dispatch(locationFindAction());
    dispatch(branchManagerFetchAction("", "university handler"));
  }, [dispatch]);

  useEffect(() => {
    dispatch(findStateMasterAction());
  }, [dispatch]);

  useEffect(() => {
    if (countryFindSuccess) {
      setCountries(countryFindSuccess);
    }
  }, [countryFindSuccess]);

  useEffect(() => {
    dispatch(findcountryMasterAddAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(universityViewAction(id));
  }, [dispatch]);
  let storedUniversityNameColumn;
  let storedCountryNameColumn;
  let storedCityColumn;
  let storedStateColumn;
  let storedLocationColumn;
  let storedRankColumn;
  let storedRankingColumn;
  let storedAffiliationColumn;
  let storedFacilitiesColumn;
  let storedTypesColumn;
  let storedEstablishedYearColumn;
  let storedWebsiteColumn;
  let storedUniversityHandlerColumn;

  useEffect(() => {
    // Retrieve column visibility state from localStorage

    storedUniversityNameColumn = localStorage.getItem("edituniversityName");
    storedCountryNameColumn = localStorage.getItem("editcountry");
    storedCityColumn = localStorage.getItem("editcity");
    storedStateColumn = localStorage.getItem("editstate");
    storedLocationColumn = localStorage.getItem("location");
    storedRankColumn = localStorage.getItem("editrank");
    storedRankingColumn = localStorage.getItem("editranking");
    storedAffiliationColumn = localStorage.getItem("editaffiliation");
    storedFacilitiesColumn = localStorage.getItem("editfacilities");
    storedTypesColumn = localStorage.getItem("edittypes");
    storedEstablishedYearColumn = localStorage.getItem("editestablishedYear");
    storedWebsiteColumn = localStorage.getItem("editwebsite");
    storedUniversityHandlerColumn = localStorage.getItem("universityHandler");

    if (storedUniversityNameColumn !== null) {
      setUniversityName(JSON.parse(storedUniversityNameColumn));
    }
    if (storedCountryNameColumn !== null) {
      let country = JSON.parse(storedCountryNameColumn);
      setCountry(country.countryName);
    }
    if (storedCityColumn !== null) {
      let city = JSON.parse(storedCityColumn);
      setCity(city?.cityName);
    }

    if (storedStateColumn !== null) {
      let state = JSON.parse(storedStateColumn);
      setState(state?.stateName);
    }

    if (storedLocationColumn !== null) {
      let location = JSON.parse(storedLocationColumn);
      setLocation(location);
    }

    if (storedRankColumn !== null) {
      setRank(JSON.parse(storedRankColumn));
    }

    if (storedRankingColumn !== null) {
      setRanking(JSON.parse(storedRankingColumn));
    }

    if (storedAffiliationColumn !== null) {
      setAffiliation(JSON.parse(storedAffiliationColumn));
    }

    if (storedFacilitiesColumn !== null) {
      setFacilities(JSON.parse(storedFacilitiesColumn));
    }

    if (storedTypesColumn !== null) {
      setTypes(JSON.parse(storedTypesColumn));
    }

    if (storedEstablishedYearColumn !== null) {
      setEstablishedYear(storedEstablishedYearColumn);
    }

    if (storedWebsiteColumn !== null) {
      setWebsite(JSON.parse(storedWebsiteColumn));
    }
  }, []);

  useEffect(() => {
    if (branchManagerSuccess) {
      setUniversityHandlerData(branchManagerSuccess);
    }
  }, [branchManagerSuccess]);

  useEffect(() => {
    if (universityViewByIdSuccess) {
      const basicInfo = universityViewByIdSuccess.basicInfo;

      console.log(
        universityViewByIdSuccess,
        "universityViewByIdSuccessuniversityViewByIdSuccessuniversityViewByIdSuccess"
      );

      if (basicInfo) {
        setUniversityName(basicInfo.universityName);
        const country = countryFindSuccess?.find(
          (value) => value.countryName === basicInfo?.universityCountry
        );
        setLocationData(
          locationFindSuccess?.data?.filter(
            (item) => item?.countryName == country?.countryName
          )
        );
        console.log(country, "coutryrrrrrrrrrrrrrrrrrrrr");

        const stateMap = stateFindSuccess?.filter(
          (data) => data?.countryCode === country?.countryCode
        );

        console.log(stateMap, "stateMapstateMapstateMapstateMap");

        const state = stateFindSuccess?.find(
          (value) => value.stateName == basicInfo?.universityState
        );

        const cityMap = cityFindSuccess?.filter(
          (data) => data.stateId == state?.id
        );

        const city = cityFindSuccess?.find(
          (value) => value.cityName == basicInfo?.universityCity
        );

        const Ranking = universityMasterFindByIdSuccess?.filter(
          (value) => value?.universityMasterName == basicInfo.rank
        );

        const establishedYear = basicInfo?.universityEstablishedYear || 0;
        let date = new Date();
        const EstablishedDate = new Date();
        date.setFullYear(establishedYear);
        date.setMonth(0);
        date.setDate(1);
        date.setHours(0, 0, 0, 0);
        const isoString = date.toISOString();
        console.log(isoString, "isoStringisoStringisoString");
        setEstablishedYear(dayjs(isoString));
        setCityData(cityMap);
        setStateData(stateMap);
        setState(state);
        setLocation(basicInfo?.universityLocation);
        setUniversityHandler(basicInfo?.universityHandler?.replace(/"/g, ""));
        setCountry(country);
        setCity(city);
        setWebsite(basicInfo.websiteLink);
        setRank(basicInfo?.rank);
        setApplicationFee(basicInfo?.applicationFee);
        setTypes(basicInfo?.universityType)
        setIsTopUniversity(basicInfo?.isTopUniversity);
        // setRanking([basicInfo.rank]);
        // setAffiliation(basicInfo.rank);
      }
    }
  }, [universityViewByIdSuccess]);

  console.log(location, "locationlocationlocation");

  console.log(locationData, "locationDatalocationData");
  console.log(universityHandler, "handlerhandlerhandler");

  console.log(
    branchManagerSuccess,
    "branchManagerSuccessbranchManagerSuccessbranchManagerSuccess"
  );

  useEffect(() => {
    if (universityMasterFindByIdSuccess) {
      // let basicInfo = universityViewByIdSuccess?.basicInfo;

      if (universityMasterFindByIdSuccess[0]?.universityMasterTypeId === 3) {
        setRanksData(universityMasterFindByIdSuccess);

        let data = universityMasterFindByIdSuccess.map((item) => ({
          id: item.id,
          universityMasterName: item.universityMasterName
        }));
        const value = data
          ?.filter((value) => {
            return universityViewByIdSuccess?.universityRanking.some(
              (item) => item.Rankname === value.universityMasterName
            );
          })
          .map((filteredItem) => {
            return {
              id: filteredItem.id,
              universityMasterName: filteredItem.universityMasterName
            };
          });
        setRanking(value);
      }
      if (universityMasterFindByIdSuccess[0]?.universityMasterTypeId === 2) {
        setAffiliationsData(universityMasterFindByIdSuccess);
        let data = universityMasterFindByIdSuccess.map((item) => ({
          id: item.id,
          universityMasterName: item.universityMasterName
        }));
        console.log(data, "datadatadatadata");
        const value = data
          ?.filter((value) => {
            return universityViewByIdSuccess?.universityAffiliation.some(
              (item) => item.affiliationName === value.universityMasterName
            );
          })
          .map((filteredItem) => {
            return {
              id: filteredItem.id,
              universityMasterName: filteredItem.universityMasterName
            };
          });

        console.log(value, "valuevaluevalue");

        setAffiliation(value);
      }

      if (universityMasterFindByIdSuccess[0]?.universityMasterTypeId === 4) {
        setFacilitiesData(universityMasterFindByIdSuccess);

        let data = universityMasterFindByIdSuccess.map((item) => ({
          id: item.id,
          universityMasterName: item.universityMasterName
        }));
        const value = data
          ?.filter((value) => {
            return universityViewByIdSuccess?.universityFacilities.some(
              (item) => item.facilitiesName === value.universityMasterName
            );
          })
          .map((filteredItem) => {
            return {
              id: filteredItem.id,
              universityMasterName: filteredItem.universityMasterName
            };
          });

        setFacilities(value);
      }

      if (universityMasterFindByIdSuccess[0]?.universityMasterTypeId === 1) {
        setTypesData(["Indirect","Direct"]);

        // let data = universityMasterFindByIdSuccess.map((item) => ({
        //   id: item.id,
        //   universityMasterName: item.universityMasterName
        // }));
        // const value = data
        //   ?.filter((value) => {
        //     return universityViewByIdSuccess?.universitytype.some(
        //       (item) => item.typesName === value.universityMasterName
        //     );
        //   })
        //   .map((filteredItem) => {
        //     return {
        //       id: filteredItem.id,
        //       universityMasterName: filteredItem.universityMasterName
        //     };
        //   });

        // setTypes(value);
      }
    }
  }, [universityMasterFindByIdSuccess, universityViewByIdSuccess]);

  useEffect(() => {
    dispatch(findMasterbyTypeIDAction(3));
  }, [dispatch]);

  useEffect(() => {
    dispatch(findMasterbyTypeIDAction(2));
  }, [dispatch]);

  useEffect(() => {
    dispatch(findMasterbyTypeIDAction(4));
  }, [dispatch]);

  useEffect(() => {
    dispatch(findMasterbyTypeIDAction(1));
  }, [dispatch]);

  useEffect(() => {
    // Retrieve column visibility state from localStorage

    const storedUniversityNameColumn =
      localStorage.getItem("edituniversityName");
    const storedCountryNameColumn = localStorage.getItem("editcountry");
    const storedCityColumn = localStorage.getItem("editcity");
    const storedStateColumn = localStorage.getItem("editstate");
    const storedLocationColumn = localStorage.getItem("location");

    const storedRankColumn = localStorage.getItem("editrank");
    const storedRankingColumn = localStorage.getItem("editranking");
    const storedAffiliationColumn = localStorage.getItem("editaffiliation");
    const storedFacilitiesColumn = localStorage.getItem("editfacilities");
    const storedTypesColumn = localStorage.getItem("edittypes");

    const storedEstablishedYearColumn = localStorage.getItem(
      "editestablishedYear"
    );
    const storedWebsiteColumn = localStorage.getItem("editwebsite");

    // const storedInternationalStudentColumn = localStorage.getItem(
    //   "internationalStudent"
    // );

    // const storedInternationalFeeColumn =
    //   localStorage.getItem("internationalFee");

    if (storedUniversityNameColumn !== null) {
      setUniversityName(JSON.parse(storedUniversityNameColumn));
    }
    if (storedCountryNameColumn !== null) {
      let country = JSON.parse(storedCountryNameColumn);
      setCountry(country.countryName);
    }
    if (storedCityColumn !== null) {
      let city = JSON.parse(storedCityColumn);
      setCity(city?.cityName);
    }

    if (storedStateColumn !== null) {
      let state = JSON.parse(storedStateColumn);
      setState(state?.stateName);
    }

    if (storedLocationColumn !== null) {
      let state = JSON.parse(storedLocationColumn);
      setState(state);
    }

    if (storedRankColumn !== null) {
      setRank(JSON.parse(storedRankColumn));
    }

    if (storedRankingColumn !== null) {
      setRanking(JSON.parse(storedRankingColumn));
    }

    if (storedAffiliationColumn !== null) {
      setAffiliation(JSON.parse(storedAffiliationColumn));
    }

    if (storedFacilitiesColumn !== null) {
      setFacilities(JSON.parse(storedFacilitiesColumn));
    }

    if (storedTypesColumn !== null) {
      setTypes(JSON.parse(storedTypesColumn));
    }

    if (storedEstablishedYearColumn !== null) {
      setEstablishedYear(storedEstablishedYearColumn);
    }

    if (storedWebsiteColumn !== null) {
      setWebsite(JSON.parse(storedWebsiteColumn));
    }

    // if (storedInternationalFeeColumn !== null) {
    //   setInternationalFee(JSON.parse(storedInternationalFeeColumn));
    // }
  }, []);

  const handleUniversityNameChange = (event) => {
    setUniversityName(event.target.value);
    setUniversityNameError(false);
  };

  const handleCountryChange = (event) => {
    let value = event.target.value;

    console.log(value, "slkdjflakjdflakjdfl;kdjf;lksdjlkh");

    if (value) {
      setCountry(value);
      if (stateFindSuccess) {
        setStateData(
          stateFindSuccess.filter(
            (data) => data?.countryCode === value.countryCode
          )
        );
      }

      if (locationFindSuccess) {
        setLocationData(
          locationFindSuccess?.data?.filter(
            (item) => item?.countryName == value?.countryName
          )
        );
      }

      setCountryError(false);
    }
  };

  const handleCityChange = (event) => {
    let value = event.target.value;
    if (value) {
      setCity(value);
      setStateError(false);
    }
  };
  const handleStateChange = (event) => {
    let value = event.target.value;
    if (value) {
      console.log(value, "jkfhsakdfhkasj");
      setState(value);

      setCityData(cityFindSuccess.filter((data) => data.stateId == value.id));
      setStateError(false);
    }
  };

  const handleRankChange = (event) => {
    const {
      target: { value }
    } = event;

    console.log(value, "dcdacdacsdcsd");

    const filteredArray = value.filter((obj) =>
      obj.hasOwnProperty("universityMasterName")
    );

    setRanking((prevRanking) => {
      const updatedRanking = [...prevRanking];

      filteredArray.forEach((selectedItem) => {
        const existingIndex = updatedRanking.findIndex(
          (item) => item.id === selectedItem.id
        );

        if (existingIndex !== -1) {
          // Item already exists in the ranking, remove it
          updatedRanking.splice(existingIndex, 1);
        } else {
          // Item doesn't exist in the ranking, add it
          updatedRanking.push({
            name: selectedItem.universityMasterName,
            id: selectedItem.id
          });
        }
      });

      return updatedRanking;
    });

    setRankError(false);
  };

  const handleAffiliationChange = (event) => {
    setAffiliation(event);
    setAffiliationError(false);
  };

  const handleFacilitieChange = (event) => {
    setFacilities(event);
    setFacilitiesError(false);
  };

  //
  // const handleTypesChange = (event) => {
  //   setTypes(event);
  //   setTypesError(false);
  // };
  const handleEstablishedYearChange = (event) => {
    setEstablishedYear(event);
    setEstablishedYearError(false);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    // Basic form validation
    let isValid = true;

    if (universityName.trim() === "") {
      setUniversityNameError(true);
      isValid = false;
    }

    if (country === null) {
      setCountryError(true);
      isValid = false;
    }

    // if (state === null) {
    //   setStateError(true);
    //   isValid = false;
    // }

    if (affiliation.length === 0) {
      setAffiliationError(true);
      isValid = false;
    }

    if (establishedYear === null) {
      setEstablishedYearError(true);
      isValid = false;
    }

    if (isValid) {
      // Call the handleComplete function or perform any desired action
      localStorage.setItem(
        "edituniversityName",
        JSON.stringify(universityName)
      );
      localStorage.setItem("editcountry", JSON.stringify(country || null));
      localStorage.setItem("editstate", JSON.stringify(state || null));
      localStorage.setItem("editcity", JSON.stringify(city || null));
      localStorage.setItem("location", JSON.stringify(location || null));

      localStorage.setItem("editranking", JSON.stringify(ranking));
      localStorage.setItem("editrank", JSON.stringify(rank || null));
      localStorage.setItem(
        "editaffiliation",
        JSON.stringify(affiliation || null)
      );
      localStorage.setItem(
        "editfacilities",
        JSON.stringify(facilities || null)
      );
      localStorage.setItem("edittypes", JSON.stringify(types || null));
      localStorage.setItem(
        "editestablishedYear",
        JSON.stringify(establishedYear || null)
      );
      localStorage.setItem(
        "editinternationalStudent",
        JSON.stringify(internationalStudent || null)
      );
      localStorage.setItem(
        "editinternationalFee",
        JSON.stringify(internationalFee || null)
      );
      localStorage.setItem(
        "editisTopUniversity",
        JSON.stringify(isTopUniversity || false)
      );
      localStorage.setItem(
        "editapplicationFee",
        JSON.stringify(applicationFee || null)
      );

      localStorage.setItem("editwebsite", JSON.stringify(website || null));
      localStorage.setItem(
        "universityHandler",
        JSON.stringify(universityHandler || null)
      );
      handleComplete("completed");
    }
  };

  console.log(establishedYear, "establishedYearestablishedYear");
  console.log(locationFindSuccess, "locationFindSuccesslocationFindSuccess");

  return (
    <>
      <TypographyText title="Basic Information" />

      {/* <Box sx={{ display: "flex", mt: 2 }}> */}
      <form onSubmit={handleFormSubmit} style={{ marginTop: "20px" }}>
        <Grid container spacing={2}>
          {/* First Column */}
          <Grid item xs={12} md={6} lg={4}>
            <InputField
              label="University Name"
              handleChange={handleUniversityNameChange}
              widthSize="97%"
              value={universityName}
              isRequired={true}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            {country && (
              <SelectInputField
                label="Country Name"
                handleSelectChange={handleCountryChange}
                widthSize="99px"
                value={country}
                mapValue={countries}
                mapMethod="countryName"
                required={true}
              />
            )}
          </Grid>
          {/* <Grid item xs={12} md={6} lg={4}>
            <SelectInputField
              label="State"
              handleSelectChange={handleStateChange}
              widthSize="40px"
              value={state}
              mapValue={stateData}
              mapMethod="universityState"
              // required={true}
            />
          </Grid> */}

          {/* Second Column */}
          {/* <Grid item xs={12} md={6} lg={4}> */}
          {/* <Autocomplete
              options={cityData}
              getOptionLabel={(option) => option.cityName}
              value={city}
              onChange={handleCityChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="City"
                  fullWidth
                  required
                  error={cityError}
                  helperText={cityError ? "City is required" : ""}
                  sx={{
                    "& .MuiInputBase-input": {
                      borderRadius: "20px",
                      height: "15px",
                    },
                    "& .MuiFormLabel-root": {
                      lineHeight: "14px",
                      fontWeight: 400,
                      color: "black",
                      fontSize: "14px",
                      width: "108px",
                      // marginBottom: "15px",
                      // position: "absolute",
                      // top: "-5px",
                      left: "0px",
                      pointerEvents: "none",
                      background: "white",
                      zIndex: 1,
                      "&.Mui-focused": {
                        color: "#7d7d7d",
                      },
                    },
                    "& .MuiInputBase-root": {
                      borderWidth: "1px",
                      borderRadius: "4px",
                      borderColor: "#fcfcfc",
                      border: "1px solid #fcfcfc",
                      "&:hover": {
                        borderColor: "#fcfcfc",
                      },
                      "&.Mui-focused": {
                        borderColor: "#fcfcfc",
                      },
                    },
                  }}
                />
              )}
            /> */}

          {/* <SelectInputField
              label="City"
              handleSelectChange={handleCityChange}
              widthSize="30px"
              value={city}
              mapValue={cityData}
              mapMethod="universityCity"
            />
          </Grid> */}

          <Grid item xs={12} md={6} lg={4}>
            <SelectInputField
              label="Location"
              handleSelectChange={(e) => setLocation(e.target.value)}
              widthSize="30px"
              value={location}
              mapValue={locationData}
              mapMethod="universityLocation"
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <InputField
              label="Website"
              handleChange={(e) => setWebsite(e.target.value)}
              widthSize="97%"
              value={website}
              isRequired={true}
            />
          </Grid>

          {/* <Grid item xs={12} md={6} lg={4}>
            <SelectInputField
              label="Ranking"
              handleSelectChange={handleRankChange}
              widthSize="59px"
              value={ranking}
              mapValue={ranksData}
              mapMethod="universityRanking"
              multiple="true"
            />
            {rankError ? (
              <p style={{ color: "red", margin: 0, padding: 0 }}>
                Please Select Rank
              </p>
            ) : null}
          </Grid> */}

          <Grid item xs={12} md={6} lg={4}>
            <SelectInputField
              label="Affiliation"
              handleSelectChange={handleAffiliationChange}
              widthSize="59px"
              value={affiliation}
              mapValue={affiliationsData}
              mapMethod="universityAffiliation"
              multiple="true"
            />
            {affiliationError ? (
              <p style={{ color: "red", margin: 0, padding: 0 }}>
                Please Select Affiliation
              </p>
            ) : null}
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            {/* <FormControl fullWidth>
              <InputLabel
                id="demo-multiple-chip-label"
                sx={{
                  width: "288px",
                  fontWeight: 400,
                  color: "black",
                  fontSize: "14px",
                }}
              >
                Facilities
              </InputLabel>
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={facilities}
                onChange={handleFacilitieChange}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 0.3,
                      width: "auto",
                      height: "auto",
                      mt: 1,
                    }}
                  >
                    {selected?.map((value) => (
                      <Chip key={value.id} label={value.name} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
                fullWidth
                sx={{
                  height: "46px", // Adjust the height to reduce the overall height of the Select component
                  "& .MuiInputBase-input": {
                    borderRadius: "20px",
                    height: "100%", // Set the input height to 100% to fill the container height
                  },
                  "& .MuiInputBase-root": {
                    borderWidth: "1px",
                    borderRadius: "4px",
                    borderColor: "#fcfcfc",
                    border: "1px solid #fcfcfc",
                    "&:hover": {
                      borderColor: "#fcfcfc", // Set the desired border color for hover state
                    },
                    "&.Mui-focused": {
                      borderColor: "#fcfcfc", // Set the desired border color for focused state
                    },
                  },
                }}
              >
                {facilitiesData.map((name) => (
                  <MenuItem
                    key={name.id}
                    value={{
                      id: name.id,
                      universityMasterName: name.universityMasterName,
                    }}
                    // style={getStyles(name, ranking, theme)}
                  >
                    {name.universityMasterName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}

            <SelectInputField
              label="Facilities"
              handleSelectChange={handleFacilitieChange}
              widthSize="58.9px"
              value={facilities}
              mapValue={facilitiesData}
              mapMethod="universityFacilities"
              multiple="true"
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <SelectInputField
              label="University Type"
              handleSelectChange={(e)=>setTypes(e.target.value)}
              widthSize="83px"
              value={types}
              mapValue={typesData}
              mapMethod="universitieType"
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            {/* <InputField
              label="Established Year"
              handleChange={handleEstablishedYearChange}
              widthSize="97%"
              value={establishedYear}
              InputType="date"
              required={true}
            />

            {establishedYearError ? (
              <p style={{ color: "red", margin: 0, padding: 0 }}>
                Please Select Established Year
              </p>
            ) : null} */}

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Established Year *"
                views={["year"]}
                required
                onChange={handleEstablishedYearChange}
                value={establishedYear}
                sx={{
                  width: "100%",
                  "& .MuiInputBase-input": { height: "13px" },
                  "& .MuiInputLabel-root": {
                    color: "rgb(5, 5, 5)",
                    fontSize: "14px",
                    marginRight: "0px",
                    paddingRight: "0px"
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>

          {/* <Grid item xs={12} md={6} lg={4} sx={{ mt: 2 }}>
              <TextField
                label="International Student"
                value={internationalStudent}
                onChange={handleInternationalStudentChange}
                fullWidth
                required
                error={internationalStudentError}
                helperText={
                  internationalStudentError
                    ? "International Student is required"
                    : ""
                }
                sx={{
                  "& .MuiInputBase-input": {
                    borderRadius: "20px",
                    height: "17px", // Set the desired height for the input element
                  },
                  "& .MuiFormLabel-root": {
                    lineHeight: "17px",
                  },

                  "& .MuiInputBase-root": {
                    borderRadius: "4px",
                    borderColor: "#fafafa",
                  },
                }}
              />
            </Grid> */}

          {/* Third Column */}
          {/* <Grid item xs={12} md={6} lg={4} sx={{ mt: 2 }}>
              <TextField
                label="International Fee"
                value={internationalFee}
                onChange={handleInternationalFeeChange}
                fullWidth
                required
                error={internationalFeeError}
                helperText={
                  internationalFeeError ? "International Fee is required" : ""
                }
                sx={{
                  "& .MuiInputBase-input": {
                    borderRadius: "20px",
                    height: "17px", // Set the desired height for the input element
                  },
                  "& .MuiFormLabel-root": {
                    lineHeight: "17px",
                  },

                  "& .MuiInputBase-root": {
                    borderRadius: "4px",
                    borderColor: "#fafafa",
                  },
                }}
              />
            </Grid> */}

          <Grid item xs={12} md={6} lg={4}>
            {/* <TextField
                label="Rank"
                select
                value={rank}
                onChange={(e) => setRank(e.target.value)}
                fullWidth
                required
                error={rankError}
                helperText={rankError ? "Rank is required" : ""}
              >
                {ranks.map((rank, index) => (
                  <MenuItem key={index} value={rank}>
                    {rank}
                  </MenuItem>
                ))}
              </TextField> */}

            {/* <SelectInputField
              label="Rank"
              handleSelectChange={(e) => setRank(e.target.value)}
              widthSize="39px"
              value={rank}
              mapValue={ranks}
              mapMethod="universityRank"
            /> */}

            <InputField
              label="Rank"
              handleChange={(e) => setRank(e.target.value)}
              widthSize="97%"
              value={rank}
              InputType="number"
              isRequired={false}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <InputField
              label="Application fee"
              handleChange={(e) => setApplicationFee(e.target.value)}
              widthSize="97%"
              value={applicationFee}
              isRequired={false}
              InputType="number"
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <SelectInputField
              label="University handler"
              handleSelectChange={(e) => setUniversityHandler(e.target.value)}
              widthSize="99px"
              value={universityHandler}
              mapValue={universityHandlerData}
              mapMethod="UniversityHandler"
            />
          </Grid>
{/* 
          <Grid item xs={12} md={6} lg={4}>
            <Autocomplete
              onChange={(event, newValue) => {
                console.log("Selected option:", newValue);
                setUniversityHandler(newValue?.id || "");
              }}
              value={
                branchManagerSuccess?.find(
                  (option) => option.id === universityHandler
                ) || null
              }
              disablePortal
              options={branchManagerSuccess || []}
              getOptionLabel={(option) => option?.firstName || ""}
              isOptionEqualToValue={(option, value) => option.id === value?.id}
              sx={{ width: "100%", height: "46px" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="University handler"
                  InputProps={{
                    ...params.InputProps,
                    sx: { height: "46px" }
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: "14px",
                      color: "black",
                      "&.Mui-focused": {
                        color: "black"
                      }
                    }
                  }}
                />
              )}
            />
          </Grid> */}

          <Grid item lg={12} xs={12}>
            <Typography variant="h6" sx={{ mt: 0.5, fontWeight: "bold" }}>
              Top university
            </Typography>
            <Box sx={{ ml: -9, mt: 2 }}>
              <SwitchButton
                // label="Video"
                handleChange={() => {
                  setIsTopUniversity(!isTopUniversity);
                }}
                checked={isTopUniversity}
              />
            </Box>
          </Grid>
        </Grid>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {/* <DoubleBoutton buttonName2="Next" /> */}

          <SubmitButton
            title="Next"
            submit="submit"
            widthSize="87px"
            heightSize="32px"
            type="submit"
            handleSubmit=""
          />
        </div>
      </form>
      {/* </Box> */}
    </>
  );
}

export default EditbasicInformation;
