import React, { useEffect, useState } from "react";
import LeadsTable from "../Table/LeadsTable";

import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { verifiedApplicationLeadFindAction } from "../../actions/leadActions/leadDocsActions";
import { Alert, AlertTitle, Box, Grid, InputAdornment, TextField } from "@mui/material";
import usePrivilegeCheck from "../../constants/customHooks/UsePrivilegeCheck";
import { findUserAction } from "../../actions/adminAuthAction";
import { RiSearch2Line } from "react-icons/ri";
import { makeStyles } from "@material-ui/core";



const useStyles = makeStyles((theme) => ({
  searchContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    position: "relative",
  },
  searchInput: {
    marginLeft: theme.spacing(1),
  },
}));

function LeadsPending(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [leadData, setLeadData] = useState([]);
  const [data, setData] = useState([]);
  const [paginationPageNumber, setPaginationPageNumber] = useState(1);
  const [pagiantionEntry, setPagiantionEntry] = useState(10);
  const [searchText, setSearchText] = useState("");


  let {
    applicationVerifiedLeadsLoading,
    applicationVerifiedLeadsSuccess,
    applicationVerifiedLeadsError
  } = useSelector((state) => {
    return state.verifiedApplicationLeadFind;
  });

  let {
    leadPendingStatusDocsAddLoading,
    leadPendingStatusDocsAddSuccess,
    leadPendingStatusDocsAddError
  } = useSelector((state) => {
    return state.pendingLeadStatusDocs;
  });

  let { loginFindSuccess } = useSelector((state) => {
    return state.findUser;
  });

  useEffect(() => {
    dispatch(findUserAction());
  }, [dispatch]);

  // material Table
  const columns = [
    {
      title: "Sl no",
      field: "sl"
    },
    {
      title: "Name",
      field: "name"
      // hidden: showNameColumn,
    },
    {
      title: "Email",
      field: "email"
      // hidden: showNameColumn,
    },
    {
      title: "Phone     ",
      field: "phone"
      // hidden: showPhoneColumn,
    },
    {
      title: "What's App Number",
      field: "whatsappNumber"
      // hidden: showWhatsAppNumColumn,
    },

    {
      title: "Lead Source",
      field: "leadSource"
      // hidden: showLeadSourceColumn,
    },
    {
      title:
        loginFindSuccess && loginFindSuccess?.role?.roleName == "branch manager"
          ? "Counsilor"
          : "Lead Owner",
      field: "leadOwner"
      // hidden: showLeadTypeColumn,
    },
    {
      title: "Lead Branch",
      field: "leadBranch"
      // hidden: showUniversityPlaceColumn,
    },
    {
      title: "Status",
      field: "status"
      // hidden: showStatusColumn,
    },
    {
      title: "Priority",
      field: "priority"
      // hidden: showEducationColumn,
    },
    {
      title: "Lead Place",
      field: "leadCity"
      // hidden: showUniversityPlaceColumn,
    },

    // {
    //   title: "Document approval",
    //   field: "Document approval"
    //   // hidden: showUniversityPlaceColumn,
    // },

    {
      title: "View Documents",
      field: "View Documents"
      // hidden: showUniversityPlaceColumn,
    },

    // {
    //   title: "Reason",
    //   field: "Reason",
    //   // hidden: showUniversityPlaceColumn,
    // },
    {
      title: "",
      field: "action",
      filtering: false
    }
  ];

  // custom hook for privilage check
  const { privilege, hasPrivilege } = usePrivilegeCheck();

  useEffect(() => {
    dispatch(verifiedApplicationLeadFindAction());
  }, [dispatch]);

  useEffect(() => {
    if (applicationVerifiedLeadsSuccess) {
      let reversedData = applicationVerifiedLeadsSuccess.reverse();
      setLeadData(reversedData);
    }
  }, [applicationVerifiedLeadsSuccess]);

  console.log(
    applicationVerifiedLeadsSuccess,
    "applicationVerifiedLeadsSuccessapplicationVerifiedLeadsSuccess"
  );

  useEffect(() => {
    setData(
      leadData?.map((lead, index) => ({
        id: lead.id,
        sl: index + 1,
        name: lead.firstName,
        email: lead.email,
        phone: lead.mobileNumber,
        whatsappNumber: lead.whatsappNumber,
        leadSource: lead.leadSource,
        leadOwner: lead.leadOwnerName,
        leadBranch: lead?.branch_master?.branchName,
        status: lead.status,
        priority: lead.priority,
        leadCity: lead.location,
        reason: lead?.lead
      }))
    );
  }, [leadData]);

  const handleDeleteClick = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        // dispatch(deleteLeadAction(leadId));
      }
    });
  };

  const selectBoxIdPassHandler = (ids) => {
    // setIds(ids);
  };

  if (!hasPrivilege("Document approval") && privilege !== null) {
    return (
      <Alert severity="error">
        <AlertTitle>warning</AlertTitle>
        You don't have permission to access — <strong>this page.!</strong>
      </Alert>
    );
  }

  const searchHandleChange = (e) => {
    let value = e.target.value;
    if (value) {
      let result = applicationVerifiedLeadsSuccess?.filter((item) =>
        item.firstName?.toLowerCase()?.includes(value?.toLowerCase()) ||
        item.email?.toLowerCase()?.includes(value?.toLowerCase()) ||
        item.mobileNumber?.toLowerCase()?.includes(value?.toLowerCase()) ||
        item.whatsappNumber?.toLowerCase()?.includes(value?.toLowerCase())
      )?.map((lead, index) => ({
        id: lead.id,
        sl: index + 1,
        name: lead.firstName,
        email: lead.email,
        phone: lead.mobileNumber,
        whatsappNumber: lead.whatsappNumber,
        leadSource: lead.leadSource,
        leadOwner: lead.leadOwnerName,
        leadBranch: lead?.branch_master?.branchName,
        status: lead.status,
        priority: lead.priority,
        leadCity: lead.location,
        reason: lead?.lead,
      }));
      setData(result);
    } else {
      setData(
        leadData?.map((lead, index) => ({
        id: lead.id,
        sl: index + 1,
        name: lead.firstName,
        email: lead.email,
        phone: lead.mobileNumber,
        whatsappNumber: lead.whatsappNumber,
        leadSource: lead.leadSource,
        leadOwner: lead.leadOwnerName,
        leadBranch: lead?.branch_master?.branchName,
        status: lead.status,
        priority: lead.priority,
        leadCity: lead.location,
        reason: lead?.lead
      })));
    }
  };

  return (
    <>
      <Box
        sx={{
          // mt: "30px",
          display: "flex",
          alignItems: "center",
          justifyContent: "end"
        }}
      >
        <div className={classes.searchContainer}>
          <TextField
            className={classes.searchInput}
            variant="outlined"
            placeholder="Search Here"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
              searchHandleChange(e);
            }}
            sx={{
              "& .MuiInputBase-input": {
                borderRadius: "20px",
                height: "1px",
                paddingRight: "48px !important"
              },
              "& .MuiFormLabel-root": {
                lineHeight: "5px"
              },
              "& .MuiInputBase-root": {
                borderRadius: "3px",
                borderColor: "#fafafa"
              },
              "& .MuiOutlinedInput-notchedOutline ": {
                borderRadius: "3px",
                borderColor: "#ECECEC !important",
                borderWidth: "1px !important"
              }
            }}
          />
          <>
            <InputAdornment
              position="end"
              sx={{
                position: "absolute",
                right: "8px",
                width: "40px",
                // top:"0",
                // bottom:"0",
                border: "1px solid #ECECEC",
                borderRadius: "3px",
                height: "34px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer"
              }}
            >
              <RiSearch2Line />
            </InputAdornment>
          </>
        </div>
      </Box>

      <Grid container spacing={2}>
        {/* <img src="./loader.gif" alt="sdf"/>
        <img src="./loader.gif" alt="sdf"/> */}
        <Grid item xs={12}>
          <LeadsTable
            toggle={props.toggle}
            showNameColumn=""
            showPrefferedColumn=""
            showPhoneColumn=""
            showLeadSourceColumn=""
            showLeadTypeColumn=""
            showStatusColumn=""
            showEducationColumn=""
            showUniversityPlaceColumn=""
            reset=""
            setIdsFunction=""
            data={data}
            columns={columns}
            leadFindLoading={applicationVerifiedLeadsLoading}
            selectBoxIdPassHandler={selectBoxIdPassHandler}
            component="pending"
            pagiantionEntry={pagiantionEntry}
            paginationPageNumber={paginationPageNumber}
            subMenu="Document approval"
            mainMenu="Leads"
          />
        </Grid>
      </Grid>
    </>
  );
}

export default LeadsPending;
