import React, { useEffect, useState } from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent, {
  timelineOppositeContentClasses
} from "@mui/lab/TimelineOppositeContent";

import { Typography } from "@mui/material";

import { MainContainer } from "../../content/content.element";
import { Box } from "@mui/system";

import { format, parseISO } from "date-fns";

import { InfoHeader } from "../../customComponent/InfoHeader";
import { useParams } from "react-router-dom";
import { Avatar, IconButton, TextField, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { findTimeLineAction } from "../../../actions/leadActions/leadDocsActions";
import { findUserAction } from "../../../actions/adminAuthAction";

export default function EmployeeTrackPage(props) {
  let { id } = useParams();
  const dispatch = useDispatch();

  const [data, setData] = useState([]);

  let { loginFindSuccess } = useSelector((state) => {
    return state.findUser;
  });

  useEffect(() => {
    dispatch(findUserAction());
  }, [dispatch]);

  let {
    leadTimeLineFindLoading,
    leadTimeLineFindSuccess,
    leadTimeLineFindError
  } = useSelector((state) => {
    return state.findTimeLine;
  });

  let {
    leadTimeLineStatusAddLoading,
    leadTimeLineStatusAddSuccess,
    leadTimeLineStatusAddError
  } = useSelector((state) => {
    return state.leadStatusTimeLine;
  });

  useEffect(() => {
    dispatch(findTimeLineAction(id));
  }, [dispatch, id, leadTimeLineStatusAddSuccess]);

  useEffect(() => {
    setData(leadTimeLineFindSuccess);
  }, [leadTimeLineFindSuccess]);

  // Group the timeline items by date
  const groupedData =
    data &&
    data !== undefined &&
    data?.reduce((groups, item) => {
      const date = parseISO(item.createdAt).toDateString();
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(item);
      return groups;
    }, {});

  return (
    <MainContainer active={props.toggle}>
      {/* <InfoHeader headTitle="Time Line" url={`/Leads/details/${id}`} /> */}
      <InfoHeader headTitle="Time Line" url={-1} />

      <div
        style={{
          display: "flex",
          marginTop: "20px",
          width: "100%",
          marginLeft: "20px"
        }}
      >
        <Box component="div" sx={{ flexGrow: 0, ml: 2 }}>
          <Tooltip title="Time line">
            <IconButton sx={{ p: 0 }}>
              <Avatar
                alt="Remy Sharp"
                src={
                  loginFindSuccess !== null && loginFindSuccess?.img_url
                    ? loginFindSuccess.img_url
                    : "/static/images/avatar/2.jpg"
                }
              />
            </IconButton>
          </Tooltip>
        </Box>
        {/* <Box component="div" sx={{ flexGrow: 0, ml: 2 }}>
          <TextField
            placeholder="Write New Message"
            variant="outlined"
            fullWidth
            InputProps={{
              sx: {
                borderRadius: "42px",
                height: "32px",
                width: "85vw",
              },
            }}
          />
        </Box> */}
      </div>

      <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
        <Timeline
          sx={{
            [`& .${timelineOppositeContentClasses.root}`]: {
              flex: 0.1
            }
          }}
        >
          {groupedData &&
            Object.entries(groupedData).map(([date, items]) => (
              <React.Fragment key={date}>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 400,
                    fontSize: "13px",
                    fontFamily: "'Open Sans', sans-serif"
                  }}
                >
                  {date}
                </Typography>
                {items?.map((item) => (
                  <TimelineItem key={item.id} sx={{ marginTop: "-1px" }}>
                    <TimelineOppositeContent color="textSecondary"></TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot color="success">
                        {item.WhichTask === "lead Creating" ||
                        item.tastType === "followUpTaskCreate" ||
                        item.tastType === "followUpMeetCreate" ||
                        item.tastType === "followUpCallCreate" ||
                        item.tastType === "createdFollowUp" ||
                        item.tastType === "updated" ||
                        item.WhichTask === "lead details updated" ||
                        item.WhichTask === "applicationStatus" ||
                        item.tastType === "updatedWaiting" ||
                        item.tastType === "updatedApproved" ||
                        item.tastType === "documentApproved" ||
                        item.tastType === "leadCredentialAction" ||
                        item.tastType === "leadOwnerChanged" ||
                        item.tastType === "verified" ||
                        item.tastType === "duplicateLead" ? (
                          <img
                            src="/menuIcons/tasUpdated.png"
                            alt="gsl"
                            style={{ width: "15px", height: "auto" }}
                          />
                        ) : null}
                        {item.WhichTask === "lead status change" ||
                        item.WhichTask === "followUpCloseUpdated" ? (
                          <img
                            src="/menuIcons/magnetUpdate.png"
                            alt="gsl"
                            style={{ width: "15px", height: "auto" }}
                          />
                        ) : null}

                        {item.WhichTask === "assigning" && (
                          <img
                            src="/menuIcons/tasUpdated.png"
                            alt="gsl"
                            style={{ width: "15px", height: "auto" }}
                          />
                        )}
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ m: 1 }}>
                      {item.WhichTask === "lead Creating"
                        ? `lead ${item.lastStatus} - Lead was created by`
                        : item.tastType === "createdFollowUp"
                        ? `Task Follow Up Created - Follow up activities created by`
                        : item.WhichTask === "followUpCloseUpdated"
                        ? `Updated - Folow Up Open Activity Closed by `
                        : item.WhichTask === "lead status change"
                        ? `Task ${item.tastType} - Lead status updated from ${
                            item.Previouse == "new"
                              ? "🟠"
                              : item.Previouse == "future"
                              ? "🟠"
                              : item.Previouse == "warm"
                              ? "🟢"
                              : item.Previouse == "cold"
                              ? "🔵"
                              : item.Previouse == "dead"
                              ? "⚪"
                              : item.Previouse == "Application Started"
                              ? "⚪"
                              : item.Previouse == "Documents is waiting"
                              ? "🟡"
                              : item.Previouse == "SOP in Progress"
                              ? "🔵"
                              : item.Previouse == "SOP Completed"
                              ? "🔵"
                              : item.Previouse == "Application in progress"
                              ? "🔵"
                              : item.Previouse == "Application Completed"
                              ? "🔵"
                              : item.Previouse == "deferral"
                              ? "🔴"
                              : item.Previouse == "Visa received"
                              ? "🔵"
                              : item.Previouse == "On hold"
                              ? "🔵"
                              : item.Previouse == "Drop"
                              ? "🔴"
                              : item.Previouse == "restarted"
                              ? "🟠"
                              : ""
                          } ${item.Previouse}  to ${item.lastStatus} by`
                        : item.WhichTask === "assigning"
                        ? `Lead owner updated - Updated Lead owner from ${
                            item.Previouse
                          } to ${item.tastType.split("assigned")[0]}${
                            item.lastStatus
                          } by `
                        : item.tastType === "followUpTaskCreate"
                        ? `Followup created - Created a task in follow up by `
                        : item.tastType === "followUpMeetCreate"
                        ? `Meeting created - Created a meet in follow up by `
                        : item.tastType === "followUpCallCreate"
                        ? `Call created - Created a Call in follow up by `
                        : item.tastType === "updated"
                        ? `Lead Updated - Lead was updated by`
                        : item.tastType === "Personaldetails update"
                        ? `Lead PersonalDetails updated - Lead personalDetails was updated by`
                        : item.tastType === "Education details update"
                        ? `Lead EducationDetails updated - Lead EducationDetails was updated by`
                        : item.tastType === "sopDetails update"
                        ? `Lead SopDetails updated - Lead SopDetails was updated by`
                        : item.tastType === "statusChange update"
                        ? `Lead ${item.lastStatus} updated - Lead ${item.lastStatus} status changed to ${item.Previouse} by`
                        : item.tastType === "travelImmigration update"
                        ? `Lead travelImmigration updated - Lead travelImmigration was updated by`
                        : item.tastType === "workExperience update"
                        ? `Lead workExperience updated - Lead workExperience was updated by`
                        : item.tastType === "Document update"
                        ? `Lead ${item.lastStatus} updated - Lead ${item.lastStatus} was updated by`
                        : item.tastType === "registrationFee update"
                        ? `Lead registrationFee updated - Lead registrationFee was updated and waiting for approval. updated by`
                        : item.tastType === "registrationFee approved"
                        ? `Lead registrationFee updated - Lead registrationFee was approvedBy by`
                        : item.tastType === "Courseapplied"
                        ? `Lead Course updated - Lead applied for the course ${item?.lastStatus}.by`
                        : item.tastType === "CourseShortListed"
                        ? `Lead Course updated - Lead shortlist course ${item?.lastStatus}.by`
                        : item.WhichTask === "applicationStatus"
                        ? `Lead ApplicationStatus updated - Lead application for ${item.tastType} changed from ${item.Previouse} to ${item?.lastStatus}.by`
                        : item.tastType === "statusApprove"
                        ? `Lead status updated - Lead status approved.by`
                        : item.tastType === "updatedWaiting"
                        ? `Lead updated - Lead Details updated and waiting for approval.updated by`
                        : item.tastType === "updatedApproved"
                        ? `Lead updated - Lead Details updated approval request has been approved by`
                        : item.WhichTask === "leadtelecallerChanged"
                        ? `Lead owner changed - Lead telecaller changed to ${item.Previouse} by`
                        : item.WhichTask === "leadBranchManagerChanged"
                        ? `Lead owner changed - Lead branchManager changed to ${item.Previouse} by`
                        : item.WhichTask === "leadCounsilorChanged"
                        ? `Lead owner changed - Lead counsilor changed to ${item.Previouse} by `
                        : item.WhichTask === "leadBranchManagerChangedApproval"
                        ? `Lead owner changing - ${item.taskOwner} try to change lead owner from ${item.Previouse} to ${item?.lastStatus} `
                        : item.WhichTask === "LeadOwner approved"
                        ? `Lead owner change approved - Lead owner changed from ${item.Previouse} to ${item.lastStatus} by `
                        : item.WhichTask === "LeadOwner rejected"
                        ? `Lead owner change rejected - Lead owner change from ${item.Previouse} to ${item.lastStatus} has been rejected by `
                        : item.WhichTask === "leadTeleCallerChanged"
                        ? `Lead telecaller Changed - Lead teleCaller changed to ${item.Previouse} by`
                        : item.WhichTask === "documentApproved"
                        ? `Lead Documents status changes - Lead Docment status changed from verified to ${item.lastStatus} by `
                        : item.tastType === "tutionFee approved"
                        ? `Lead TutionFee Approved - Lead tutionFee approved by `
                        : item.WhichTask === "LeadCredential Action"
                        ? `Lead Password changed - Lead Password has been changed by `
                        : item.WhichTask === "PasswordChange"
                        ? `Lead Password Resend - Lead Password has been resended by `
                        : item.WhichTask === "Duplicate lead approved"
                        ? `Duplicate Lead Approved - Duplicate lead was approved by`
                        : item.WhichTask === "Duplicate lead rejected"
                        ? `Duplicate Lead Rejected - Duplicate lead was rejected by`
                        : item.WhichTask === "Duplicate Lead approval"
                        ? `Approval request - Duplicate lead approval request by`
                        : item.WhichTask === "Refund Approved"
                        ? `Refund Approved - Refund amount has been approved by`
                        : item.WhichTask === "PhoneNumber verified"
                        ? `Phone number verified - Lead phone number is verified by`
                        : item.WhichTask === "email verified"
                        ? `Email verified - Lead email is verified by`
                        : `Task ${item.lastStatus} - Task was created by`}
                      &nbsp;
                      {item.WhichTask !==
                        "leadBranchManagerChangedApproval" && (
                        <Typography
                          component="span"
                          variant="h6"
                          sx={{ fontWeight: 600, fontSize: "16px" }}
                        >
                          {item.taskOwner}
                        </Typography>
                      )}
                    </TimelineContent>
                    <div style={{ marginLeft: "auto", paddingLeft: "auto" }}>
                      <TimelineContent
                        sx={{
                          m: 1,
                          fontWeight: 400,
                          fontSize: "14px",
                          color: "rgba(114, 114, 114, 1)"
                        }}
                      >
                        {item.createdAt
                          ? format(parseISO(item.createdAt), "hh:mm a")
                          : null}
                      </TimelineContent>
                    </div>
                  </TimelineItem>
                ))}
              </React.Fragment>
            ))}
        </Timeline>
      </Box>
    </MainContainer>
  );
}
