import axios from "axios";
import {
  PAYMENTAPPROVAL_FIND_ERR,
  PAYMENTAPPROVAL_FIND_REQUEST,
  PAYMENTAPPROVAL_FIND_SUCCESS,
  PAYMENTAPPROVAL_REGISTRATIONFEE_APPROVE_ERR,
  PAYMENTAPPROVAL_REGISTRATIONFEE_APPROVE_REQUEST,
  PAYMENTAPPROVAL_REGISTRATIONFEE_APPROVE_SUCCESS,
  TUTIONFEEPAYMENTAPPROVAL_APPROVE_ERR,
  TUTIONFEEPAYMENTAPPROVAL_APPROVE_REQUEST,
  TUTIONFEEPAYMENTAPPROVAL_APPROVE_SUCCESS,
  TUTIONFEEPAYMENTAPPROVAL_FIND_ERR,
  TUTIONFEEPAYMENTAPPROVAL_FIND_REQUEST,
  TUTIONFEEPAYMENTAPPROVAL_FIND_SUCCESS,
} from "../constants/paymentApprovalConstants";
import { SUCCESS_TRUE_MSG_ERR } from "../constants/roleManagmentConstant";

export const paymentApprovalFindAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: PAYMENTAPPROVAL_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      "/api/admin/paymentApproval/findRegistrationFeeApproval",
      config
    );

    dispatch({ type: PAYMENTAPPROVAL_FIND_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: PAYMENTAPPROVAL_FIND_ERR, payload: error.response.data });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// registration fee approve action

export const paymentApprovalRegistrationFeeApproveAction = (id,leadID) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: PAYMENTAPPROVAL_REGISTRATIONFEE_APPROVE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let taskOwner = isUserExist?.name;
    let userID = isUserExist?.UserId;

    let { data } = await axios.put(
      `/api/admin/paymentApproval/approveRegistrationFee?id=${id}`,
      { userID,leadID,taskOwner },
      config
    );

    dispatch({
      type: PAYMENTAPPROVAL_REGISTRATIONFEE_APPROVE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PAYMENTAPPROVAL_REGISTRATIONFEE_APPROVE_ERR,
      payload: error.response.data,
    });
  }
};

//TutionFee approval find action

export const tutionFeepaymentApprovalFindAction = () => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: TUTIONFEEPAYMENTAPPROVAL_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      "/api/admin/paymentApproval/findTutionFeeApproval",
      config
    );

    dispatch({ type: TUTIONFEEPAYMENTAPPROVAL_FIND_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: TUTIONFEEPAYMENTAPPROVAL_FIND_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

//TutionFee approval find action

export const tutionFeepaymentApprovalApproveAction = (id) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: TUTIONFEEPAYMENTAPPROVAL_APPROVE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };
    let taskOwner = isUserExist?.name;
    let leadId = isUserExist?.UserId;

    let { data } = await axios.put(
      `/api/admin/paymentApproval/approveTutionFee?id=${id}`,
      { leadId, taskOwner },
      config
    );

    dispatch({ type: TUTIONFEEPAYMENTAPPROVAL_APPROVE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: TUTIONFEEPAYMENTAPPROVAL_APPROVE_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};
