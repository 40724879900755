import axios from "axios";
import {
  ADD_TO_SHORT_LIST_UNIVERSITY_COURSE_ERR,
  ADD_TO_SHORT_LIST_UNIVERSITY_COURSE_REQUEST,
  ADD_TO_SHORT_LIST_UNIVERSITY_COURSE_SUCCESS,
  APPLICATION_VERIFIED_LEAD_FIND_ERR,
  APPLICATION_VERIFIED_LEAD_FIND_REQUEST,
  APPLICATION_VERIFIED_LEAD_FIND_SUCCESS,
  COUNTRY_ADMISSION_DOCS_FIND_ERR,
  COUNTRY_ADMISSION_DOCS_FIND_REQUEST,
  COUNTRY_ADMISSION_DOCS_FIND_SUCCESS,
  FIND_SHORT_LIST_UNIVERSITY_COURSE_ERR,
  FIND_SHORT_LIST_UNIVERSITY_COURSE_REQUEST,
  FIND_SHORT_LIST_UNIVERSITY_COURSE_SUCCESS,
  LEAD_COUNTRY_COMMON_DOCS_FINDING_ERR,
  LEAD_COUNTRY_COMMON_DOCS_FINDING_REQUEST,
  LEAD_COUNTRY_COMMON_DOCS_FINDING_SUCCESS,
  LEAD_COUNTRY_COMMON_DOCS_UPLOADING_ERR,
  LEAD_COUNTRY_COMMON_DOCS_UPLOADING_REQUEST,
  LEAD_COUNTRY_COMMON_DOCS_UPLOADING_SUCCESS,
  LEAD_COUNTRY_SPECIFIC_DOCS_FINDING_ERR,
  LEAD_COUNTRY_SPECIFIC_DOCS_FINDING_REQUEST,
  LEAD_COUNTRY_SPECIFIC_DOCS_FINDING_SUCCESS,
  LEAD_COUNTRY_SPECIFIC_DOCS_UPLOADING_ERR,
  LEAD_COUNTRY_SPECIFIC_DOCS_UPLOADING_REQUEST,
  LEAD_COUNTRY_SPECIFIC_DOCS_UPLOADING_SUCCESS,
  LEAD_DOCUMENT_REUPLOADING_ERR,
  LEAD_DOCUMENT_REUPLOADING_REQUEST,
  LEAD_DOCUMENT_REUPLOADING_SUCCESS,
  LEAD_SOP_PDF_FINDING_ERR,
  LEAD_SOP_PDF_FINDING_REQUEST,
  LEAD_SOP_PDF_FINDING_SUCCESS,
  LEAD_SOP_PDF_STATUS_CHANGE_ERR,
  LEAD_SOP_PDF_STATUS_CHANGE_REQUEST,
  LEAD_SOP_PDF_STATUS_CHANGE_SUCCESS,
  LEAD_SOP_PDF_UPLOAD_ERR,
  LEAD_SOP_PDF_UPLOAD_REQUEST,
  LEAD_SOP_PDF_UPLOAD_SUCCESS,
  LEAD_TIME_LINE_FIND_ERR,
  LEAD_TIME_LINE_FIND_REQUEST,
  LEAD_TIME_LINE_FIND_SUCCESS,
  LEAD_TIME_LINE_STATUS_ADD_ERR,
  LEAD_TIME_LINE_STATUS_ADD_REQUEST,
  LEAD_TIME_LINE_STATUS_ADD_SUCCESS,
  LEAD_TRAVELIMMIGRATION_PDF_FINDING_ERR,
  LEAD_TRAVELIMMIGRATION_PDF_FINDING_REQUEST,
  LEAD_TRAVELIMMIGRATION_PDF_FINDING_SUCCESS,
  LEAD_TRAVELIMMIGRATION_PDF_UPLOAD_ERR,
  LEAD_TRAVELIMMIGRATION_PDF_UPLOAD_REQUEST,
  LEAD_TRAVELIMMIGRATION_PDF_UPLOAD_SUCCESS,
  LEAD_WORK_DETAILS_ADD_ERR,
  LEAD_WORK_DETAILS_ADD_REQUEST,
  LEAD_WORK_DETAILS_ADD_SUCCESS,
  LEAD_WORK_DETAILS_DELETE_REQUEST,
  LEAD_WORK_DETAILS_DELETE_SUCCESS,
  LEAD_WORK_DETAILS_FIND_ERR,
  LEAD_WORK_DETAILS_FIND_REQUEST,
  LEAD_WORK_DETAILS_FIND_SUCCESS,
  PAYMENT_STATUS_UPDATE_SHORTLIST_ERR,
  PAYMENT_STATUS_UPDATE_SHORTLIST_REQUEST,
  PAYMENT_STATUS_UPDATE_SHORTLIST_SUCCESS,
  SHORT_LIST_METHOD_APPLY_ERR,
  SHORT_LIST_METHOD_APPLY_REQUEST,
  SHORT_LIST_METHOD_APPLY_SUCCESS,
  SHORT_LIST_METHOD_DELETE_ERR,
  SHORT_LIST_METHOD_DELETE_REQUEST,
  SHORT_LIST_METHOD_DELETE_SUCCESS,
  SHORT_LIST_METHOD_FIND_ERR,
  SHORT_LIST_METHOD_FIND_REQUEST,
  SHORT_LIST_METHOD_FIND_SUCCESS,
  SHORT_LIST_UNIVERSITY_COURSE_FINDING_ERR,
  SHORT_LIST_UNIVERSITY_COURSE_FINDING_MESSAGE,
  SHORT_LIST_UNIVERSITY_COURSE_FINDING_REQUEST,
  SHORT_LIST_UNIVERSITY_COURSE_FINDING_SUCCESS,
} from "../../constants/leadConstant";
import {
  SUCCESS_TRUE_MSG_ERR,
  SUCCESS_TRUE_MSG_SUCCESS,
} from "../../constants/roleManagmentConstant";

// LeadSopUploadingAction
export const leadSopUploadingAction = (
  file,
  countryId,
  leadId,
  countryName
) => async (dispatch, getState) => {
  console.log(
    file,
    "leadSopUploadingActionleadSopUploadingActionleadSopUploadingAction"
  );
  try {
    dispatch({ type: LEAD_SOP_PDF_UPLOAD_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let taskOwner = isUserExist?.name;

    let { data } = await axios.post(
      "/api/admin/lead/leadSopUploading",
      { file, countryId, leadId, countryName, taskOwner },
      config
    );

    dispatch({ type: LEAD_SOP_PDF_UPLOAD_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: LEAD_SOP_PDF_UPLOAD_ERR,
      payload: error.response.data,
    });
  }
};

// leadSopFinding
export const leadSopFindingAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: LEAD_SOP_PDF_FINDING_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      `/api/admin/lead/leadSopFinding?id=${id}`,
      config
    );

    dispatch({ type: LEAD_SOP_PDF_FINDING_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: LEAD_SOP_PDF_FINDING_ERR,
      payload: error.response.data,
    });
  }
};

// changeSopApplicationStatusAction
export const changeSopApplicationStatusAction = (
  id,
  CommonDocId,
  status,
  section
) => async (dispatch, getState) => {
  console.log(
    id,
    CommonDocId,
    status,
    section,
    "********************************************************"
  );

  try {
    dispatch({ type: LEAD_SOP_PDF_STATUS_CHANGE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let taskOwner = isUserExist.name;

    let { data } = await axios.put(
      `/api/admin/lead/leadSopStatusChange?id=${id}`,
      { CommonDocId, status, section, taskOwner },
      config
    );

    dispatch({ type: LEAD_SOP_PDF_STATUS_CHANGE_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: LEAD_SOP_PDF_STATUS_CHANGE_ERR,
      payload: error.response.data,
    });
  }
};

// travel and immigration

// leadTravelAndImmigrationUploadingAction
export const leadTravelAndImmigrationUploadingAction = (
  file,
  countryId,
  leadId,
  countryName
) => async (dispatch, getState) => {
  console.log(
    file,
    "leadTravelImmigrationUploadingleadTravelImmigrationUploadingleadTravelImmigrationUploading"
  );
  try {
    dispatch({ type: LEAD_TRAVELIMMIGRATION_PDF_UPLOAD_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let taskOwner = isUserExist.name;

    let { data } = await axios.post(
      "/api/admin/lead/leadTravelImmigrationUploading",
      { file, countryId, leadId, countryName, taskOwner },
      config
    );

    dispatch({
      type: LEAD_TRAVELIMMIGRATION_PDF_UPLOAD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: LEAD_TRAVELIMMIGRATION_PDF_UPLOAD_ERR,
      payload: error.response.data,
    });
  }
};

// leadTraveAndImmigrationFindingAction
export const leadTraveAndImmigrationFindingAction = (id) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: LEAD_TRAVELIMMIGRATION_PDF_FINDING_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      `/api/admin/lead/leadTravelImmigrationFinding?id=${id}`,
      config
    );

    dispatch({
      type: LEAD_TRAVELIMMIGRATION_PDF_FINDING_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: LEAD_TRAVELIMMIGRATION_PDF_FINDING_ERR,
      payload: error.response.data,
    });
  }
};

// work details

// leadWorkDetailsAddAction
export const leadWorkDetailsAddAction = (
  workDetailsData,
  refereeDetails,
  leadId,
  haveExperience,
  haveReferee
) => async (dispatch, getState) => {
  try {
    dispatch({ type: LEAD_WORK_DETAILS_ADD_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let taskOwner = isUserExist.name;

    let { data } = await axios.post(
      "/api/admin/lead/leadWorkDetailsAdd",
      {
        workDetailsData,
        refereeDetails,
        leadId,
        haveExperience,
  haveReferee,
        taskOwner,
      },
      config
    );

    dispatch({
      type: LEAD_WORK_DETAILS_ADD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: LEAD_WORK_DETAILS_ADD_ERR,
      payload: error.response.data,
    });
  }
};

// leadWorkDetailsDeleteAction
export const leadWorkDetailsDeleteAction = (id, section) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: LEAD_WORK_DETAILS_DELETE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.delete(
      `/api/admin/lead/leadWorkDetailsDelete?id=${id}&section=${section}`,
      config
    );

    dispatch({
      type: LEAD_WORK_DETAILS_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: LEAD_WORK_DETAILS_ADD_ERR,
      payload: error.response.data,
    });
  }
};

// leadWorkDetailsFindAction
export const leadWorkDetailsFindAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: LEAD_WORK_DETAILS_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      `/api/admin/lead/leadWorkDetailsFind?id=${id}`,
      config
    );

    dispatch({
      type: LEAD_WORK_DETAILS_FIND_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: LEAD_WORK_DETAILS_FIND_ERR,
      payload: error.response.data,
    });
  }
};

//  Admision Docs
// countryAllAdmissionDocsFindAction
export const countryAllAdmissionDocsFindAction = (id) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: COUNTRY_ADMISSION_DOCS_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      `/api/admin/lead/countryAdmissionDocsFind?id=${id}`,
      config
    );

    dispatch({
      type: COUNTRY_ADMISSION_DOCS_FIND_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: COUNTRY_ADMISSION_DOCS_FIND_ERR,
      payload: error.response.data,
    });
  }
};

// leadCountrycommonDocUploadingAction
export const leadCountrycommonDocUploadingAction = (
  file,
  CommonDocId,
  countryId,
  id
) => async (dispatch, getState) => {
  try {
    dispatch({ type: LEAD_COUNTRY_COMMON_DOCS_UPLOADING_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let taskOwner = isUserExist?.name;

    let { data } = await axios.post(
      "/api/admin/lead/leadCountryCommonDocs",
      { file, CommonDocId, countryId, id, taskOwner },
      config
    );

    dispatch({
      type: LEAD_COUNTRY_COMMON_DOCS_UPLOADING_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: LEAD_COUNTRY_COMMON_DOCS_UPLOADING_ERR,
      payload: error.response.data,
    });
  }
};

// leadCountryCommonDocsFindDocs
export const leadCountryCommonDocsFindDocsAction = (leadId) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: LEAD_COUNTRY_COMMON_DOCS_FINDING_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      `/api/admin/lead/leadCountryCommonDocsFindDocs?id=${leadId}`,
      config
    );

    dispatch({
      type: LEAD_COUNTRY_COMMON_DOCS_FINDING_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: LEAD_COUNTRY_COMMON_DOCS_FINDING_ERR,
      payload: error.response.data,
    });
  }
};

// leadCountrySpecificDocUploadingAction
export const leadCountrySpecificDocUploadingAction = (
  file,
  docId,
  leadId,
  details
) => async (dispatch, getState) => {
  console.log(details, "llllllllllllllllllllllllllllllllllllllllllllllll");
  try {
    dispatch({ type: LEAD_COUNTRY_SPECIFIC_DOCS_UPLOADING_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let taskOwner = isUserExist?.name;

    let { data } = await axios.post(
      "/api/admin/lead/leadCountrySpecificDocsUploadingDocs",
      { file, docId, leadId, details, taskOwner },
      config
    );

    dispatch({
      type: LEAD_COUNTRY_SPECIFIC_DOCS_UPLOADING_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: LEAD_COUNTRY_SPECIFIC_DOCS_UPLOADING_ERR,
      payload: error.response.data,
    });
  }
};

// leadDocumentReuploadAction

export const leadDocReUploadingAction = (Doc, DocId, datas, status) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: LEAD_DOCUMENT_REUPLOADING_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };
    const leadId = isUserExist.id;

    let { data } = await axios.post(
      "/api/admin/lead/leadDocumentReupload",
      { Doc, DocId, datas, status, leadId },
      config
    );

    dispatch({
      type: LEAD_DOCUMENT_REUPLOADING_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: LEAD_DOCUMENT_REUPLOADING_ERR,
      payload: error.response.data,
    });
  }
};

// leadCountrySpecificDocsFindingDocsAction
export const leadCountrySpecificDocsFindingDocsAction = (leadId) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: LEAD_COUNTRY_SPECIFIC_DOCS_FINDING_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      `/api/admin/lead/leadCountrySpecificDocsFindingDocs?id=${leadId}`,
      config
    );

    dispatch({
      type: LEAD_COUNTRY_SPECIFIC_DOCS_FINDING_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: LEAD_COUNTRY_SPECIFIC_DOCS_FINDING_ERR,
      payload: error.response.data,
    });
  }
};

// shortlist actions

// universityCourseFindForShortListAction
export const universityCourseFindForShortListAction = (
  country,
  location,
  level,
  subject,
  university,
  courseStartMonth,
  intake
) => async (dispatch, getState) => {
  console.log(
    country,
    location,
    level,
    subject,
    university,
    courseStartMonth,
    intake
  );
  try {
    dispatch({ type: SHORT_LIST_UNIVERSITY_COURSE_FINDING_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.post(
      "/api/admin/lead/shortListUniversityCourseFind",
      {
        country,
        location,
        level,
        subject,
        university,
        courseStartMonth,
        intake,
      },
      config
    );
    console.log(data, "datadatadatadatadata");

    dispatch({
      type: SHORT_LIST_UNIVERSITY_COURSE_FINDING_SUCCESS,
      payload: data,
    });
    dispatch({
      type: SHORT_LIST_UNIVERSITY_COURSE_FINDING_MESSAGE,
      payload: data?.message,
    });

    dispatch({ type: SUCCESS_TRUE_MSG_SUCCESS, payload: true });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: SHORT_LIST_UNIVERSITY_COURSE_FINDING_ERR,
      payload: error.response.data,
    });

    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// courseAddShortListAction
export const courseAddShortListAction = (
  university,
  course,
  intakeDate,
  intakeId,
  leadId
) => async (dispatch, getState) => {
  console.log(university, course, intakeDate, intakeId, leadId);
  try {
    dispatch({ type: ADD_TO_SHORT_LIST_UNIVERSITY_COURSE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let taskOwner = isUserExist.name;

    let { data } = await axios.post(
      "/api/admin/lead/addShortListUniversityCourse",
      {
        university,
        course,
        intakeDate,
        intakeId,
        leadId,
        taskOwner,
      },
      config
    );
    console.log(data, "datadatadatadatadata");

    dispatch({
      type: ADD_TO_SHORT_LIST_UNIVERSITY_COURSE_SUCCESS,
      payload: data,
    });

    dispatch({ type: SUCCESS_TRUE_MSG_SUCCESS, payload: true });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: ADD_TO_SHORT_LIST_UNIVERSITY_COURSE_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// findShortListUniversityCourseAction
export const findShortListUniversityCourseAction = (id) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: FIND_SHORT_LIST_UNIVERSITY_COURSE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      `/api/admin/lead/findShortListUniversityCourse?id=${id}`,
      config
    );
    console.log(data, "datadatadatadatadata");

    dispatch({
      type: FIND_SHORT_LIST_UNIVERSITY_COURSE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: FIND_SHORT_LIST_UNIVERSITY_COURSE_ERR,
      payload: error.response.data,
    });
  }
};

// shortListMethodApplyAction
export const shortListMethodApplyAction = (
  methodName,
  applyData,
  leadId,
  approveStatus,
  applicationHanlder,
  universityHandler,
  applicationId
) => async (dispatch, getState) => {
  try {
    dispatch({ type: SHORT_LIST_METHOD_APPLY_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let createdBy = isUserExist.name;

    let { data } = await axios.post(
      "/api/admin/lead/shortListMethodApply",
      {
        methodName,
        applyData,
        leadId,
        approveStatus,
        applicationHanlder,
        universityHandler,
        applicationId,
        createdBy,
      },
      config
    );

    dispatch({
      type: SHORT_LIST_METHOD_APPLY_SUCCESS,
      payload: data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_SUCCESS, payload: true });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: SHORT_LIST_METHOD_APPLY_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// shortListDeleteAction
export const shortListDeleteAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SHORT_LIST_METHOD_DELETE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.delete(
      `/api/admin/lead/shortListMethodDetete?id=${id}`,
      config
    );
    console.log(data, "datadatadatadatadata");

    dispatch({
      type: SHORT_LIST_METHOD_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: SHORT_LIST_METHOD_DELETE_ERR,
      payload: error.response.data,
    });
  }
};

// shortListMethodFindAction
export const shortListMethodFindAction = (leadId) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: SHORT_LIST_METHOD_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let userId = isUserExist?.UserId;

    let { data } = await axios.get(
      `/api/admin/lead/shortListMethodFind?id=${leadId}&userId=${userId}`,
      config
    );
    console.log(data, "datadatadatadatadata");

    dispatch({
      type: SHORT_LIST_METHOD_FIND_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: SHORT_LIST_METHOD_FIND_ERR,
      payload: error.response.data,
    });
  }
};

// paymentUpdationInShortListAction
export const paymentUpdationInShortListAction = (
  paymentStatus,
  shortListId
) => async (dispatch, getState) => {
  try {
    dispatch({ type: PAYMENT_STATUS_UPDATE_SHORTLIST_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let userId = isUserExist?.UserId;

    let { data } = await axios.put(
      `/api/admin/lead/paymentUpdationInShortList?id=${shortListId}`,
      { paymentStatus: paymentStatus, userId: userId },
      config
    );
    console.log(data, "datadatadatadatadata");

    dispatch({
      type: PAYMENT_STATUS_UPDATE_SHORTLIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: PAYMENT_STATUS_UPDATE_SHORTLIST_ERR,
      payload: error.response.data,
    });
  }
};

// verifiedApplicationLeadFindAction
export const verifiedApplicationLeadFindAction = () => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: APPLICATION_VERIFIED_LEAD_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let UserId = isUserExist?.UserId;

    let { data } = await axios.get(
      `/api/admin/lead/verifiedApplicationLeadFind?id=${UserId}`,
      config
    );
    console.log(data, "datadatadatadatadata");

    dispatch({
      type: APPLICATION_VERIFIED_LEAD_FIND_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: APPLICATION_VERIFIED_LEAD_FIND_ERR,
      payload: error.response.data,
    });
  }
};

// LEAD TIME LINE

// findTimeLineAction
export const findTimeLineAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: LEAD_TIME_LINE_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      `/api/admin/lead/findTimeLine?id=${id}`,
      config
    );
    dispatch({
      type: LEAD_TIME_LINE_FIND_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: LEAD_TIME_LINE_FIND_ERR,
      payload: error.response.data,
    });
  }
};

// leadStatusTimeLineAction
export const leadStatusTimeLineAction = (
  tasType,
  WhichTask,
  id,
  status
) => async (dispatch, getState) => {
  try {
    dispatch({ type: LEAD_TIME_LINE_STATUS_ADD_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let taskOwner = isUserExist?.name;

    let { data } = await axios.post(
      "/api/admin/lead/AddTimeLine",
      { tasType, WhichTask, taskOwner, id, status },
      config
    );
    dispatch({
      type: LEAD_TIME_LINE_STATUS_ADD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: LEAD_TIME_LINE_STATUS_ADD_ERR,
      payload: error.response.data,
    });
  }
};
