import axios from "axios";
import {
  A_LEAD_DETAIL_FETCH_ERR,
  A_LEAD_DETAIL_FETCH_REQUEST,
  A_LEAD_DETAIL_FETCH_SUCCESS,
  LEAD_CREATE_SUBMIT_ERR,
  LEAD_CREATE_SUBMIT_REQUEST,
  LEAD_CREATE_SUBMIT_SUCCESS,
  LEAD_DELETE_ERR,
  LEAD_DELETE_REQUEST,
  LEAD_DELETE_SUCCESS,
  LEAD_EDUCATION_DETAILS_ADD_ERR,
  LEAD_EDUCATION_DETAILS_ADD_REQUEST,
  LEAD_EDUCATION_DETAILS_ADD_SUCCESS,
  LEAD_EDUCATION_DETAILS_DELETE_ERR,
  LEAD_EDUCATION_DETAILS_DELETE_REQUEST,
  LEAD_EDUCATION_DETAILS_DELETE_SUCCESS,
  LEAD_EDUCATION_DETAILS_FIND_ERR,
  LEAD_EDUCATION_DETAILS_FIND_REQUEST,
  LEAD_EDUCATION_DETAILS_FIND_SUCCESS,
  LEAD_EDUCATION_ENGLISH_TEST_DETAILS_DELETE_ERR,
  LEAD_EDUCATION_ENGLISH_TEST_DETAILS_DELETE_REQUEST,
  LEAD_EDUCATION_ENGLISH_TEST_DETAILS_DELETE_SUCCESS,
  LEAD_FIND_ERR,
  LEAD_FIND_REQUEST,
  LEAD_FIND_SUCCESS,
  LEAD_OWNER_CHANGE_ERR,
  LEAD_OWNER_CHANGE_REQUEST,
  LEAD_OWNER_CHANGE_SUCCESS,
  LEAD_PENDING_STATUS_CHANGE_ERR,
  LEAD_PENDING_STATUS_CHANGE_REQUEST,
  LEAD_PENDING_STATUS_CHANGE_SUCCESS,
  FIND_LEAD_PENDING_STATUS_CHANGE_REQUEST,
  FIND_LEAD_PENDING_STATUS_CHANGE_SUCCESS,
  FIND_LEAD_PENDING_STATUS_CHANGE_ERR,
  LEAD_PERSONAL_DETAILS_ADD_ERR,
  LEAD_PERSONAL_DETAILS_ADD_REQUEST,
  LEAD_PERSONAL_DETAILS_ADD_SUCCESS,
  LEAD_PERSONAL_DETAILS_SHOW_ERR,
  LEAD_PERSONAL_DETAILS_SHOW_REQUEST,
  LEAD_PERSONAL_DETAILS_SHOW_SUCCESS,
  LEAD_STATUS_SHOW_ERR,
  LEAD_STATUS_SHOW_REQUEST,
  LEAD_STATUS_SHOW_SUCCESS,
  FIND_APPROVING_LEAD_STATUS_REQUEST,
  FIND_APPROVING_LEAD_STATUS_SUCCESS,
  FIND_APPROVING_LEAD_STATUS_ERR,
  CHANGE_APPROVING_LEAD_STATUS_REQUEST,
  CHANGE_APPROVING_LEAD_STATUS_SUCCESS,
  CHANGE_APPROVING_LEAD_STATUS_ERR,
  LEAD_RESTART_APPLICATION_REQUEST,
  LEAD_RESTART_APPLICATION_SUCCESS,
  LEAD_RESTART_APPLICATION_ERR,
  LEAD_EDIT_SUBMIT_REQUEST,
  LEAD_EDIT_SUBMIT_SUCCESS,
  LEAD_EDIT_SUBMIT_ERR,
  LEAD_BULKUPLOAD_REQUEST,
  LEAD_BULKUPLOAD_SUCCESS,
  LEAD_BULKUPLOAD_ERR,
  LEAD_REGISTRATIONFEE_CREATE_REQUEST,
  LEAD_REGISTRATIONFEE_CREATE_SUCCESS,
  LEAD_REGISTRATIONFEE_CREATE_ERR,
  LEAD_REGISTRATIONFEE_FIND_REQUEST,
  LEAD_REGISTRATIONFEE_FIND_SUCCESS,
  LEAD_REGISTRATIONFEE_FIND_ERR,
  SHORTLIST_VALIDATION_FIND_REQUEST,
  SHORTLIST_VALIDATION_FIND_SUCCESS,
  SHORTLIST_VALIDATION_FIND_ERR,
  LEAD_APPROVAL_REQUEST_FIND_REQUEST,
  LEAD_APPROVAL_REQUEST_FIND_SUCCESS,
  LEAD_APPROVAL_REQUEST_FIND_ERR,
  LEAD_APPROVAL_REQUEST_APPROVE_REQUEST,
  LEAD_APPROVAL_REQUEST_APPROVE_SUCCESS,
  LEAD_APPROVAL_REQUEST_APPROVE_ERR,
  LEAD_APPROVAL_REQUEST_REJECT_REQUEST,
  LEAD_APPROVAL_REQUEST_REJECT_SUCCESS,
  LEAD_APPROVAL_REQUEST_REJECT_ERR,
  LEAD_OWNERCHANGE_APPROVAL_REQUEST_FIND_REQUEST,
  LEAD_OWNERCHANGE_APPROVAL_REQUEST_FIND_SUCCESS,
  LEAD_OWNERCHANGE_APPROVAL_REQUEST_FIND_ERR,
  LEAD_OWNERCHANGE_APPROVAL_REQUEST_APPROVE_REQUEST,
  LEAD_OWNERCHANGE_APPROVAL_REQUEST_APPROVE_SUCCESS,
  LEAD_OWNERCHANGE_APPROVAL_REQUEST_APPROVE_ERR,
  LEAD_OWNERCHANGE_APPROVAL_REQUEST_REJECT_REQUEST,
  LEAD_OWNERCHANGE_APPROVAL_REQUEST_REJECT_SUCCESS,
  LEAD_OWNERCHANGE_APPROVAL_REQUEST_REJECT_ERR,
  LEAD_CREDENTIAL_RESEND_REQUEST,
  LEAD_CREDENTIAL_RESEND_SUCCESS,
  LEAD_CREDENTIAL_RESEND_ERR,
  LEAD_CREDENTIAL_SETPASSWORD_REQUEST,
  LEAD_CREDENTIAL_SETPASSWORD_SUCCESS,
  LEAD_CREDENTIAL_SETPASSWORD_ERR,
  LEAD_COUNSILORNOTE_CREATE_REQUEST,
  LEAD_COUNSILORNOTE_CREATE_SUCCESS,
  LEAD_COUNSILORNOTE_CREATE_ERR,
  LEAD_TELECALLERNOTE_CREATE_REQUEST,
  LEAD_TELECALLERNOTE_CREATE_SUCCESS,
  LEAD_TELECALLERNOTE_CREATE_ERR,
  LEAD_PERSONAL_DETAILS_ADD_SUCCESS_MESSAGE,
  LEAD_PERSONAL_DETAILS_ADD_ERR_MESSAGE,
  LEAD_DUPLICATE_APPROVAL_FIND_SUCCESS,
  LEAD_DUPLICATE_APPROVAL_FIND_ERR,
  LEAD_DUPLICATE_APPROVAL_FIND_REQUEST,
  LEAD_DUPLICATE_APPROVAL_APPROVE_REQUEST,
  LEAD_DUPLICATE_APPROVAL_APPROVE_SUCCESS,
  LEAD_DUPLICATE_APPROVAL_APPROVE_ERR,
  LEAD_DUPLICATE_APPROVAL_REJECT_REQUEST,
  LEAD_DUPLICATE_APPROVAL_REJECT_SUCCESS,
  LEAD_DUPLICATE_APPROVAL_REJECT_ERR,
  LEAD_DUPLICATE_APPROVAL_FINDONE_REQUEST,
  LEAD_DUPLICATE_APPROVAL_FINDONE_SUCCESS,
  LEAD_DUPLICATE_APPROVAL_FINDONE_ERR,
  LEAD_REFUND_CREATE_REQUEST,
  LEAD_REFUND_CREATE_SUCCESS,
  LEAD_REFUND_CREATE_ERR,
  LEAD_REFUND_FIND_REQUEST,
  LEAD_REFUND_FIND_SUCCESS,
  LEAD_REFUND_FIND_ERR,
  LEAD_REFUND_UPDATE_REQUEST,
  LEAD_REFUND_UPDATE_SUCCESS,
  LEAD_REFUND_UPDATE_ERR,
  LEAD_MARKETING_CREATE_REQUEST,
  LEAD_MARKETING_CREATE_SUCCESS,
  LEAD_MARKETING_CREATE_ERR,
  LEAD_MARKETINGNOTE_CREATE_REQUEST,
  LEAD_MARKETINGNOTE_CREATE_SUCCESS,
  LEAD_MARKETINGNOTE_CREATE_ERR,
  LEAD_PHONENUMBER_VERIFIED_REQUEST,
  LEAD_PHONENUMBER_VERIFIED_SUCCESS,
  LEAD_PHONENUMBER_VERIFIED_ERR,
  LEAD_EMAIL_VERIFIED_REQUEST,
  LEAD_EMAIL_VERIFIED_SUCCESS,
  LEAD_EMAIL_VERIFIED_ERR,
  LEAD_OTP_VERIFY_REQUEST,
  LEAD_OTP_VERIFY_SUCCESS,
  LEAD_OTP_VERIFY_ERR
} from "../../constants/leadConstant";
import {
  SUCCESS_TRUE_MSG_ERR,
  SUCCESS_TRUE_MSG_SUCCESS
} from "../../constants/roleManagmentConstant";

// leadAddAction
export const leadAddAction =
  (
    firstName,
    lastName,
    email,
    gender,
    mobileNumber,
    countryCode,
    whatsappNumber,
    location,
    dateOfBirth,
    education,
    leadSource,
    referralName,
    preferredCountry,
    remark,
    profile,
    // newPass,
    // confirmPass,
    countryCodeSecondary,
    secMobileNumber,
    livingCountry,
    isMarried,
    selectedState,
    selectedCity
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: LEAD_CREATE_SUBMIT_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`
        }
      };

      let createdBy = isUserExist.name;
      let createdByID = isUserExist.UserId;
      let { data } = await axios.post(
        "/api/admin/lead/leadAdd",
        {
          firstName,
          lastName,
          email,
          gender,
          mobileNumber,
          countryCode,
          whatsappNumber,
          location,
          dateOfBirth,
          education,
          leadSource,
          referralName,
          preferredCountry,
          remark,
          profile,
          createdBy,
          // newPass,
          // confirmPass,
          countryCodeSecondary,
          secMobileNumber,
          livingCountry,
          isMarried,
          selectedState,
          selectedCity,
          createdByID
        },
        config
      );

      dispatch({ type: LEAD_CREATE_SUBMIT_SUCCESS, payload: data });

      // let isUserExist = localStorage.getItem("loginInfo")
      //   ? JSON.parse(localStorage.getItem("loginInfo"))
      //   : null;
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({ type: LEAD_CREATE_SUBMIT_ERR, payload: error.response.data });
      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
    }
  };

// leadEditAction
export const leadEditAction =
  (
    leadId,
    firstName,
    lastName,
    email,
    gender,
    mobileNumber,
    countryCode,
    whatsappNumber,
    location,
    dateOfBirth,
    education,
    leadSource,
    referralName,
    preferredCountry,
    remark,
    profile,
    newPass,
    confirmPass,
    selectedCountry,
    isMarried,
    selectedState,
    selectedCity
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: LEAD_EDIT_SUBMIT_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`
        }
      };

      let createdBy = isUserExist.name;
      let updatedByID = isUserExist.UserId;

      let { data } = await axios.put(
        `/api/admin/lead/leadEdit?id=${leadId}`,
        {
          firstName,
          lastName,
          email,
          gender,
          mobileNumber,
          countryCode,
          whatsappNumber,
          location,
          dateOfBirth,
          education,
          leadSource,
          referralName,
          preferredCountry,
          remark,
          profile,
          createdBy,
          newPass,
          confirmPass,
          selectedCountry,
          isMarried,
          selectedState,
          selectedCity,
          updatedByID
        },
        config
      );

      dispatch({ type: LEAD_EDIT_SUBMIT_SUCCESS, payload: data });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({ type: LEAD_EDIT_SUBMIT_ERR, payload: error.response.data });
      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: false });
    }
  };

// leadFindAction
export const leadFindAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: LEAD_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`
      }
    };

    let UserId = isUserExist?.UserId;

    let { data } = await axios.get(
      `/api/admin/lead/leadFind?id=${UserId}`,
      config
    );

    dispatch({ type: LEAD_FIND_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({ type: LEAD_FIND_ERR, payload: error.response.data });
  }
};

// deleteLeadAction
export const deleteLeadAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: LEAD_DELETE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`
      }
    };

    let { data } = await axios.delete(
      `/api/admin/lead/leadDelete?id=${id}`,
      config
    );

    dispatch({ type: LEAD_DELETE_SUCCESS, payload: data });

    // let isUserExist = localStorage.getItem("loginInfo")
    //   ? JSON.parse(localStorage.getItem("loginInfo"))
    //   : null;
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({ type: LEAD_DELETE_ERR, payload: error.response.data });
  }
};

// leadDetailFetchAction
export const leadDetailFetchAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: A_LEAD_DETAIL_FETCH_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`
      }
    };

    let { data } = await axios.get(
      `/api/admin/lead/leadDetailFetch?id=${id}`,
      config
    );

    dispatch({ type: A_LEAD_DETAIL_FETCH_SUCCESS, payload: data });

    // let isUserExist = localStorage.getItem("loginInfo")
    //   ? JSON.parse(localStorage.getItem("loginInfo"))
    //   : null;
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({ type: A_LEAD_DETAIL_FETCH_ERR, payload: error.response.data });
  }
};

// leadPersonalDetailsAddAction
export const leadPersonalDetailsAddAction =
  (formValues, id) => async (dispatch, getState) => {
    try {
      dispatch({ type: LEAD_PERSONAL_DETAILS_ADD_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`
        }
      };

      const userName = isUserExist?.name;

      let { data } = await axios.post(
        "/api/admin/lead/leadPersonalDetailsAdd",
        { formValues, id, userName },
        config
      );

      dispatch({ type: LEAD_PERSONAL_DETAILS_ADD_SUCCESS, payload: data });
      dispatch({
        type: LEAD_PERSONAL_DETAILS_ADD_SUCCESS_MESSAGE,
        payload: data
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: LEAD_PERSONAL_DETAILS_ADD_ERR,
        payload: error.response.data
      });

      dispatch({
        type: LEAD_PERSONAL_DETAILS_ADD_ERR_MESSAGE,
        payload: error.response.data
      });
    }
  };

// leadPersonalDetailsShowAction
export const leadPersonalDetailsShowAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: LEAD_PERSONAL_DETAILS_SHOW_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`
        }
      };

      let { data } = await axios.get(
        `/api/admin/lead/leadPersonalDetailsShow?id=${id}`,
        config
      );

      dispatch({ type: LEAD_PERSONAL_DETAILS_SHOW_SUCCESS, payload: data });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: LEAD_PERSONAL_DETAILS_SHOW_ERR,
        payload: error.response.data
      });
    }
  };

// leadOwnerChangeAction
export const leadOwnerChangeAction =
  (
    bulkLeadId,
    selectedLeadId,
    branchId,
    managerId,
    status,
    appliedApplicationId,
    universityGivenId,
    visaSummary,
    visaDetails,
    declarationForm,
    component
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: LEAD_OWNER_CHANGE_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`
        }
      };

      let taskOwner = isUserExist?.name;
      let taskOwnerId = isUserExist?.UserId;

      if (status === "visaDetails") {
        visaDetails.UserId = isUserExist?.UserId;
      }

      let { data } = await axios.put(
        `/api/admin/lead/leadOwnerChange`,
        {
          branchId,
          managerId,
          bulkLeadId,
          selectedLeadId,
          status,
          appliedApplicationId,
          universityGivenId,
          visaSummary,
          taskOwner,
          visaDetails,
          declarationForm,
          component,
          taskOwnerId
        },
        config
      );

      dispatch({ type: LEAD_OWNER_CHANGE_SUCCESS, payload: data });
    } catch (error) {
      console.log(error, "error.response");
      dispatch({
        type: LEAD_OWNER_CHANGE_ERR,
        payload: error.response.data
      });
      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
    }
  };

// leadChangeStatusAction
export const leadChangeStatusAction =
  (status, id, reason, menu, intakeMonth, intakeYear) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: LEAD_STATUS_SHOW_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`
        }
      };

      let taskOwner = isUserExist.name;
      let { data } = await axios.patch(
        `/api/admin/lead/leadStatusChange?id=${id}`,
        {
          status: status,
          taskOwner: taskOwner,
          reason: reason,
          menu: menu,
          intakeMonth: intakeMonth,
          intakeYear: intakeYear
        },
        config
      );

      dispatch({ type: LEAD_STATUS_SHOW_SUCCESS, payload: data });
      dispatch({ type: SUCCESS_TRUE_MSG_SUCCESS, payload: true });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: LEAD_STATUS_SHOW_ERR,
        payload: error.response.data
      });
      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
    }
  };

// leadEducationDetailsAddAction
export const leadEducationDetailsAddAction =
  (
    qualificationName,
    highSecField,
    highSchoolCompletionYear,
    highSchoolBoard,
    highSchoolScore,
    higherSecondaryCompletionYear,
    higherSecondarySteam,
    higherSecondaryBoard,
    higherSecondaryScore,
    academicDetails,
    englishTestDetails,
    id
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: LEAD_EDUCATION_DETAILS_ADD_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`
        }
      };

      let taskOwner = isUserExist?.name;

      let { data } = await axios.post(
        "/api/admin/lead/leadEducationAdd",
        {
          qualificationName,
          highSecField,
          highSchoolCompletionYear,
          highSchoolBoard,
          highSchoolScore,
          higherSecondaryCompletionYear,
          higherSecondarySteam,
          higherSecondaryBoard,
          higherSecondaryScore,
          academicDetails,
          englishTestDetails,
          id,
          taskOwner
        },
        config
      );

      dispatch({ type: LEAD_EDUCATION_DETAILS_ADD_SUCCESS, payload: data });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: LEAD_EDUCATION_DETAILS_ADD_ERR,
        payload: error.response.data
      });
    }
  };

// leadEducationDetailsShowAction
export const leadEducationDetailsShowAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: LEAD_EDUCATION_DETAILS_FIND_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`
        }
      };

      let { data } = await axios.get(
        `/api/admin/lead/leadEducationDetailsShow?id=${id}`,
        config
      );

      dispatch({ type: LEAD_EDUCATION_DETAILS_FIND_SUCCESS, payload: data });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: LEAD_EDUCATION_DETAILS_FIND_ERR,
        payload: error.response.data
      });
    }
  };

// deleteAcademicDetailsAction
export const deleteAcademicDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: LEAD_EDUCATION_DETAILS_DELETE_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`
        }
      };

      let { data } = await axios.delete(
        `/api/admin/lead/deleteAcademicDetail?id=${id}`,
        config
      );

      dispatch({ type: LEAD_EDUCATION_DETAILS_DELETE_SUCCESS, payload: data });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: LEAD_EDUCATION_DETAILS_DELETE_ERR,
        payload: error.response.data
      });
    }
  };

// deleteEnglishTestAction
export const deleteEnglishTestAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: LEAD_EDUCATION_ENGLISH_TEST_DETAILS_DELETE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`
      }
    };

    let { data } = await axios.delete(
      `/api/admin/lead/deleteEnglishTestDetail?id=${id}`,
      config
    );

    dispatch({
      type: LEAD_EDUCATION_ENGLISH_TEST_DETAILS_DELETE_SUCCESS,
      payload: data
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: LEAD_EDUCATION_ENGLISH_TEST_DETAILS_DELETE_ERR,
      payload: error.response.data
    });
  }
};

// LEAD PENDING DOCS STATUS

// pendingLeadStatusDocsAction
export const pendingLeadStatusDocsAction =
  (id, status, reason) => async (dispatch, getState) => {
    try {
      console.log(id, status, "staaaaaaaaaaaaaaaaaaaaaaa");
      dispatch({ type: LEAD_PENDING_STATUS_CHANGE_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`
        }
      };
      let taskOwner = isUserExist?.name;
      let { data } = await axios.post(
        "/api/admin/lead/addPendingStatusLeadChange",
        { id, status, taskOwner, reason },
        config
      );

      dispatch({
        type: LEAD_PENDING_STATUS_CHANGE_SUCCESS,
        payload: data
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: LEAD_PENDING_STATUS_CHANGE_ERR,
        payload: error.response.data
      });
    }
  };

// findPendingStatusLeadChange
export const findPendingStatusLeadChangeAction =
  () => async (dispatch, getState) => {
    try {
      dispatch({ type: FIND_LEAD_PENDING_STATUS_CHANGE_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`
        }
      };
      // let taskOwner = isUserExist?.name;
      let { data } = await axios.get(
        "/api/admin/lead/findPendingStatusLeadChange",
        config
      );

      dispatch({
        type: FIND_LEAD_PENDING_STATUS_CHANGE_SUCCESS,
        payload: data
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: FIND_LEAD_PENDING_STATUS_CHANGE_ERR,
        payload: error.response.data
      });
    }
  };

// FIND STATUS APPROVING LIST

// findApprovingStatusLeadAction

export const findApprovingStatusLeadAction =
  () => async (dispatch, getState) => {
    try {
      dispatch({ type: FIND_APPROVING_LEAD_STATUS_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`
        }
      };
      let taskOwner = isUserExist?.UserId;
      let { data } = await axios.get(
        `/api/admin/lead/findApprovingStatusLead?branchManger=${taskOwner}`,
        config
      );

      dispatch({
        type: FIND_APPROVING_LEAD_STATUS_SUCCESS,
        payload: data
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: FIND_APPROVING_LEAD_STATUS_ERR,
        payload: error.response.data
      });
    }
  };

// leadStatusApprovingChangeAction
export const leadStatusApprovingChangeAction =
  (status, leadId, statusId, requestStatus) => async (dispatch, getState) => {
    try {
      dispatch({ type: CHANGE_APPROVING_LEAD_STATUS_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`
        }
      };
      let taskOwner = isUserExist?.name;
      let taskOwnerId = isUserExist?.UserId;
      let { data } = await axios.post(
        "/api/admin/lead/changeApprovingStatusLead",
        { status, leadId, statusId, requestStatus, taskOwner, taskOwnerId },
        config
      );

      dispatch({
        type: CHANGE_APPROVING_LEAD_STATUS_SUCCESS,
        payload: data
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: CHANGE_APPROVING_LEAD_STATUS_ERR,
        payload: error.response.data
      });
    }
  };

// LEAD RESTART APPLICATION FROM DEFERRAL

// leadRestartApplicationAction
export const leadRestartApplicationAction =
  (leadId, requestStatus) => async (dispatch, getState) => {
    try {
      dispatch({ type: LEAD_RESTART_APPLICATION_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`
        }
      };
      // let taskOwner = isUserExist?.UserId;
      let { data } = await axios.put(
        `/api/admin/lead/leadRestartApplication?id=${leadId}`,
        { requestStatus },
        config
      );

      dispatch({
        type: LEAD_RESTART_APPLICATION_SUCCESS,
        payload: data
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: LEAD_RESTART_APPLICATION_ERR,
        payload: error.response.data
      });
    }
  };

// leadRegistrationFee create action

export const leadRegistrationFeeCreateAction =
  (formData) => async (dispatch, getState) => {
    try {
      dispatch({ type: LEAD_REGISTRATIONFEE_CREATE_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
          "Content-Type": "multipart/form-data"
        }
      };

      let leadID = isUserExist?.UserId;
      let taskOwner = isUserExist?.name;

      let { data } = await axios.post(
        `/api/admin/lead/registrationFee?userID=${leadID}&taskOwner=${taskOwner}`,
        formData,
        config
      );

      dispatch({
        type: LEAD_REGISTRATIONFEE_CREATE_SUCCESS,
        payload: data
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: LEAD_REGISTRATIONFEE_CREATE_ERR,
        payload: error.response.data
      });
    }
  };

// leadRegistrationFee find action

export const leadRegistrationFeeFindAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: LEAD_REGISTRATIONFEE_FIND_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`
        }
      };

      let leadID = isUserExist?.UserId;

      let { data } = await axios.get(
        `/api/admin/lead/registrationFeeFind?leadID=${id}`,
        config
      );

      dispatch({
        type: LEAD_REGISTRATIONFEE_FIND_SUCCESS,
        payload: data
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: LEAD_REGISTRATIONFEE_FIND_ERR,
        payload: error.response.data
      });
    }
  };

// ::::::::::BulkUpload lead :::::::::::::::::::

// Lead bulkUploadAction

export const leadBulkUploadAction =
  (formData) => async (dispatch, getState) => {
    try {
      dispatch({ type: LEAD_BULKUPLOAD_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
          "Content-Type": "multipart/form-data"
        }
      };

      const userId = isUserExist.UserId;

      // let taskOwner = isUserExist?.UserId;
      let { data } = await axios.post(
        `/api/admin/lead/bulkUploadForLeads?id=${userId}`,
        formData,
        config
      );

      dispatch({
        type: LEAD_BULKUPLOAD_SUCCESS,
        payload: data
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: LEAD_BULKUPLOAD_ERR,
        payload: error.response.data
      });
    }
  };

// Lead shortListApply validation

export const leadShortlistApplyValidationAction =
  (leadID) => async (dispatch, getState) => {
    try {
      dispatch({ type: SHORTLIST_VALIDATION_FIND_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
          "Content-Type": "multipart/form-data"
        }
      };

      const userId = isUserExist.UserId;
      // let taskOwner = isUserExist?.UserId;
      let { data } = await axios.get(
        `/api/admin/lead/shortListApplyValidate?leadID=${leadID}`,
        config
      );

      dispatch({
        type: SHORTLIST_VALIDATION_FIND_SUCCESS,
        payload: data
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: SHORTLIST_VALIDATION_FIND_ERR,
        payload: error.response.data
      });
    }
  };

// :::::::::::::::::::::::::::::: LEAD EDIT APPROVAL REQUEST FIND :::::::::::::::::::::::::::::::::::::::::

// Action for find approval request

export const leadApprovalRequestFindAction =
  () => async (dispatch, getState) => {
    try {
      dispatch({ type: LEAD_APPROVAL_REQUEST_FIND_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`
        }
      };

      // let taskOwner = isUserExist?.UserId;
      let { data } = await axios.get(
        "/api/admin/lead/findApprovalRequest",
        config
      );

      dispatch({
        type: LEAD_APPROVAL_REQUEST_FIND_SUCCESS,
        payload: data
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: LEAD_APPROVAL_REQUEST_FIND_ERR,
        payload: error.response.data
      });
    }
  };

// Action for approve lead edit approval request

export const leadApprovalRequestApproveAction =
  (leadApprovalID) => async (dispatch, getState) => {
    try {
      dispatch({ type: LEAD_APPROVAL_REQUEST_APPROVE_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`
        }
      };

      let taskOwner = isUserExist?.name;

      let { data } = await axios.post(
        "/api/admin/lead/approvalRequestApprove",
        { taskOwner, leadApprovalID },
        config
      );

      dispatch({
        type: LEAD_APPROVAL_REQUEST_APPROVE_SUCCESS,
        payload: data
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: LEAD_APPROVAL_REQUEST_APPROVE_ERR,
        payload: error.response.data
      });
    }
  };

// Action for approve lead edit reject request

export const leadApprovalRequestRejectAction =
  (leadTableID) => async (dispatch, getState) => {
    try {
      dispatch({ type: LEAD_APPROVAL_REQUEST_REJECT_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`
        }
      };

      let taskOwner = isUserExist?.name;

      let { data } = await axios.put(
        "/api/admin/lead/approvalRequestRejected",
        { taskOwner, leadTableID },
        config
      );

      dispatch({
        type: LEAD_APPROVAL_REQUEST_REJECT_SUCCESS,
        payload: data
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: LEAD_APPROVAL_REQUEST_REJECT_ERR,
        payload: error.response.data
      });
    }
  };

// :::::::::::::::::::::::::::::: LEAD OWNERCHANGE APPROVAL REQUEST FIND :::::::::::::::::::::::::::::::::::::::::

export const leadOwnerChangeRequestFindAction =
  () => async (dispatch, getState) => {
    try {
      dispatch({ type: LEAD_OWNERCHANGE_APPROVAL_REQUEST_FIND_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`
        }
      };

      let { data } = await axios.get(
        "/api/admin/lead/findOwnerChangeApprovalRequest",
        config
      );

      dispatch({
        type: LEAD_OWNERCHANGE_APPROVAL_REQUEST_FIND_SUCCESS,
        payload: data
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: LEAD_OWNERCHANGE_APPROVAL_REQUEST_FIND_ERR,
        payload: error.response.data
      });
    }
  };

// Action for approve lead edit approval request

export const leadOwnerChangeApprovalRequestApproveAction =
  (leadID, tableID) => async (dispatch, getState) => {
    try {
      dispatch({ type: LEAD_OWNERCHANGE_APPROVAL_REQUEST_APPROVE_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`
        }
      };

      let taskOwner = isUserExist?.name;

      let { data } = await axios.post(
        "/api/admin/lead/OwnerChangeApproveRequest",
        { taskOwner, leadID, tableID },
        config
      );

      dispatch({
        type: LEAD_OWNERCHANGE_APPROVAL_REQUEST_APPROVE_SUCCESS,
        payload: data
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: LEAD_OWNERCHANGE_APPROVAL_REQUEST_APPROVE_ERR,
        payload: error.response.data
      });
    }
  };

// Action for approve lead edit reject request

export const leadOwnerChangeApprovalRequestRejectAction =
  (id, leadID, reason) => async (dispatch, getState) => {
    try {
      dispatch({ type: LEAD_OWNERCHANGE_APPROVAL_REQUEST_REJECT_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`
        }
      };

      let taskOwner = isUserExist?.name;

      let { data } = await axios.put(
        "/api/admin/lead/OwnerChangeRejectRequest",
        { taskOwner, id, leadID, reason },
        config
      );

      dispatch({
        type: LEAD_OWNERCHANGE_APPROVAL_REQUEST_REJECT_SUCCESS,
        payload: data
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: LEAD_OWNERCHANGE_APPROVAL_REQUEST_REJECT_ERR,
        payload: error.response.data
      });
    }
  };

// Lead Resend credentials Action

export const leadResendCredentialAction =
  (leadId) => async (dispatch, getState) => {
    try {
      dispatch({ type: LEAD_CREDENTIAL_RESEND_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`
        }
      };
      let taskOwner = isUserExist?.name;
      let { data } = await axios.post(
        "/api/admin/lead/resendCredentials",
        { leadId, taskOwner },
        config
      );

      dispatch({
        type: LEAD_CREDENTIAL_RESEND_SUCCESS,
        payload: data
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: LEAD_CREDENTIAL_RESEND_ERR,
        payload: error.response.data
      });
    }
  };

// Lead set credentials Action

export const leadSetCredentialAction =
  (password, leadId) => async (dispatch, getState) => {
    try {
      dispatch({ type: LEAD_CREDENTIAL_SETPASSWORD_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`
        }
      };

      let taskOwner = isUserExist?.name;

      let { data } = await axios.post(
        "/api/admin/lead/setLeadPassword",
        { password, leadId, taskOwner },
        config
      );

      dispatch({
        type: LEAD_CREDENTIAL_SETPASSWORD_SUCCESS,
        payload: data
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: LEAD_CREDENTIAL_SETPASSWORD_ERR,
        payload: error.response.data
      });
    }
  };

// Lead CounsilorNote create Action

export const leadCounsilorNoteCreateAction =
  (leadId, counsilorNote, date,currentTime) => async (dispatch, getState) => {
    try {
      dispatch({ type: LEAD_COUNSILORNOTE_CREATE_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`
        }
      };

      let taskOwner = isUserExist?.name;

      let { data } = await axios.post(
        "/api/admin/lead/counsilorNoteCreate",
        { leadId, counsilorNote, date,currentTime,taskOwner },
        config
      );

      dispatch({
        type: LEAD_COUNSILORNOTE_CREATE_SUCCESS,
        payload: data
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: LEAD_COUNSILORNOTE_CREATE_ERR,
        payload: error.response.data
      });
    }
  };

// Lead TeleCallerNote create Action

export const leadTelecallerNoteCreateAction =
  (leadId, TelecallerNote, date,currentTime) => async (dispatch, getState) => {
    try {
      dispatch({ type: LEAD_TELECALLERNOTE_CREATE_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`
        }
      };

      let taskOwner = isUserExist?.name;

      let { data } = await axios.post(
        "/api/admin/lead/telecallerNoteCreate",
        { leadId, TelecallerNote, date,currentTime, taskOwner },
        config
      );

      dispatch({
        type: LEAD_TELECALLERNOTE_CREATE_SUCCESS,
        payload: data
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: LEAD_TELECALLERNOTE_CREATE_ERR,
        payload: error.response.data
      });
    }
  };


// Lead Marketing note create Action

export const leadMarketingNoteCreateAction =
  (leadId, marketingNote, date,currentTime) => async (dispatch, getState) => {
    try {
      dispatch({ type: LEAD_MARKETINGNOTE_CREATE_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`
        }
      };

      let taskOwner = isUserExist?.name;

      let { data } = await axios.post(
        "/api/admin/lead/marketingNoteCreate",
        { leadId, marketingNote, date,currentTime, taskOwner },
        config
      );

      dispatch({
        type: LEAD_MARKETINGNOTE_CREATE_SUCCESS,
        payload: data
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: LEAD_MARKETINGNOTE_CREATE_ERR,
        payload: error.response.data
      });
    }
  };

// Lead Duplicate approval find Action

export const leadDuplicateApprovalFindAction =
  () => async (dispatch, getState) => {
    try {
      dispatch({ type: LEAD_DUPLICATE_APPROVAL_FIND_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`
        }
      };

      let taskOwner = isUserExist?.name;

      let { data } = await axios.get(
        "/api/admin/lead/duplicateApproval",
        config
      );

      dispatch({
        type: LEAD_DUPLICATE_APPROVAL_FIND_SUCCESS,
        payload: data
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: LEAD_DUPLICATE_APPROVAL_FIND_ERR,
        payload: error.response.data
      });
    }
  };

// Lead Duplicate approval findOne Action

export const leadDuplicateApprovalFindOneAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: LEAD_DUPLICATE_APPROVAL_FINDONE_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`
        }
      };

      let taskOwner = isUserExist?.name;

      let { data } = await axios.get(
        `/api/admin/lead/duplicateApprovalFindOne?id=${id}`,
        config
      );

      dispatch({
        type: LEAD_DUPLICATE_APPROVAL_FINDONE_SUCCESS,
        payload: data
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: LEAD_DUPLICATE_APPROVAL_FINDONE_ERR,
        payload: error.response.data
      });
    }
  };

// Lead Duplicate approval approve Action

export const leadDuplicateApproveAction =
  (leadApprovalId) => async (dispatch, getState) => {
    try {
      dispatch({ type: LEAD_DUPLICATE_APPROVAL_APPROVE_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`
        }
      };

      let taskOwner = isUserExist?.name;

      let { data } = await axios.put(
        "/api/admin/lead/duplicateLeadApprove",
        {
          leadApprovalId,
          taskOwner
        },
        config
      );

      dispatch({
        type: LEAD_DUPLICATE_APPROVAL_APPROVE_SUCCESS,
        payload: data
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: LEAD_DUPLICATE_APPROVAL_APPROVE_ERR,
        payload: error.response.data
      });
    }
  };

// Lead Duplicate approval reject Action

export const leadDuplicateRejectAction =
  (leadApprovalId, reason) => async (dispatch, getState) => {
    try {
      dispatch({ type: LEAD_DUPLICATE_APPROVAL_REJECT_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`
        }
      };

      let taskOwner = isUserExist?.name;

      let { data } = await axios.put(
        "/api/admin/lead/duplicateLeadReject",
        {
          leadApprovalId,
          reason,
          taskOwner
        },
        config
      );

      dispatch({
        type: LEAD_DUPLICATE_APPROVAL_REJECT_SUCCESS,
        payload: data
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: LEAD_DUPLICATE_APPROVAL_REJECT_ERR,
        payload: error.response.data
      });
    }
  };

// Lead Refund create Action

export const leadRefundCreateAction =
  (currency, refundAmount, leadId, applicationId) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: LEAD_REFUND_CREATE_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`
        }
      };

      let taskOwner = isUserExist?.UserId;

      let { data } = await axios.post(
        "/api/admin/lead/createRefund",
        {
          currency,
          refundAmount,
          leadId,
          applicationId,
          taskOwner
        },
        config
      );

      dispatch({
        type: LEAD_REFUND_CREATE_SUCCESS,
        payload: data
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: LEAD_REFUND_CREATE_ERR,
        payload: error.response.data
      });
    }
  };

// Lead Refund find Action

export const leadRefundFindAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: LEAD_REFUND_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`
      }
    };

    let taskOwner = isUserExist?.UserId;

    let { data } = await axios.get(
      "/api/admin/lead/findRefundApproval",
      config
    );

    dispatch({
      type: LEAD_REFUND_FIND_SUCCESS,
      payload: data
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: LEAD_REFUND_FIND_ERR,
      payload: error.response.data
    });
  }
};

// Lead Refund Approval Action

export const leadRefundApprovalAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: LEAD_REFUND_FIND_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`
        }
      };

      let taskOwner = isUserExist?.name;

      let { data } = await axios.put(
        `/api/admin/lead/refundApproval?id=${id}&taskOwner?=${taskOwner}`,{},
        config
      );

      dispatch({
        type: LEAD_REFUND_FIND_SUCCESS,
        payload: data
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: LEAD_REFUND_FIND_ERR,
        payload: error.response.data
      });
    }
  };




// Lead Refund Update Action

export const leadRefundUpdateAction =
  (currency,amount,id) => async (dispatch, getState) => {
    try {
      dispatch({ type: LEAD_REFUND_UPDATE_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`
        }
      };

      let taskOwner = isUserExist?.UserId;

      let { data } = await axios.put(
        '/api/admin/lead/updateRefund',{
          currency,
          amount,
          id
        },
        config
      );

      dispatch({
        type: LEAD_REFUND_UPDATE_SUCCESS,
        payload: data
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: LEAD_REFUND_UPDATE_ERR,
        payload: error.response.data
      });
    }
  };



  // Lead PhoneNumber verified Action

export const leadPhoneNumberVerifiedAction =
(leadId) => async (dispatch, getState) => {
  try {
    dispatch({ type: LEAD_PHONENUMBER_VERIFIED_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`
      }
    };

    let taskOwner = isUserExist?.UserId;

    let { data } = await axios.post(
      '/api/admin/lead/verifyPhoneNumber',{
        leadId
      },
      config
    );

    dispatch({
      type: LEAD_PHONENUMBER_VERIFIED_SUCCESS,
      payload: data
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: LEAD_PHONENUMBER_VERIFIED_ERR,
      payload: error.response.data
    });
  }
};


  // Lead Email verified Action

  export const leadEmailVerifiedAction =
  (leadId) => async (dispatch, getState) => {
    try {
      dispatch({ type: LEAD_EMAIL_VERIFIED_REQUEST });
  
      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;
  
      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`
        }
      };
  
      let taskOwner = isUserExist?.UserId;
  
      let { data } = await axios.post(
        '/api/admin/lead/verifyEmail',{
          leadId
        },
        config
      );
  
      dispatch({
        type: LEAD_EMAIL_VERIFIED_SUCCESS,
        payload: data
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: LEAD_EMAIL_VERIFIED_ERR,
        payload: error.response.data
      });
    }
  };



  // Lead Otp verified Action

  export const leadOtpVerifyAction =
  (otp,id,verificationType) => async (dispatch, getState) => {
    try {
      dispatch({ type: LEAD_OTP_VERIFY_REQUEST });
  
      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;
  
      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`
        }
      };
  
      let taskOwner = isUserExist?.name;
  
      let { data } = await axios.post(
        '/api/admin/lead/otpVerification',{
          otp,id,verificationType,taskOwner
        },
        config
      );
  
      dispatch({
        type: LEAD_OTP_VERIFY_SUCCESS,
        payload: data
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: LEAD_OTP_VERIFY_ERR,
        payload: error.response.data
      });
    }
  };