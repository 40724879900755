import { Box, Grid, ImageListItem, Skeleton, Typography } from "@mui/material";
import React, { forwardRef, useEffect, useMemo, useState } from "react";

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TableSortLabel
} from "@mui/material";

import { shortListMethodFindAction } from "../../../../actions/leadActions/leadDocsActions";
import AppliedShortListChip from "./AppliedShortListChip";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import Dialogue from "../../../customComponent/Dialogue";
import MenuBar from "../../../customComponent/MenuBar";
import Swal from "sweetalert2";
import TableNoItemComponent from "../../../customComponent/TableNoItemComponent";
import { SUCCESS_TRUE_MSG_ERR } from "../../../../constants/roleManagmentConstant";
import { AiOutlineEye } from "react-icons/ai";
import { LEAD_OWNER_CHANGE_SUCCESS } from "../../../../constants/leadConstant";
import Loader from "../../../customComponent/Loader";
import { BiSortAlt2 } from "react-icons/bi";
import "./application.css"

const useStyles = makeStyles({
  table: {
    borderCollapse: "separate",
    borderSpacing: "1 8px",
    border: "1px solid #ececec",
    padding: "10px"
  },
  tableCell: {
    backgroundColor: "#fafafa",
    color: "#9499A4",
    fontFamily: "'Akatab', sans-serif",
    fontSize: "-16px",
    fontWeight: 500,
    marginTop: "10px"
  },
  headerText: {
    fontSize: "13px"
  },
  tableRow: {
    "& > *": {
      border: "none"
    }
  }
});

function AppliedShortList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, subMenu, mainMenu } = useParams();
  const classes = useStyles();

  const [shortListShowData, setShortListShowData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogComponentName, setDialogComponentName] = useState("");
  const [appliedApplicationId, setAppliedApplicationId] = useState("");
  const [leadId, setLeadId] = useState("");

  const [menuData, setMenuData] = useState();
  const [currentStatus, setCurrentStatus] = useState();

  const [applicationCountryList, setApplicationCountryList] = useState();
  const [applicationCountry, setApplicationCountry] = useState("");
  const [universityHandlerName, setUniversityHandlerName] = useState("");
  const [data, setData] = useState([]);

  const { leadShortListAppliedFindLoading, leadShortListAppliedFindSuccess } =
    useSelector((state) => {
      return state.shortListMethodFind;
    });

  const { leadShortListApplyAddToAppliedSuccess } = useSelector((state) => {
    return state.shortListMethodApply;
  });

  let { leadOwnerLoading, leadOwnerSuccess, leadOwnererror } = useSelector(
    (state) => {
      return state.leadOwnerChange;
    }
  );

  let { successAlertMsgSuccess, successAlertMsgErr } = useSelector((state) => {
    return state.successTrueMsg;
  });

  useEffect(() => {
    if (leadOwnerSuccess && leadOwnerSuccess?.data == "Deposit Paid") {
      Swal.fire(
        "Success",
        "Payment details have been submitted for finance team approval. The added data will be reflected after approval.",
        "success"
      );
      dispatch({ type: LEAD_OWNER_CHANGE_SUCCESS, payload: false });
    }
    if (leadOwnerSuccess && leadOwnerSuccess?.data == "Refund In Progress") {
      Swal.fire(
        "Success",
        "Payment details have been submitted for finance team approval. The added data will be reflected after approval.",
        "success"
      );
      dispatch({ type: LEAD_OWNER_CHANGE_SUCCESS, payload: false });
    }
  }, [leadOwnerSuccess]);

  useEffect(() => {
    if (leadOwnererror && successAlertMsgErr) {
      Swal.fire("warning", leadOwnererror.message, "error");
      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: false });
    }
  }, [leadOwnererror, successAlertMsgErr]);

  useEffect(() => {
    if (leadShortListAppliedFindSuccess) {
      setShortListShowData(leadShortListAppliedFindSuccess);
    }
  }, [leadShortListAppliedFindSuccess]);

  useEffect(() => {
    dispatch(shortListMethodFindAction(id));
  }, [dispatch, id, leadShortListApplyAddToAppliedSuccess, leadOwnerSuccess]);

  // material Table
  const columns = [
    {
      title: "Sl no",
      field: "sl",
      // type: "numeric",
      // hidden: true,
      headerStyle: { backgroundColor: "#F9F9FB" }
    },
    {
      title: "University",
      field: "university",
      //   hidden: showEmployeeNameColumn,
      headerStyle: { backgroundColor: "#F9F9FB" }
    },
    {
      title: "Country",
      field: "country",
      //   hidden: showEmailColumn,
      headerStyle: { backgroundColor: "#F9F9FB" }
    },
    {
      title: "Course Type",
      field: "courseType",
      //   hidden: showEmailColumn,
      headerStyle: { backgroundColor: "#F9F9FB" }
    },
    {
      title: "Course",
      field: "course",
      //   hidden: showEmailColumn,
      headerStyle: { backgroundColor: "#F9F9FB" }
    },
    {
      title: "Intake",
      field: "intake",
      //   hidden: showEmailColumn,
      headerStyle: { backgroundColor: "#F9F9FB" }
    },
    {
      title: "Application Started Date",
      field: "application started date",
      //   hidden: showEmailColumn,
      headerStyle: { backgroundColor: "#F9F9FB" }
    },
    {
      title: "Tuition fee",
      field: "tuitionFee",
      //   hidden: showEmailColumn,
      headerStyle: { backgroundColor: "#F9F9FB" }
    },
    {
      title: "Student ID",
      field: "studentId",
      //   filtering: false,
      headerStyle: { backgroundColor: "#F9F9FB" }
    },
    {
      title: "OfferLetter Type",
      field: "OfferLetter Type",
      //   filtering: false,
      headerStyle: { backgroundColor: "#F9F9FB" }
    },
    {
      title: "OfferLetter Received Date",
      field: "OfferLetter Received Date",
      //   filtering: false,
      headerStyle: { backgroundColor: "#F9F9FB" }
    },
    {
      title: "Application status",
      field: "applicationStatus",
      //   filtering: false,
      headerStyle: { backgroundColor: "#F9F9FB" }
    },

    {
      title: "Application Details",
      field: "applicationStatus",
      //   filtering: false,
      headerStyle: { backgroundColor: "#F9F9FB" }
    }

    // {
    //   title: "Offer",
    //   field: "action",
    //   filtering: false,
    //   headerStyle: { backgroundColor: "#F9F9FB" },
    // },
  ];

  useEffect(() => {
    let shortlistFilter = shortListShowData?.map((data, index) => ({
      id: data.id,
      sl: index + 1,
      leadId: data?.leadId,
      university: data.universityName,
      country: data.universityCountry,
      courseType: data.courseLevel,
      course: data.courseName,
      intake: data.intakeDate,
      tuitionFee: data.universityInternationalFee,
      applicationStatus: data.methodStatus,
      universityGivenId: data.universityGivenId,
      OfferLetterType: data.OfferLetterType,
      universityHandlerName: data.university?.universityHandler,
      offerLetterAddedDate: data?.OfferLetterAddedDate,
      applicationStartedDate: data?.createdAt?.split("T")[0]
    }));
    setData(shortlistFilter);

    let applicationCountry = shortlistFilter.map((data) => data.country);
    let uniqueArray = [...new Set(applicationCountry)];
    setApplicationCountryList(uniqueArray);
    console.log(
      shortlistFilter,
      "shortlistFiltershortlistFiltershortlistFilter"
    );
  }, [shortListShowData]);

  // sorting table
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  const requestSort = (column) => {
    let newSortOrder = "asc";

    if (column === sortBy && sortOrder === "asc") {
      newSortOrder = "desc";
    }

    setSortBy(column);
    setSortOrder(newSortOrder);
  };

  let sortedData = useMemo(() => {
    if (sortBy && data) {
      const sortedItems = [...data].sort((a, b) => {
        if (sortOrder === "asc") {
          return a[sortBy] < b[sortBy] ? -1 : 1;
        } else {
          return a[sortBy] < b[sortBy] ? 1 : -1;
        }
      });

      return sortedItems;
    }

    return data;
  }, [data, sortBy, sortOrder]);

  console.log(
    leadShortListAppliedFindSuccess,
    "leadShortListAppliedFindSuccess"
  );
  console.log(
    universityHandlerName,
    "universityHandlerNameuniversityHandlerName"
  );

  const openMenu = Boolean(anchorEl);

  const handleClickOpen = (event, appliedListId, status) => {
    console.log(appliedListId, "appliedListIdappliedListId");
    setCurrentStatus(status);
    setMenuData(appliedListId.applicationStatus);
    setAppliedApplicationId(appliedListId.id);
    setLeadId(appliedListId?.leadId);
    setApplicationCountry(appliedListId.country);
    setUniversityHandlerName(appliedListId?.universityHandlerName);
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
    setOpenDialog(false);
  };

  const handleOpenDialog = (status, statusName) => {
    setAnchorEl(null);
    setOpen(false);
    setDialogComponentName(statusName);
    setCurrentStatus(statusName);
    setOpenDialog(true);
  };

  const handleSelectedChips = (name) => {
    let shortlistFilter = shortListShowData?.map((data, index) => ({
      id: data.id,
      leadId: data?.leadId,
      sl: index + 1,
      university: data.universityName,
      country: data.universityCountry,
      courseType: data.courseLevel,
      course: data.courseName,
      intake: data.intakeDate,
      tuitionFee: data.universityInternationalFee,
      applicationStatus: data.methodStatus,
      universityGivenId: data.universityGivenId,
      universityHandlerName: data.university?.universityHandler,
      applicationStartedDate: data?.createdAt?.split("T")[0]
    }));
    let filter = shortlistFilter.filter((c) => c.country === name);
    if (name == "All") {
      setData(shortlistFilter);
    } else {
      setData(filter);
    }
  };

  const CustomSortIcon = ({ direction }) => {
    return direction === "asc" ? (
      <img
        src="/menuIcons/tableUpSort.png"
        className="administratCustomIcon"
        alt="gsl"
        style={{ padding: "15px" }}
      />
    ) : (
      <BiSortAlt2 className="LeadCustomIcon" />
    );
  };

  return (
    <>
      {/* {leadOwnerSuccess && <Loader />} */}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AppliedShortListChip
            array={applicationCountryList}
            handleChange={handleSelectedChips}
          />
        </Grid>
        {/* {sortedData?.length > 0 && ( */}

        <Grid item xs={12}>
          <Box className="application-table-scroll" sx={{ overflowX: "auto" }}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  {columns?.map((column) => {
                    if (column.hidden) {
                      return null;
                    }
                    return (
                      <TableCell key={column.field} className="tableCell">
                        <TableSortLabel
                          className={classes.headerText}
                          active={column === sortBy}
                          direction={sortOrder}
                          onClick={() => requestSort(column)}
                          sx={{
                            textTransform: "capitalize"
                          }}
                        >
                          {column.title}
                        </TableSortLabel>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedData?.map((item, indx) => (
                  <TableRow className={classes.tableRow} key={indx}>
                    <TableCell>{item.sl}</TableCell>
                    <TableCell>{item.university}</TableCell>
                    <TableCell>{item.country ? item.country : "-"}</TableCell>
                    <TableCell>
                      {item.courseType ? item.courseType : "-"}
                    </TableCell>
                    <TableCell>{item.course ? item.course : "-"}</TableCell>
                    <TableCell>{item.intake ? item.intake : "-"}</TableCell>
                    <TableCell>
                      {item.applicationStartedDate
                        ? item.applicationStartedDate
                        : "-"}
                    </TableCell>
                    <TableCell>
                      {item.tuitionFee ? item.tuitionFee : "-"}
                    </TableCell>
                    <TableCell>
                      {item.universityGivenId ? item.universityGivenId : "-"}
                    </TableCell>
                    <TableCell>
                      {item.OfferLetterType ? item.OfferLetterType : "-"}
                    </TableCell>
                    <TableCell>
                      {item.offerLetterAddedDate
                        ? item.offerLetterAddedDate
                        : "-"}
                    </TableCell>
                    <TableCell sx={{ color: "#F9A450" }}>
                      <div style={{ display: "flex" }}>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "17px"
                          }}
                        >
                          {item.applicationStatus}
                        </Typography>
                        {mainMenu !== "entrolled" ? (
                          <ImageListItem
                            id="demo-positioned-button"
                            aria-controls={
                              open ? "demo-positioned-menu" : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            onClick={(e) => {
                              if (item.applicationStatus === "deferral") {
                                Swal.fire({
                                  icon: "error",
                                  title: "Oops...",
                                  text: "Something went wrong!... please shortlist other application",
                                  footer: `You cannot update anything here as this application status is deferral`
                                });
                              } else {
                                handleClickOpen(
                                  e,
                                  item,
                                  item.applicationStatus
                                );
                              }
                            }}
                            key={item.img}
                            style={{ display: "block" }}
                          >
                            <img
                              style={{
                                cursor: "pointer",
                                paddingLeft: "15px",
                                width: "20px",
                                height: "auto"
                              }}
                              src="/menuIcons/aplicationAppliedStatus.png"
                              alt="gsl_shorlist"
                            />
                          </ImageListItem>
                        ) : (
                          ""
                        )}
                      </div>
                    </TableCell>
                    <TableCell
                      style={{
                        padding: "0 16px",
                        whiteSpace: "nowrap"
                      }}
                    >
                      <Box
                        sx={{ paddingLeft: "30px", cursor: "pointer" }}
                        target="blank"
                        onClick={() =>
                          navigate(
                            `/application/applicationDetails/${item?.id}/${item?.leadId}`
                          )
                        }
                      >
                        <AiOutlineEye
                          style={{
                            fontSize: "lg",
                            width: "20px",
                            height: "19px"
                          }}
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter />
            </Table>
          </Box>
        </Grid>

        {/* )} */}
      </Grid>
      {/* {sortedData?.length === 0 && <TableNoItemComponent />} */}

      {openDialog === true && (
        <Dialogue
          openTrue={openDialog}
          handleClose={handleClose}
          headerName={
            dialogComponentName === "Interview Training"
              ? "Choose Interviewer"
              : dialogComponentName === "Submitted to the university"
              ? "Choose University Handler"
              : dialogComponentName === "Submitted for Visa"
              ? "Choose Visa Officer"
              : dialogComponentName === "Visa received"
              ? "Visa details"
              : dialogComponentName === "Visa Applied"
              ? "Visa Status"
              : dialogComponentName === "Offer Letter Received" ||
                dialogComponentName === "Unconditional Offer"
              ? "Enter Details"
              : dialogComponentName === "Submitted Declaration"
              ? "Upload Declaration form"
              : dialogComponentName === "Declaration Completed"
              ? "Upload Declaration form from student"
              : dialogComponentName === "Deposit Paid"
              ? "Enter Details"
              : dialogComponentName === "Refund In Progress"
              ? "Enter Refund amount"
              : dialogComponentName === "Enrolled"
              ? "Enter visa received date"
              : ""
          }
          Dailogcomponent={
            dialogComponentName === "Interview Training"
              ? "Interviewer"
              : dialogComponentName === "Submitted to the university"
              ? "university handler"
              : dialogComponentName === "Submitted for Visa"
              ? "visa officer"
              : dialogComponentName === "Visa received"
              ? "university ID"
              : dialogComponentName === "Visa Applied"
              ? "visaDetails"
              : dialogComponentName === "Offer Letter Received"
              ? "Offer Letter Received"
              : dialogComponentName === "Unconditional Offer"
              ? "Unconditional Offer"
              : dialogComponentName === "Submitted Declaration"
              ? "Declaraton"
              : dialogComponentName === "Declaration Completed"
              ? "declarationCompleted"
              : dialogComponentName === "Deposit Paid"
              ? "depositPaid"
              : dialogComponentName === "Refund In Progress"
              ? "refundPopup"
              : dialogComponentName === "Enrolled"
              ? "entrolled"
              : ""
          }
          appliedApplicationId={appliedApplicationId}
          applicationCountry={applicationCountry}
          handler={universityHandlerName}
          leadID={leadId}
        />
      )}

      {open === true && (
        <MenuBar
          openTrue={open}
          handleClose={handleClose}
          handleOpenDialog={handleOpenDialog}
          appliedApplicationId={appliedApplicationId}
          menuStatus={menuData}
          currentStatus={currentStatus.trim()}
          applicationCountry={applicationCountry}
        />
      )}
    </>
  );
}

export default AppliedShortList;
