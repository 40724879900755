import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./university.css";

import {
  TextField,
  Button,
  Chip,
  Grid,
  Typography,
  Paper,
  Container,
  Divider,
  Alert,
  AlertTitle
} from "@mui/material";

import {
  findUniversityAction,
  universityAndCourseBulkUploadAction,
  universityDeleteAction
} from "../../../actions/universityActions/universityManagmentAction";
import Swal from "sweetalert2";
import { OutLinedButton, SubmitButton } from "../../customComponent/Buttons";
import BulkUploadModal from "./BulkUploadModal";
import { getUniversityCoverImageAction } from "../../../actions/settings/settingsActions";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";
import { CourseBulkUploadAction } from "../../../actions/universityActions/courseActions/universityCourseAction";
import { successTrueMsgAction } from "../../../actions/globalAction";
import {
  UNIVERSITYANDCOURSE_BULKUPLOAD_SUCCESS,
  UNIVERSITYANDCOURSE_BULKUPLOAD_ERR
} from "../../../constants/university/universityManagentConstant";
import {
  UNIVERSITY_COURSE_BULKUPLOAD_SUCCESS,
  UNIVERSITY_COURSE_BULKUPLOAD_ERR
} from "../../../constants/university/courseConstant/universityCourseConstant";

function Universities() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { privilege, hasPrivilege, isCreatePrevilage } = usePrivilegeCheck();
  const [data, setData] = useState([]);
  const [sortingOrder, setSortingOrder] = useState("asc");
  const [university, setUniversity] = useState("");
  const [country, setCountry] = useState("");
  const [bulkUploadModalOpen, setBulkUploadModalOpen] = useState(false);
  const [bulkUploadModelTitle, setBulkUploadModelTitle] = useState("");
  const [bulkUploadModelUrl, setBulkUploadModelUrl] = useState("");
  const [bulkUploadName, setBulkUploadName] = useState("");

  const [universityExcel, setUniversityExcel] = useState("");

  const { universityFindLoading, universityFindSuccess, universityFindErr } =
    useSelector((state) => {
      return state.findUniversity;
    });

  let {
    universityMasterAddLoading,
    universityMasterAddSuccess,
    universityMasterAddErr
  } = useSelector((state) => {
    return state.universityMasterAdd;
  });

  let {
    universityAndCourseBulkUploadLoading,
    universityAndCourseBulkUploadSuccess,
    universityAndCourseBulkUploadErr
  } = useSelector((state) => {
    return state.universityAndCourseBulkUpload;
  });

  let {
    universityCourseBulkUploadLoading,
    universityCourseBulkUploadSuccess,
    universityCourseBulkUploadErr
  } = useSelector((state) => {
    return state.courseBulkUpload;
  });

  const { universityAddLoading, universityAddSuccess, universityAddErr } =
    useSelector((state) => {
      return state.universityAddSubmit;
    });

  const { universityDeleteSuccess } = useSelector((state) => {
    return state.universityDelete;
  });

  let { successAlertMsgSuccess, successAlertMsgErr } = useSelector((state) => {
    return state.successTrueMsg;
  });

  // Response of getUniversityCoverImageAction

  let { getUniversityCoverImageSuccess } = useSelector(
    (state) => state.getUniversityCoverImage
  );

  // UseEffect to find Default universityCoverImage

  useEffect(() => {
    dispatch(getUniversityCoverImageAction());
  }, []);

  useEffect(() => {
    if (universityMasterAddErr) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${universityMasterAddErr}`
      });
    }
  }, [universityMasterAddErr]);

  useEffect(() => {
    if (
      (universityAndCourseBulkUploadSuccess?.success == true &&
        successAlertMsgSuccess) ||
      (universityCourseBulkUploadSuccess?.success == true &&
        successAlertMsgSuccess)
    ) {
      Swal.fire("Added!", "Uploaded Successfully.", "success").then((value) => {
        dispatch({
          type: UNIVERSITYANDCOURSE_BULKUPLOAD_SUCCESS,
          payload: false
        });
        dispatch({
          type: UNIVERSITY_COURSE_BULKUPLOAD_SUCCESS,
          payload: false
        });
      });
      dispatch(successTrueMsgAction(false));
    }

    if (
      universityCourseBulkUploadErr?.success == false &&
      successAlertMsgErr == true
    ) {
      Swal.fire("Error!", "Uploaded Error.", "error").then((value) => {
        dispatch({ type: UNIVERSITYANDCOURSE_BULKUPLOAD_ERR, payload: false });
        dispatch({ type: UNIVERSITY_COURSE_BULKUPLOAD_ERR, payload: false });
      });
      dispatch(successTrueMsgAction(false));
    }
  }, [
    universityAndCourseBulkUploadSuccess,
    universityCourseBulkUploadSuccess,
    successAlertMsgSuccess,
    successAlertMsgErr
  ]);

  console.log(
    universityCourseBulkUploadErr,
    "universityCourseBulkUploadErruniversityCourseBulkUploadErr"
  );

  useEffect(() => {
    dispatch(findUniversityAction());
  }, [
    dispatch,
    university,
    country,
    universityMasterAddSuccess,
    universityAddSuccess,
    universityAndCourseBulkUploadSuccess,
    universityCourseBulkUploadSuccess,
    universityDeleteSuccess
  ]);

  useEffect(() => {
    if (universityFindSuccess) {
      setData(universityFindSuccess);
    }
  }, [universityFindSuccess]);

  // search bar

  const [sortBy, setSortBy] = useState("");

  const handleUniversityChange = (event) => {
    setUniversity(event.target.value);
  };

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };

  const handleClear = () => {
    setUniversity("");
    setCountry("");
    setData(universityFindSuccess);
  };

  const handleSortByChange = (value) => {
    // if (value === sortBy) {
    if (value === "rank") {
      // setSortBy(`-${value}`);
      setSortingOrder(sortingOrder === "asc" ? "desc" : "asc");

      const sortedData = [...data].sort((a, b) => {
        const rankA = parseInt(a.universityRank, 10);
        const rankB = parseInt(b.universityRank, 10);

        if (sortingOrder === "asc") {
          return rankA - rankB; // Sort in ascending order
        } else {
          return rankB - rankA; // Sort in descending order
        }
      });
      setData(sortedData);
    }
    if (value === "AtoZ") {
      const AlphasortedData = [...data].sort((a, b) => {
        const lowercaseA = a.universityName.toLowerCase();
        const lowercaseB = b.universityName.toLowerCase();

        if (lowercaseA < lowercaseB) {
          return -1; // a should come before b
        }
        if (lowercaseA > lowercaseB) {
          return 1; // a should come after b
        }
        return 0;
      });
      setData(AlphasortedData);
    }

    if (value === "ZtoA") {
      const AlphadecsortedData = [...data].sort((a, b) => {
        const lowercaseA = a.universityName.toLowerCase();
        const lowercaseB = b.universityName.toLowerCase();

        if (lowercaseA > lowercaseB) {
          return -1; // a should come after b for descending order
        }
        if (lowercaseA < lowercaseB) {
          return 1; // a should come before b for descending order
        }
        return 0; // a and b are equal
      });

      setData(AlphadecsortedData);
    }
    // Clicked on the currently selected sorting option, toggle ascending/descending order

    // } else {
    //   setSortBy(value);
    // }
  };

  const handleSearch = () => {
    const filteredData = data.filter(
      (item) =>
        item.universityName.toLowerCase().includes(university.toLowerCase()) &&
        item.universityCountry.toLowerCase().includes(country.toLowerCase())
    );
    // Update the state with the filtered data
    setData(filteredData);
    console.log(`Searching for university: ${university}, country: ${country}`);
  };

  const handleAddCountry = () => {
    navigate("/university/addUniversity");
  };

  const openBulkUploadModal = (title, url, uploadName) => {
    setBulkUploadModalOpen(true);
    setBulkUploadModelTitle(title);
    setBulkUploadModelUrl(url);
    setBulkUploadName(uploadName);
  };

  const handleBulkUploadSubmit = (bulkUploadValue, name) => {
    const formData = new FormData();
    formData.append("file", bulkUploadValue);
    console.log(name, "nameeeeeeeeee");

    if (name == "university") {
      dispatch(universityAndCourseBulkUploadAction(formData));
      dispatch(successTrueMsgAction(true));
    }
    if (name == "course") {
      dispatch(CourseBulkUploadAction(formData));
      dispatch(successTrueMsgAction(true));
    }

    console.log(bulkUploadValue, "bulkUpload");
  };

  if (!hasPrivilege("universities") && privilege !== null) {
    return (
      <Alert severity="error">
        <AlertTitle>warning</AlertTitle>
        You don't have permission to access — <strong>this page.!</strong>
      </Alert>
    );
  }

  console.log(isCreatePrevilage("universities"), "universitiesuniversities");

  console.log(data, "datadata");
  console.log(getUniversityCoverImageSuccess, "getUniversityCoverImageSuccessgetUniversityCoverImageSuccess");


  return (
    <>
      <BulkUploadModal
        open={bulkUploadModalOpen}
        handleClose={() => setBulkUploadModalOpen(false)}
        handleSubmit={handleBulkUploadSubmit}
        title={bulkUploadModelTitle}
        downloadurl={bulkUploadModelUrl}
        name={bulkUploadName}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "9px",
          gap: "15px"
        }}
      >
        {isCreatePrevilage("universities") && (
          <>
            <SubmitButton
              title="Bulk Upload university"
              submit=""
              widthSize="200px"
              heightSize="35px"
              type="click"
              handleSubmit={() =>
                openBulkUploadModal(
                  "Bulk upload university",
                  "https://gsl-dev2.s3.ap-south-1.amazonaws.com/bulkUpload/bulkUpload594",
                  "university"
                )
              }
            />

            <SubmitButton
              title="Bulk Upload course"
              submit=""
              widthSize="180px"
              heightSize="35px"
              type="click"
              handleSubmit={() =>
                openBulkUploadModal(
                  "Bulk upload course",
                  "https://gsl-dev2.s3.ap-south-1.amazonaws.com/bulkUpload/bulkUpload601",
                  "course"
                )
              }
            />

            <SubmitButton
              title="Add University"
              submit=""
              widthSize="150px"
              heightSize="35px"
              type="click"
              handleSubmit={handleAddCountry}
            />
          </>
        )}
      </div>

      <Paper
        elevation={0}
        sx={{
          p: 3,
          backgroundColor: "#f1efef",
          marginBottom: "59px",
          width: "auto",
          height: "167px"
        }}
      >
        <Grid container spacing={2} sx={{ mt: "-30px" }}>
          <Grid item xs={12} lg={6}>
            <Paper sx={{ height: "35px", pt: 1, pb: 1 }} elevation={0}>
              <TextField
                className="textField"
                label="University"
                value={university}
                onChange={handleUniversityChange}
                fullWidth
                sx={{
                  textAlign: "center",
                  // backgroundColor: "white",
                  border: "none",
                  "& fieldset": { border: "none", borderRadius: "4px" },
                  // height: "auto",
                  mt: -0.5
                }}
              />
            </Paper>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Paper sx={{ height: "35px", pt: 1, pb: 1 }} elevation={0}>
              <TextField
                className="textField"
                label="Country"
                value={country}
                onChange={handleCountryChange}
                fullWidth
                sx={{
                  textAlign: "center",
                  // backgroundColor: "white",
                  border: "none",
                  "& fieldset": { border: "none", borderRadius: "4px" },
                  // height: "auto",
                  mt: -0.5
                }}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Typography
              sx={{
                fontFamily: "Alexandria",
                fontWeight: 400,
                fontSize: "13px"
              }}
            >
              Sort Result by
            </Typography>
          </Grid>
          <Grid item xs={6} lg={12}>
            <Chip
              label="Rank"
              onClick={() => handleSortByChange("rank")}
              variant="outlined"
              sx={{
                fontWeight: 400,
                borderColor: "#141E3C",
                width: "78px",
                height: "27px"
              }}
            />

            <Chip
              sx={{
                ml: 2,
                fontWeight: 400,
                fontSize: "11px",
                borderColor: "#141E3C",
                width: "78px",
                height: "27px"
              }}
              label="A -> Z"
              onClick={() => handleSortByChange("AtoZ")}
              variant="outlined"
            />

            <Chip
              sx={{
                ml: 2,
                fontWeight: 400,
                fontSize: "12px",
                borderColor: "#141E3C",
                width: "78px",
                height: "27px"
              }}
              label="Z -> A"
              onClick={() => handleSortByChange("ZtoA")}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={6} lg={6} sx={{ mt: 1 }}>
            {/* <Button
              variant="outlined"
              sx={{
                color: "#141E3C",
                borderColor: "#141E3C",
                fontWeight: 700,
                fontSize: "12px",
                borderRadius: "8px",
                boxShadow: 0,
              }}
              onClick={handleClear}
            >
              Clear
            </Button> */}

            <OutLinedButton
              title="Clear"
              handleClick={handleClear}
              widthSize=""
              heightSize=""
            />

            <Button
              sx={{
                color: "white",
                backgroundColor: "#141E3C",
                ml: 1,
                fontWeight: 700,
                fontSize: "12px",
                borderRadius: "8px",
                boxShadow: 0,
                "&:hover": {
                  backgroundColor: "rgba(20, 30, 60, 0.9)"
                }
              }}
              variant="contained"
              onClick={handleSearch}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Grid container>
        <Grid item xs={6} lg={6}>
          {data.length !== 0 && (
            <Typography style={{ fontWeight: 700, fontSize: "16px" }}>
              Viewing - {data.length}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Container maxWidth="xl">
        {data.map((university) => {
          return (
            <>
              <Grid
                container
                sx={{ mt: 5, backgroundColor: "white", height: "auto" }}
              >
                <Grid item xs={12} lg={3}>
                  <img
                    src={`${
                      university?.images?.length !== 0
                        ? university?.images[0]
                        : getUniversityCoverImageSuccess
                        ? getUniversityCoverImageSuccess[0]?.image
                        : ""
                    }`}
                    style={{ width: "200px", height: "auto" }}
                    alt="gsl_university_image"
                  />
                </Grid>

                <Grid item xs={12} lg={4} sx={{ width: "auto", mt: 2 }}>
                  <Typography variant="h5">
                    {university.universityName}
                  </Typography>
                  <Typography sx={{ color: "red" }}>
                    universityID :<span>{university.universityID}</span>
                  </Typography>

                  <Typography style={{ width: "auto", wordWrap: "break-word" }}>
                    {university.aboutUniversity}
                  </Typography>
                </Grid>

                <Grid item xs={12} lg={5}>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      sx={{
                        color: "white",
                        backgroundColor: "#141E3C",
                        fontWeight: 700,
                        fontSize: "12px",
                        borderRadius: "8px",
                        width: "128px",
                        "&:hover": {
                          backgroundColor: "rgba(20, 30, 60, 0.9)"
                        }
                      }}
                      onClick={() =>
                        navigate(
                          `/university/addUniversity/ViewUniversity/${university.id}`
                        )
                      }
                    >
                      View Profile
                    </Button>

                    <Button
                      sx={{
                        color: "white",
                        backgroundColor: "#EA4A67",
                        fontWeight: 700,
                        fontSize: "12px",
                        borderRadius: "8px",
                        width: "128px",
                        "&:hover": {
                          backgroundColor: "red"
                        },
                        ml: 3
                      }}
                      onClick={() => {
                        Swal.fire({
                          title: "Are you sure?",
                          text: "do You want to delete this ?",
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#3085d6",
                          cancelButtonColor: "#d33",
                          confirmButtonText: "Yes, delete it!"
                        }).then((result) => {
                          if (result.isConfirmed) {
                            dispatch(universityDeleteAction(university.id));

                            Swal.fire(
                              "Deleted!",
                              "This university is deleted.",
                              "success"
                            );
                          }
                        });
                      }}
                    >
                      Delete Profile
                    </Button>
                  </div>
                </Grid>
              </Grid>
              <Divider sx={{ mt: 0.8 }} />
            </>
          );
        })}
      </Container>
    </>
  );
}

export default Universities;
