import React, { useEffect, useState } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { MainContainer } from "../../content/content.element.js";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  InputAdornment,
  TextField,
  Typography
} from "@mui/material";
import EnquiryTable from "../../Enquiry/EnquiryTable.js";
import { RiSearch2Line } from "react-icons/ri";
import { DialogTitle, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import TableNoItemComponent from "../../customComponent/TableNoItemComponent.js";
import RegistrationFeeTable from "./registrationFeeTable.js";
import {
  applicationDepositeCreateAction,
  applicationPaymentHistoryFindAction
} from "../../../actions/aplicationActions/applicationsAction.js";
import TutionFeeTable from "./TutionFeeTable.js";
import { Close } from "@mui/icons-material";
import InputField from "../../customComponent/InputField.js";
import { FcRemoveImage } from "react-icons/fc";
import Dialogue from "../../customComponent/Dialogue.js";
import { RxCross1 } from "react-icons/rx";
import Swal from "sweetalert2";
import { APPLICATION_DEPOSITE_CREATE_SUCCESS } from "../../../constants/applicationConstant.js";
import RefundFeeTable from "./RefundFeeTable.js";

const useStyles = makeStyles((theme) => ({
  searchContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    position: "relative"
  },
  searchInput: {
    marginLeft: theme.spacing(1)
  }
}));
function PaymentHistory({ toggle }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { applicationID, leadID } = useParams();
  console.log(applicationID, leadID, "aplicationIDddddd");
  const [refistrationFeeList, setRegistrationFeeList] = useState([]);
  const [tutionFeeList, setTutionFeeList] = useState([]);
  const [refundFeeList, setRefundFeeList] = useState([]);

  const [openAlert, setOpenAlert] = useState(false);
  const [remainingBalance, setBalanceAmount] = useState("");
  const [addedBalanceAmount, setAddedBalanceAmount] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");

  // tution fee
  const [paymentFeeFormError, setPaymentFeeFormError] = useState({});

  // tution fee

  const [discount, setDiscount] = useState(false);
  const [scholarShip, setScholarShip] = useState(false);

  const [formValue, setFormValue] = useState({
    totalTutionFee: 0,
    paidAmount: 0,
    paymentDate: 0,
    paymentTime: 0,
    receipt: "",
    remarks: "",
    scholarShipName: "",
    scholarShipAmount: 0,
    discountAmount: 0,
    balanceAmount: 0
  });

  let { applicationPaymenthistoryFindSuccess } = useSelector(
    (state) => state.applicationPaymenthistoryFind
  );

  // Desposite create reducer

  const { applicationDepositeCreateSuccess, applicationDepositeCreateError } =
    useSelector((state) => {
      return state.applicationDepositeCreate;
    });

  useEffect(() => {
    dispatch(applicationPaymentHistoryFindAction(leadID, applicationID));
  }, [dispatch, applicationDepositeCreateSuccess]);

  useEffect(() => {
    if (applicationPaymenthistoryFindSuccess) {
      setRegistrationFeeList(
        applicationPaymenthistoryFindSuccess?.data?.registrationFee
      );
      setTutionFeeList(applicationPaymenthistoryFindSuccess?.data?.tutionFee);

      setRefundFeeList(applicationPaymenthistoryFindSuccess?.data?.refundFee);

      // Sum all paidAmount values
      const totalPaidAmount =
        applicationPaymenthistoryFindSuccess?.data?.tutionFee.reduce(
          (sum, entry) => {
            return sum + parseFloat(entry.paidAmount);
          },
          0
        );

      setBalanceAmount(totalPaidAmount);

      setAddedBalanceAmount(
        applicationPaymenthistoryFindSuccess?.data?.tutionFee[0]
          ?.totalTutionFee - totalPaidAmount
      );
    }
  }, [applicationPaymenthistoryFindSuccess]);

  // useEffect(() => {
  //   if (applicationDepositeCreateSuccess) {
  //     Swal.fire(
  //       "Success!",
  //       "Payment details have been submitted for finance team approval. The added data will be reflected after approval.",
  //       "success"
  //     );
  //     dispatch({ type: APPLICATION_DEPOSITE_CREATE_SUCCESS, payload: false });
  //   }
  // }, [applicationDepositeCreateSuccess]);

  console.log(
    applicationPaymenthistoryFindSuccess,
    "applicationPaymenthistoryFindSuccessapplicationPaymenthistoryFindSuccess"
  );

  console.log(formValue, "formValueformValueformValue");

  const [searchText, setSearchText] = useState("");
  const [paginationPageNumber, setPaginationPageNumber] = useState(1);
  const [pagiantionEntry, setPagiantionEntry] = useState(8);

  const searchHandleChange = (e) => {
    let value = e.target.value;
    let result = applicationPaymenthistoryFindSuccess?.filter(
      (item) =>
        item.firstName?.toLowerCase()?.includes(value?.toLowerCase()) ||
        item.lastName?.toLowerCase()?.includes(value?.toLowerCase())
    );
    console.log(result);
    setRegistrationFeeList(result);
  };

  const handleUpdateTutionFee = () => {
    const validate = () => {
      let errors = {};

      if (formValue.paidAmount === "") {
        errors.paidAmount = "paidAmount is required";
      }
      if (formValue.paymentDate === "") {
        errors.paymentDate = "paymentDate is required";
      }
      if (formValue.receipt === "") {
        errors.receipt = "receipt is required";
      }
      
      setPaymentFeeFormError(errors);
      console.log(errors);
      return Object.keys(errors).length === 0;
    };

    if (validate()) {
      const formData = new FormData();
      formData.append("currency", tutionFeeList[0].currency);
      formData.append("totalTutionFee", tutionFeeList[0]?.totalTutionFee);
      formData.append("scholarShipName", formValue.scholarShipName);
      formData.append("scholarShipAmount", formValue.scholarShipAmount);
      formData.append("discountAmount", formValue.discountAmount);
      formData.append(
        "totalFeeToBePaid",
        Number(remainingBalance) + Number(formValue.paidAmount)
      );
      formData.append("paidAmount", formValue.paidAmount);
      formData.append("paidDate", formValue.paymentDate);
      formData.append("paidTime", formValue.paymentTime);
      formData.append("receipt", formValue.receipt);
      formData.append("balanceAmount", formValue.balanceAmount);
      formData.append("remark", formValue.remarks);
      formData.append("leadID", leadID);
      formData.append("aplicationID", applicationID);
      dispatch(applicationDepositeCreateAction(formData));
      Swal.fire(
        "Success!",
        "Payment details have been submitted for finance team approval. The added data will be reflected after approval.",
        "success"
      );
    }
    setOpenAlert(false);
  };

  console.log(tutionFeeList, "tutionFeeListtutionFeeList");

  const columns = [
    {
      title: "Sl no",
      field: "sl"
    },
    {
      title: "AMOUNT",
      field: "AMOUNT"
    },
    {
      title: "DATE & TIME",
      field: "DATE & TIME"
    },
    {
      title: "REMARK",
      field: "REMARK"
    },

    {
      title: "RECEIPT",
      field: "RECEIPT"
    }
  ];

  const tutionFeecolumns = [
    {
      title: "Sl no",
      field: "sl"
    },
    {
      title: "STUDENT ID",
      field: "STUDENT ID"
    },
    {
      title: "APPLICATION ID",
      field: "APPLICATION ID"
    },
    {
      title: "UNIVERSITY",
      field: "UNIVERSITY"
    },
    {
      title: "UNIVERSITY TYPE",
      field: "UNIVERSITY TYPE"
    },
    {
      title: "TOTAL AMOUNT",
      field: "TOTAL AMOUNT"
    },

    {
      title: "SCHOLARSHIP",
      field: "SCHOLARSHIP"
    },

    {
      title: "DISCOUNT",
      field: "DISCOUNT"
    },

    {
      title: "TOTAL PAID AMOUNT",
      field: "TOTAL PAID AMOUNT"
    },

    {
      title: "PAID AMOUNT",
      field: "PAID AMOUNT"
    },

    {
      title: "BALANCE AMOUNT",
      field: "BALANCE AMOUNT"
    },

    {
      title: "PAID DATE & TIME",
      field: "PAID DATE & TIME"
    },

    {
      title: "REMARK",
      field: "REMARK"
    },

    {
      title: "APPROVED BY",
      field: "APPROVED BY"
    },

    {
      title: "APPROVED DATE",
      field: "APPROVED DATE"
    },

    {
      title: "STATUS",
      field: "STATUS"
    },
    {
      title: "PAYSLIP",
      field: "PAYSLIP"
    }
  ];

  const Refundcolumns = [
    {
      title: "SL NO",
      field: "sl"
    },
    {
      title: "STUDENT ID",
      field: "STUDENT ID"
    },
    {
      title: "STUDENT NAME",
      field: "STUDENT NAME"
    },
    {
      title: "APPLICATION ID",
      field: "APPLICATION ID"
    },
    {
      title: "COUNTRY",
      field: "COUNTRY"
    },
    {
      title: "UNIVERSITY",
      field: "UNIVERSITY"
    },
    {
      title: "UNIVERSITY TYPE",
      field: "UNIVERSITY TYPE"
    },
    {
      title: "COURSE",
      field: "COURSE"
    },
    {
      title: "TOTAL AMOUNT",
      field: "TOTAL AMOUNT"
    },

    {
      title: "TOTAL PAID AMOUNT",
      field: "TOTAL PAID AMOUNT"
    },

    {
      title: "REFUND AMOUNT",
      field: "REFUND AMOUNT"
    },

    {
      title: "REFUND INITIATED BY",
      field: "REFUND INITIATED BY"
    },
    {
      title: "REFUND INITIATED DATE &TIME",
      field: "REFUND INITIATED DATE &TIME"
    }
  ];

  return (
    <MainContainer active={toggle}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "10px"
        }}
      >
        <KeyboardBackspaceIcon
          onClick={() =>
            navigate(
              `/application/applicationDetails/${applicationID}/${leadID}`
            )
          }
          sx={{ cursor: "pointer" }}
        />
        <Typography
          sx={{
            color: "#05050F",
            fontSize: "22px",
            fontWeight: "700"
          }}
        >
          Payment History
        </Typography>
      </Box>

      <Box
        sx={{
          mt: "30px"
        }}
      >
        <Box sx={{ mb: 2 }}>
          <Typography
            sx={{ fontWeight: 600, fontSize: "16px", color: "#000000" }}
          >
            Registration Fee
          </Typography>
        </Box>
        <Box>
          {refistrationFeeList?.length > 0 ? (
            <RegistrationFeeTable
              columns={columns}
              data={refistrationFeeList}
              paginationPageNumber={paginationPageNumber}
              pagiantionEntry={pagiantionEntry}
            />
          ) : (
            ""
          )}
        </Box>
        <Box
          sx={{
            mb: 2,
            mt: 15,
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <Typography
            sx={{ fontWeight: 600, fontSize: "16px", color: "#000000" }}
          >
            Tution Fee
          </Typography>

          <Box>
            {tutionFeeList?.length !== 0 && (
              <Button
                sx={{
                  backgroundColor: "#141E3C",
                  color: "white",
                  width: "76px",
                  height: "25px",
                  fontWeight: 400,
                  fontSize: "12px",
                  mr: 2,
                  "&:hover": {
                    backgroundColor: "rgba(34, 110, 0, 0.5)"
                  }
                }}
                onClick={() => setOpenAlert(true)}
              >
                Add
              </Button>
            )}
          </Box>
        </Box>
        <Box>
          {tutionFeeList?.length > 0 ? (
            <TutionFeeTable
              columns={tutionFeecolumns}
              data={tutionFeeList}
              paginationPageNumber={paginationPageNumber}
              pagiantionEntry={pagiantionEntry}
            />
          ) : (
            ""
          )}
        </Box>

        <Typography
          sx={{ fontWeight: 600, fontSize: "16px", color: "#000000",mb:2 }}
        >
          Refund Details
        </Typography>

        <Box>
          {refundFeeList?.length > 0 ? (
            <RefundFeeTable
              columns={Refundcolumns}
              data={refundFeeList}
              paginationPageNumber={paginationPageNumber}
              pagiantionEntry={pagiantionEntry}
            />
          ) : (
            ""
          )}
        </Box>
        {refistrationFeeList?.length == 0 && tutionFeeList?.length == 0 ? (
          <TableNoItemComponent />
        ) : (
          ""
        )}
      </Box>

      {openAlert && (
        <Dialog
          open={openAlert}
          onClose={() => setOpenAlert(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            sx: {
              bottom: "35vh",
              width: "850px",
              maxWidth: "585px",
              height: "fit-content",
              maxHeight: "602px",
              marginTop: "45%"
            }
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "none"
            }}
          >
            <DialogTitle
              id="alert-dialog-title"
              sx={{ fontSize: "25px", fontWeight: 800 }}
            >
              Tuition Fee
            </DialogTitle>

            <RxCross1
              style={{
                width: "30px",
                height: "auto",
                margin: "15px",
                cursor: "pointer"
              }}
              onClick={() => setOpenAlert(false)}
            />
          </div>
          <DialogContent
            sx={{
              pt: "0"
            }}
          >
            <Box
              sx={{
                mt: 1,
                "& .input_style": { maxWidth: "calc(100% - 10px)" }
              }}
            >
              <Box>
                <Typography sx={{ fontWeight: 400, fontSize: "20px", mb: 2 }}>
                  Total tuition fee :
                  <span>{tutionFeeList[0]?.totalTutionFee}</span>
                </Typography>
                {/* <Typography sx={{ fontWeight: 400, fontSize: "20px", mb: 2 }}>
                  Amount to be paid :
                  <span>{tutionFeeList[0]?.totalFeeTobePaid}</span>
                </Typography> */}
                <Typography sx={{ fontWeight: 400, fontSize: "20px", mb: 2 }}>
                  Amount paid :<span>{remainingBalance}</span>
                </Typography>
                <Typography sx={{ fontWeight: 400, fontSize: "20px" }}>
                  Balance amount :
                  <span>
                    {tutionFeeList[tutionFeeList?.length - 1]?.balanceAmount}
                  </span>
                </Typography>
              </Box>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#F8469A",
                      "&.Mui-checked": {
                        color: "#F8469A"
                      }
                    }}
                    checked={scholarShip}
                    onChange={(e) => {
                      setScholarShip(!scholarShip);
                    }}
                  />
                }
                label={"Scholarship"}
              />
              {scholarShip && (
                <Box sx={{ display: "flex", gap: 2, mt: 1 }}>
                  <Grid md={6} sx={{ mr: 2 }}>
                    <InputField
                      label="ScholarShip Name"
                      handleChange={(e) =>
                        setFormValue((prevState) => ({
                          ...prevState,
                          ["scholarShipName"]: e.target.value
                        }))
                      }
                      widthSize="auto"
                      value={formValue.scholarShipName}
                      required
                      paramses
                    />
                  </Grid>
                  <Grid md={6}>
                    <InputField
                      label="ScholarShip Amount"
                      // handleChange={(e) =>
                      //   setFormValue((prevState) => ({
                      //     ...prevState,
                      //     ["scholarShipAmount"]: e.target.value
                      //   }))
                      // }

                      handleChange={(e) => {
                        const { value } = e.target;

                        setFormValue((prevState) => {
                          console.log(prevState, "Current state before update");

                          const paidAmount = Number(prevState.paidAmount);
                          const balanceAmount =
                            tutionFeeList[tutionFeeList?.length - 1]
                              ?.balanceAmount;
                          const scholarShipAmount = Number(value || 0);
                          const discountAmount = Number(
                            prevState.discountAmount || 0
                          );

                          // Calculate the new balance amount
                          const balanceRemaining =
                            balanceAmount -
                            scholarShipAmount -
                            discountAmount -
                            paidAmount;

                          return {
                            ...prevState,
                            scholarShipAmount: value,
                            balanceAmount: balanceRemaining
                          };
                        });
                      }}
                      widthSize="auto"
                      value={formValue.scholarShipAmount}
                      required
                      paramses
                    />
                  </Grid>
                </Box>
              )}

              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#F8469A",
                      "&.Mui-checked": {
                        color: "#F8469A"
                      }
                    }}
                    checked={discount}
                    onChange={(e) => {
                      setDiscount(!discount);
                    }}
                  />
                }
                label={"Discount"}
              />
              {discount && (
                <Grid md={12} sx={{ mr: 2 }}>
                  <InputField
                    label="Discount amount"
                    handleChange={(e) => {
                      const { value } = e.target;
                      setFormValue((prevState) => {
                        console.log(prevState, "Current state before update");

                        const paidAmount = Number(prevState.paidAmount);
                        const balanceAmount =
                          tutionFeeList[tutionFeeList?.length - 1]
                            ?.balanceAmount;
                        const scholarShipAmount = Number(
                          prevState.scholarShipAmount || 0
                        );
                        const discountAmount = Number(value || 0);

                        // Calculate the new balance amount
                        const balanceRemaining =
                          balanceAmount -
                          scholarShipAmount -
                          discountAmount -
                          paidAmount;

                        return {
                          ...prevState,
                          discountAmount: value,
                          balanceAmount: balanceRemaining
                        };
                      });
                    }}
                    widthSize="auto"
                    value={formValue.discountAmount}
                    required
                    paramses
                  />
                </Grid>
              )}
              <Box sx={{ display: "flex", mt: 2 }}>
                <Grid md={4} sx={{ mr: 2 }}>
                  <InputField
                    label="Paid Amount"
                    handleChange={(e) => {
                      const { value } = e.target;
                      setFormValue((prevState) => {
                        console.log(prevState, "Current state before update");

                        const paidAmount = Number(value);
                        const balanceAmount =
                          tutionFeeList[tutionFeeList?.length - 1]
                            ?.balanceAmount;
                        const scholarShipAmount = Number(
                          prevState.scholarShipAmount || 0
                        );
                        const discountAmount = Number(
                          prevState.discountAmount || 0
                        );

                        // Calculate the new balance amount
                        const balanceRemaining =
                          balanceAmount -
                          scholarShipAmount -
                          discountAmount -
                          paidAmount;
                        setPaymentStatus(balanceRemaining);
                        return {
                          ...prevState,
                          paidAmount: value,
                          balanceAmount: balanceRemaining
                        };
                      });
                    }}
                    widthSize="auto"
                    value={formValue.paidAmount}
                    required
                    paramses
                    error={Boolean(paymentFeeFormError?.paidAmount)}
                    helperText={paymentFeeFormError?.paidAmount}
                  />
                  {/* <span
                    style={{
                      color: "#FF2400",
                      fontSize: "12px",
                    }}
                  >
                    {paymentFeeFormError?.paidAmount}
                  </span> */}
                </Grid>
                <Grid md={4} sx={{ mr: 2 }}>
                  <InputField
                    handleChange={(e) => {
                      const selectedDate = new Date(e.target.value);
                      const today = new Date();
                      selectedDate.setHours(0, 0, 0, 0);
                      today.setHours(0, 0, 0, 0);

                      console.log(today, "today (normalized)");
                      console.log(selectedDate, "selectedDate (normalized)");

                      if (selectedDate > today) {
                        setPaymentFeeFormError((prevError) => ({
                          ...prevError,
                          paymentDate: "Future dates are not allowed"
                        }));
                      } else {
                        setPaymentFeeFormError((prevError) => ({
                          ...prevError,
                          paymentDate: ""
                        }));
                        setFormValue((prevState) => ({
                          ...prevState,
                          paymentDate: e.target.value
                        }));
                      }
                    }}
                    widthSize="auto"
                    value={formValue.paymentDate}
                    // required
                    paramses
                    InputType="date"
                    error={Boolean(paymentFeeFormError?.paymentDate)}
                    helperText={
                      paymentFeeFormError?.paymentDate ? (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {paymentFeeFormError.paymentDate}
                        </span>
                      ) : (
                        ""
                      )
                    }
                  />
                </Grid>
                <Grid md={4} sx={{ mr: 2 }}>
                  <InputField
                    label="Time"
                    handleChange={(e) =>
                      setFormValue((prevState) => ({
                        ...prevState,
                        ["paymentTime"]: e.target.value
                      }))
                    }
                    widthSize="auto"
                    value={formValue.paymentTime}
                    paramses
                    error={Boolean(paymentFeeFormError?.paymentTime)}
                    helperText={paymentFeeFormError?.paymentTime}
                  />
                </Grid>
              </Box>

              <Box>
                {paymentStatus !== "" &&
                  (paymentStatus <= 0 ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            color: "green",
                            "&.Mui-checked": {
                              color: "#F8469A"
                            }
                          }}
                          checked={true}
                        />
                      }
                      label={"Completed"}
                    />
                  ) : (
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            color: "green",
                            "&.Mui-checked": {
                              color: "#F8469A"
                            }
                          }}
                          checked={true}
                        />
                      }
                      label={"Desposit"}
                    />
                  ))}
              </Box>

              <Box>
                <Typography>Receipt</Typography>
              </Box>
              <Box sx={{ mt: 2 }}>
                {formValue.receipt ? (
                  <>
                    <FcRemoveImage
                      style={{
                        fontSize: "16px",
                        width: "115px",
                        height: "auto",
                        cursor: "pointer"
                      }}
                      onClick={() =>
                        setFormValue((prevState) => ({
                          ...prevState,
                          ["receipt"]: ""
                        }))
                      }
                    />
                    {/* <Typography>{leadAgreement.name}</Typography> */}
                  </>
                ) : (
                  <>
                    <label
                      for="upload-company-logo"
                      style={{
                        display: "inline-block",
                        cursor: "pointer",
                        backgroundColor: "#141E3C",
                        color: "#fff",
                        padding: "10px 20px",
                        borderRadius: "4px",
                        fontWeight: "bold",
                        transition: "background-color 0.3s ease",
                        fontSize: "14px"
                      }}
                    >
                      <span style={{ marginRight: "5px" }}>+</span> Upload
                      Receipt
                    </label>
                    <input
                      accept="pdf"
                      id="upload-company-logo"
                      type="file"
                      onChange={(e) =>
                        setFormValue((prevState) => ({
                          ...prevState,
                          ["receipt"]: e.target.files[0]
                        }))
                      }
                      style={{ display: "none" }}
                    />
                  </>
                )}
              </Box>
              <span
                style={{
                  color: "#FF2400",
                  fontSize: "12px"
                }}
              >
                {paymentFeeFormError?.receipt}
              </span>

              <Box sx={{ mt: 2 }}>
                <Grid md={4} sx={{ mr: 2 }}>
                  <InputField
                    label="Balance Amount"
                    handleChange={(e) =>
                      setFormValue((prevState) => ({
                        ...prevState,
                        ["balanceAmount"]: e.target.value
                      }))
                    }
                    widthSize="auto"
                    value={formValue.balanceAmount}
                    required
                    paramses
                    error={Boolean(paymentFeeFormError?.balanceAmount)}
                    helperText={paymentFeeFormError?.balanceAmount}
                    readonly={true}
                  />
                  {/* 
                  <span
                    style={{
                      color: "#FF2400",
                      fontSize: "12px",
                    }}
                  >
                    {paymentFeeFormError?.balanceAmount}
                  </span> */}
                </Grid>
                <Grid md={12} sx={{ mr: 2 }}>
                  <InputField
                    label="Remark"
                    handleChange={(e) =>
                      setFormValue((prevState) => ({
                        ...prevState,
                        ["remarks"]: e.target.value
                      }))
                    }
                    widthSize="auto"
                    value={formValue.remarks}
                    paramses
                    error={Boolean(paymentFeeFormError?.remarks)}
                    helperText={paymentFeeFormError?.remarks}
                  />
                  {/* <span
                    style={{
                      color: "#FF2400",
                      fontSize: "12px",
                    }}
                  >
                    {paymentFeeFormError?.remarks}
                  </span> */}
                </Grid>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  sx={{
                    backgroundColor: "#141E3C",
                    color: "white",
                    width: "180px",
                    height: "35px",
                    fontWeight: 600,
                    fontSize: "12px",
                    alignItems: "end",
                    mr: 2,
                    "&:hover": {
                      backgroundColor: "rgba(34, 110, 0, 0.5)"
                    }
                  }}
                  onClick={handleUpdateTutionFee}
                >
                  Submit for approval
                </Button>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </MainContainer>
  );
}

export default PaymentHistory;
