export const APPLICATION_ALL_FIND_LEAD_REQUEST =
  "APPLICATION_ALL_FIND_LEAD_REQUEST";
export const APPLICATION_ALL_FIND_LEAD_SUCCESS =
  "APPLICATION_ALL_FIND_LEAD_SUCCESS";
export const APPLICATION_ALL_FIND_LEAD_ERR = "APPLICATION_ALL_FIND_LEAD_ERR";

export const APPLICATION_FIND_BY_ID_LEAD_REQUEST =
  "APPLICATION_FIND_BY_ID_LEAD_REQUEST";
export const APPLICATION_FIND_BY_ID_LEAD_SUCCESS =
  "APPLICATION_FIND_BY_ID_LEAD_SUCCESS";
export const APPLICATION_FIND_BY_ID_LEAD_ERR =
  "APPLICATION_FIND_BY_ID_LEAD_ERR";

export const FIND_ALL_ASIGNED_STAFF_REQUEST = "FIND_ALL_ASIGNED_STAFF_REQUEST";
export const FIND_ALL_ASIGNED_STAFF_SUCCESS = "FIND_ALL_ASIGNED_STAFF_SUCCESS";
export const FIND_ALL_ASIGNED_STAFF_ERR = "FIND_ALL_ASIGNED_STAFF_ERR";

export const STAFF_ADD_INCENTIVE_AMOUNT_REQUEST =
  "STAFF_ADD_INCENTIVE_AMOUNT_REQUEST";
export const STAFF_ADD_INCENTIVE_AMOUNT_SUCCESS =
  "STAFF_ADD_INCENTIVE_AMOUNT_SUCCESS";
export const STAFF_ADD_INCENTIVE_AMOUNT_ERR = "STAFF_ADD_INCENTIVE_AMOUNT_ERR";

export const GET_STAFF_INCENTIVE_AMOUNT_REQUEST =
  "GET_STAFF_INCENTIVE_AMOUNT_REQUEST";
export const GET_STAFF_INCENTIVE_AMOUNT_SUCCESS =
  "GET_STAFF_INCENTIVE_AMOUNT_SUCCESS";
export const GET_STAFF_INCENTIVE_AMOUNT_ERR = "GET_STAFF_INCENTIVE_AMOUNT_ERR";

export const GET_BONUS_EMPLOYEES_REQUEST = "GET_BONUS_EMPLOYEES_REQUEST";
export const GET_BONUS_EMPLOYEES_SUCCESS = "GET_BONUS_EMPLOYEES_SUCCESS";
export const GET_BONUS_EMPLOYEES_ERR = "GET_BONUS_EMPLOYEES_ERR";

export const ADD_BONUS_EMPLOYEES_REQUEST = "ADD_BONUS_EMPLOYEES_REQUEST";
export const ADD_BONUS_EMPLOYEES_SUCCESS = "ADD_BONUS_EMPLOYEES_SUCCESS";
export const ADD_BONUS_EMPLOYEES_ERR = "ADD_BONUS_EMPLOYEES_ERR";

export const FIND_BONUS_EMPLOYEES_DETAILS_REQUEST =
  "FIND_BONUS_EMPLOYEES_DETAILS_REQUEST";
export const FIND_BONUS_EMPLOYEES_DETAILS_SUCCESS =
  "FIND_BONUS_EMPLOYEES_DETAILS_SUCCESS";
export const FIND_BONUS_EMPLOYEES_DETAILS_ERR =
  "FIND_BONUS_EMPLOYEES_DETAILS_ERR";

export const PARTNER_TEAM_LEAD_ASSIGN_REQUEST =
  "PARTNER_TEAM_LEAD_ASSIGN_REQUEST";
export const PARTNER_TEAM_LEAD_ASSIGN_SUCCESS =
  "PARTNER_TEAM_LEAD_ASSIGN_SUCCESS";
export const PARTNER_TEAM_LEAD_ASSIGN_ERR = "PARTNER_TEAM_LEAD_ASSIGN_ERR";

export const TEAM_LEAD_ASSIGN_STAFF_REQUEST = "TEAM_LEAD_ASSIGN_STAFF_REQUEST";
export const TEAM_LEAD_ASSIGN_STAFF_SUCCESS = "TEAM_LEAD_ASSIGN_STAFF_SUCCESS";
export const TEAM_LEAD_ASSIGN_STAFF_ERR = "TEAM_LEAD_ASSIGN_STAFF_ERR";

export const APPLICATION_DETAILS_FIND_REQUEST =
  "APPLICATION_DETAILS_FIND_REQUEST";
export const APPLICATION_DETAILS_FIND_SUCCESS =
  "APPLICATION_DETAILS_FIND_SUCCESS";
export const APPLICATION_DETAILS_FIND_ERR = "APPLICATION_DETAILS_FIND_ERR";

export const APPLICATION_DECLARATION_APPROVE_REQUEST =
  "APPLICATION_DECLARATION_APPROVE_REQUEST";
export const APPLICATION_DECLARATION_APPROVE_SUCCESS =
  "APPLICATION_DECLARATION_APPROVE_SUCCESS";
export const APPLICATION_DECLARATION_APPROVE_ERR =
  "APPLICATION_DECLARATION_APPROVE_ERR";

export const APPLICATION_DEPOSITE_CREATE_REQUEST =
  "APPLICATION_DEPOSITE_CREATE_REQUEST";
export const APPLICATION_DEPOSITE_CREATE_SUCCESS =
  "APPLICATION_DEPOSITE_CREATE_SUCCESS";
export const APPLICATION_DEPOSITE_CREATE_ERR =
  "APPLICATION_DEPOSITE_CREATE_ERR";


export const APPLICATION_PAYMENTHISTORY_FIND_REQUEST =
"APPLICATION_DEPOSITE_PAYMENTHISTORY_FIND_REQUEST";
export const APPLICATION_PAYMENTHISTORY_FIND_SUCCESS =
"APPLICATION_PAYMENTHISTORY_FIND_SUCCESS";
export const APPLICATION_PAYMENTHISTORY_FIND_ERR =
"APPLICATION_PAYMENTHISTORY_FIND_ERR";


export const APPLICATION_PAYMENTDETAILS_FIND_REQUEST =
"APPLICATION_DEPOSITE_PAYMENTDETAILS_FIND_REQUEST";
export const APPLICATION_PAYMENTDETAILS_FIND_SUCCESS =
"APPLICATION_PAYMENTDETAILS_FIND_SUCCESS";
export const APPLICATION_PAYMENTDETAILS_FIND_ERR =
"APPLICATION_PAYMENTDETAILS_FIND_ERR";
