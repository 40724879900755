import React, { useEffect, useState } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { MainContainer } from "../../content/content.element.js";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  InputAdornment,
  TextField,
  Typography
} from "@mui/material";
import EnquiryTable from "../../Enquiry/EnquiryTable.js";
import { RiSearch2Line } from "react-icons/ri";
import { DialogTitle, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import TableNoItemComponent from "../../customComponent/TableNoItemComponent.js";
import RegistrationFeeTable from "./registrationFeeTable.js";
import {
  applicationDepositeCreateAction,
  applicationPaymentDetailsFindAction,
  applicationPaymentHistoryFindAction
} from "../../../actions/aplicationActions/applicationsAction.js";
import TutionFeeTable from "./TutionFeeTable.js";
import { Close } from "@mui/icons-material";
import InputField from "../../customComponent/InputField.js";
import { FcRemoveImage } from "react-icons/fc";
import Dialogue from "../../customComponent/Dialogue.js";
import { RxCross1 } from "react-icons/rx";
import Swal from "sweetalert2";
import { APPLICATION_DEPOSITE_CREATE_SUCCESS } from "../../../constants/applicationConstant.js";
import RefundFeeTable from "./RefundFeeTable.js";

const useStyles = makeStyles((theme) => ({
  searchContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    position: "relative"
  },
  searchInput: {
    marginLeft: theme.spacing(1)
  }
}));
function PaymentDetails({ toggle }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { leadID } = useParams();
  console.log(leadID, "aplicationIDddddd");
  const [refistrationFeeList, setRegistrationFeeList] = useState([]);
  const [tutionFeeList, setTutionFeeList] = useState([]);
  const [refundFeeList, setRefundFeeList] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [remainingBalance, setBalanceAmount] = useState("");
  const [addedBalanceAmount, setAddedBalanceAmount] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");

  // tution fee
  const [paymentFeeFormError, setPaymentFeeFormError] = useState({});

  // tution fee

  const [discount, setDiscount] = useState(false);
  const [scholarShip, setScholarShip] = useState(false);

  const [formValue, setFormValue] = useState({
    totalTutionFee: "",
    paidAmount: "",
    paymentDate: "",
    paymentTime: "",
    receipt: "",
    remarks: "",
    scholarShipName: "",
    scholarShipAmount: "",
    discountAmount: "",
    balanceAmount: ""
  });

  let { applicationPaymentDetailsFindSuccess } = useSelector(
    (state) => state.applicationPaymentDetailsFind
  );

  // Desposite create reducer

  const { applicationDepositeCreateSuccess, applicationDepositeCreateError } =
    useSelector((state) => {
      return state.applicationDepositeCreate;
    });

  useEffect(() => {
    dispatch(applicationPaymentDetailsFindAction(leadID));
  }, [dispatch, applicationDepositeCreateSuccess]);

  useEffect(() => {
    if (applicationPaymentDetailsFindSuccess) {
      setRegistrationFeeList(
        applicationPaymentDetailsFindSuccess?.data?.registrationFee
      );
      setTutionFeeList(applicationPaymentDetailsFindSuccess?.data?.tutionFee);

      setRefundFeeList(applicationPaymentDetailsFindSuccess?.data?.refundFee);

      // Sum all paidAmount values
      const totalPaidAmount =
        applicationPaymentDetailsFindSuccess?.data?.tutionFee.reduce(
          (sum, entry) => {
            return sum + parseFloat(entry.paidAmount);
          },
          0
        );

      setBalanceAmount(totalPaidAmount);

      setAddedBalanceAmount(
        applicationPaymentDetailsFindSuccess?.data?.tutionFee[0]
          ?.totalTutionFee - totalPaidAmount
      );
    }
  }, [applicationPaymentDetailsFindSuccess]);

 

  console.log(
    applicationPaymentDetailsFindSuccess,
    "applicationPaymentDetailsFindSuccessapplicationPaymentDetailsFindSuccess"
  );

  console.log(formValue, "formValueformValueformValue");

  const [searchText, setSearchText] = useState("");
  const [paginationPageNumber, setPaginationPageNumber] = useState(1);
  const [pagiantionEntry, setPagiantionEntry] = useState(8);

  const searchHandleChange = (e) => {
    let value = e.target.value;
    let result = applicationPaymentDetailsFindSuccess?.filter(
      (item) =>
        item.firstName?.toLowerCase()?.includes(value?.toLowerCase()) ||
        item.lastName?.toLowerCase()?.includes(value?.toLowerCase())
    );
    console.log(result);
    setRegistrationFeeList(result);
  };



  console.log(tutionFeeList, "tutionFeeListtutionFeeList");

  const columns = [
    {
      title: "Sl no",
      field: "sl"
    },
    {
      title: "AMOUNT",
      field: "AMOUNT"
    },
    {
      title: "DATE & TIME",
      field: "DATE & TIME"
    },
    {
      title: "REMARK",
      field: "REMARK"
    },

    {
      title: "RECEIPT",
      field: "RECEIPT"
    }
  ];

  const tutionFeecolumns = [
    {
      title: "Sl no",
      field: "sl"
    },
    {
      title: "STUDENT ID",
      field: "STUDENT ID"
    },
    {
      title: "APPLICATION ID",
      field: "APPLICATION ID"
    },
    {
      title: "COUNTRY",
      field: "COUNTRY"
    },
    {
      title: "UNIVERSITY",
      field: "UNIVERSITY"
    },
    {
      title: "UNIVERSITY TYPE",
      field: "UNIVERSITY TYPE"
    },
    {
      title: "TOTAL AMOUNT",
      field: "TOTAL AMOUNT"
    },

    {
      title: "SCHOLARSHIP",
      field: "SCHOLARSHIP"
    },

    {
      title: "DISCOUNT",
      field: "DISCOUNT"
    },

    {
      title: "AMOUNT TO BE PAID",
      field: "AMOUNT TO BE PAID"
    },

    {
      title: "PAID AMOUNT",
      field: "PAID AMOUNT"
    },

    {
      title: "BALANCE AMOUNT",
      field: "BALANCE AMOUNT"
    },

    {
      title: "PAID DATE & TIME",
      field: "PAID DATE & TIME"
    },

    {
      title: "REMARK",
      field: "REMARK"
    },

    {
      title: "APPROVED BY",
      field: "APPROVED BY"
    },

    {
      title: "APPROVED DATE",
      field: "APPROVED DATE"
    },

    {
      title: "STATUS",
      field: "STATUS"
    },
    {
      title: "PAYSLIP",
      field: "PAYSLIP"
    }
  ];


  const Refundcolumns = [
    {
      title: "SL NO",
      field: "sl"
    },
    {
      title: "STUDENT ID",
      field: "STUDENT ID"
    },
    {
      title: "STUDENT NAME",
      field: "STUDENT NAME"
    },
    {
      title: "APPLICATION ID",
      field: "APPLICATION ID"
    },
    {
      title: "COUNTRY",
      field: "COUNTRY"
    },
    {
      title: "UNIVERSITY",
      field: "UNIVERSITY"
    },
    {
      title: "UNIVERSITY TYPE",
      field: "UNIVERSITY TYPE"
    },
    {
      title: "COURSE",
      field: "COURSE"
    },
    {
      title: "TOTAL AMOUNT",
      field: "TOTAL AMOUNT"
    },

    {
      title: "TOTAL PAID AMOUNT",
      field: "TOTAL PAID AMOUNT"
    },

    {
      title: "REFUND AMOUNT",
      field: "REFUND AMOUNT"
    },

    {
      title: "REFUND INITIATED BY",
      field: "REFUND INITIATED BY"
    },
    {
      title: "REFUND INITIATED DATE &TIME",
      field: "REFUND INITIATED DATE &TIME"
    }
  ];

  return (
    <MainContainer active={toggle}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "10px"
        }}
      >
        <KeyboardBackspaceIcon
          onClick={
            () => 
             navigate(`/Leads/details/${leadID}/${"Deals"}/${"Deals"}`)
          }
          sx={{ cursor: "pointer" }}
        />
        <Typography
          sx={{
            color: "#05050F",
            fontSize: "22px",
            fontWeight: "700"
          }}
        >
          Payment Details
        </Typography>
      </Box>

      <Box
        sx={{
          mt: "30px"
        }}
      >
        <Box sx={{ mb: 2 }}>
          <Typography
            sx={{ fontWeight: 600, fontSize: "16px", color: "#000000" }}
          >
            Registration Fee
          </Typography>
        </Box>
        <Box>
          {refistrationFeeList?.length > 0 ? (
            <RegistrationFeeTable
              columns={columns}
              data={refistrationFeeList}
              paginationPageNumber={paginationPageNumber}
              pagiantionEntry={pagiantionEntry}
            />
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%"
              }}
            >
              <Typography sx={{ mt: 2 }}>No items to show</Typography>
            </Box>
          )}
        </Box>

        <Box
          sx={{
            mb: 2,
            mt: 15,
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <Typography
            sx={{ fontWeight: 600, fontSize: "16px", color: "#000000" }}
          >
            Tuition Fee
          </Typography>

          {/* <Box>
            {tutionFeeList?.length !== 0 && (
              <Button
                sx={{
                  backgroundColor: "#141E3C",
                  color: "white",
                  width: "76px",
                  height: "25px",
                  fontWeight: 400,
                  fontSize: "12px",
                  mr: 2,
                  "&:hover": {
                    backgroundColor: "rgba(34, 110, 0, 0.5)"
                  }
                }}
                onClick={() => setOpenAlert(true)}
              >
                Add
              </Button>
            )}
          </Box> */}
        </Box>
        <Box>
          {tutionFeeList?.length > 0 ? (
            <TutionFeeTable
              columns={tutionFeecolumns}
              data={tutionFeeList}
              paginationPageNumber={paginationPageNumber}
              pagiantionEntry={pagiantionEntry}
              component="paymentDetails"
            />
          ) : (
            ""
          )}
        </Box>

        <Typography
          sx={{ fontWeight: 600, fontSize: "16px", color: "#000000",mb:2 }}
        >
          Refund Details
        </Typography>

        <Box>
          {refundFeeList?.length > 0 ? (
            <RefundFeeTable
              columns={Refundcolumns}
              data={refundFeeList}
              paginationPageNumber={paginationPageNumber}
              pagiantionEntry={pagiantionEntry}
            />
          ) : (
            ""
          )}
        </Box>
        {refistrationFeeList?.length == 0 && tutionFeeList?.length == 0 ? (
          <TableNoItemComponent />
        ) : (
          ""
        )}
      </Box>
    </MainContainer>
  );
}

export default PaymentDetails;
