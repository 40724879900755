import React, { useEffect, useState } from "react";

import { AiOutlineDownload, AiOutlinePlus } from "react-icons/ai";

import { SiMicrosoftexcel } from "react-icons/si";

import { RiSearch2Line } from "react-icons/ri";

import {
  Box,
  Button,
  Checkbox,
  Chip,
  Grid,
  Icon,
  InputAdornment,
  ListItem,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import { makeStyles } from "@material-ui/core";
import { OutLinedButton, SubmitButton } from "../customComponent/Buttons";
import BulkUploadModal from "../university/universities/BulkUploadModal";
import { useDispatch } from "react-redux";
import { employeeBulkUploadAction } from "../../actions/employeeManagmentAction";
import { rollBulkUploadAction } from "../../actions/roleMangmentAction";
import { successTrueMsgAction } from "../../actions/globalAction";
import usePrivilegeCheck from "../../constants/customHooks/UsePrivilegeCheck";

const useStyles = makeStyles((theme) => ({
  searchContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(0.5),
  },
  searchInput: {
    marginLeft: theme.spacing(0.5),
  },
}));

function ColumnVisible({
  columnVisibleHandler,
  data,
  searchHandleChange,
  pdfDownloadClick,
  excelDownloadClick,
  component,
  employeeRow1,
  employeeRow2,
  employeeRow3,
  employeeRow4,
  roleRow1,
  roleRow2,
  roleRow3,
  roleRow4,
  whichFilter,
  filterPassData,
  handleResetData,
  subMenu,
}) {
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  // const [rows, setRows] = useState(data);
  const [searched, setSearched] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [chipData, setChipData] = useState([]);
  const [bulkUploadModalOpen, setBulkUploadModalOpen] = useState(false);

  const { privilege, hasPrivilege, isCreatePrevilage } = usePrivilegeCheck();

  useEffect(() => {
    if (whichFilter !== null && filterPassData !== null) {
      setChipData((prevChipData) => {
        const updatedChipData = prevChipData.map((chip) => {
          if (chip.label === whichFilter) {
            return {
              ...chip,
              result: filterPassData,
            };
          }
          return chip;
        });

        if (!updatedChipData.some((chip) => chip.label === whichFilter)) {
          return [
            ...updatedChipData,
            { label: whichFilter, result: filterPassData },
          ];
        }

        return updatedChipData;
      });
    } else {
      setChipData([]);
    }
  }, [whichFilter, filterPassData]);

  // chip

  const handleDelete = (chipToDelete) => () => {
    setChipData((chips) => chips.filter((chip) => chip.label !== chipToDelete));
    handleResetData(true);
  };

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // button Export
  const [exportShow, setExportShow] = React.useState(null);
  const openExport = Boolean(exportShow);

  const handleClickExport = (event) => {
    setExportShow(event.currentTarget);
  };
  const handleCloseExport = () => {
    setExportShow(null);
  };

  // button Action
  const [actionShow, setActionShow] = React.useState(null);
  const openAction = Boolean(actionShow);

  const handleClickAction = (event) => {
    setActionShow(event.currentTarget);
  };
  const handleCloseAction = () => {
    setActionShow(null);
  };

  //  bulk upload popup

  const openBulkUploadModal = () => {
    setBulkUploadModalOpen(true);
  };

  // Bulk upload api call

  const handleBulkUploadSubmit = (bulkUploadValue) => {
    const formData = new FormData();
    formData.append("file", bulkUploadValue);
    dispatch(employeeBulkUploadAction(formData));
    dispatch(successTrueMsgAction(true));

    // if (component == "role") {
    //   dispatch(rollBulkUploadAction(formData));
    //   dispatch(successTrueMsgAction(true));

    // }

    console.log(bulkUploadValue, "bulkUpload");
  };

  return (
    <>
      <Grid container spacing={2}>
        <BulkUploadModal
          open={bulkUploadModalOpen}
          handleClose={() => setBulkUploadModalOpen(false)}
          handleSubmit={handleBulkUploadSubmit}
          title="Bulk upload employee"
          downloadurl="https://gsl-dev2.s3.ap-south-1.amazonaws.com/bulkUpload/bulkUpload102"
        />
        <Grid item xs={3}>
          {component === "employee" && (
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                marginTop: "5px", // Adjusted margin top
                width: "auto",
                fontSize: "8px", // Adjusted font size
              }}
            >
              {chipData?.map((data, index) => {
                return (
                  <ListItem key={index} style={{ marginBottom: "2px" }}>
                    <Chip
                      size="small"
                      className="MuiChip-sizeSmall"
                      classes={{
                        iconSmall: "MuiChip-iconSmall",
                        labelSmall: "MuiChip-labelSmall",
                      }}
                      icon={`${data.label}:`}
                      label={
                        <Typography variant="body2" style={{ fontSize: 11 }}>
                          <span style={{ fontWeight: 600, fontSize: 12 }}>
                            Branch:{" "}
                          </span>
                          {data.result}
                        </Typography>
                      }
                      onDelete={handleDelete(data.label)}
                      style={{
                        fontWeight: 100,
                        fontSize: "auto",
                        marginLeft: "-15px",
                      }}
                    />
                  </ListItem>
                );
              })}
            </div>
          )}
        </Grid>
        <Grid item xs={9}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Grid>
              <div className={classes.searchContainer}>
                <TextField
                  className={classes.searchInput}
                  variant="outlined"
                  placeholder="Search Here"
                  value={searchText}
                  onChange={(e) => {
                    searchHandleChange(e);
                    setSearchText(e.target.value);
                  }}
                  sx={{
                    "& .MuiInputBase-input": {
                      borderRadius: "22px",
                      height: "0.5px",
                    },
                    "& .MuiFormLabel-root": {
                      lineHeight: "5px",
                    },

                    "& .MuiInputBase-root": {
                      borderRadius: "4px",
                      borderColor: "#fafafa",
                    },
                  }}
                />
                <>
                  <Box
                    sx={{
                      width: "40px",
                      height: "16px",
                      pt: 1.9,
                      textAlign: "center",
                      border: "1px solid #9d9fa1",
                      borderBottomRightRadius: "7px",
                      WebkitBorderTopRightRadius: "7px",
                      fontSize: "24px",
                    }}
                  >
                    <InputAdornment position="end">
                      <RiSearch2Line />
                    </InputAdornment>
                  </Box>
                </>
              </div>
            </Grid>
            <Grid>
              {/* <Button
                id="demo-positioned-button"
                aria-controls={open ? "demo-positioned-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                variant="outlined"
                sx={{
                  borderColor: "black",
                  size: "10px",
                  width: "182px",
                  height: "30px",
                  padding: 1,
                  margin: 1,
                  color: "#141E3C",
                  fontWeight: 700,
                  fontSize: "14px",
                }}
              >
               column visibility
              </Button> */}

              <Box
                sx={{
                  margin: 0.5,
                }}
              >
                <OutLinedButton
                  title="Column visibility"
                  handleClick={handleClick}
                  widthSize="182px"
                  heightSize="30px"
                />
              </Box>

              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                sx={{ borderColor: "black", borderRadius: "24px", mt: 6 }}
              >
                <MenuItem>
                  {component === "employee" ? (
                    <>
                      <Checkbox
                        sx={{
                          color: "#F8469A",
                          "&.Mui-checked": {
                            color: "#F8469A",
                          },
                        }}
                        checked={employeeRow1 === true ? true : false}
                        onChange={(e) =>
                          columnVisibleHandler(e.target.checked, "employeeName")
                        }
                      />
                      Name
                    </>
                  ) : (
                    <>
                      {" "}
                      <Checkbox
                        sx={{
                          color: "#F8469A",
                          "&.Mui-checked": {
                            color: "#F8469A",
                          },
                        }}
                        checked={roleRow1 === true ? true : false}
                        onChange={(e) =>
                          columnVisibleHandler(e.target.checked, "roleName")
                        }
                      />
                      Role Name{" "}
                    </>
                  )}
                </MenuItem>

                <MenuItem>
                  {component === "employee" ? (
                    <>
                      <Checkbox
                        sx={{
                          color: "#F8469A",
                          "&.Mui-checked": {
                            color: "#F8469A",
                          },
                        }}
                        checked={employeeRow2 === true ? true : false}
                        onChange={(e) =>
                          columnVisibleHandler(e.target.checked, "email")
                        }
                      />
                      Email
                    </>
                  ) : (
                    <>
                      <Checkbox
                        sx={{
                          color: "#F8469A",
                          "&.Mui-checked": {
                            color: "#F8469A",
                          },
                        }}
                        checked={roleRow2 === true ? true : false}
                        onChange={(e) =>
                          columnVisibleHandler(e.target.checked, "Description")
                        }
                      />{" "}
                      Description{" "}
                    </>
                  )}
                </MenuItem>
                <MenuItem>
                  {component === "employee" ? (
                    <>
                      <Checkbox
                        sx={{
                          color: "#F8469A",
                          "&.Mui-checked": {
                            color: "#F8469A",
                          },
                        }}
                        checked={employeeRow3 === true ? true : false}
                        onChange={(e) =>
                          columnVisibleHandler(e.target.checked, "mobile")
                        }
                      />
                      Mobile
                    </>
                  ) : (
                    <>
                      {" "}
                      <Checkbox
                        sx={{
                          color: "#F8469A",
                          "&.Mui-checked": {
                            color: "#F8469A",
                          },
                        }}
                        checked={roleRow3 === true ? true : false}
                        onChange={(e) =>
                          columnVisibleHandler(e.target.checked, "updateby")
                        }
                      />{" "}
                      Update By{" "}
                    </>
                  )}{" "}
                </MenuItem>
                <MenuItem>
                  {component === "employee" ? (
                    <>
                      <Checkbox
                        sx={{
                          color: "#F8469A",
                          "&.Mui-checked": {
                            color: "#F8469A",
                          },
                        }}
                        checked={employeeRow4 === true ? true : false}
                        onChange={(e) =>
                          columnVisibleHandler(e.target.checked, "role")
                        }
                      />
                      Role
                    </>
                  ) : (
                    <>
                      <Checkbox
                        sx={{
                          color: "#F8469A",
                          "&.Mui-checked": {
                            color: "#F8469A",
                          },
                        }}
                        checked={roleRow4 === true ? true : false}
                        onChange={(e) =>
                          columnVisibleHandler(e.target.checked, "lastupdateby")
                        }
                      />
                      LastUpdate By
                    </>
                  )}
                </MenuItem>
              </Menu>
            </Grid>

            {/* export Button */}
            <Box
              sx={{
                margin: 0.5,
              }}
            >
              <OutLinedButton
                title="Export"
                handleClick={handleClickExport}
                widthSize="auto"
                heightSize="30px"
              />
            </Box>

            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={exportShow}
              open={openExport}
              onClose={handleCloseExport}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              sx={{ borderColor: "black", borderRadius: "24px", mt: 6 }}
            >
              <MenuItem onClick={pdfDownloadClick}>
                <Icon fontSize="medium" color="secondary" sx={{ m: 1 }}>
                  <AiOutlineDownload
                    style={{ color: "#141E3C", fontSize: "24px" }}
                  />
                </Icon>
                Pdf
              </MenuItem>
              <MenuItem onClick={excelDownloadClick}>
                <Icon fontSize="medium" color="secondary" sx={{ m: 1 }}>
                  <SiMicrosoftexcel
                    style={{ color: "#141E3C", fontSize: "24px" }}
                  />
                </Icon>
                Excel
              </MenuItem>
            </Menu>

            {/* Action Button */}

            {/* Action Button */}

            {isCreatePrevilage(subMenu) && (
              <Grid>
                <Box
                  sx={{
                    margin: 0.5,
                  }}
                >
                  <SubmitButton
                    title="Action"
                    submit=""
                    widthSize="75px"
                    heightSize="30px"
                    type="click"
                    handleSubmit={handleClickAction}
                  />
                </Box>

                <Menu
                  id="demo-positioned-menu"
                  aria-labelledby="demo-positioned-button"
                  anchorEl={actionShow}
                  open={openAction}
                  onClose={handleCloseAction}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  sx={{ borderColor: "black", borderRadius: "24px", mt: 6 }}
                >
                  <MenuItem>
                    {component !== "employee" ? (
                      <Button
                        startIcon={<AiOutlinePlus />}
                        sx={{
                          backgroundColor: "#141E3C",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "rgba(20, 30, 60, 0.9)",
                          },
                        }}
                        onClick={() => navigate("/Administration/createRole")}
                      >
                        Create a New Role
                      </Button>
                    ) : (
                      <Button
                        startIcon={<AiOutlinePlus />}
                        sx={{
                          backgroundColor: "#141E3C",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "rgba(20, 30, 60, 0.9)",
                          },
                        }}
                        onClick={() =>
                          navigate("/Administration/employee/addEmployee")
                        }
                      >
                        Add Employee
                      </Button>
                    )}
                  </MenuItem>
                </Menu>
              </Grid>
            )}

            {/* BulkUpload button */}
            {component == "employee" && isCreatePrevilage(subMenu) ? (
              <Grid>
                <Box
                  sx={{
                    margin: 0.5,
                  }}
                >
                  <SubmitButton
                    title="Bulk Upload"
                    submit=""
                    widthSize="150px"
                    heightSize="30px"
                    type="click"
                    handleSubmit={openBulkUploadModal}
                  />
                </Box>
              </Grid>
            ) : (
              ""
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default ColumnVisible;
