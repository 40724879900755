import {
  Box,
  Button,
  Checkbox,
  Menu,
  MenuItem,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BiSortAlt2 } from "react-icons/bi";
import { SlOptionsVertical } from "react-icons/sl";
import { AiOutlineEye } from "react-icons/ai";

function TutionFeeTable({
  data,
  columns,
  pagiantionEntry,
  paginationPageNumber,
  component
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedRows, setSelectedRows] = useState([]);
  const [pendingData, setPendingData] = useState([]);
  // pagination

  const [page, setPage] = useState(paginationPageNumber);
  const [rowsPerPage, setRowsPerPage] = useState(pagiantionEntry);

  useEffect(() => {
    setRowsPerPage(pagiantionEntry);
    setPage(paginationPageNumber);
  }, [data, paginationPageNumber, pagiantionEntry]);

  useEffect(() => {
    const maxPage = Math.ceil(data?.length / pagiantionEntry);
    if (page > maxPage) {
      setPage(maxPage);
    }
  }, [data, pagiantionEntry, page]);

  // pagination

  // Event handler for changing page
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Create a function to handle selecting all rows:
  const handleSelectAllRows = () => {
    if (selectedRows.length === sortedData.length) {
      setSelectedRows([]);
    } else {
      let Ids = sortedData.map((item) => item.id);
      setSelectedRows(Ids);
    }
  };

  // Create a function to handle selecting individual rows:
  const handleSelectRow = (rowId) => {
    let updatedSelectedRows;
    if (selectedRows.includes(rowId)) {
      updatedSelectedRows = selectedRows.filter((id) => id !== rowId);
      setSelectedRows(selectedRows.filter((id) => id !== rowId));
    } else {
      updatedSelectedRows = [...selectedRows, rowId];
      setSelectedRows(updatedSelectedRows);
    }
  };

  // table action
  const [leadActionButtonShow, setLeadActionButtonShow] = useState(null);
  const [tableId, setTableId] = useState("");

  const leadOpenActionButton = Boolean(leadActionButtonShow);

  const handleClickAction = (event, id) => {
    setLeadActionButtonShow(event.currentTarget);
    setTableId(id);
  };

  const handleCloseAction = () => {
    setLeadActionButtonShow(null);
  };

  const CustomSortIcon = ({ direction }) => {
    return direction === "asc" ? (
      <img
        src="/menuIcons/tableUpSort.png"
        className="administratCustomIcon"
        alt="gsl"
        style={{ padding: "15px" }}
      />
    ) : (
      <BiSortAlt2 className="LeadCustomIcon" />
    );
  };

  // sorting table
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  const requestSort = (column) => {
    let newSortOrder = "asc";
    if (column === sortBy && sortOrder === "asc") {
      newSortOrder = "desc";
    }

    setSortBy(column);
    setSortOrder(newSortOrder);
  };

  let sortedData = useMemo(() => {
    if (sortBy && data) {
      const sortedItems = [...data].sort((a, b) => {
        if (sortOrder === "asc") {
          return a[sortBy] < b[sortBy] ? -1 : 1;
        } else {
          return a[sortBy] < b[sortBy] ? 1 : -1;
        }
      });

      return sortedItems;
    }

    return data;
  }, [data, sortBy, sortOrder]);

  console.log(data, "datadatadata");

  return (
    <Box>
      <div
        style={{
          overflowX: "scroll",
          border: "0px solid #ffff"
        }}
        className="scrollBar"
      >
        <Table className="LeadTable">
          <TableHead>
            <TableRow className="LeadTableCell">
              <TableCell
                style={{
                  position: "sticky",
                  left: 0,
                  zIndex: 1,
                  padding: "1px",
                  border: "1px solid #ddd",
                  backgroundColor: "#fafafa"
                }}
              >
                <Checkbox
                  checked={selectedRows?.length === sortedData?.length}
                  onChange={handleSelectAllRows}
                  style={{ color: "#f81b82" }}
                />
              </TableCell>
              {columns?.map((column) => {
                if (column.hidden) {
                  return null;
                }
                return (
                  <TableCell
                    key={column.field}
                    className="LeadTableCell LeadCommon"
                    style={{
                      padding: "0 16px",
                      whiteSpace: "nowrap",
                      textTransform: "uppercase",
                      fontWeight: "600"
                    }}
                  >
                    <TableSortLabel
                      className="LeadHeaderText"
                      active={column === sortBy}
                      direction={sortOrder}
                      onClick={() => requestSort(column)}
                      IconComponent={
                        column.field !== "action" && CustomSortIcon
                      }
                    >
                      {column.title}
                    </TableSortLabel>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData
              ?.slice(
                (page - 1) * rowsPerPage,
                (page - 1) * rowsPerPage + rowsPerPage
              )
              .map((item, indx) => (
                <TableRow className="LeadTable" key={indx}>
                  <TableCell
                    style={{
                      position: "sticky",
                      left: 0,
                      zIndex: 1, // To ensure it's above other cells
                      backgroundColor: "white", // Adjust as needed
                      padding: "1px",
                      border: "1px solid #ddd"
                    }}
                  >
                    <Checkbox
                      checked={selectedRows.includes(indx)}
                      onChange={() => handleSelectRow(indx)}
                      style={{ color: "#f81b82" }}
                    />
                  </TableCell>
                  <TableCell
                    style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                  >
                    {indx + 1}
                  </TableCell>

                  <TableCell
                    style={{
                      padding: "0 16px",
                      whiteSpace: "nowrap",
                      textTransform: "capitalize"
                    }}
                  >
                    {item.lead_applied_short_list?.universityGivenId || "-"}
                  </TableCell>

                  <TableCell
                    style={{
                      padding: "0 16px",
                      whiteSpace: "nowrap",
                      textTransform: "capitalize"
                    }}
                  >
                    {item.lead_applied_short_list?.applicationId || "-"}
                  </TableCell>
                  {component == "paymentDetails" && (
                    <TableCell
                      style={{
                        padding: "0 16px",
                        whiteSpace: "nowrap",
                        textTransform: "capitalize"
                      }}
                    >
                      {item.lead_applied_short_list?.university?.universityCountry || "-"}
                    </TableCell>
                  )}

                  <TableCell
                    style={{
                      padding: "0 16px",
                      whiteSpace: "nowrap",
                      textTransform: "capitalize"
                    }}
                  >
                    {item.lead_applied_short_list?.universityName || "-"}
                  </TableCell>

                  <TableCell
                    style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                  >
                    {item.lead_applied_short_list?.university?.universityType || "-"}
                  </TableCell>

                  <TableCell
                    style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                  >
                    {item?.currency + "" + item.totalTutionFee || "-"}
                  </TableCell>

                  <TableCell
                    style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                  >
                    {item?.scholarShipAmount !== ""
                      ? item?.currency + "" + item?.scholarShipAmount
                      : "-"}
                  </TableCell>

                  <TableCell
                    style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                  >
                    {item?.discount !== ""
                      ? item?.currency + "" + item?.discount
                      : "-"}
                  </TableCell>

                  <TableCell
                    style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                  >
                    {item?.totalFeeTobePaid !== ""
                      ? item?.currency + "" + item?.totalFeeTobePaid
                      : "-"}
                  </TableCell>

                  <TableCell
                    style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                  >
                    {item?.paidAmount !== ""
                      ? item?.currency + "" + item?.paidAmount
                      : "-"}
                  </TableCell>

                  <TableCell
                    style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                  >
                    {item?.balanceAmount !== ""
                      ? item?.currency + "" + item?.balanceAmount
                      : "-"}
                  </TableCell>

                  <TableCell
                    style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                  >
                    {item?.paidDate + " " + item?.paidTime}
                  </TableCell>

                  <TableCell
                    style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                  >
                    {item?.remark}
                  </TableCell>

                  <TableCell
                    style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                  >
                    {item?.ApprovedBy}
                  </TableCell>

                  <TableCell
                    style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                  >
                    {item?.ApprovedDate}
                  </TableCell>

                  <TableCell
                    style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                  >
                    <span
                      style={{
                        color:
                          item?.paymentStatus == "deposite" ? "red" : "green"
                      }}
                    >
                      {item?.paymentStatus}
                    </span>
                  </TableCell>

                  <TableCell
                    style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                  >
                    <a
                      href={item?.receipt}
                      // style={{ paddingLeft: "30px" }}
                      target="blank"
                    >
                      <Button
                        sx={{
                          backgroundColor: "#141E3C",
                          color: "white",
                          width: "76px",
                          height: "25px",
                          fontWeight: 400,
                          fontSize: "12px",
                          mr: 2,
                          "&:hover": {
                            backgroundColor: "rgba(34, 110, 0, 0.5)"
                          }
                        }}
                      >
                        view
                      </Button>
                    </a>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
          <TableFooter />
        </Table>
      </div>
      <Pagination
            sx={{
              mt: "28px",
              "& .Mui-selected": {
                background: "#FE0B7A !important",
              },
              "& .MuiPagination-ul": {
                justifyContent: "end",
              },
            }}
            count={Math.ceil(data.length / rowsPerPage)}
            page={page}
            onChange={handleChangePage}
            color="primary"
          />
    </Box>
  );
}

export default TutionFeeTable;
