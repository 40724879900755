import React, { useEffect, useState } from "react";
import { Alert, AlertTitle, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";
import DownloadExcel from "../../extraComponents/DownloadExcel";
import DownloadPdf from "../../extraComponents/DownloadPdf";
import ApprovalSearchHeader from "./ApprovalSearchHeader";
import ApprovalFilterSearch from "./ApprovalFilterSearch";
import { tutionFeepaymentApprovalFindAction } from "../../../actions/paymentApprovalAction";
import TutionFeeApprovalTable from "./tutionFeeApprovalTable";
import RefundApprovalTable from "./refundApprovalTable";
import { leadRefundFindAction } from "../../../actions/leadActions/leadActions";
import Swal from "sweetalert2";
import { LEAD_REFUND_UPDATE_SUCCESS } from "../../../constants/leadConstant";

function RefundApprovalMain(props) {
  const dispatch = useDispatch();
  const [filterPassData, setFilterPassData] = useState();
  const [whichFilter, setwhichFilter] = useState();
  const [reset, setReset] = useState(false);
  const [leadData, setLeadData] = useState([]);
  const [refundApprovalList, setRefundApprovalList] = useState([]);

  const { privilege, hasPrivilege } = usePrivilegeCheck();

  // Employee column Visibility

  const [showNameColumn, setshowNameColumn] = useState(false);
  const [showDateColumn, setshowDateColumn] = useState(false);
  const [showMailIdColumn, setshowMailIdColumn] = useState(false);
  const [showPhoneNumberColumn, setshowPhoneNumberColumn] = useState(false);
  const [showCountryOfResidenceColumn, setShowCountryResidenceColumn] =
    useState(false);
  const [showReferredByColumn, setshowReferredByColumn] = useState(false);
  const [showStatusColumn, setShowStatusColumn] = useState(false);

  const [columnName, setColumnName] = useState("");
  const [leadId, setLeadId] = useState([]);
  const [ids, setIds] = useState([]);

  // tabel columns

  const columns = [
    {
      title: "SL NO",
      field: "sl"
    },
    {
      title: "STUDENT ID",
      field: "STUDENT ID",
      hidden: showNameColumn
    },
    {
      title: "STUDENT NAME",
      field: "STUDENT NAME",
      hidden: showDateColumn
    },

    {
      title: "PASSPORT NUMBER",
      field: "PASSPORT NUMBER",
      hidden: showNameColumn,
    },
    {
      title: "EMAIL",
      field: "EMAIL",
      hidden: showNameColumn,
    },
    {
      title: "PHONE NUMBER",
      field: "PHONE NUMBER",
      hidden: showNameColumn,
    },
    {
      title: "APPLICATION ID",
      field: "APPLICATION ID",
      hidden: showDateColumn
    },
    {
      title: "COUNTRY",
      field: "COUNTRY",
      hidden: showMailIdColumn
    },
    {
      title: "UNIVERSITY",
      field: "UNIVERSITY",
      hidden: showPhoneNumberColumn
    },
    {
      title: "UNIVERSITY TYPE",
      field: "UNIVERSITY TYPE",
      hidden: showCountryOfResidenceColumn
    },
    {
      title: "COURSE",
      field: "COURSE",
      hidden: showReferredByColumn
    },
    {
      title: "TOTAL AMOUNT",
      field: "TOTAL AMOUNT",
      hidden: showStatusColumn
    },

    {
      title: "TOTAL PAID AMOUNT",
      field: "TOTAL PAID AMOUNT",
      hidden: showStatusColumn
    },

    {
      title: "REFUND AMOUNT",
      field: "REFUND AMOUNT",
      hidden: showStatusColumn
    },

    {
      title: "REFUND INITIATED BY",
      field: "REFUND INITIATED BY",
      hidden: showStatusColumn
    },
    {
      title: "REFUND INITIATED DATE &TIME",
      field: "REFUND INITIATED DATE &TIME",
      hidden: showStatusColumn
    },

    {
      title: "ACTION",
      field: "ACTION",
      hidden: showStatusColumn
    }
  ];

  // // Excel export function
  // const excelDownloadClick = () => {
  //   let columnName = columns.filter((value) => value.hidden == false);
  //   console.log(columnName, "columnNameeeeeeeeeee");
  //   console.log(paymentApprovallist, "paymentApprovallistpaymentApprovallist");
  //   const data = paymentApprovallist.map((value, index) => ({
  //     sl: columns[index].hidden ? false : index + 1,
  //     coloumn1: columns[index].hidden ? false : value.teacherName,
  //     coloumn2: columns[index].hidden ? false : value.teacherEmail,
  //     coloumn3: columns[index].hidden ? false : value.teacherPhone,
  //     coloumn4: columns[index].hidden ? false : value.center,
  //     coloumn5: columns[index].hidden ? false : value.teacherSpecialization,
  //     coloumn6: columns[index].hidden ? false : value.status,
  //   }));

  //   console.log(data, "datatatattata564s654644665676754");
  //   DownloadExcel(columnName, data, "EntrolledTeacherList");
  // };

  // // Pdf Export function

  // const pdfDownloadClick = () => {
  //   let columnName = columns.filter((value) => value.hidden == false);
  //   DownloadPdf(
  //     columnName,
  //     paymentApprovallist,
  //     "Entrolled Teacher List",
  //     "Entrolled Teacher List"
  //   );
  // };

  // const toggleNameColumn = (status, column) => {
  //   if (column === "name") {
  //     setColumnName(column);
  //     setshowNameColumn(status);
  //   }

  //   if (column === "mailId") {
  //     setColumnName(column);
  //     setshowDateColumn(status);
  //   }
  //   if (column === "mobile") {
  //     setColumnName(column);
  //     setshowMailIdColumn(status);
  //   }
  //   if (column === "center") {
  //     setColumnName(column);
  //     setshowPhoneNumberColumn(status);
  //   }
  //   if (column === "specilization") {
  //     setColumnName(column);
  //     setShowCountryResidenceColumn(status);
  //   }
  //   if (column === "status") {
  //     setColumnName(column);
  //     setShowStatusColumn(status);
  //   }
  // };

  // useEffect(() => {
  //   // Retrieve column visibility state from localStorage
  //   const storedteacherName = localStorage.getItem("teacherName");
  //   const storedMail = localStorage.getItem("mailId");
  //   const storedMobile = localStorage.getItem("mobile");
  //   const storedCenter = localStorage.getItem("center");
  //   const storedSpecilization = localStorage.getItem("specilization");
  //   const storedStatus = localStorage.getItem("status");

  //   if (storedteacherName !== null) {
  //     setColumnName("teacherName");
  //     setshowNameColumn(JSON.parse(storedteacherName));
  //   }

  //   if (storedMail !== null) {
  //     setColumnName("mailId");
  //     setshowDateColumn(JSON.parse(storedMail));
  //   }
  //   if (storedMobile !== null) {
  //     setColumnName("mobile");
  //     setshowMailIdColumn(JSON.parse(storedMobile));
  //   }
  //   if (storedCenter !== null) {
  //     setColumnName("center");
  //     setshowPhoneNumberColumn(JSON.parse(storedCenter));
  //   }
  //   if (storedSpecilization !== null) {
  //     setColumnName("specilization");
  //     setShowCountryResidenceColumn(JSON.parse(storedSpecilization));
  //   }
  //   if (storedStatus !== null) {
  //     setColumnName("status");
  //     setShowStatusColumn(JSON.parse(storedStatus));
  //   }
  // }, []);

  // useEffect(() => {
  //   // Save column visibility state to localStorage
  //   localStorage.setItem("teacherName", JSON.stringify(showNameColumn));
  //   localStorage.setItem("mailId", JSON.stringify(showDateColumn));
  //   localStorage.setItem("mobile", JSON.stringify(showMailIdColumn));
  //   localStorage.setItem("center", JSON.stringify(showPhoneNumberColumn));
  //   localStorage.setItem(
  //     "specilization",
  //     JSON.stringify(showCountryOfResidenceColumn)
  //   );
  //   localStorage.setItem("status", JSON.stringify(showStatusColumn));
  // }, [
  //   showNameColumn,
  //   showDateColumn,
  //   showMailIdColumn,
  //   showPhoneNumberColumn,
  //   showCountryOfResidenceColumn,
  //   showReferredByColumn,
  //   showStatusColumn,
  //   columnName,
  // ]);

  // Find referred students

  let { leadRefundFindSuccess, leadRefundFindError } = useSelector((state) => {
    return state.leadRefundFind;
  });

  let { leadRefundUpdateSuccess, leadRefundUpdateError } = useSelector(
    (state) => {
      return state.leadRefundUpdate;
    }
  );

  useEffect(() => {
    dispatch(leadRefundFindAction());
  }, [dispatch, leadRefundUpdateSuccess]);

  // useEffect(() => {
  //   if (leadRefundUpdateSuccess !== false) {
  //     Swal.fire("Updated!", "Refund amount updated successfully.", "success");
  //     dispatch({ type: LEAD_REFUND_UPDATE_SUCCESS, payload: false });
  //   }
  // }, [leadRefundUpdateSuccess]);

  console.log(
    leadRefundFindSuccess,
    "leadRefundFindSuccessleadRefundFindSuccessleadRefundFindSuccess"
  );

  useEffect(() => {
    if (leadRefundFindSuccess) {
      setRefundApprovalList(leadRefundFindSuccess.data);
    }
  }, [leadRefundFindSuccess, reset]);

  // Handle SearchFunction

  const searchHandleChange = (value) => {
    if (value) {
      setRefundApprovalList(
        leadRefundFindSuccess.data?.filter((item) => {
          const valueLower = value?.toLowerCase() || "";

          const countryMatch = item.lead_applied_short_list.universityCountry
            ? item.lead_applied_short_list.universityCountry
                .toLowerCase()
                .includes(valueLower)
            : false;

          const nameMatch = item.lead?.firstName
            ? item.lead.firstName.toLowerCase().includes(valueLower)
            : false;

          const studentIdMatch = item.lead_applied_short_list?.universityGivenId
            ? item.lead_applied_short_list.universityGivenId
                .toLowerCase()
                .includes(valueLower)
            : false;

          const universityMatch = item.lead_applied_short_list?.universityName
            ? item.lead_applied_short_list.universityName
                .toLowerCase()
                .includes(valueLower)
            : false;

          const courseMatch = item.lead_applied_short_list?.courseName
            ? item.lead_applied_short_list?.courseName
                .toLowerCase()
                .includes(valueLower)
            : false;

          const statusMatch = item?.paymentStatus
            ? item?.paymentStatus.toLowerCase().includes(valueLower)
            : false;

          const applicationId = item?.lead_applied_short_list?.applicationId
            ? item?.lead_applied_short_list?.applicationId
                .toLowerCase()
                .includes(valueLower)
            : false;

          const universityType = item?.lead_applied_short_list?.university
            ?.universityType
            ? item?.lead_applied_short_list?.university?.universityType
                .toLowerCase()
                .includes(valueLower)
            : false;

          return (
            countryMatch ||
            nameMatch ||
            studentIdMatch ||
            universityMatch ||
            courseMatch ||
            statusMatch ||
            applicationId ||
            universityType
          );
        })
      );
    } else {
      setRefundApprovalList(leadRefundFindSuccess.data);
    }
  };

  const setIdsFunction = (leadsId) => {
    setLeadId(leadsId);
  };

  const selectBoxIdPassHandler = (ids) => {
    setIds(ids);
  };

  if (!hasPrivilege("TutionFee Approval") && privilege !== null) {
    return (
      <Alert severity="error">
        <AlertTitle>warning</AlertTitle>
        You don't have permission to access — <strong>this page.!</strong>
      </Alert>
    );
  }

  // // handleFilter Data

  const LeadFilterFunction = (data, filterOption, checkedStatus) => {
    if (filterOption === "status") {
      if (checkedStatus === true) {
        const filterData = leadRefundFindSuccess.data?.filter(
          (value) => data == value.status
        );
        setRefundApprovalList(filterData);
        setwhichFilter(filterOption);
        setFilterPassData(data);
      } else {
        setwhichFilter(null);
        setFilterPassData(null);
        setRefundApprovalList(leadRefundFindSuccess.data);
      }
    }
    if (filterOption === "Date") {
      const date = new Date(data);
      const adjustedDate = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      );
      const formattedDate = adjustedDate.toISOString().split("T")[0];

      const filterData = leadRefundFindSuccess.data?.filter(
        (value) => formattedDate == value.createdAt.split("T")[0]
      );

      console.log(filterData, "filterDataaaa");
      setRefundApprovalList(filterData);
      // setwhichFilter(filterOption);
      // setFilterPassData("");
    }

    if (filterOption == "course") {
      if (checkedStatus === true) {
        const filterData = leadRefundFindSuccess.data?.filter(
          (value) =>
            value.lead_applied_short_list?.courseName
              .toLowerCase()
              .includes(data.toLowerCase())
        );
        setRefundApprovalList(filterData);
        setwhichFilter(filterOption);
        setFilterPassData(data);
      } else {
        setwhichFilter(null);
        setFilterPassData(null);
        setRefundApprovalList(leadRefundFindSuccess.data);
      }
    }

    if (filterOption == "university") {
      if (checkedStatus === true) {
        const filterData = leadRefundFindSuccess.data?.filter(
          (value) =>
            value.lead_applied_short_list?.universityName
              .toLowerCase()
              .includes(data.toLowerCase())
        );
        setRefundApprovalList(filterData);
        setwhichFilter(filterOption);
        setFilterPassData(data);
      } else {
        setwhichFilter(null);
        setFilterPassData(null);
        setRefundApprovalList(leadRefundFindSuccess.data);
      }
    }

    if (filterOption == "Country") {
      if (checkedStatus === true) {
        const filterData = leadRefundFindSuccess.data?.filter(
          (value) =>
            value.lead_applied_short_list?.universityCountry
              .toLowerCase()
              .includes(data.toLowerCase())
        );
        setRefundApprovalList(filterData);
        setwhichFilter(filterOption);
        setFilterPassData(data);
      } else {
        setwhichFilter(null);
        setFilterPassData(null);
        setRefundApprovalList(leadRefundFindSuccess.data);
      }
    }

    if (filterOption == "paymentType") {
      if (checkedStatus === true) {
        console.log(data, "datadatadatadatadatadata");
        const filterData = leadRefundFindSuccess.data?.filter(
          (value) => value.paymentStatus?.toLowerCase() == data?.toLowerCase()
        );
        setRefundApprovalList(filterData);
        setwhichFilter(filterOption);
        setFilterPassData(data);
      } else {
        setwhichFilter(null);
        setFilterPassData(null);
        setRefundApprovalList(leadRefundFindSuccess.data);
      }
    }

    if (filterOption == "universityType") {
      console.log(data, "vissssssssssssss");
      if (checkedStatus === true) {
        const filterData = leadRefundFindSuccess.data?.filter(
          (value) =>
            value.lead_applied_short_list?.university?.universityType?.toLowerCase() ==
            data?.toLowerCase()
        );

        console.log(filterData, "filterrrrrrrrrrrrr");
        setRefundApprovalList(filterData);
        setwhichFilter(filterOption);
        setFilterPassData(data);
      } else {
        setwhichFilter(null);
        setFilterPassData(null);
        setRefundApprovalList(leadRefundFindSuccess.data);
      }
    }

    if (filterOption == "paymentDate") {
      const paymentDateBtw = checkedStatus?.map((value) => value?.$d);
      const dateObject1 = new Date(paymentDateBtw[0]);
      const dateObject2 = new Date(paymentDateBtw[1]);
      const options = { year: "numeric", month: "long", day: "numeric" };
      const formattedDate1 = new Intl.DateTimeFormat("en-US", options).format(
        dateObject1
      );
      const formattedDate2 = new Intl.DateTimeFormat("en-US", options).format(
        dateObject2
      );
      const finalFormattedDate1 = formattedDate1.replace(",", "");
      const finalFormattedDate2 = formattedDate2.replace(",", "");

      // Convert formatted dates back to Date objects
      const date1 = new Date(finalFormattedDate1);
      const date2 = new Date(finalFormattedDate2);

      console.log(date1, date2, "date2date2date2date2");
      const filterData = leadRefundFindSuccess.data?.filter(
        (value) => {
          // Parse the paymentUpdatedDate
          const paymentDateString = value.createdAt; // e.g., "October 3 2024"
          const paymentDate = new Date(paymentDateString); // Convert to Date object
          console.log(paymentDate, "paymentDateStringpaymentDateString");
          // Check if paymentDate is between date1 and date2
          return paymentDate >= date1 && paymentDate <= date2;
        }
      );
      setRefundApprovalList(filterData);
      setwhichFilter(filterOption);
      setFilterPassData(data);
    }
  };

  // handleResetData

  const handleResetData = (status) => {
    setwhichFilter(null);
    setFilterPassData(null);
    setReset(!reset);
    setwhichFilter(null);
    setFilterPassData(null);
  };

  console.log(refundApprovalList, "refundApprovalListrefundApprovalList");

  return (
    <>
      <ApprovalSearchHeader
        // columnVisibleHandler={toggleNameColumn}
        // excelDownloadClick={excelDownloadClick}
        // pdfDownloadClick={pdfDownloadClick}
        showNameColumnColumn={showNameColumn}
        showDateColumn={showDateColumn}
        showPhoneNumberColumn={showPhoneNumberColumn}
        showMailIdColumn={showMailIdColumn}
        showCountryOfResidenceColumn={showCountryOfResidenceColumn}
        showReferredByColumn={showReferredByColumn}
        showStatusColumn={showStatusColumn}
        handleResetData={handleResetData}
        searchHandleChange={searchHandleChange}
        ids={ids}
        leadId={leadId}
      />
      {/* <div style={{display:"flex",justifyContent:"space-evenly", width:"auto"}}> */}
      <Grid container spacing={-3} sx={{ mt: "-31px" }}>
        <Grid item xs={12} md={2} lg={2.2}>
          <ApprovalFilterSearch
            LeadFilterFunction={LeadFilterFunction}
            handleResetData={handleResetData}
            component="refundApproval"
            data={refundApprovalList}
          />
        </Grid>
        <Grid item xs={12} md={10} lg={9.8}>
          <RefundApprovalTable
            toggle={props.toggle}
            showNameColumnColumn={showNameColumn}
            showDateColumn={showDateColumn}
            showPhoneNumberColumn={showPhoneNumberColumn}
            showMailIdColumn={showMailIdColumn}
            showCountryOfResidenceColumn={showCountryOfResidenceColumn}
            showReferredByColumn={showReferredByColumn}
            showStatusColumn={showStatusColumn}
            reset={reset}
            setIdsFunction={setIdsFunction}
            data={refundApprovalList}
            // leadFindLoading={leadFindLoading}
            selectBoxIdPassHandler={selectBoxIdPassHandler}
            // isExistCounsilor={loginFindSuccess}
            component="EmployeeJob"
            columns={columns}
            subMenu="TutionFee Approval"
          />
        </Grid>
      </Grid>
    </>
  );
}

export default RefundApprovalMain;
