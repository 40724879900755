import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  TextField,
  Button,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import { Box, makeStyles, setRef } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  leadWorkDetailsAddAction,
  leadWorkDetailsDeleteAction,
  leadWorkDetailsFindAction
} from "../../../actions/leadActions/leadDocsActions";
import { SubmitButton } from "../../customComponent/Buttons";
import {
  SelectInputField,
  TopInputFieldBorderLabel
} from "../../customComponent/InputField";
import Swal from "sweetalert2";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";
import currencyToSymbolMap from "currency-symbol-map/map";
import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { GetCountries } from "react-country-state-city";
import {
  leadRegistrationFeeCreateAction,
  leadRegistrationFeeFindAction
} from "../../../actions/leadActions/leadActions";
import { successTrueMsgAction } from "../../../actions/globalAction";

const useStyles = makeStyles((theme) => ({
  input: {
    height: "10px",
    border: "1px solid #ECECEC"
  },
  formLabel: {
    lineHeight: "13px"
  },
  outlinedInput: {
    // borderRadius: "13px",
    borderColor: "#ECECEC",
    border: "1px solid #ECECEC"
  }
}));

const RegistrationFee = ({ handleNext, handleCompleteTick }) => {
  const {
    privilege,
    hasPrivilege,
    isApprovePrevilage,
    isCreatePrevilage,
    isDeletePrevilage,
    isUpdatePrevilage,
    isVerifyPrevilage,
    isChangeOwnerPrevilage,
    isChangePriorityPrevilage,
    isChangeStatusPrevilage,
    isAssignCounsilorPrevilage
  } = usePrivilegeCheck();

  const dispatch = useDispatch();
  const { id } = useParams();

  let { successAlertMsgSuccess, successAlertMsgErr } = useSelector((state) => {
    return state.successTrueMsg;
  });

  // registrationFee find reducer

  let { leadRegistrationFeeFindSuccess, leadRegistrationFeeFindError } =
    useSelector((state) => {
      return state.leadRegistrationFeeFind;
    });

  // registrationFee create reducer

  let { leadRegistrationFeeCreateSuccess, leadRegistrationFeeCreateError } =
    useSelector((state) => {
      return state.leadRegistrationFeeCreate;
    });
  // const symbolsArray = Object.values(currencyToSymbolMap);

  const [time, setTime] = useState(dayjs());

  const [formValues, setFormValues] = useState({
    currency: leadRegistrationFeeFindSuccess?.data?.currency,
    amount: leadRegistrationFeeFindSuccess?.data?.registrationAmount,
    Date: leadRegistrationFeeFindSuccess?.data?.paymentDate,
    remarks: leadRegistrationFeeFindSuccess?.data?.Remark
  });

  const [formErrors, setFormErrors] = useState({});
  const [paySlip, setPaySlip] = useState("");
  const [paySlipUploaed, setPaySlipUploaded] = useState("");
  const [symbolArray, setSymbolArray] = useState([]);

  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormValues((prevValues) => ({ ...prevValues, [name]: newValue }));
    setFormErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleUploadCommonDocsChange = (e) => {
    const value = e.target.files[0];
    setPaySlip(value);
  };

  // console.log(symbolsArray,"symbolsArraysymbolsArray")

  const validate = () => {
    let errors = {};

    if (formValues.currency === "") {
      errors.currency = "Currency is required";
    }
    if (paySlip === "") {
      errors.paySlip = "payslip is required";
    }
    if (formValues.amount === "") {
      errors.amount = "amount is required";
    }

    if (formValues.Date === "") {
      errors.Date = "Date is required";
    }
    if (formValues.remarks === "") {
      errors.remarks = "remarks is required";
    }
    if (time === "") {
      errors.time = "time is required";
    }

    setFormErrors(errors);
    console.log(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = () => {
    if (validate()) {
      const formData = new FormData();
      formData.append("currency", formValues.currency);
      formData.append("amount", formValues.amount);
      formData.append("Date", formValues.Date);
      formData.append("remarks", formValues.remarks);
      formData.append("time", time.$d);
      formData.append("paySlip", paySlip);
      formData.append("leadID", id);
      dispatch(leadRegistrationFeeCreateAction(formData));
      dispatch(successTrueMsgAction(true));
      setPaySlip("");
    }
  };

  useEffect(() => {
    if (leadRegistrationFeeCreateSuccess && successAlertMsgSuccess) {
      Swal.fire(
        "Added!",
        "Payment details have been submitted for finance team approval. The added data will be reflected after approval.",
        "success"
      );
      dispatch(successTrueMsgAction(false));
    }
  }, [leadRegistrationFeeCreateSuccess, successAlertMsgSuccess]);

  useEffect(() => {
    if (leadRegistrationFeeFindSuccess) {
      handleCompleteTick();
    }
  }, [leadRegistrationFeeFindSuccess, handleCompleteTick]);

  useEffect(() => {
    dispatch(leadRegistrationFeeFindAction(id));
    GetCountries().then((result) => {
      setSymbolArray(result);
    });
  }, [dispatch,leadRegistrationFeeCreateSuccess]);

  useEffect(() => {
    if (leadRegistrationFeeFindSuccess) {
      setFormValues({
        currency: leadRegistrationFeeFindSuccess?.data?.currency,
        amount: leadRegistrationFeeFindSuccess?.data?.registrationAmount,
        Date: leadRegistrationFeeFindSuccess?.data?.paymentDate,
        remarks: leadRegistrationFeeFindSuccess?.data?.Remark
      });
      setPaySlipUploaded(leadRegistrationFeeFindSuccess?.data?.reciept);
      setTime(dayjs(leadRegistrationFeeFindSuccess?.data?.paymentTime));
    }
  }, [leadRegistrationFeeFindSuccess]);

  console.log(
    leadRegistrationFeeFindSuccess,
    "leadRegistrationFeeFindSuccessleadRegistrationFeeFindSuccess"
  );

  console.log(formValues, "formValuesformValues");

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography sx={{fontWeight:700}} variant="h6" gutterBottom>
          Registration Fee Information
        </Typography>
      </div>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "#efedf0",
          height: "350px"
        }}
      >
        <Grid container spacing={2} sx={{ m: 1 }}>
          <Grid item xs={12} md={6} lg={3}>
            <SelectInputField
              label="Currency"
              handleSelectChange={handleChange}
              widthSize="32px"
              value={formValues.currency}
              mapValue={symbolArray}
              mapMethod="currencyList"
              name="currency"
              height="50px"
            />
            <span
              style={{
                color: "#FF2400",
                fontSize: "12px"
              }}
            >
              {formErrors?.currency}
            </span>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TopInputFieldBorderLabel
              label="Amount"
              handleChange={handleChange}
              widthSize="fullWidth"
              heightSize="42px"
              name="amount"
              value={formValues.amount}
              required
              paramses
              type="text"
            />
            <span
              style={{
                color: "#FF2400",
                fontSize: "12px"
              }}
            >
              {formErrors?.amount}
            </span>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TopInputFieldBorderLabel
              label="Date"
              handleChange={handleChange}
              widthSize="fullWidth"
              heightSize="42px"
              value={formValues.Date}
              name="Date"
              required
              paramses
              type="date"
            />

            <span
              style={{
                color: "#FF2400",
                fontSize: "12px"
              }}
            >
              {formErrors?.Date}
            </span>
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileTimePicker
                value={time}
                onChange={(newValue) => setTime(newValue)}
                label="Time"
                sx={{
                  textAlign: "center",
                  backgroundColor: "white",
                  borderRadius: "4px",
                  height: "49px",
                  // border: "2px solid red",
                  "& fieldset": {
                    border: "2px solid red",
                    borderRadius: "9px"
                  },
                  // height: "auto",
                  "& .MuiFormLabel-root": {
                    height: "30px",
                    pt: 1,
                    // pb:2,

                    "&.Mui-focused": {
                      color: "#7d7d7d",
                      pt: 0.9
                    }
                  }
                }}
              />
            </LocalizationProvider>

            <span
              style={{
                color: "#FF2400",
                fontSize: "12px"
              }}
            >
              {formErrors?.time}
            </span>
          </Grid>
          <Grid item xs={12}>
            <TopInputFieldBorderLabel
              label="Remarks"
              handleChange={handleChange}
              //   widthSize="100%"
              //   heightSize="100px"
              value={formValues.remarks}
              name="remarks"
              required
              paramses
              type="text"
            />

            <span
              style={{
                color: "#FF2400",
                fontSize: "12px"
              }}
            >
              {formErrors?.remarks}
            </span>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            {!paySlip && !paySlipUploaed ? (
              <>
                <Box
                  sx={{
                    width: "auto",
                    height: "82px",
                    backgroundColor: "white",
                    border: "1.5px dashed #ECECEC"
                  }}
                >
                  <label
                    htmlFor="avatar_file"
                    style={{ display: "flex", margin: "10px" }}
                    // onClick={() => setCountryId(data.id)}
                  >
                    <img
                      src="/menuIcons/docs.png"
                      alt="gsl"
                      style={{
                        width: "auto",
                        height: "auto",
                        cursor: "pointer",
                        color: "#4086f4"
                      }}
                    />
                    <Typography
                      sx={{
                        mt: 2,
                        color: "#ACB1C6",
                        fontWeight: 400,
                        fontSize: "20px"
                      }}
                    >
                      Upload Receipt +
                    </Typography>
                    <div style={{ marginLeft: "auto", paddingLeft: "auto" }}>
                      <img
                        src="/menuIcons/upload.png"
                        alt="gsl_download"
                        style={{ cursor: "pointer", marginTop: "10px" }}
                      />
                    </div>
                  </label>
                  <span
                    style={{
                      color: "#FF2400",
                      fontSize: "12px"
                    }}
                  >
                    {formErrors?.paySlip}
                  </span>
                </Box>
              </>
            ) : (
              <Box
                style={{
                  backgroundColor: "white",
                  border: "1px solid #DADBE4",
                  maxWidth: "433px",
                  width: "100%",
                  gap: "10px",
                  height: "60px",
                  borderRadius: "12px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "20px"
                }}
                container
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "20px",
                    alignItems: "center"
                  }}
                >
                  <img
                    src="/docicon.png"
                    alt=""
                    style={{
                      width: "40px",
                      height: "60px"
                      // margin: "30px",
                    }}
                  />

                  <Box>
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: "16px",
                        fontFamily: "'Poppins', sans-serif",
                        color: "black"
                        // mt: "35px",
                        // ml: "-10px",
                      }}
                    >
                      Document.pdf
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: "14px",
                        fontFamily: "'Poppins', sans-serif",
                        color: "#1DC02D"
                        // ml: -1,
                      }}
                    >
                      Uploaded
                    </Typography>
                  </Box>
                </Box>
                <Grid sx={{ display: "flex" }}>
                  <a
                    href={
                      paySlipUploaed
                        ? paySlipUploaed
                        : URL.createObjectURL(paySlip)
                    }
                    // download={URL.createObjectURL(paySlip)}
                    target="blank"
                    style={{ textDecoration: "none" }}
                    // onClick={handleDownload}
                  >
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: "14px",
                        fontFamily: "'Poppins', sans-serif",
                        color: "black",
                        pr: 2
                      }}
                    >
                      view
                    </Typography>
                  </a>
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: "14px",
                      fontFamily: "'Poppins', sans-serif",
                      color: "#FF1B6F",
                      pr: 2,
                      cursor: "pointer"
                    }}
                    onClick={() => setPaySlip("")}
                  >
                    Delete
                  </Typography>
                </Grid>
              </Box>
            )}

            <input
              type="file"
              accept=".pdf,.doc,.docx,.xlsx"
              id="avatar_file"
              style={{ display: "none" }}
              multiple
              onChange={(e) => handleUploadCommonDocsChange(e)}
            />
          </Grid>
        </Grid>
      </Box>

      {/* Submit Button */}
      {leadRegistrationFeeFindSuccess?.data == null && (
        <Grid
          item
          xs={12}
          textAlign="center"
          marginTop="1rem"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <SubmitButton
            title="Submit to approval"
            submit="button"
            widthSize="200px"
            heightSize="40px"
            type="click"
            handleSubmit={handleSubmit}
          />
        </Grid>
      )}
    </div>
  );
};

export default RegistrationFee;
