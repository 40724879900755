import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

import { useDispatch, useSelector } from "react-redux";
import { findcountryMasterAddAction } from "../../../../actions/countryMasterAction";
import { findStateMasterAction } from "../../../../actions/StateMasterAction";
import { findCityMasterAction } from "../../../../actions/cityManagmentAction";
import { findMasterbyTypeIDAction } from "../../../../actions/universityActions/universityMasterManagmentAction";
import { TypographyText } from "../../../customComponent/Typography";
import {
  SubmitButton,
  SwitchButton
} from "../../../customComponent/Buttons.js";
import InputField, {
  SelectInputField
} from "../../../customComponent/InputField";
import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import { locationFindAction } from "../../../../actions/locationAction.js";
import { branchManagerFetchAction } from "../../../../actions/employeeManagmentAction.js";

const ranks = Array.from({ length: 10 }, (_, i) => i + 1);

function BasicInformation({ handleComplete }) {
  const dispatch = useDispatch();
  const [universityName, setUniversityName] = useState("");
  const [country, setCountry] = useState(null);
  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);
  const [rank, setRank] = useState("");
  const [establishedYear, setEstablishedYear] = useState(dayjs());
  const [website, setWebsite] = useState("");
  const [internationalStudent, setInternationalStudent] = useState("");
  const [internationalFee, setInternationalFee] = useState("");

  const [universityNameError, setUniversityNameError] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [stateError, setStateError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [rankError, setRankError] = useState(false);
  const [affiliationError, setAffiliationError] = useState(false);
  const [facilitiesError, setFacilitiesError] = useState(false);
  const [typesError, setTypesError] = useState(false);

  const [establishedYearError, setEstablishedYearError] = useState(false);
  const [internationalStudentError, setInternationalStudentError] =
    useState(false);
  const [internationalFeeError, setInternationalFeeError] = useState(false);

  const [countries, setCountries] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [ranksData, setRanksData] = useState([]);
  const [affiliationsData, setAffiliationsData] = useState([]);
  const [facilitiesData, setFacilitiesData] = useState([]);
  const [typesData, setTypesData] = useState(["Indirect", "Direct"]);

  const [ranking, setRanking] = useState([]);
  const [affiliation, setAffiliation] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [applicationFee, setApplicationFee] = useState("");
  const [types, setTypes] = useState([]);
  const [isTopUniversity, setIsTopUniversity] = useState(false);

  const [location, setLocation] = useState("");
  const [locationData, setLocationData] = useState([]);
  const [universityHandler, setUniversityHandler] = useState("");

  console.log(rank, "rankrankrankrankrank");

  // selectors
  let { countryFindLoading, countryFindSuccess, countryFindErr } = useSelector(
    (state) => {
      return state.findcountryMasterAdd;
    }
  );
  // state selector
  const { stateFindLoading, stateFindSuccess, stateFindErr } = useSelector(
    (state) => {
      return state.findStateMaster;
    }
  );

  let { cityFindLoading, cityFindSuccess, cityFindErr } = useSelector(
    (state) => {
      return state.findCityMaster;
    }
  );

  let { locationFindLoading, locationFindSuccess } = useSelector((state) => {
    return state.LocationFind;
  });

  let { branchManagerLoading, branchManagerSuccess, branchManagerError } =
    useSelector((state) => {
      return state.branchManagerFetch;
    });

  let {
    universityMasterFindByIdLoading,
    universityMasterFindByIdSuccess,
    universityMasterFindByIdErr
  } = useSelector((state) => {
    return state.findMasterbyTypeID;
  });

  useEffect(() => {
    if (universityMasterFindByIdSuccess) {
      if (universityMasterFindByIdSuccess[0]?.universityMasterTypeId === 3) {
        setRanksData(universityMasterFindByIdSuccess);
      }
      if (universityMasterFindByIdSuccess[0]?.universityMasterTypeId === 2) {
        setAffiliationsData(universityMasterFindByIdSuccess);
      }

      if (universityMasterFindByIdSuccess[0]?.universityMasterTypeId === 4) {
        setFacilitiesData(universityMasterFindByIdSuccess);
      }

      // if (universityMasterFindByIdSuccess[0]?.universityMasterTypeId === 1) {
      //   setTypesData(universityMasterFindByIdSuccess);
      // }
    }
  }, [universityMasterFindByIdSuccess]);

  useEffect(() => {
    dispatch(findCityMasterAction());
    dispatch(branchManagerFetchAction("", "university handler"));
  }, [dispatch]);

  useEffect(() => {
    dispatch(findStateMasterAction());
  }, [dispatch]);

  useEffect(() => {
    if (countryFindSuccess) {
      setCountries(countryFindSuccess);
    }
  }, [countryFindSuccess]);

  useEffect(() => {
    dispatch(findcountryMasterAddAction());
    dispatch(locationFindAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(findMasterbyTypeIDAction(3));
  }, [dispatch]);

  useEffect(() => {
    dispatch(findMasterbyTypeIDAction(2));
  }, [dispatch]);

  useEffect(() => {
    dispatch(findMasterbyTypeIDAction(4));
  }, [dispatch]);

  useEffect(() => {
    dispatch(findMasterbyTypeIDAction(1));
  }, [dispatch]);

  console.log(universityHandler, "universityHandleruniversityHandler");

  useEffect(() => {
    // Retrieve column visibility state from localStorage

    const storedUniversityNameColumn = localStorage.getItem("universityName");
    const storedCountryNameColumn = localStorage.getItem("country");
    const storedCityColumn = localStorage.getItem("city");
    const storedStateColumn = localStorage.getItem("state");
    const storedRankColumn = localStorage.getItem("rank");
    const storedRankingColumn = localStorage.getItem("ranking");
    const storedAffiliationColumn = localStorage.getItem("affiliation");
    const storedFacilitiesColumn = localStorage.getItem("facilities");
    const storedTypesColumn = localStorage.getItem("types");

    const storedEstablishedYearColumn = localStorage.getItem("establishedYear");

    const storedWebsiteColumn = localStorage.getItem("website");

    const storedUniversityHandlerColumn =
      localStorage.getItem("universityHandler");

    // const storedInternationalStudentColumn = localStorage.getItem(
    //   "internationalStudent"
    // );

    // const storedInternationalFeeColumn =
    //   localStorage.getItem("internationalFee");

    if (storedUniversityNameColumn !== null) {
      setUniversityName(JSON.parse(storedUniversityNameColumn));
    }
    if (storedCountryNameColumn !== null) {
      let country = JSON.parse(storedCountryNameColumn);
      setCountry(country.countryName);
    }
    if (storedCityColumn !== null) {
      let city = JSON.parse(storedCityColumn);
      setCity(city?.cityName);
    }

    if (storedStateColumn !== null) {
      let state = JSON.parse(storedStateColumn);
      setState(state?.stateName);
    }

    if (storedRankColumn !== null) {
      setRank(JSON.parse(storedRankColumn));
    }

    if (storedRankingColumn !== null) {
      setRanking(JSON.parse(storedRankingColumn));
    }

    if (storedAffiliationColumn !== null) {
      setAffiliation(JSON.parse(storedAffiliationColumn));
    }

    if (storedFacilitiesColumn !== null) {
      setFacilities(JSON.parse(storedFacilitiesColumn));
    }

    if (storedTypesColumn !== null) {
      setTypes(JSON.parse(storedTypesColumn));
    }

    if (storedEstablishedYearColumn !== null) {
      setEstablishedYear(dayjs(JSON.parse(storedEstablishedYearColumn)));
    }

    if (storedWebsiteColumn !== null) {
      setWebsite(JSON.parse(storedWebsiteColumn));
    }

    if (storedUniversityHandlerColumn !== null) {
      setWebsite(JSON.parse(storedUniversityHandlerColumn));
    }

    // if (storedInternationalFeeColumn !== null) {
    //   setInternationalFee(JSON.parse(storedInternationalFeeColumn));
    // }
  }, []);

  const handleUniversityNameChange = (event) => {
    setUniversityName(event.target.value);
    setUniversityNameError(false);
  };

  const handleCountryChange = (event) => {
    let value = event.target.value;

    console.log(value, "slkdjflakjdflakjdfl;kdjf;lksdjlkh");

    if (value) {
      setCountry(value);
      if (stateFindSuccess) {
        setStateData(
          stateFindSuccess.filter(
            (data) => data?.countryCode === value.countryCode
          )
        );
      }

      if (locationFindSuccess) {
        setLocationData(
          locationFindSuccess?.data?.filter(
            (item) => item?.countryName == value?.countryName
          )
        );
      }

      setCountryError(false);
    }
  };

  const handleCityChange = (event) => {
    let value = event.target.value;
    if (value) {
      setCity(value);
      setStateError(false);
    }
  };
  const handleStateChange = (event) => {
    let value = event.target.value;
    if (value) {
      console.log(value, "jkfhsakdfhkasj");
      setState(value);

      setCityData(cityFindSuccess.filter((data) => data.stateId == value.id));
      setStateError(false);
    }
  };

  const handleRankChange = (event) => {
    // const {
    //   target: { value },
    // } = event;

    // console.log(value);

    // const filteredArray = value.filter((obj) =>
    //   obj.hasOwnProperty("universityMasterName")
    // );

    // setRanking((prevRanking) => {
    //   const updatedRanking = [...prevRanking];

    //   filteredArray.forEach((selectedItem) => {
    //     const existingIndex = updatedRanking.findIndex(
    //       (item) => item.id === selectedItem.id
    //     );

    //     if (existingIndex !== -1) {
    //       // Item already exists in the ranking, remove it
    //       updatedRanking.splice(existingIndex, 1);
    //     } else {
    //       // Item doesn't exist in the ranking, add it
    //       updatedRanking.push({
    //         name: selectedItem.universityMasterName,
    //         id: selectedItem.id,
    //       });
    //     }
    //   });

    //   return updatedRanking;
    // });
    setRanking(event);

    setRankError(false);
  };

  const handleAffiliationChange = (event) => {
    // const {
    //   target: { value },
    // } = event;

    // const filteredArray = value.filter((obj) =>
    //   obj.hasOwnProperty("universityMasterName")
    // );

    setAffiliation(event);
    //   const updatedRanking = [...prevRanking]; // Create a copy of the current ranking state

    //   filteredArray.forEach((selectedItem) => {
    //     const existingIndex = updatedRanking.findIndex(
    //       (item) => item.id === selectedItem.id
    //     );

    //     if (existingIndex !== -1) {
    //       // Item already exists in the ranking, remove it
    //       updatedRanking.splice(existingIndex, 1);
    //     } else {
    //       // Item doesn't exist in the ranking, add it
    //       updatedRanking.push({
    //         name: selectedItem.universityMasterName,
    //         id: selectedItem.id,
    //       });
    //     }
    //   });

    //   return updatedRanking;
    // });
    setAffiliationError(false);
  };

  const handleFacilitieChange = (event) => {
    // const {
    //   target: { value },
    // } = event;

    // const filteredArray = value.filter((obj) =>
    //   obj.hasOwnProperty("universityMasterName")
    // );

    setFacilities(event);
    //   const updatedRanking = [...prevRanking]; // Create a copy of the current ranking state

    //   filteredArray.forEach((selectedItem) => {
    //     const existingIndex = updatedRanking.findIndex(
    //       (item) => item.id === selectedItem.id
    //     );

    //     if (existingIndex !== -1) {
    //       // Item already exists in the ranking, remove it
    //       updatedRanking.splice(existingIndex, 1);
    //     } else {
    //       // Item doesn't exist in the ranking, add it
    //       updatedRanking.push({
    //         name: selectedItem.universityMasterName,
    //         id: selectedItem.id,
    //       });
    //     }
    //   });

    //   return updatedRanking;
    // });

    setFacilitiesError(false);
  };

  //
  // const handleTypesChange = (event) => {
  //   // const {
  //   //   target: { value },
  //   // } = event;

  //   // const filteredArray = value.filter((obj) =>
  //   //   obj.hasOwnProperty("universityMasterName")
  //   // );

  //   // setTypes((prevRanking) => {
  //   //   const updatedRanking = [...prevRanking]; // Create a copy of the current ranking state

  //   //   filteredArray.forEach((selectedItem) => {
  //   //     const existingIndex = updatedRanking.findIndex(
  //   //       (item) => item.id === selectedItem.id
  //   //     );

  //   //     if (existingIndex !== -1) {
  //   //       // Item already exists in the ranking, remove it
  //   //       updatedRanking.splice(existingIndex, 1);
  //   //     } else {
  //   //       // Item doesn't exist in the ranking, add it
  //   //       updatedRanking.push({
  //   //         name: selectedItem.universityMasterName,
  //   //         id: selectedItem.id,
  //   //       });
  //   //     }
  //   //   });

  //   //   return updatedRanking;
  //   // });
  //   setTypes(event);

  //   setTypesError(false);
  // };
  const handleEstablishedYearChange = (event) => {
    console.log(event, "eventeventeventevent");
    setEstablishedYear(event);
    setEstablishedYearError(false);
  };

  // const handleInternationalStudentChange = (event) => {
  //   setInternationalStudent(event.target.value);
  //   setInternationalStudentError(false);
  // };

  // const handleInternationalFeeChange = (event) => {
  //   setInternationalFee(event.target.value);
  //   setInternationalFeeError(false);
  // };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    // Basic form validation
    let isValid = true;

    if (universityName.trim() === "") {
      setUniversityNameError(true);
      isValid = false;
    }

    if (country === null) {
      setCountryError(true);
      isValid = false;
    }

    // if (state === null) {
    //   setStateError(true);
    //   isValid = false;
    // }

    if (affiliation === null || affiliation.length === 0) {
      setAffiliationError(true);
      isValid = false;
    }

    if (establishedYear === null) {
      setEstablishedYearError(true);
      isValid = false;
    }

    // if (internationalStudent.trim() === "") {
    //   setInternationalStudentError(true);
    //   isValid = false;
    // }

    // if (internationalFee.trim() === "") {
    //   setInternationalFeeError(true);
    //   isValid = false;
    // }

    console.log(isValid, "isValidisValidisValid");

    if (isValid) {
      // Call the handleComplete function or perform any desired action
      localStorage.setItem("universityName", JSON.stringify(universityName));

      localStorage.setItem("country", JSON.stringify(country));
      localStorage.setItem("state", JSON.stringify(state));
      localStorage.setItem("city", JSON.stringify(city));
      localStorage.setItem("location", JSON.stringify(location));

      localStorage.setItem("ranking", JSON.stringify(ranking));
      localStorage.setItem("rank", JSON.stringify(rank));

      localStorage.setItem("affiliation", JSON.stringify(affiliation));
      localStorage.setItem("facilities", JSON.stringify(facilities));
      localStorage.setItem("types", JSON.stringify(types));

      localStorage.setItem("establishedYear", JSON.stringify(establishedYear));
      localStorage.setItem(
        "internationalStudent",
        JSON.stringify(internationalStudent)
      );
      localStorage.setItem(
        "internationalFee",
        JSON.stringify(internationalFee)
      );
      localStorage.setItem("isTopUniversity", JSON.stringify(isTopUniversity));
      localStorage.setItem("applicationFee", JSON.stringify(applicationFee));
      localStorage.setItem(
        "universityHandler",
        JSON.stringify(universityHandler)
      );

      localStorage.setItem("website", JSON.stringify(website));

      handleComplete("completed");
    }
  };

  console.log(establishedYear, "establishedYearestablishedYear");

  return (
    <>
      <TypographyText title="Basic Information" />

      {/* <Box sx={{ display: "flex", mt: 2 }}> */}
      <form onSubmit={handleFormSubmit} style={{ marginTop: "20px" }}>
        <Grid container spacing={2}>
          {/* First Column */}
          <Grid item xs={12} md={6} lg={4}>
            <InputField
              label="University Name"
              handleChange={handleUniversityNameChange}
              widthSize="97%"
              value={universityName}
              isRequired={true}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <SelectInputField
              label="Country Name"
              handleSelectChange={handleCountryChange}
              widthSize="99px"
              value={country}
              mapValue={countries}
              mapMethod="countryName"
              required={true}
            />
          </Grid>
          {/* <Grid item xs={12} md={6} lg={4}>
            <SelectInputField
              label="State"
              handleSelectChange={handleStateChange}
              widthSize="40px"
              value={state}
              mapValue={stateData}
              mapMethod="universityState"
              // required={true}
            />
          </Grid> */}

          {/* Second Column */}
          {/* <Grid item xs={12} md={6} lg={4}>
            <SelectInputField
              label="City"
              handleSelectChange={handleCityChange}
              widthSize="30px"
              value={city}
              mapValue={cityData}
              mapMethod="universityCity"
            />
          </Grid> */}

          <Grid item xs={12} md={6} lg={4}>
            <SelectInputField
              label="Location"
              handleSelectChange={(e) => setLocation(e.target.value)}
              widthSize="30px"
              value={location}
              mapValue={locationData}
              mapMethod="universityLocation"
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <InputField
              label="Website"
              handleChange={(e) => setWebsite(e.target.value)}
              widthSize="97%"
              value={website}
              isRequired={true}
            />
          </Grid>

          {/* <Grid item xs={12} md={6} lg={4}>
            <SelectInputField
              label="Ranking"
              handleSelectChange={handleRankChange}
              widthSize="59px"
              value={ranking}
              mapValue={ranksData}
              mapMethod="universityRanking"
              multiple="true"
              required={true}
            />
            {rankError ? (
              <p
                style={{
                  color: "red",
                  margin: 0,
                  padding: 0,
                  fontSize: "12px",
                }}
              >
                Please Select Rank
              </p>
            ) : null}
          </Grid> */}

          <Grid item xs={12} md={6} lg={4}>
            {/* <FormControl fullWidth>
              <InputLabel
                id="demo-multiple-chip-label"
                sx={{
                  width: "150px",
                  fontWeight: 400,
                  color: "black",
                  fontSize: "14px",
                }}
              >
                Affiliation
              </InputLabel>
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                fullWidth
                value={affiliation}
                onChange={handleAffiliationChange}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 0.3,
                      width: "auto",
                      height: "auto",
                      mt: 1,
                    }}
                  >
                    {selected?.map((value) => (
                      <Chip key={value.id} label={value.name} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
                sx={{
                  height: "46px", // Adjust the height to reduce the overall height of the Select component
                  "& .MuiInputBase-input": {
                    borderRadius: "20px",
                    height: "100%", // Set the input height to 100% to fill the container height
                  },
                  "& .MuiInputBase-root": {
                    borderWidth: "1px",
                    borderRadius: "4px",
                    borderColor: "#fcfcfc",
                    border: "1px solid #fcfcfc",
                    "&:hover": {
                      borderColor: "#fcfcfc", // Set the desired border color for hover state
                    },
                    "&.Mui-focused": {
                      borderColor: "#fcfcfc", // Set the desired border color for focused state
                    },
                  },
                }}
              >
                {affiliationsData.map((name) => (
                  <MenuItem
                    key={name.id}
                    value={{
                      id: name.id,
                      universityMasterName: name.universityMasterName,
                    }}
                    // style={getStyles(name, ranking, theme)}
                  >
                    {name.universityMasterName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}

            <SelectInputField
              label="Affiliation"
              handleSelectChange={handleAffiliationChange}
              widthSize="59px"
              value={affiliation}
              mapValue={affiliationsData}
              mapMethod="universityAffiliation"
              multiple="true"
              required={true}
            />
            {affiliationError ? (
              <p
                style={{
                  color: "red",
                  margin: 0,
                  padding: 0,
                  fontSize: "12px"
                }}
              >
                Please Select Affiliation
              </p>
            ) : null}
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            {/* <FormControl fullWidth>
              <InputLabel
                id="demo-multiple-chip-label"
                sx={{
                  width: "288px",
                  fontWeight: 400,
                  color: "black",
                  fontSize: "14px",
                }}
              >
                Facilities
              </InputLabel>
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={facilities}
                onChange={handleFacilitieChange}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 0.3,
                      width: "auto",
                      height: "auto",
                      mt: 1,
                    }}
                  >
                    {selected?.map((value) => (
                      <Chip key={value.id} label={value.name} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
                fullWidth
                sx={{
                  height: "46px", // Adjust the height to reduce the overall height of the Select component
                  "& .MuiInputBase-input": {
                    borderRadius: "20px",
                    height: "100%", // Set the input height to 100% to fill the container height
                  },
                  "& .MuiInputBase-root": {
                    borderWidth: "1px",
                    borderRadius: "4px",
                    borderColor: "#fcfcfc",
                    border: "1px solid #fcfcfc",
                    "&:hover": {
                      borderColor: "#fcfcfc", // Set the desired border color for hover state
                    },
                    "&.Mui-focused": {
                      borderColor: "#fcfcfc", // Set the desired border color for focused state
                    },
                  },
                }}
              >
                {facilitiesData.map((name) => (
                  <MenuItem
                    key={name.id}
                    value={{
                      id: name.id,
                      universityMasterName: name.universityMasterName,
                    }}
                    // style={getStyles(name, ranking, theme)}
                  >
                    {name.universityMasterName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}

            <SelectInputField
              label="Facilities"
              handleSelectChange={handleFacilitieChange}
              widthSize="58.9px"
              value={facilities}
              mapValue={facilitiesData}
              mapMethod="universityFacilities"
              multiple="true"
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <SelectInputField
              label="University Type"
              handleSelectChange={(e) => setTypes(e.target.value)}
              widthSize="83px"
              value={types}
              mapValue={typesData}
              mapMethod="universitieType"
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            {/* <InputField
              label="Established Year"
              handleChange={handleEstablishedYearChange}
              widthSize="97%"
              value={establishedYear}
              InputType="date"
              required={true}
            />
              
            
            {establishedYearError ? (
              <p style={{ color: "red", margin: 0, padding: 0 }}>
                Please Select Established Year
              </p>
            ) : null} */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Established Year *"
                views={["year"]}
                required
                onChange={handleEstablishedYearChange}
                value={establishedYear}
                sx={{
                  width: "100%",
                  "& .MuiInputBase-input": { height: "13px" },
                  "& .MuiInputLabel-root": {
                    color: "rgb(5, 5, 5)",
                    fontSize: "14px",
                    marginRight: "0px",
                    paddingRight: "0px"
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <InputField
              label="Rank"
              handleChange={(e) => setRank(e.target.value)}
              widthSize="97%"
              value={rank}
              InputType="number"
              isRequired={false}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <InputField
              label="Application fee"
              handleChange={(e) => setApplicationFee(e.target.value)}
              widthSize="97%"
              value={applicationFee}
              isRequired={false}
              InputType="number"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Autocomplete
              onChange={(event, newValue) =>
                setUniversityHandler(newValue?.id || "")
              }
              value={
                branchManagerSuccess?.find(
                  (option) => option.id === universityHandler
                ) || null
              }
              disablePortal
              options={branchManagerSuccess || []}
              getOptionLabel={(option) => option?.firstName || ""}
              sx={{ width: "100%", height: "46px" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="University handler"
                  InputProps={{
                    ...params.InputProps,
                    sx: { height: "46px" }
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: "14px",
                      color: "black",
                      "&.Mui-focused": {
                        color: "black"
                      }
                    }
                  }}
                />
              )}
            />
          </Grid>

          <Grid item lg={12} xs={12}>
            <Typography variant="h6" sx={{ mt: 0.5, fontWeight: "bold" }}>
              Top university
            </Typography>
            <Box sx={{ ml: -9, mt: 2 }}>
              <SwitchButton
                // label="Video"
                handleChange={() => {
                  setIsTopUniversity(!isTopUniversity);
                }}
                checked={isTopUniversity}
              />
            </Box>
          </Grid>
        </Grid>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <SubmitButton
            title="Next"
            submit="submit"
            widthSize="115px"
            heightSize="50px"
            type="submit"
            handleSubmit=""
          />
        </div>
      </form>
      {/* </Box> */}
    </>
  );
}

export default BasicInformation;
