import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Chip,
  Grid,
  InputAdornment,
  TextField,
  Typography
} from "@mui/material";
import { SubmitButton } from "../../customComponent/Buttons";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";
import PremissionDialog from "../../customComponent/premissionDialog";
import { RiSearch2Line } from "react-icons/ri";
import EducationDetails from "../creatApplicationDetails/EducationDetails";
import SopDetails from "../creatApplicationDetails/SopDetails";
import TravelImmigration from "../creatApplicationDetails/TravelImmigration";
import WorkDetails from "../creatApplicationDetails/WorkDetails";
import CountryDocs from "../creatApplicationDetails/CountryDocs";
import { useDispatch, useSelector } from "react-redux";
import { leadEducationDetailsShowAction } from "../../../actions/leadActions/leadActions";
import { leadWorkDetailsFindAction } from "../../../actions/leadActions/leadDocsActions";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(2),
    marginTop: "8px"
  },
  hoverElement: {
    "&:hover": {
      color: "#FE0B7A" // Set the hover color
    },
    "&.clicked": {
      backgroundColor: "#FE0B7A",
      color: "white"
    }
  }
}));

const useStylesForSearch = makeStyles((theme) => ({
  searchContainer: {
    display: "flex",
    // alignItems: ""
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    position: "relative"
  },
  searchInput: {
    marginLeft: theme.spacing(1)
  }
}));

function EducationAndDocStepper({ handleNext, handleCompleteTick, tab }) {
  const { id } = useParams();
  const classes = useStyles();
  const searchClasses = useStylesForSearch();
  const dispatch = useDispatch();

  const [clicked, setClicked] = useState("education");
  const [subTabs, setSubTabs] = useState([
    "education",
    "sop",
    "travel&immigration",
    "workDetails",
    "documents"
  ]);

  const [addFormCountry, setAddFormCountry] = useState(false);
  const [addFormState, setAddFormState] = useState(false);
  const [addFormCity, setAddFormCity] = useState(false);
  const [addFormLocation, setAddFormLocation] = useState(false);

  const { privilege, hasPrivilege, isCreatePrevilage } = usePrivilegeCheck();
  const [openAlert, setOpenAlert] = useState(false);
  const [search, setSearch] = useState("");

  // Reducers ::::::::::::::

  let {
    leadEducationDetailFindLoading,
    leadEducationDetailFindSuccess,
    leadEducationDetailFinderror
  } = useSelector((state) => {
    return state.leadEducationDetailsShow;
  });

  let { leadWorkDetailsFindingSuccess } = useSelector((state) => {
    return state.leadWorkDetailsFind;
  });

  console.log(
    leadWorkDetailsFindingSuccess,
    "leadWorkDetailsFindingSuccessleadWorkDetailsFindingSuccess"
  );

  useEffect(() => {
    if (
      leadEducationDetailFindSuccess?.aLeadEducationDeatails !== null &&
      leadWorkDetailsFindingSuccess?.leadRefereeDeatails?.length !== 0 &&
      leadWorkDetailsFindingSuccess?.leadWorkDeatails?.length !== 0
    ) {
      handleCompleteTick();
    }
  }, [leadEducationDetailFindSuccess, leadWorkDetailsFindingSuccess]);

  useEffect(() => {
    if (tab == 1) {
      handleClick("education");
    }
  }, [tab]);

  // HandleClose dialog

  const handleCloseDialog = () => {
    setOpenAlert(false);
  };

  const handleClick = (master) => {
    setClicked(master);
  };

  // if (!hasPrivilege("master") && privilege !== null) {
  //   return (
  //     <Alert severity="error">
  //       <AlertTitle>warning</AlertTitle>
  //       You don't have permission to access — <strong>this page.!</strong>
  //     </Alert>
  //   );
  // }

  const handleNextTab = () => {
    if (clicked == "education") {
      dispatch(leadEducationDetailsShowAction(id));

      setClicked("sop");
    }
    if (clicked == "sop") {
      setClicked("travel&immigration");
    }
    if (clicked == "travel&immigration") {
      setClicked("workDetails");
    }

    if (clicked == "workDetails") {
      dispatch(leadWorkDetailsFindAction(id));
      setClicked("documents");
    }
  };

  const handleBack = () => {
    if (clicked == "sop") {
      dispatch(leadEducationDetailsShowAction(id));
      setClicked("education");
    }
    if (clicked == "travel&immigration") {
      setClicked("sop");
    }
    if (clicked == "workDetails") {
      setClicked("travel&immigration");
    }

    if (clicked == "documents") {
      dispatch(leadWorkDetailsFindAction(id));
      setClicked("workDetails");
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} lg={8}>
          <Chip
            className={`${classes.hoverElement} ${
              clicked === "education" ? "clicked" : ""
            }`}
            label="Education"
            component="a"
            clickable
            sx={{ m: 1 }}
            onClick={() => handleClick("education")}
          />
          <Chip
            className={`${classes.hoverElement} ${
              clicked === "sop" ? "clicked" : ""
            }`}
            label="Sop"
            component="a"
            clickable
            sx={{ m: 1 }}
            onClick={() => handleClick("sop")}
          />
          <Chip
            className={`${classes.hoverElement} ${
              clicked === "travel&immigration" ? "clicked" : ""
            }`}
            label="Travel & Immigration"
            component="a"
            clickable
            sx={{ m: 1 }}
            onClick={() => handleClick("travel&immigration")}
          />

          <Chip
            className={`${classes.hoverElement} ${
              clicked === "workDetails" ? "clicked" : ""
            }`}
            label="Work Details"
            component="a"
            clickable
            sx={{ m: 1 }}
            onClick={() => handleClick("workDetails")}
          />

          <Chip
            className={`${classes.hoverElement} ${
              clicked === "documents" ? "clicked" : ""
            }`}
            label="Documents"
            component="a"
            clickable
            sx={{ m: 1 }}
            onClick={() => handleClick("documents")}
          />
        </Grid>
      </Grid>
      {clicked === "education" && (
        <EducationDetails
          handleNext={handleNextTab}
          handleCompleteTick={handleCompleteTick}
        />
      )}
      {clicked === "sop" && (
        <SopDetails
          handleNext={handleNextTab}
          handleCompleteTick={handleCompleteTick}
        />
      )}
      {clicked === "travel&immigration" && (
        <TravelImmigration
          handleNext={handleNextTab}
          handleCompleteTick={handleCompleteTick}
        />
      )}
      {clicked === "workDetails" && (
        <WorkDetails
          handleNext={handleNextTab}
          handleCompleteTick={handleCompleteTick}
        />
      )}
      {clicked === "documents" && (
        <CountryDocs
          handleNext={handleNextTab}
          handleCompleteTick={handleCompleteTick}
        />
      )}
      <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
        <Button
          color="inherit"
          disabled={clicked === "education"}
          onClick={handleBack}
          sx={{ mr: 1 }}
        >
          Back
        </Button>
        <>
          {clicked !== "documents" && (
            <>
              <Box sx={{ flex: "1 1 auto" }} />
              <Box sx={{ mr: 2 }}>
                <SubmitButton
                  title="Next"
                  submit="submit"
                  widthSize="109px"
                  heightSize="30px"
                  type="click"
                  handleSubmit={handleNextTab}
                />
              </Box>
            </>
          )}

          {/* <Typography variant="caption" sx={{ display: "inline-block" }}>
            Step  already completed
          </Typography> */}
        </>
      </Box>
    </>
  );
}

export default EducationAndDocStepper;
