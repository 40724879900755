import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkEmployeePrivilegeAction } from "../../actions/employeeManagmentAction";
import { findUserAction } from "../../actions/adminAuthAction";

const usePrivilegeCheck = () => {
  const [privilege, setPrivilege] = useState(null);
  const dispatch = useDispatch();

  const [isUserExist, setIsUserExist] = useState(
    localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null
  );

  const { checkEmployeePrivilegeSuccess } = useSelector(
    (state) => state.checkEmployeePrivilege
  );

  let { loginFindLoading, loginFindSuccess, loginFinderror } = useSelector(
    (state) => {
      return state.findUser;
    }
  );

  useEffect(() => {
    dispatch(findUserAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(checkEmployeePrivilegeAction());
  }, [dispatch, isUserExist, loginFindSuccess]);

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "loginInfo") {
        setIsUserExist(event.newValue ? JSON.parse(event.newValue) : null);
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [checkEmployeePrivilegeSuccess]);

  console.log(
    checkEmployeePrivilegeSuccess,
    "checkEmployeePrivilegeSuccesscheckEmployeePrivilegeSuccesscheckEmployeePrivilegeSuccess"
  );

  useEffect(() => {
    if (checkEmployeePrivilegeSuccess) {
      setPrivilege(checkEmployeePrivilegeSuccess.data);
    }
  }, [checkEmployeePrivilegeSuccess]);

  console.log(privilege, "privilegeprivilegeprivilegeprivilegeprivilege");

  // Orginall

  // const hasPrivilege = (subMenuName) => {
  //   // Check if privilege is not null and role_privilages array exists
  //   if (privilege && privilege.role_sub_menus) {
  //     // Check if any role in role_privilages has the specified subMenuName
  //     return privilege.role_sub_menus.some((role) =>
  //       role?.side_menu?.sub_menus?.some(
  //         (subMenu) =>
  //           subMenu.name == subMenuName &&
  //           subMenu?.role_privilages?.length !== 0
  //       )
  //     );
  //   }
  //   return false;
  // };

  // Test

  // For view previlage

  const hasPrivilege = (subMenuName) => {
    if (loginFindSuccess?.fk_role_id == "admin") {
      return true;
    }
    if (privilege && privilege.role_sub_menus) {
      console.log(privilege,"privilgeeeeeeeeeeeeeeeeeeeeee",)
      // Check if privilege is not null and role_privilages array exists
      // Check if any role in role_privilages has the specified subMenuName
      return privilege.role_sub_menus.some((role) =>
        role?.side_menu?.sub_menus?.some(
          (subMenu) =>
            subMenu.name == subMenuName &&
            subMenu?.role_privilages?.some(
              (permission) =>
                permission?.permission?.name == "view" &&
                permission.roleId == privilege.id
            )
        )
      );
    }
    return false;
  };

  // for create privilage

  const isCreatePrevilage = (subMenuName) => {
    if (loginFindSuccess?.fk_role_id == "admin") {
      return true;
    }
    // Check if privilege is not null and role_privilages array exists
    if (privilege && privilege.role_sub_menus) {
      // Check if any role in role_privilages has the specified subMenuName
      return privilege.role_sub_menus.some((role) =>
        role?.side_menu?.sub_menus?.some(
          (subMenu) =>
            subMenu.name == subMenuName &&
            subMenu?.role_privilages?.some(
              (permission) =>
                permission?.permission?.name == "create" &&
                permission.roleId == privilege.id
            )
        )
      );
    }
    return false;
  };

  // for Delete previlage

  const isDeletePrevilage = (subMenuName) => {
    if (loginFindSuccess?.fk_role_id == "admin") {
      return true;
    }
    // Check if privilege is not null and role_privilages array exists
    if (privilege && privilege.role_sub_menus) {
      // Check if any role in role_privilages has the specified subMenuName
      return privilege.role_sub_menus.some((role) =>
        role?.side_menu?.sub_menus?.some(
          (subMenu) =>
            subMenu.name == subMenuName &&
            subMenu?.role_privilages?.some(
              (permission) =>
                permission?.permission?.name == "delete" &&
                permission.roleId == privilege.id
            )
        )
      );
    }
    return false;
  };

  // for update previlage

  const isUpdatePrevilage = (subMenuName) => {
    if (loginFindSuccess?.fk_role_id == "admin") {
      return true;
    }
    // Check if privilege is not null and role_privilages array exists
    if (privilege && privilege.role_sub_menus) {
      // Check if any role in role_privilages has the specified subMenuName
      return privilege.role_sub_menus.some((role) =>
        role?.side_menu?.sub_menus?.some(
          (subMenu) =>
            subMenu.name == subMenuName &&
            subMenu?.role_privilages?.some(
              (permission) =>
                permission?.permission?.name == "update" &&
                permission.roleId == privilege.id
            )
        )
      );
    }
    return false;
  };

  // For verify Previlage

  const isVerifyPrevilage = (subMenuName) => {
    if (loginFindSuccess?.fk_role_id == "admin") {
      return true;
    }
    // Check if privilege is not null and role_privilages array exists
    if (privilege && privilege.role_sub_menus) {
      // Check if any role in role_privilages has the specified subMenuName
      return privilege.role_sub_menus.some((role) =>
        role?.side_menu?.sub_menus?.some(
          (subMenu) =>
            subMenu.name == subMenuName &&
            subMenu?.role_privilages?.some(
              (permission) =>
                permission?.permission?.name == "verify" &&
                permission.roleId == privilege.id
            )
        )
      );
    }
    return false;
  };

  // For approve Previlage

  const isApprovePrevilage = (subMenuName) => {
    if (loginFindSuccess?.fk_role_id == "admin") {
      return true;
    }
    // Check if privilege is not null and role_privilages array exists
    if (privilege && privilege.role_sub_menus) {
      // Check if any role in role_privilages has the specified subMenuName
      return privilege.role_sub_menus.some((role) =>
        role?.side_menu?.sub_menus?.some(
          (subMenu) =>
            subMenu.name == subMenuName &&
            subMenu?.role_privilages?.some(
              (permission) =>
                permission?.permission?.name == "approve" &&
                permission.roleId == privilege.id
            )
        )
      );
    }
    return false;
  };

  // For changeOwner previlage

  const isChangeOwnerPrevilage = (subMenuName) => {
    if (loginFindSuccess?.fk_role_id == "admin") {
      return true;
    }
    // Check if privilege is not null and role_privilages array exists
    if (privilege && privilege.role_sub_menus) {
      // Check if any role in role_privilages has the specified subMenuName
      return privilege.role_sub_menus.some((role) =>
        role?.side_menu?.sub_menus?.some(
          (subMenu) =>
            subMenu.name == subMenuName &&
            subMenu?.role_privilages?.some(
              (permission) =>
                permission?.permission?.name == "change owner" &&
                permission.roleId == privilege.id
            )
        )
      );
    }
    return false;
  };

  // For changeStatus previlage

  const isChangeStatusPrevilage = (subMenuName) => {
    if (loginFindSuccess?.fk_role_id == "admin") {
      return true;
    }
    // Check if privilege is not null and role_privilages array exists
    if (privilege && privilege.role_sub_menus) {
      // Check if any role in role_privilages has the specified subMenuName
      return privilege.role_sub_menus.some((role) =>
        role?.side_menu?.sub_menus?.some(
          (subMenu) =>
            subMenu.name == subMenuName &&
            subMenu?.role_privilages?.some(
              (permission) =>
                permission?.permission?.name == "change status" &&
                permission.roleId == privilege.id
            )
        )
      );
    }
    return false;
  };

  // For changePriority previlage

  const isChangePriorityPrevilage = (subMenuName) => {
    if (loginFindSuccess?.fk_role_id == "admin") {
      return true;
    }
    // Check if privilege is not null and role_privilages array exists
    if (privilege && privilege.role_sub_menus) {
      // Check if any role in role_privilages has the specified subMenuName
      return privilege.role_sub_menus.some((role) =>
        role?.side_menu?.sub_menus?.some(
          (subMenu) =>
            subMenu.name == subMenuName &&
            subMenu?.role_privilages?.some(
              (permission) =>
                permission?.permission?.name == "change priority" &&
                permission.roleId == privilege.id
            )
        )
      );
    }
    return false;
  };

  // For assign counsellor previlage

  const isAssignCounsilorPrevilage = (subMenuName) => {
    if (loginFindSuccess?.fk_role_id == "admin") {
      return true;
    }
    // Check if privilege is not null and role_privilages array exists
    if (privilege && privilege.role_sub_menus) {
      // Check if any role in role_privilages has the specified subMenuName
      return privilege.role_sub_menus.some((role) =>
        role?.side_menu?.sub_menus?.some(
          (subMenu) =>
            subMenu.name == subMenuName &&
            subMenu?.role_privilages?.some(
              (permission) =>
                permission?.permission?.name == "assign counsellor" &&
                permission.roleId == privilege.id
            )
        )
      );
    }
    return false;
  };

  // for FollowUp previlage

  const isFollowUpPrevilage = (subMenuName) => {
    if (loginFindSuccess?.fk_role_id == "admin") {
      return true;
    }
    // Check if privilege is not null and role_privilages array exists
    if (privilege && privilege.role_sub_menus) {
      // Check if any role in role_privilages has the specified subMenuName
      return privilege.role_sub_menus.some((role) =>
        role?.side_menu?.sub_menus?.some(
          (subMenu) =>
            subMenu.name == subMenuName &&
            subMenu?.role_privilages?.some(
              (permission) =>
                permission?.permission?.name == "followup" &&
                permission.roleId == privilege.id
            )
        )
      );
    }
    return false;
  };

  // for leadStatusChange previlage

  const isLeadStatusChangePrevilage = (subMenuName) => {
    if (loginFindSuccess?.fk_role_id == "admin") {
      return true;
    }
    // Check if privilege is not null and role_privilages array exists
    if (privilege && privilege.role_sub_menus) {
      // Check if any role in role_privilages has the specified subMenuName
      return privilege.role_sub_menus.some((role) =>
        role?.side_menu?.sub_menus?.some(
          (subMenu) =>
            subMenu.name == subMenuName &&
            subMenu?.role_privilages?.some(
              (permission) =>
                permission?.permission?.name == "leadStatusChange" &&
                permission.roleId == privilege.id
            )
        )
      );
    }
    return false;
  };

  // For timeLina previlage

  const isTimeLinePrevilage = (subMenuName) => {
    if (loginFindSuccess?.fk_role_id == "admin") {
      return true;
    }
    // Check if privilege is not null and role_privilages array exists
    if (privilege && privilege.role_sub_menus) {
      // Check if any role in role_privilages has the specified subMenuName
      return privilege.role_sub_menus.some((role) =>
        role?.side_menu?.sub_menus?.some(
          (subMenu) =>
            subMenu.name == subMenuName &&
            subMenu?.role_privilages?.some(
              (permission) =>
                permission?.permission?.name == "timeLine" &&
                permission.roleId == privilege.id
            )
        )
      );
    }
    return false;
  };

  // For Restart Previlage

  // For timeLina previlage

  const isRestartPrevilage = (subMenuName) => {
    if (loginFindSuccess?.fk_role_id == "admin") {
      return true;
    }
    // Check if privilege is not null and role_privilages array exists
    if (privilege && privilege.role_sub_menus) {
      // Check if any role in role_privilages has the specified subMenuName
      return privilege.role_sub_menus.some((role) =>
        role?.side_menu?.sub_menus?.some(
          (subMenu) =>
            subMenu.name == subMenuName &&
            subMenu?.role_privilages?.some(
              (permission) =>
                permission?.permission?.name == "restart" &&
                permission.roleId == privilege.id
            )
        )
      );
    }
    return false;
  };

  // For create new partner previlage

  const isCreateNewPartnerPrevilage = (subMenuName) => {
    if (loginFindSuccess?.fk_role_id == "admin") {
      return true;
    }
    // Check if privilege is not null and role_privilages array exists
    if (privilege && privilege.role_sub_menus) {
      // Check if any role in role_privilages has the specified subMenuName
      return privilege.role_sub_menus.some((role) =>
        role?.side_menu?.sub_menus?.some(
          (subMenu) =>
            subMenu.name == subMenuName &&
            subMenu?.role_privilages?.some(
              (permission) =>
                permission?.permission?.name == "create new partner" &&
                permission.roleId == privilege.id
            )
        )
      );
    }
    return false;
  };

  // For changeLeader previlage

  const isChangeLeaderPrevilage = (subMenuName) => {
    if (loginFindSuccess?.fk_role_id == "admin") {
      return true;
    }
    // Check if privilege is not null and role_privilages array exists
    if (privilege && privilege.role_sub_menus) {
      // Check if any role in role_privilages has the specified subMenuName
      return privilege.role_sub_menus.some((role) =>
        role?.side_menu?.sub_menus?.some(
          (subMenu) =>
            subMenu.name == subMenuName &&
            subMenu?.role_privilages?.some(
              (permission) =>
                permission?.permission?.name == "change Leader" &&
                permission.roleId == privilege.id
            )
        )
      );
    }
    return false;
  };

  // For commisionCategoryChange previlage

  const isCommisionCategoryChangePrevilage = (subMenuName) => {
    if (loginFindSuccess?.fk_role_id == "admin") {
      return true;
    }
    // Check if privilege is not null and role_privilages array exists
    if (privilege && privilege.role_sub_menus) {
      // Check if any role in role_privilages has the specified subMenuName
      return privilege.role_sub_menus.some((role) =>
        role?.side_menu?.sub_menus?.some(
          (subMenu) =>
            subMenu.name == subMenuName &&
            subMenu?.role_privilages?.some(
              (permission) =>
                permission?.permission?.name == "commission categoryChange" &&
                permission.roleId == privilege.id
            )
        )
      );
    }
    return false;
  };

  // For applicationStatusChange previlage

  const isapplicationStatusChangePrevilage = (subMenuName) => {
    if (loginFindSuccess?.fk_role_id == "admin") {
      return true;
    }
    // Check if privilege is not null and role_privilages array exists
    if (privilege && privilege.role_sub_menus) {
      // Check if any role in role_privilages has the specified subMenuName
      return privilege.role_sub_menus.some((role) =>
        role?.side_menu?.sub_menus?.some(
          (subMenu) =>
            subMenu.name == subMenuName &&
            subMenu?.role_privilages?.some(
              (permission) =>
                permission?.permission?.name == "applicationStatusChange" &&
                permission.roleId == privilege.id
            )
        )
      );
    }
    return false;
  };

  // For rolePermission previlage

  const isrolePermissionChangePrevilage = (subMenuName) => {
    if (loginFindSuccess?.fk_role_id == "admin") {
      return true;
    }
    // Check if privilege is not null and role_privilages array exists
    if (privilege && privilege.role_sub_menus) {
      // Check if any role in role_privilages has the specified subMenuName
      return privilege.role_sub_menus.some((role) =>
        role?.side_menu?.sub_menus?.some(
          (subMenu) =>
            subMenu.name == subMenuName &&
            subMenu?.role_privilages?.some(
              (permission) =>
                permission?.permission?.name == "role permission" &&
                permission.roleId == privilege.id
            )
        )
      );
    }
    return false;
  };

  // For statusChange previlage

  const isStatusChangePrevilage = (subMenuName) => {
    if (loginFindSuccess?.fk_role_id == "admin") {
      return true;
    }
    // Check if privilege is not null and role_privilages array exists
    if (privilege && privilege.role_sub_menus) {
      // Check if any role in role_privilages has the specified subMenuName
      return privilege.role_sub_menus.some((role) =>
        role?.side_menu?.sub_menus?.some(
          (subMenu) =>
            subMenu.name == subMenuName &&
            subMenu?.role_privilages?.some(
              (permission) =>
                permission?.permission?.name == "statusChange" &&
                permission.roleId == privilege.id
            )
        )
      );
    }
    return false;
  };

  // For conevert to lead previlage

  const isConvertleadPrevilage = (subMenuName) => {
    if (loginFindSuccess?.fk_role_id == "admin") {
      return true;
    }
    // Check if privilege is not null and role_privilages array exists
    if (privilege && privilege.role_sub_menus) {
      // Check if any role in role_privilages has the specified subMenuName
      return privilege.role_sub_menus.some((role) =>
        role?.side_menu?.sub_menus?.some(
          (subMenu) =>
            subMenu.name == subMenuName &&
            subMenu?.role_privilages?.some(
              (permission) =>
                permission?.permission?.name == "convert to lead" &&
                permission.roleId == privilege.id
            )
        )
      );
    }
    return false;
  };

  // For CredentialAction

  const isCredentialActionPrevilage = (subMenuName) => {
    if (loginFindSuccess?.fk_role_id == "admin") {
      return true;
    }
    // Check if privilege is not null and role_privilages array exists
    if (privilege && privilege.role_sub_menus) {
      // Check if any role in role_privilages has the specified subMenuName
      return privilege.role_sub_menus.some((role) =>
        role?.side_menu?.sub_menus?.some(
          (subMenu) =>
            subMenu.name == subMenuName &&
            subMenu?.role_privilages?.some(
              (permission) =>
                permission?.permission?.name == "CredentialAction" &&
                permission.roleId == privilege.id
            )
        )
      );
    }
    return false;
  };

  // For PaymentDetails Action

  const isPaymentDetailsPrevilage = (subMenuName) => {
    if (loginFindSuccess?.fk_role_id == "admin") {
      return true;
    }
    // Check if privilege is not null and role_privilages array exists
    if (privilege && privilege.role_sub_menus) {
      // Check if any role in role_privilages has the specified subMenuName
      return privilege.role_sub_menus.some((role) =>
        role?.side_menu?.sub_menus?.some(
          (subMenu) =>
            subMenu.name == subMenuName &&
            subMenu?.role_privilages?.some(
              (permission) =>
                permission?.permission?.name == "paymentDetails" &&
                permission.roleId == privilege.id
            )
        )
      );
    }
    return false;
  };

  // const getPermissionName = (subMenuName, status) => {
  //   if (privilege && privilege.role_privilages) {
  //     return privilege.role_privilages.some((role) =>
  //       role.role_sub_menu?.side_menu?.sub_menus?.some(
  //         (subMenu) =>
  //           subMenu.name === subMenuName && role?.permission?.name === status
  //       )
  //     );
  //   }
  //   return null; // Return null if privilege is null or role_privilages array doesn't exist
  // };

  // const getMenuNames = () => {
  //   const sideMenuName = privilege?.role_privilages.map(
  //     (role) => role?.role_sub_menu?.side_menu?.name
  //   );
  //   const subMenuNames = privilege?.role_privilages.flatMap((role) =>
  //     role?.role_sub_menu?.side_menu?.sub_menus?.map((subMenu) => subMenu.name)
  //   );
  //   return { sideMenuName, subMenuNames };
  // };

  return {
    privilege,
    hasPrivilege,
    isCreatePrevilage,
    isUpdatePrevilage,
    isDeletePrevilage,
    isVerifyPrevilage,
    isApprovePrevilage,
    isChangeOwnerPrevilage,
    isChangePriorityPrevilage,
    isChangeStatusPrevilage,
    isAssignCounsilorPrevilage,
    isFollowUpPrevilage,
    isTimeLinePrevilage,
    isLeadStatusChangePrevilage,
    isRestartPrevilage,
    isCreateNewPartnerPrevilage,
    isChangeLeaderPrevilage,
    isCommisionCategoryChangePrevilage,
    isapplicationStatusChangePrevilage,
    isrolePermissionChangePrevilage,
    isStatusChangePrevilage,
    isConvertleadPrevilage,
    isCredentialActionPrevilage,
    isPaymentDetailsPrevilage
  };
};

export default usePrivilegeCheck;
