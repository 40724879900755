import { Box, CircularProgress } from "@mui/material";
import React from "react";

function Loader() {
  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        backgroundColor: "rgba(255,255,255,0.95)",
        zIndex: 999999,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          width: {
            xs: "100px",
            md: "190px",
          },
          height: {
            xs: "100px",
            md: "190px",
          },
          margin: "auto",
          //   mt: "2px",
          borderRadius: "50%",
          position: "relative",
          top: "0",
          bottom: "0",
          "& img": {
            width: "100%",
            height: "100%",
            borderRadius: "50%",
          },
          "& svg": {
            animation: `rotate 3s linear infinite`,
            width: {
              xs: "120px",
              md: "auto",
            },
          },
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <img src="/LoaderImage/LoaderImage.svg" alt="" /> */}
          <CircularProgress color="inherit" />
        </Box>
        {/* <img src="/LoaderImage.svg" alt="" /> */}
      </Box>
    </Box>
  );
}

export default Loader;
