import React, { useEffect, useState } from "react";
import LeadsTable from "../Table/LeadsTable";
import LeadsFilterSearch from "./LeadsFilterSearch";

import { Alert, AlertTitle, Grid } from "@mui/material";
import LeadersSearchHeader from "./LeadersSearchHeader";
import {
  deleteLeadAction,
  leadFindAction
} from "../../actions/leadActions/leadActions";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import usePrivilegeCheck from "../../constants/customHooks/UsePrivilegeCheck";
import { findUserAction } from "../../actions/adminAuthAction";
import DownloadExcel from "../extraComponents/DownloadExcel";

function DealsMain(props) {
  const dispatch = useDispatch();
  const [filterPassData, setFilterPassData] = useState();
  const [whichFilter, setwhichFilter] = useState();
  const [reset, setReset] = useState(false);
  const [leadData, setLeadData] = useState([]);
  const [data, setData] = useState([]);
  const [paginationPageNumber, setPaginationPageNumber] = useState(1);
  const [pagiantionEntry, setPagiantionEntry] = useState(25);
  const [counsilorNote, setCounsilorNote] = useState({});
  const [teleCallerNote, setTeleCallerNote] = useState({});
  const [marketingNote, setMarketingNote] = useState({});


  const { privilege, hasPrivilege } = usePrivilegeCheck();

  let { leadFindLoading, leadFindSuccess } = useSelector((state) => {
    return state.leadFind;
  });

  let { leadAddSuccess } = useSelector((state) => {
    return state.leadAdd;
  });

  let { leadDeleteSuccess } = useSelector((state) => {
    return state.deleteLead;
  });

  let { leadOwnerSuccess } = useSelector((state) => {
    return state.leadOwnerChange;
  });

  let { loginFindSuccess } = useSelector((state) => {
    return state.findUser;
  });

  useEffect(() => {
    dispatch(findUserAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(leadFindAction());
  }, [dispatch, leadAddSuccess, leadDeleteSuccess, leadOwnerSuccess]);

  useEffect(() => {
    if (leadFindSuccess || reset) {
      setLeadData(leadFindSuccess);

      const latestTeleCallerNote = leadFindSuccess?.reduce(
        (latest, current) => {
          // Check if the current object has telecaller notes
          if (
            current.lead_telecaller_notes &&
            current.lead_telecaller_notes.length > 0
          ) {
            // Find the latest note in the current object's telecaller notes
            const latestInCurrent = current.lead_telecaller_notes.reduce(
              (latestNote, currentNote) =>
                new Date(currentNote.createdAt) > new Date(latestNote.createdAt)
                  ? currentNote
                  : latestNote,
              current.lead_telecaller_notes[0]
            );

            // Compare with the overall latest note
            return new Date(latestInCurrent.createdAt) >
              new Date(latest.createdAt)
              ? latestInCurrent
              : latest;
          }

          return latest;
        },
        { createdAt: "1970-01-01T00:00:00.000Z" }
      ); // Start with a very old date

      const latestCounsilorNote = leadFindSuccess?.reduce(
        (latest, current) => {
          // Check if the current object has telecaller notes
          if (
            current.lead_counsilor_notes &&
            current.lead_counsilor_notes.length > 0
          ) {
            // Find the latest note in the current object's telecaller notes
            const latestInCurrent = current.lead_counsilor_notes.reduce(
              (latestNote, currentNote) =>
                new Date(currentNote.createdAt) > new Date(latestNote.createdAt)
                  ? currentNote
                  : latestNote,
              current.lead_counsilor_notes[0]
            );

            // Compare with the overall latest note
            return new Date(latestInCurrent.createdAt) >
              new Date(latest.createdAt)
              ? latestInCurrent
              : latest;
          }

          return latest;
        },
        { createdAt: "1970-01-01T00:00:00.000Z" }
      ); // Start with a very old date

      const latestMarketingNote = leadFindSuccess?.reduce(
        (latest, current) => {
          // Check if the current object has telecaller notes
          if (
            current.lead_marketing_notes &&
            current.lead_marketing_notes.length > 0
          ) {
            // Find the latest note in the current object's telecaller notes
            const latestInCurrent = current.lead_marketing_notes.reduce(
              (latestNote, currentNote) =>
                new Date(currentNote.createdAt) > new Date(latestNote.createdAt)
                  ? currentNote
                  : latestNote,
              current.lead_marketing_notes[0]
            );

            // Compare with the overall latest note
            return new Date(latestInCurrent.createdAt) >
              new Date(latest.createdAt)
              ? latestInCurrent
              : latest;
          }

          return latest;
        },
        { createdAt: "1970-01-01T00:00:00.000Z" }
      ); // Start with a very old date
      setCounsilorNote(latestCounsilorNote);
      setTeleCallerNote(latestTeleCallerNote);
      setMarketingNote(latestMarketingNote)

    }
  }, [leadFindSuccess, reset]);

  useEffect(() => {
    setData(
      leadData
        ?.map((lead, index) => {
          if (
            lead.status == "Application Started" ||
            lead.status == "Documents is waiting" ||
            lead.status == "SOP in Progress" ||
            lead.status == "Application in progress" ||
            lead.status == "Application Completed"
          ) {
            return {
              id: lead.id,
              sl: index + 1,
              name: lead?.firstName,
              email: lead.email,
              phone: lead.mobileNumber,
              whatsappNumber: lead.whatsappNumber,
              passportNumber: lead.lead_personal_details?.[0]?.passportNumber,
              tags: lead?.DuplicateLead,
              preferredCountry: lead.preferredCountryId,
              leadSource: lead.leadSource,
              leadOwner:
                loginFindSuccess?.role?.roleName !== "branch manager"
                  ? lead.leadOwnerName
                    ? lead.leadOwnerName
                    : "-"
                  : null,
              status: lead.status,
              priority: lead.priority ? lead.priority : "-",
              leadCity: lead.location,
              branchId: lead.branchId,
              leadBranch: lead?.branch_master?.branchName
                ? lead?.branch_master?.branchName
                : "-",
              applicationStatus: lead?.lead_applied_short_lists,
              leadCounsilor:
                loginFindSuccess?.role?.roleName !== "counsellor"
                  ? lead?.counsilorID
                    ? lead?.counsilorID
                    : "-"
                  : null,
              CounsilorNote:
                counsilorNote?.leadId == lead?.id
                  ? counsilorNote?.counsilorNote
                  : "-",
              leadTeleCaller:
                loginFindSuccess?.role?.roleName !== "tele caller" ||
                loginFindSuccess?.isTeleCallerTeamLead == true ||
                loginFindSuccess?.isTelecallerManager == true
                  ? lead?.teleCallerId
                    ? lead?.teleCallerId
                    : "-"
                  : null,
              TeleCallerNote:
                teleCallerNote?.leadId == lead?.id
                  ? teleCallerNote?.teleCallerNote
                  : "-",

               marketingNote:marketingNote?.leadId == lead?.id?marketingNote?.marketingNote:"-"
              
            };
          }
          return null;
        })
        .filter(Boolean)
    );
  }, [leadData]);

  const searchHandleChange = (e) => {
    if (e.target.value !== "" && e.target.value !== " ") {
      setData(
        data?.filter((item) => {
          const name = item.name ? item.name.toLowerCase() : "";
          const email = item.email ? item.email.toLowerCase() : "";
          const phone = item.phone ? item.phone.toLowerCase() : "";
          const whatsappNumber = item.whatsappNumber
            ? item.whatsappNumber.toLowerCase()
            : "";
          const passportNumber = item.passportNumber
            ? item.passportNumber.toLowerCase()
            : "";
          const preferredCountry = item.preferredCountry
            ? item.preferredCountry.toLowerCase()
            : "";
          const leadSource = item.leadSource
            ? item.leadSource.toLowerCase()
            : "";
          const leadOwner = item.leadOwner ? item.leadOwner.toLowerCase() : "";
          const status = item.status ? item.status.toLowerCase() : "";
          const education = item.education ? item.education.toLowerCase() : "";

          const leadCity = item.leadCity ? item.leadCity.toLowerCase() : "";
          const leadBranch = item.branchMaster
            ? item.leadCity.toLowerCase()
            : "";

          // :lead?.branch_master?.branchName
          return (
            name.includes(e.target.value.toLowerCase()) ||
            phone.includes(e.target.value.toLowerCase().startsWith("+91") ? e.target.value.slice(3) : e.target.value) ||
            whatsappNumber.includes(e.target.value.toLowerCase()) ||
            passportNumber.includes(e.target.value.toLowerCase()) ||
            email.includes(e.target.value.toLowerCase()) ||
            preferredCountry.includes(e.target.value.toLowerCase()) ||
            leadSource.includes(e.target.value.toLowerCase()) ||
            leadOwner.includes(e.target.value.toLowerCase()) ||
            status.includes(e.target.value.toLowerCase()) ||
            education.includes(e.target.value.toLowerCase()) ||
            leadCity.includes(e.target.value.toLowerCase()) ||
            leadBranch.includes(e.target.value.toLowerCase())
          );
        })
      );
    } else {
      setData(
        leadData.map((lead, index) => ({
          id: lead.id,
          sl: index + 1,
          name: lead?.firstName,
          email: lead.email,
          phone: lead.mobileNumber,
          whatsappNumber: lead.whatsappNumber,
          passportNumber: lead.lead_personal_details?.[0]?.passportNumber,
          tags: lead?.DuplicateLead,
          preferredCountry: lead.preferredCountryId,
          leadSource: lead.leadSource,
          leadOwner:
            loginFindSuccess?.role?.roleName !== "branch manager"
              ? lead.leadOwnerName
                ? lead.leadOwnerName
                : "-"
              : null,
          status: lead.status,
          priority: lead.priority ? lead.priority : "-",
          leadCity: lead.location,
          branchId: lead.branchId,
          leadBranch: lead?.branch_master?.branchName
            ? lead?.branch_master?.branchName
            : "-",
          applicationStatus: lead?.lead_applied_short_lists,
          leadCounsilor:
            loginFindSuccess?.role?.roleName !== "counsellor"
              ? lead?.counsilorID
                ? lead?.counsilorID
                : "-"
              : null,
          CounsilorNote:
            counsilorNote?.leadId == lead?.id
              ? counsilorNote?.counsilorNote
              : "-",
          leadTeleCaller:
            loginFindSuccess?.role?.roleName !== "tele caller" ||
            loginFindSuccess?.isTeleCallerTeamLead == true ||
            loginFindSuccess?.isTelecallerManager == true
              ? lead?.teleCallerId
                ? lead?.teleCallerId
                : "-"
              : null,
          TeleCallerNote:
            teleCallerNote?.leadId == lead?.id
              ? teleCallerNote?.teleCallerNote
              : "-",

           marketingNote:marketingNote?.leadId == lead?.id?marketingNote?.marketingNote:"-"
        }))
      );
    }
  };

  // Employee column Visibility
  const [showNameColumn, setShowNameColumn] = useState(false);
  const [showEmailColumn, setShowEmailColumn] = useState(false);
  const [showPrefferedColumn, setShowPrefferedColumn] = useState(false);
  const [showPhoneColumn, setShowPhoneColumn] = useState(false);
  const [showWhatsAppNumColumn, setShowWhatsAppNumColumn] = useState(false);
  const [showLeadSourceColumn, setShowLeadSourceColumn] = useState(false);
  const [showLeadOwnerColumn, setShowLeadOwnerColumn] = useState(false);
  const [showLeadBranchColumn, setShowLeadBranchColumnn] = useState(false);
  const [showStatusColumn, setShowStatusColumn] = useState(false);
  const [showPriorityColumn, setShowPriorityColumn] = useState(false);
  const [showLeadPlaceColumn, setShowLeadPlaceColumn] = useState(false);
  const [showTelecallerColumn, setShowTelecallerColumn] = useState(false);
  const [showCounsilorColumn, setShowCounsilorColumn] = useState(false);
  const [columnName, setColumnName] = useState("");
  const [leadId, setLeadId] = useState([]);
  const [ids, setIds] = useState([]);

  // material Table
  const columns = [
    {
      title: "Sl no",
      field: "sl"
    },
    {
      title: "Name",
      field: "name",
      hidden: showNameColumn
    },
    {
      title: "Email",
      field: "email",
      hidden: showEmailColumn
    },
    {
      title: "Phone     ",
      field: "phone",
      hidden: showPhoneColumn
    },

    {
      title: "What's App Number",
      field: "whatsappNumber",
      hidden: showWhatsAppNumColumn
    },
    {
      title: "Passport number",
      field: "passportNumber",
      hidden: showWhatsAppNumColumn
    },
    {
      title: "Tags",
      field: "Tags",
      hidden: showLeadBranchColumn
    },
    {
      title: "Preferred Country",
      field: "preferredCountry",
      hidden: showPrefferedColumn
    },
    {
      title: "Lead Source",
      field: "leadSource",
      hidden: showLeadSourceColumn
    },
    {
      title:
        loginFindSuccess?.role?.roleName !== "branch manager"
          ? "Lead Owner"
          : null,

      field: "leadOwner",
      hidden: showLeadOwnerColumn
    },
    {
      title: "Lead Branch",
      field: "leadBranch",
      hidden: showLeadBranchColumn
    },
    {
      title: "Status",
      field: "status",
      hidden: showStatusColumn
    },
    {
      title: "Priority",
      field: "priority",
      hidden: showPriorityColumn
    },
    {
      title: "Lead Place",
      field: "leadCity",
      hidden: showLeadPlaceColumn
    },
    {
      title:
        loginFindSuccess?.role == null ||
        loginFindSuccess?.isManager ||
        loginFindSuccess?.role?.roleName !== "tele caller" ||
        loginFindSuccess?.isTeleCallerTeamLead == true ||
        loginFindSuccess?.isTelecallerManager == true
          ? "TeleCaller"
          : null,
      field: "TeleCaller",
      hidden: showTelecallerColumn
    },
    {
      title: "Telecaller note",
      field: "telecallerNote",
      hidden: showLeadPlaceColumn
    },
    {
      title:
        loginFindSuccess?.role == null ||
        loginFindSuccess?.isManager ||
        loginFindSuccess?.role?.roleName !== "counsellor"
          ? "Counsilor"
          : null,
      field: "Counsilor",
      hidden: showCounsilorColumn
    },
    {
      title: "Counsilor note",
      field: "counsilorNote",
      filtering: false
    },
    {
      title: "Marketing note",
      field: "marketingNote",
      filtering: false
    },
    {
      title: "",
      field: "action",
      filtering: false
    }
  ];

  const excelDownloadClick = () => {
    DownloadExcel(columns, data, "Lead_Managment");
  };

  const toggleNameColumn = (status, column) => {
    if (column === "name") {
      setColumnName(column);
      setShowNameColumn(status);
    }

    if (column === "email") {
      setColumnName(column);
      setShowEmailColumn(status);
    }

    if (column === "mobile") {
      setColumnName(column);
      setShowPhoneColumn(status);
    }

    if (column === "whatappnumber") {
      setColumnName(column);
      setShowWhatsAppNumColumn(status);
    }

    if (column === "prefferedCountry") {
      setColumnName(column);
      setShowPrefferedColumn(status);
    }
    if (column === "leadSource") {
      setColumnName(column);
      setShowLeadSourceColumn(status);
    }

    if (column === "leadowner") {
      setColumnName(column);
      setShowLeadOwnerColumn(status);
    }

    if (column === "leadbranch") {
      setColumnName(column);
      setShowLeadBranchColumnn(status);
    }

    if (column === "status") {
      setColumnName(column);
      setShowStatusColumn(status);
    }

    if (column === "priority") {
      setColumnName(column);
      setShowPriorityColumn(status);
    }

    if (column === "place") {
      setColumnName(column);
      setShowLeadPlaceColumn(status);
    }

    if (column === "telecaller") {
      setColumnName(column);
      setShowTelecallerColumn(status);
    }

    if (column === "counsilor") {
      setColumnName(column);
      setShowCounsilorColumn(status);
    }
  };

  useEffect(() => {
    // Retrieve column visibility state from localStorage
    const storedRollNameColumn = localStorage.getItem("Dealname");
    const storedRollEmailColumn = localStorage.getItem("Dealemail");
    const storedPhoneColumn = localStorage.getItem("Dealmobile");
    const storedWhatsAppColumn = localStorage.getItem("Dealwhatappnumber");
    const storedPrefferedCountryColumn = localStorage.getItem(
      "DealprefferedCountry"
    );
    const storedLeadSourceColumn = localStorage.getItem("DealleadSource");
    const storedLeadOwnerColumn = localStorage.getItem("DealleadOwner");
    const storedLeadBranchColumn = localStorage.getItem("Dealleadbranch");
    const storedStatusColumn = localStorage.getItem("Dealstatus");
    const storedPriorityColumn = localStorage.getItem("Dealpriority");
    const storedPlaceColumn = localStorage.getItem("Dealplace");
    const storedTeleCallerColumn = localStorage.getItem("Dealtelecaller");
    const storedCounsilorColumn = localStorage.getItem("Dealcounsilor");

    if (storedRollNameColumn !== null) {
      setColumnName("name");
      setShowNameColumn(JSON.parse(storedRollNameColumn));
    }

    if (storedRollEmailColumn !== null) {
      setColumnName("email");
      setShowEmailColumn(JSON.parse(storedRollEmailColumn));
    }

    // if (storedDescriptionNameColumn !== null) {
    //   setColumnName("email");
    //   setShowPrefferedColumn(JSON.parse(storedDescriptionNameColumn));
    // }
    if (storedPhoneColumn !== null) {
      setColumnName("mobile");
      setShowPhoneColumn(JSON.parse(storedPhoneColumn));
    }

    if (storedWhatsAppColumn !== null) {
      setColumnName("whatsAppNumber");
      setShowWhatsAppNumColumn(JSON.parse(storedWhatsAppColumn));
    }

    if (storedPrefferedCountryColumn !== null) {
      setColumnName("prefferedCountry");
      setShowPrefferedColumn(JSON.parse(storedPrefferedCountryColumn));
    }
    if (storedLeadSourceColumn !== null) {
      setColumnName("leadSource");
      setShowLeadSourceColumn(JSON.parse(storedLeadSourceColumn));
    }

    if (storedLeadOwnerColumn !== null) {
      setColumnName("leadOwner");
      setShowLeadOwnerColumn(JSON.parse(storedLeadOwnerColumn));
    }
    if (storedLeadBranchColumn !== null) {
      setColumnName("leadbranch");
      setShowLeadBranchColumnn(JSON.parse(storedLeadBranchColumn));
    }
    if (storedStatusColumn !== null) {
      setColumnName("status");
      setShowStatusColumn(JSON.parse(storedStatusColumn));
    }

    if (storedPriorityColumn !== null) {
      setColumnName("priority");
      setShowPriorityColumn(JSON.parse(storedPriorityColumn));
    }

    if (storedPlaceColumn !== null) {
      setColumnName("place");
      setShowLeadPlaceColumn(JSON.parse(storedPlaceColumn));
    }

    if (storedTeleCallerColumn !== null) {
      setColumnName("telecaller");
      setShowTelecallerColumn(JSON.parse(storedTeleCallerColumn));
    }

    if (storedCounsilorColumn !== null) {
      setColumnName("counsilor");
      setShowCounsilorColumn(JSON.parse(storedCounsilorColumn));
    }
  }, []);

  useEffect(() => {
    // Save column visibility state to localStorage
    localStorage.setItem("Dealname", JSON.stringify(showNameColumn));
    localStorage.setItem("Dealemail", JSON.stringify(showEmailColumn));
    localStorage.setItem("Dealemail", JSON.stringify(showPrefferedColumn));
    localStorage.setItem("Dealmobile", JSON.stringify(showPhoneColumn));
    localStorage.setItem(
      "Dealwhatappnumber",
      JSON.stringify(showWhatsAppNumColumn)
    );
    localStorage.setItem(
      "DealprefferedCountry",
      JSON.stringify(showPrefferedColumn)
    );
    localStorage.setItem(
      "DealleadSource",
      JSON.stringify(showLeadSourceColumn)
    );
    localStorage.setItem("DealleadOwner", JSON.stringify(showLeadOwnerColumn));
    localStorage.setItem(
      "Dealleadbranch",
      JSON.stringify(showLeadBranchColumn)
    );
    localStorage.setItem("Dealstatus", JSON.stringify(showStatusColumn));
    localStorage.setItem("Dealpriority", JSON.stringify(showPriorityColumn));
    localStorage.setItem("Dealplace", JSON.stringify(showLeadPlaceColumn));
    localStorage.setItem(
      "Dealtelecaller",
      JSON.stringify(showTelecallerColumn)
    );
    localStorage.setItem("Dealcounsilor", JSON.stringify(showCounsilorColumn));
  }, [
    showNameColumn,
    showEmailColumn,
    showPhoneColumn,
    showWhatsAppNumColumn,
    showPrefferedColumn,
    showLeadSourceColumn,
    showLeadOwnerColumn,
    showLeadBranchColumn,
    showStatusColumn,
    showPriorityColumn,
    showLeadPlaceColumn,
    showTelecallerColumn,
    showCounsilorColumn,
    columnName
  ]);
  const LeadFilterFunction = (data, filterOption, checkedStatus) => {
    if (filterOption === "city") {
      console.log(data, "dataaaaaaaaaaaaaaaa");
      setLeadData(
        leadData?.filter((item) => {
          console.log(item, "itemitemitemitemitem");
          return (
            item.location !== null &&
            item.location.toLowerCase().includes(data.toLowerCase())
          );
        })
      );

      setwhichFilter(filterOption);
      setFilterPassData(data);
    }
    if (filterOption === "preffered country") {
      if (checkedStatus === true) {
        setLeadData(
          leadData?.filter((item) => {
            return (
              item.preferredCountryId !== null &&
              item.preferredCountryId.toLowerCase().includes(data.toLowerCase())
              // data.includes(item.leadSource)
            );
          })
        );
        setwhichFilter(filterOption);
        setFilterPassData(data);
      } else {
        setwhichFilter(null);
        setFilterPassData(null);
        setLeadData(leadFindSuccess);
      }
    }

    if (filterOption === "Role Name") {
      if (checkedStatus === true) {
        setLeadData(
          leadData?.filter((item) => {
            return (
              item.name !== null &&
              item.name.toLowerCase().includes(data.toLowerCase())
            );
          })
        );
        setwhichFilter(filterOption);
        setFilterPassData(data);
      } else {
        setwhichFilter(null);
        setFilterPassData(null);
        setLeadData(leadFindSuccess);
      }
    }

    if (filterOption === "leadStatus") {
      console.log(data, "datadatadatadatadatadata");
      if (checkedStatus == true) {
        setLeadData(
          leadData?.filter((item) => {
            return (
              item.status !== null &&
              item.status.toLowerCase().includes(data.toLowerCase())
              // data.includes(item.status)
            );
          })
        );
        setwhichFilter(filterOption);
        setFilterPassData(checkedStatus);
      } else {
        setwhichFilter(null);
        setFilterPassData(null);
        setLeadData(leadFindSuccess);
      }
    }

    if (filterOption === "leadSource") {
      if (checkedStatus === true) {
        setLeadData(
          leadData?.filter((item) => {
            return (
              item.leadSource !== null &&
              item.leadSource.toLowerCase().includes(data.toLowerCase())
            );
          })
        );
        setwhichFilter(filterOption);
        setFilterPassData(data);
      } else {
        setwhichFilter(null);
        setFilterPassData(null);
        setLeadData(leadFindSuccess);
      }
    }

    if (filterOption === "leadBranch") {
      if (checkedStatus === true) {
        console.log(data, "evalueevalueevalue");
        setLeadData(
          leadData?.filter((item) => {
            return (
              item?.branch_master?.branchName !== null &&
              item.branch_master?.branchName
                .toLowerCase()
                .includes(data.toLowerCase())
            );
          })
        );
        setwhichFilter(filterOption);
        setFilterPassData(data);
      } else {
        setwhichFilter(null);
        setFilterPassData(null);
        setLeadData(leadFindSuccess);
      }
    }

    if (filterOption === "paymentStatus") {
      if (checkedStatus === true) {
        console.log(data, "evalueevalueevalue");
        setLeadData(
          leadData?.filter((item) => {
            return item?.lead_applied_short_lists?.some(
              (status) =>
                status.paymentStatus?.toLowerCase() === data?.toLowerCase()
            );
          })
        );
        setwhichFilter(filterOption);
        setFilterPassData(data);
      } else {
        setwhichFilter(null);
        setFilterPassData(null);
        setLeadData(leadFindSuccess);
      }
    }

    if (filterOption === "refund") {
      if (checkedStatus === true) {
        console.log(data, "evalueevalueevalue");
        setLeadData(
          leadData?.filter((item) => {
            return item?.lead_applied_short_lists?.some(
              (status) =>
                status.methodStatus?.toLowerCase() === data?.toLowerCase()
            );
          })
        );
        setwhichFilter(filterOption);
        setFilterPassData(data);
      } else {
        setwhichFilter(null);
        setFilterPassData(null);
        setLeadData(leadFindSuccess);
      }
    }
  };
  const handleResetData = (status) => {
    setwhichFilter(null);
    setFilterPassData(null);
    setReset(!reset);
    setwhichFilter(null);
    setFilterPassData(null);
    setPaginationPageNumber(1);
  };

  const handlePaginationEntry = (e) => {
    let value = e.target.value;
    if (value <= 100) {
      setPagiantionEntry(value);
    }
  };

  const setIdsFunction = (leadsId) => {
    setLeadId(leadsId);
  };
  const handleDeleteClick = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteLeadAction(leadId));
      }
    });
  };

  const selectBoxIdPassHandler = (ids) => {
    setIds(ids);
  };

  if (!hasPrivilege("Deals") && privilege !== null) {
    return (
      <Alert severity="error">
        <AlertTitle>warning</AlertTitle>
        You don't have permission to access — <strong>this page.!</strong>
      </Alert>
    );
  }

  console.log(
    hasPrivilege,
    "hasPrivilegehasPrivilegel",
    privilege,
    "privilegeprivilege"
  );

  console.log(leadData, "leadDataleadData");

  return (
    <>
      <LeadersSearchHeader
        columnVisibleHandler={toggleNameColumn}
        excelDownloadClick={excelDownloadClick}
        showNameColumn={showNameColumn}
        showEmailColumn={showEmailColumn}
        showPrefferedColumn={showPrefferedColumn}
        showPhoneColumn={showPhoneColumn}
        showWhatAppNumberColumn={showWhatsAppNumColumn}
        showLeadSourceColumn={showLeadSourceColumn}
        showLeadOwnerColumn={showLeadOwnerColumn}
        showLeadBranchColumn={showLeadBranchColumn}
        showStatusColumn={showStatusColumn}
        showPriorityColumn={showPriorityColumn}
        showLeadPlaceColumn={showLeadPlaceColumn}
        showTelecallerColumn={showTelecallerColumn}
        showCounsilorColumn={showCounsilorColumn}
        filterPassData={filterPassData ? filterPassData : null}
        whichFilter={whichFilter ? whichFilter : null}
        handleResetData={handleResetData}
        handleDeleteClick={handleDeleteClick}
        searchHandleChange={searchHandleChange}
        ids={ids}
        component="deals"
        leadId={leadId}
        subMenu="Deals"
      />
      {/* <div style={{display:"flex",justifyContent:"space-evenly", width:"auto"}}> */}
      <Grid container spacing={-3} sx={{ mt: "-31px" }}>
        <Grid item xs={12} md={2} lg={2.2}>
          <LeadsFilterSearch
            LeadFilterFunction={LeadFilterFunction}
            handleResetData={handleResetData}
            statusComponent="lead"
            data={leadFindSuccess}
            pagiantionEntry={pagiantionEntry}
            handlePaginationEntry={handlePaginationEntry}
            component="deals"
          />
        </Grid>
        <Grid item xs={12} md={10} lg={9.8}>
          <LeadsTable
            toggle={props.toggle}
            showNameColumn={showNameColumn}
            showEmailColumn={showEmailColumn}
            showPrefferedColumn={showPrefferedColumn}
            showPhoneColumn={showPhoneColumn}
            showWhatAppNumberColumn={showWhatsAppNumColumn}
            showLeadSourceColumn={showLeadSourceColumn}
            showLeadOwnerColumn={showLeadOwnerColumn}
            showLeadBranchColumn={showLeadBranchColumn}
            showStatusColumn={showStatusColumn}
            showPriorityColumn={showPriorityColumn}
            showLeadPlaceColumn={showLeadPlaceColumn}
            showTelecallerColumn={showTelecallerColumn}
            showCounsilorColumn={showCounsilorColumn}
            reset={reset}
            setIdsFunction={setIdsFunction}
            data={data}
            columns={columns}
            leadFindLoading={leadFindLoading}
            selectBoxIdPassHandler={selectBoxIdPassHandler}
            isExistCounsilor={loginFindSuccess}
            component="deals"
            pagiantionEntry={pagiantionEntry}
            paginationPageNumber={paginationPageNumber}
            subMenu="Deals"
            mainMenu="Deals"
          />
        </Grid>
      </Grid>
    </>
  );
}

export default DealsMain;
