import {
  Button,
  CircularProgress,
  FormControlLabel,
  Switch,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

export const AddButton = ({ title }) => {
  const navigate = useNavigate();
  return (
    <Button
      style={{
        color: "white",
        height: "35px",
        width: "150px",
        backgroundColor: "#141E3C",
        cursor: "pointer",
        zIndex: 1,
        marginBottom: "2px",
        position: "relative",
        fontWeight: 700,
        fontSize: "12px",
        borderRadius: "8px",
        boxShadow: 0,
      }}
      onClick={() => navigate("/university/addUniversity")}
    >
      {title}
    </Button>
  );
};

export const DoubleBoutton = ({ buttonName1, buttonName2, handleBack }) => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        margin: "10px",
      }}
    >
      {buttonName1 && (
        <Button
          color="inherit"
          onClick={handleBack}
          sx={{
            mr: 1,
            mt: 1,
            borderColor: "#141E3C",
            width: "100px",
            height: "45px",
            color: "black",
            fontWeight: 700,
            fontSize: "13px",
          }}
          variant="outlined"
        >
          {buttonName1}
        </Button>
      )}

      {buttonName2 && (
        <Button
          type="submit"
          sx={{
            mt: 1,
            backgroundColor: "#141E3C",
            width: "100px",
            height: "45px",
            color: "white",
            fontWeight: 700,
            fontSize: "13px",
          }}
        >
          {buttonName2}
        </Button>
      )}
    </div>
  );
};

export const OutLinedButton = ({
  title,
  handleClick,
  widthSize,
  heightSize,
}) => {
  return (
    <Button
      style={{
        border: "2px solid #141E3C",
        color: "black",
        height: heightSize,
        width: widthSize,
        cursor: "pointer",
        zIndex: 1,
        marginBottom: "2px",
        position: "relative",
        fontWeight: 600,
        fontSize: "12px",
        borderRadius: "8px",
        boxShadow: 0,
      }}
      variant="outlined"
      onClick={handleClick}
    >
      {title}
    </Button>
  );
};

export const SubmitButton = ({
  title,
  submit,
  widthSize,
  heightSize,
  type,
  handleSubmit,
}) => {
  return (
    <Button
      type={submit}
      onClick={type === "click" ? handleSubmit : null}
      sx={{
        width: widthSize,
        height: heightSize,
        color: "white",
        backgroundColor: "white",
        borderRadius: "8px",
        fontWeight: 600,
        fontSize: "12px",
        color:"black",
        border:"2px solid #141E3C",
        "&:hover": {
          backgroundColor: "#141E3C",
          color:"#fff"
        },
      }}
    >
      {title}
    </Button>
  );
};

export const SwitchButton = ({ label, handleChange, checked, name, color }) => {
  return (
    <>
      <FormControlLabel
        sx={{
          "& .MuiSwitch-thumb": {
            backgroundColor: "#fff",
            border: "1px solid #141E3C",
            width: "19px",
            height: "19px",
          },
          "& .MuiButtonBase-root": {
            padding: "2px !important",
          },
          "& .MuiSwitch-root ": {
            padding: "0",
            height: "25px !important",
            width: "44px !important",
            paddingBottom: "2px",
          },
          "& .MuiSwitch-track": {
            border: "1px solid #141E3C",
            backgroundColor: "#fff !important",
            opacity: "1 !important",
            borderRadius: "12px",
            mb: "2px",
          },
          "& .Mui-checked .MuiSwitch-thumb": {
            backgroundColor: "#fff",
          },
          "& .Mui-checked ~ .MuiSwitch-track": {
            backgroundColor: color ? color : "#141E3C !important",
          },
          "&.MuiFormControlLabel-root": {
            width: "100px",
            justifyContent: "space-between",
            gap: "20px",
          },
        }}
        value={checked}
        control={
          <Switch
            color="primary"
            name={name ? name : ""}
            checked={checked}
            onChange={handleChange}
          />
        }
        label={label}
        labelPlacement="start"
      />
    </>
  );
};

export const UploadButton = ({
  width,
  height,
  mt,
  onClickFn,
  title,
  symbol,
  bgcolor,
  disabled,
  loading,
}) => {
  return (
    <>
      <Button
        disabled={disabled}
        sx={{
          color: "white",
          fontWeight: 700,
          fontFamily: "'Poppins', sans-serif",
          width: width,
          height: height,
          marginTop: mt ? mt : "25px",
          marginLeft: "10px",
          borderRadius: "15px",
          backgroundColor: bgcolor ? bgcolor : "#141E3C",
          fontSize: "11px",
          "&:hover": {
            backgroundColor: "rgba(20, 30, 60, 0.9)",
          },
          ":disabled": {
            backgroundColor: "gray",
            color: "white",
          },
        }}
        onClick={onClickFn}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <span style={{ fontSize: "15px" }}>{symbol}</span>
            {title}
          </>
        )}
      </Button>
    </>
  );
};
