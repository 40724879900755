import React, { useEffect, useState } from "react";
import { MdClear } from "react-icons/md";
import { AiOutlineSearch } from "react-icons/ai";

import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  ListItemIcon,
  TextField,
  Typography
} from "@mui/material";
import { RiSearch2Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { findcountryMasterAddAction } from "../../actions/countryMasterAction";
import { allRoleFindAction } from "../../actions/roleMangmentAction";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

function LeadsFilterSearch(props) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [secListopen, setSecListOpen] = useState(false);
  const [thirdListopen, setThirdListOpen] = useState(false);
  const [fourthListopen, setFourthListOpen] = useState(false);
  const [fifthListopen, setFifthListOpen] = useState(false);
  const [sixthListopen, setSixthListOpen] = useState(false);
  const [sevenListopen, setseventhListOpen] = useState(false);
  const [eightListopen, seteightListOpen] = useState(false);
  const [ninthListopen, setninthListOpen] = useState(false);

  const [cityFind, setCityFind] = useState("");
  const [roleList, setRoleList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [selectedItem, setSelectedItem] = useState("");
  const [source, setSource] = useState([]);
  const [branch, setBranch] = useState([]);
  const [status, setStatus] = useState([]);

  let { countryFindSuccess } = useSelector((state) => {
    return state.findcountryMasterAdd;
  });

  const { allRoleFindSuccess } = useSelector((state) => {
    return state.allRoleFind;
  });

  useEffect(() => {
    dispatch(findcountryMasterAddAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(allRoleFindAction());
  }, [dispatch]);

  useEffect(() => {
    if (countryFindSuccess) {
      setCountryList(countryFindSuccess);
    }
  }, [countryFindSuccess]);

  useEffect(() => {
    if (allRoleFindSuccess) {
      setRoleList(allRoleFindSuccess);
    }
  }, [allRoleFindSuccess]);

  const handleSubmit = (e, chipName) => {
    e.preventDefault();
    // dispatch(city(cityFind, id, "cityFind"));
    props.LeadFilterFunction(cityFind, chipName);
    setCityFind("");
    // Add your logic here to handle the form submission with the entered application fee
  };

  const handleMonthFilter = (data, chipName) => {
    props.LeadFilterFunction(data, chipName);
  };

  const handleYearFilter = (data, chipName) => {
    props.LeadFilterFunction(data, chipName);
  };

  const handleClick = () => {
    setOpen(!open);
  };
  const handleClick2 = () => {
    setSecListOpen(!secListopen);
  };
  const handleClick3 = () => {
    setThirdListOpen(!thirdListopen);
  };
  const handleClick4 = () => {
    setFourthListOpen(!fourthListopen);
  };
  const handleClick5 = () => {
    setFifthListOpen(!fifthListopen);
  };
  const handleClick6 = () => {
    setSixthListOpen(!sixthListopen);
  };
  const handleClick7 = () => {
    setseventhListOpen(!sevenListopen);
  };
  const handleClick8 = () => {
    seteightListOpen(!eightListopen);
  };

  const handleClick9 = () => {
    setninthListOpen(!ninthListopen);
  };

  const handleClearClick = () => {
    // setSelectedDate(e.target.value);
    setCityFind("");
    props.handleResetData(true);
    setSelectedItem("");
    setSource([]);
    setBranch([]);
    setStatus([]);
    setOpen(!open);
    setSecListOpen(false);
    setThirdListOpen(false);
    setFourthListOpen(false);
    setFifthListOpen(false);
    setSixthListOpen(false);
    setseventhListOpen(false);
    seteightListOpen(false);
    setninthListOpen(false);
  };
  console.log(countryList);
  const handleChange = (e) => {
    const searchText = e.target.value.toLowerCase();

    if (searchText === "") {
      setCountryList(countryFindSuccess); // Reset to the original data when search text is empty
    } else {
      setCountryList((chips) =>
        chips.filter((data) =>
          data.countryName.toLowerCase().includes(searchText)
        )
      );
    }
  };

  const handleCheckCountry = (event, branchId) => {
    props.LeadFilterFunction(
      branchId,
      "preffered country",
      event.target.checked
    );
  };

  // role search show
  const handleStatusFilter = (status) => {
    props.LeadFilterFunction(status, "Status");
  };

  // role search show
  const handleCheckRole = (event, branchId) => {
    props.LeadFilterFunction(event.target.checked, "Role Name", branchId);
  };

  // Role seraching
  const handleRole = (e) => {
    const searchText = e.target.value.toLowerCase();

    if (searchText === "") {
      setRoleList(allRoleFindSuccess); // Reset to the original data when search text is empty
    } else {
      setRoleList((chips) =>
        chips.filter((data) => data.roleName.toLowerCase().includes(searchText))
      );
    }
  };

  // Status :::::::::::::

  const uniqueStatuses = Array.from(
    new Set(props?.data?.map((item) => item.status))
  );

  const uniqueApplicationStatus = Array.from(
    new Set(props?.data?.map((item) => item.methodStatus))
  );

  // Source :::::::::::::::::

  const uniqueSource = Array.from(
    new Set(
      props?.data
        ?.map((item) => item.leadSource)
        .filter((source) => source !== "" && source !== null)
    )
  );

  // Branch ::::::::::::::::::::

  const uniqueBranch = Array.from(
    new Set(
      props?.data
        ?.map((item) => item?.branch_master?.branchName)
        .filter(
          (source) => source !== "" && source !== null && source !== undefined
        )
    )
  );

  // University

  const uniqueUniversity = Array.from(
    new Set(
      props?.data
        ?.map((item) => item?.universityName)
        .filter(
          (source) => source !== "" && source !== null && source !== undefined
        )
    )
  );

  const MapStatus = ["Active", "Inactive"];
  console.log(uniqueSource, "uniqueSourceuniqueSource");
  console.log(uniqueBranch, "uniqueBranchuniqueBranch");
  console.log(source, "sourcesourcesource");

  return (
    <>
      <List
        sx={{
          // width:"fit-content",
          maxWidth: "316px",
          // height: "620px",
          height: "auto",
          bgcolor: "background.paper",
          borderRadius: "8px",
          backgroundColor: "rgba(255, 255, 255, 0.5)",
          // overflow: "scroll",
          // overflowX: "hidden",
          border: "1px solid #ECECEC",
          mt: 2.5
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader
            component="div"
            id="nested-list-subheader"
            sx={{ backgroundColor: "#F2F2F2" }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>Filter</div>
              <div
                style={{
                  display: "flex",
                  marginTop: "12px",
                  cursor: "pointer"
                }}
                onClick={handleClearClick}
              >
                <MdClear style={{ fontSize: "22px", height: "20px" }} />{" "}
                <Typography>Clear All</Typography>
              </div>
            </div>
          </ListSubheader>
        }
      >
        {props.statusComponent === "lead" ||
        props.statusComponent === "leadDeferral" ? (
          <>
            {/* first List */}
            <ListItemButton onClick={handleClick}>
              <ListItemText
                primary="city"
                sx={{ textTransform: "capitalize" }}
              />
              {open ? (
                <ExpandLess sx={{ fontSize: "17px", fontWeight: "thin" }} />
              ) : (
                <ExpandMore sx={{ fontSize: "17px", fontWeight: "thin" }} />
              )}
            </ListItemButton>
            {/* collapse */}
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton sx={{ textAlign: "center" }}>
                  <form onSubmit={(e) => handleSubmit(e, "city")}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <TextField
                        variant="outlined"
                        id="city"
                        placeholder="Search Here"
                        value={cityFind}
                        onChange={(e) => setCityFind(e.target.value)}
                        sx={{
                          "& .MuiInputBase-input": {
                            borderRadius: "20px",
                            height: "1px"
                          },
                          "& .MuiFormLabel-root": {
                            lineHeight: "5px"
                          },

                          "& .MuiInputBase-root": {
                            borderRadius: "3px",
                            borderColor: "#fafafa"
                          }
                        }}
                      />
                      <>
                        <Box
                          component="button"
                          sx={{
                            width: "40px",
                            height: "34px",
                            textAlign: "center",
                            border: "1px solid #9d9fa1",
                            borderBottomRightRadius: "5px",
                            WebkitBorderTopRightRadius: "5px",
                            fontSize: "24px"
                          }}
                          type="submit"
                        >
                          <InputAdornment position="end">
                            <RiSearch2Line />
                          </InputAdornment>
                        </Box>
                      </>
                    </div>
                  </form>
                </ListItemButton>
              </List>
            </Collapse>
            {/* second List */}
            <ListItemButton onClick={handleClick2}>
              <ListItemText
                primary="prefferred country"
                sx={{ textTransform: "capitalize" }}
              />
              {secListopen ? (
                <ExpandLess sx={{ fontSize: "17px", fontWeight: "thin" }} />
              ) : (
                <ExpandMore sx={{ fontSize: "17px", fontWeight: "thin" }} />
              )}
            </ListItemButton>
            <Collapse in={secListopen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton>
                  <TextField
                    placeholder="Search Here"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <IconButton>
                            <AiOutlineSearch />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    onChange={handleChange}
                    sx={{
                      backgroundColor: "white",
                      margin: 1,
                      width: "auto",
                      "& .MuiInputBase-input": {
                        borderRadius: "20px",
                        height: "1px"
                      },
                      "& .MuiFormLabel-root": {
                        lineHeight: "5px"
                      },

                      "& .MuiInputBase-root": {
                        borderRadius: "3px",
                        borderColor: "#fafafa"
                      }
                    }}
                  />
                </ListItemButton>

                <ListItemButton>
                  <FormGroup>
                    {countryList &&
                      countryList?.map((country) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{
                                color: "#F8469A",
                                "&.Mui-checked": {
                                  color: "#F8469A"
                                }
                              }}
                              value={country.countryName.split("(")[0]}
                              onChange={(e) => {
                                handleCheckCountry(
                                  e,
                                  country.countryName.split("(")[0]
                                );
                              }}
                            />
                          }
                          label={country.countryName.split("(")[0]}
                        />
                      ))}
                    {/* <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            color: "#F8469A",
                            "&.Mui-checked": {
                              color: "#F8469A",
                            },
                          }}
                        />
                      }
                      label="View all..."
                    /> */}
                  </FormGroup>
                </ListItemButton>
              </List>
              {/* <ListItemButton>
                <Typography color="#F8469A" variant="p">
                  view all ...
                </Typography>
              </ListItemButton> */}
            </Collapse>
            {/* third List */}

            {props.statusComponent !== "leadDeferral" && (
              <>
                <ListItemButton onClick={handleClick3}>
                  <ListItemText
                    primary="status"
                    sx={{ textTransform: "capitalize" }}
                  />
                  {thirdListopen ? (
                    <ExpandLess sx={{ fontSize: "17px", fontWeight: "thin" }} />
                  ) : (
                    <ExpandMore sx={{ fontSize: "17px", fontWeight: "thin" }} />
                  )}
                </ListItemButton>
                <Collapse in={thirdListopen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton
                      style={{
                        textAlign: "center"
                      }}
                    >
                      <FormGroup>
                        {uniqueStatuses.map((item) => {
                          return (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  sx={{
                                    color: "#F8469A",
                                    "&.Mui-checked": {
                                      color: "#F8469A"
                                    }
                                  }}
                                  value={item}
                                  onChange={(e) => {
                                    // setStatus((prev) => {
                                    //   const value = e.target.value;
                                    //   const updatedSource = e.target.checked
                                    //     ? [...prev, value]
                                    //     : prev.filter((item) => item !== value);
                                    props.LeadFilterFunction(
                                      e.target.value,
                                      "leadStatus",
                                      e.target.checked
                                    );
                                    //   return updatedSource;
                                    // });
                                  }}
                                />
                              }
                              label={item?.[0]?.toUpperCase() + item?.slice(1)}
                            />
                          );
                        })}
                      </FormGroup>
                    </ListItemButton>
                  </List>
                </Collapse>
              </>
            )}

            {/* collapse */}
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton sx={{ textAlign: "center" }}>
                  <form onSubmit={(e) => handleSubmit(e, "city")}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <TextField
                        variant="outlined"
                        id="city"
                        placeholder="Search Here"
                        value={cityFind}
                        onChange={(e) => setCityFind(e.target.value)}
                        sx={{
                          "& .MuiInputBase-input": {
                            borderRadius: "20px",
                            height: "1px"
                          },
                          "& .MuiFormLabel-root": {
                            lineHeight: "5px"
                          },

                          "& .MuiInputBase-root": {
                            borderRadius: "3px",
                            borderColor: "#fafafa"
                          }
                        }}
                      />
                      <>
                        <Box
                          component="button"
                          sx={{
                            width: "40px",
                            height: "34px",
                            textAlign: "center",
                            border: "1px solid #9d9fa1",
                            borderBottomRightRadius: "5px",
                            WebkitBorderTopRightRadius: "5px",
                            fontSize: "24px"
                          }}
                          type="submit"
                        >
                          <InputAdornment position="end">
                            <RiSearch2Line />
                          </InputAdornment>
                        </Box>
                      </>
                    </div>
                  </form>
                </ListItemButton>
              </List>
            </Collapse>
            {/* second List */}

            {props.statusComponent == "leadDeferral" ? (
              <>
                <ListItemButton onClick={handleClick4}>
                  <ListItemText
                    primary="next intake month"
                    sx={{ textTransform: "capitalize" }}
                  />
                  {fourthListopen ? (
                    <ExpandLess sx={{ fontSize: "17px", fontWeight: "thin" }} />
                  ) : (
                    <ExpandMore sx={{ fontSize: "17px", fontWeight: "thin" }} />
                  )}
                </ListItemButton>
                <Collapse in={fourthListopen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton>
                      <Box sx={{ mt: 1 }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            views={["month"]}
                            label="Month"
                            // value={intakeMonth}
                            sx={{ width: 200 }}
                            onChange={(newValue) =>
                              handleMonthFilter(newValue, "month")
                            }
                            renderInput={(params) => (
                              <TextField {...params} fullWidth />
                            )}
                          />
                        </LocalizationProvider>
                        {/* <Typography>{error?.intakeMonth}</Typography> */}
                      </Box>
                    </ListItemButton>
                  </List>
                </Collapse>
                <ListItemButton onClick={handleClick8}>
                  <ListItemText
                    primary="next intake year"
                    sx={{ textTransform: "capitalize" }}
                  />
                  {eightListopen ? (
                    <ExpandLess sx={{ fontSize: "17px", fontWeight: "thin" }} />
                  ) : (
                    <ExpandMore sx={{ fontSize: "17px", fontWeight: "thin" }} />
                  )}
                </ListItemButton>
                <Collapse in={eightListopen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton>
                      <Box sx={{ mt: 3 }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            views={["year"]}
                            label="Year"
                            // value={intakeYear}
                            // minDate={new Date()} // Restricts to the current year and future years
                            sx={{ width: 200 }}
                            onChange={(newValue) =>
                              handleYearFilter(newValue, "year")
                            }
                            renderInput={(params) => (
                              <TextField {...params} fullWidth />
                            )}
                          />
                        </LocalizationProvider>
                      </Box>
                    </ListItemButton>
                  </List>
                </Collapse>
              </>
            ) : (
              ""
            )}
            {/* fourth List */}
            {/* <ListItemButton onClick={handleClick4}>
              <ListItemText
                primary="assigned"
                sx={{ textTransform: "capitalize" }}
              />
              {fourthListopen ? (
                <ExpandLess sx={{ fontSize: "17px", fontWeight: "thin" }} />
              ) : (
                <ExpandMore sx={{ fontSize: "17px", fontWeight: "thin" }} />
              )}
            </ListItemButton> */}
            {/* collapse */}
            {/* <Collapse in={fourthListopen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding> */}
            {/* <ListItemButton>
                  <ListItemIcon>
                    <StarBorder />
                  </ListItemIcon>
                  <ListItemText primary="Starred" />
                </ListItemButton> */}
            {/* </List>
            </Collapse> */}
            {/* fifth List */}
            <ListItemButton onClick={handleClick5}>
              <ListItemText
                primary="source"
                sx={{ textTransform: "capitalize" }}
              />
              {fifthListopen ? (
                <ExpandLess sx={{ fontSize: "17px", fontWeight: "thin" }} />
              ) : (
                <ExpandMore sx={{ fontSize: "17px", fontWeight: "thin" }} />
              )}
            </ListItemButton>
            {/* collapse */}
            <Collapse in={fifthListopen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton>
                  <FormGroup>
                    {uniqueSource?.map((item) => {
                      return (
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{
                                color: "#F8469A",
                                "&.Mui-checked": {
                                  color: "#F8469A"
                                }
                              }}
                              value={item}
                              onChange={(e) => {
                                // setSource((prev) => {
                                //   const value = e.target.value;
                                //   const updatedSource = e.target.checked
                                //     ? [...prev, value]
                                //     : prev.filter((item) => item !== value);
                                //   console.log(
                                //     updatedSource,
                                //     "updatedSourseeeeeeeeeeeeeee"
                                //   );
                                props.LeadFilterFunction(
                                  e.target.value,
                                  "leadSource",
                                  e.target.checked
                                );
                                //   return updatedSource;
                                // });
                              }}
                            />
                          }
                          label={item}
                        />
                      );
                    })}
                    {/* <ListItemIcon
                          sx={{
                            fontSize: "14px",
                            fontWeight: 400,
                            color: "#000000",
                          }}
                        >
                          {item}
                        </ListItemIcon> */}
                  </FormGroup>
                </ListItemButton>
              </List>
            </Collapse>
            {/* sixth List */}
            <ListItemButton onClick={handleClick6}>
              <ListItemText
                primary="branch"
                sx={{ textTransform: "capitalize" }}
              />
              {sixthListopen ? (
                <ExpandLess sx={{ fontSize: "17px", fontWeight: "thin" }} />
              ) : (
                <ExpandMore sx={{ fontSize: "17px", fontWeight: "thin" }} />
              )}
            </ListItemButton>
            {/* collapse */}
            <Collapse in={sixthListopen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton>
                  <FormGroup>
                    {uniqueBranch?.map((item) => {
                      return (
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{
                                color: "#F8469A",
                                "&.Mui-checked": {
                                  color: "#F8469A"
                                }
                              }}
                              value={item}
                              onChange={(e) => {
                                // setBranch((prev) => {
                                //   const value = e.target.value;
                                //   const updatedSource = e.target.checked
                                //     ? [...prev, value]
                                //     : prev.filter((item) => item !== value);

                                props.LeadFilterFunction(
                                  e.target.value,
                                  "leadBranch",
                                  e.target.checked
                                );
                                //   return updatedSource;
                                // });
                              }}
                            />
                          }
                          label={item}
                        />
                      );
                    })}
                    {/* <ListItemIcon
                          sx={{
                            fontSize: "14px",
                            fontWeight: 400,
                            color: "#000000",
                          }}
                        >
                          {item}
                        </ListItemIcon> */}
                  </FormGroup>
                </ListItemButton>
              </List>
            </Collapse>
            {/* sixth List */}
            <ListItemButton onClick={handleClick7}>
              <ListItemText
                primary="Tags"
                sx={{ textTransform: "capitalize" }}
              />
              {sevenListopen ? (
                <ExpandLess sx={{ fontSize: "17px", fontWeight: "thin" }} />
              ) : (
                <ExpandMore sx={{ fontSize: "17px", fontWeight: "thin" }} />
              )}
            </ListItemButton>
            {/* collapse */}
            <Collapse in={sevenListopen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            color: "#F8469A",
                            "&.Mui-checked": {
                              color: "#F8469A"
                            }
                          }}
                          value={"Duplicate"}
                          onChange={(e) => {
                            // setBranch((prev) => {
                            //   const value = e.target.value;
                            //   const updatedSource = e.target.checked
                            //     ? [...prev, value]
                            //     : prev.filter((item) => item !== value);

                            props.LeadFilterFunction(
                              e.target.value,
                              "leadTags",
                              e.target.checked
                            );
                            //   return updatedSource;
                            // });
                          }}
                        />
                      }
                      label={"Duplicate"}
                    />

                    {/* <ListItemIcon
                          sx={{
                            fontSize: "14px",
                            fontWeight: 400,
                            color: "#000000",
                          }}
                        >
                          {item}
                        </ListItemIcon> */}
                  </FormGroup>
                </ListItemButton>
              </List>
            </Collapse>

            {props.component === "deals" && (
              <>
                {/* Payment Status List */}
                <ListItemButton onClick={handleClick8}>
                  <ListItemText
                    primary="Payment status"
                    sx={{ textTransform: "capitalize" }}
                  />
                  {eightListopen ? (
                    <ExpandLess sx={{ fontSize: "17px", fontWeight: "thin" }} />
                  ) : (
                    <ExpandMore sx={{ fontSize: "17px", fontWeight: "thin" }} />
                  )}
                </ListItemButton>

                {/* Collapse for Payment Status */}
                <Collapse in={eightListopen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{
                                color: "#F8469A",
                                "&.Mui-checked": { color: "#F8469A" }
                              }}
                              value="Deposite"
                              onChange={(e) => {
                                props.LeadFilterFunction(
                                  e.target.value,
                                  "paymentStatus",
                                  e.target.checked
                                );
                              }}
                            />
                          }
                          label="Deposite"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{
                                color: "#F8469A",
                                "&.Mui-checked": { color: "#F8469A" }
                              }}
                              value="Completed"
                              onChange={(e) => {
                                props.LeadFilterFunction(
                                  e.target.value,
                                  "paymentStatus",
                                  e.target.checked
                                );
                              }}
                            />
                          }
                          label="Completed"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{
                                color: "#F8469A",
                                "&.Mui-checked": { color: "#F8469A" }
                              }}
                              value="Refund Credited"
                              onChange={(e) => {
                                props.LeadFilterFunction(
                                  e.target.value,
                                  "refund",
                                  e.target.checked
                                );
                              }}
                            />
                          }
                          label="Refunded"
                        />
                      </FormGroup>
                    </ListItemButton>
                  </List>
                </Collapse>
              </>
            )}
          </>
        ) : (
          ""
        )}

        {props.statusComponent === "employee" && (
          <>
            {/* first List */}
            <ListItemButton onClick={handleClick}>
              <ListItemText
                primary="Branch"
                sx={{ textTransform: "capitalize" }}
              />
              {open ? (
                <ExpandLess sx={{ fontSize: "17px", fontWeight: "thin" }} />
              ) : (
                <ExpandMore sx={{ fontSize: "17px", fontWeight: "thin" }} />
              )}
            </ListItemButton>

            {/* collapse */}
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton sx={{ textAlign: "center" }}>
                  <form onSubmit={(e) => handleSubmit(e, "Branch")}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <TextField
                        variant="outlined"
                        id="city"
                        placeholder="Search Here"
                        value={cityFind}
                        onChange={(e) => setCityFind(e.target.value)}
                        sx={{
                          "& .MuiInputBase-input": {
                            borderRadius: "20px",
                            height: "1px"
                          },
                          "& .MuiFormLabel-root": {
                            lineHeight: "5px"
                          },

                          "& .MuiInputBase-root": {
                            borderRadius: "3px",
                            borderColor: "#fafafa"
                          }
                        }}
                      />
                      <>
                        <Box
                          component="button"
                          sx={{
                            width: "40px",
                            height: "34px",
                            textAlign: "center",
                            border: "1px solid #9d9fa1",
                            borderBottomRightRadius: "5px",
                            WebkitBorderTopRightRadius: "5px",
                            fontSize: "24px"
                          }}
                          type="submit"
                        >
                          <InputAdornment position="end">
                            <RiSearch2Line />
                          </InputAdornment>
                        </Box>
                      </>
                    </div>
                  </form>
                </ListItemButton>
              </List>
            </Collapse>

            {/* second List */}
            <ListItemButton onClick={handleClick2}>
              <ListItemText
                primary="Role"
                sx={{ textTransform: "capitalize" }}
              />
              {secListopen ? (
                <ExpandLess sx={{ fontSize: "17px", fontWeight: "thin" }} />
              ) : (
                <ExpandMore sx={{ fontSize: "17px", fontWeight: "thin" }} />
              )}
            </ListItemButton>

            <Collapse in={secListopen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton>
                  <TextField
                    placeholder="Search Here"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <IconButton>
                            <AiOutlineSearch />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    sx={{
                      backgroundColor: "white",
                      margin: 1,
                      width: "auto",
                      "& .MuiInputBase-input": {
                        borderRadius: "20px",
                        height: "1px"
                      },
                      "& .MuiFormLabel-root": {
                        lineHeight: "5px"
                      },

                      "& .MuiInputBase-root": {
                        borderRadius: "3px",
                        borderColor: "#fafafa"
                      }
                    }}
                    onChange={handleRole}
                  />
                </ListItemButton>

                <ListItemButton>
                  <FormGroup>
                    {roleList &&
                      roleList?.map((role) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{
                                color: "#F8469A",
                                "&.Mui-checked": {
                                  color: "#F8469A"
                                }
                              }}
                              onChange={(e) => {
                                handleCheckRole(e, role.roleName);
                              }}
                            />
                          }
                          label={role.roleName}
                        />
                      ))}

                    {/* <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            color: "#F8469A",
                            "&.Mui-checked": {
                              color: "#F8469A",
                            },
                          }}
                        />
                      }
                      label="Required"
                    /> */}
                  </FormGroup>
                </ListItemButton>
              </List>
              {/* <ListItemButton>
                <Typography color="#F8469A" variant="p">
                  view all
                </Typography>
              </ListItemButton> */}
            </Collapse>

            {/* thirsd List */}
            <ListItemButton onClick={handleClick3}>
              <ListItemText
                primary="Status"
                sx={{ textTransform: "capitalize" }}
              />
              {secListopen ? (
                <ExpandLess sx={{ fontSize: "17px", fontWeight: "thin" }} />
              ) : (
                <ExpandMore sx={{ fontSize: "17px", fontWeight: "thin" }} />
              )}
            </ListItemButton>

            <Collapse in={thirdListopen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton>
                  <FormGroup>
                    {MapStatus &&
                      MapStatus?.map((role) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{
                                color: "#F8469A",
                                "&.Mui-checked": {
                                  color: "#F8469A"
                                }
                              }}
                              onChange={() => {
                                handleStatusFilter(role);
                              }}
                            />
                          }
                          label={role}
                        />
                      ))}
                  </FormGroup>
                </ListItemButton>
              </List>
            </Collapse>
          </>
        )}

        {props.statusComponent === "leadApplication" && (
          <>
            <ListItemButton onClick={handleClick2}>
              <ListItemText
                primary="Country"
                sx={{ textTransform: "capitalize" }}
              />
              {secListopen ? (
                <ExpandLess sx={{ fontSize: "17px", fontWeight: "thin" }} />
              ) : (
                <ExpandMore sx={{ fontSize: "17px", fontWeight: "thin" }} />
              )}
            </ListItemButton>
            <Collapse in={secListopen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton>
                  <TextField
                    placeholder="Search Here"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <IconButton>
                            <AiOutlineSearch />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    onChange={handleChange}
                    sx={{
                      backgroundColor: "white",
                      margin: 1,
                      width: "auto",
                      "& .MuiInputBase-input": {
                        borderRadius: "20px",
                        height: "1px"
                      },
                      "& .MuiFormLabel-root": {
                        lineHeight: "5px"
                      },

                      "& .MuiInputBase-root": {
                        borderRadius: "3px",
                        borderColor: "#fafafa"
                      }
                    }}
                  />
                </ListItemButton>

                <ListItemButton>
                  <FormGroup>
                    {countryList &&
                      countryList?.map((country) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{
                                color: "#F8469A",
                                "&.Mui-checked": {
                                  color: "#F8469A"
                                }
                              }}
                              value={country.countryName.split("(")[0]}
                              onChange={(e) => {
                                handleCheckCountry(
                                  e,
                                  country.countryName.split("(")[0]
                                );
                              }}
                            />
                          }
                          label={country.countryName.split("(")[0]}
                        />
                      ))}
                    {/* <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            color: "#F8469A",
                            "&.Mui-checked": {
                              color: "#F8469A",
                            },
                          }}
                        />
                      }
                      label="View all..."
                    /> */}
                  </FormGroup>
                </ListItemButton>
              </List>
              {/* <ListItemButton>
                <Typography color="#F8469A" variant="p">
                  view all ...
                </Typography>
              </ListItemButton> */}
            </Collapse>
            <ListItemButton onClick={handleClick3}>
              <ListItemText
                primary="status"
                sx={{ textTransform: "capitalize" }}
              />
              {thirdListopen ? (
                <ExpandLess sx={{ fontSize: "17px", fontWeight: "thin" }} />
              ) : (
                <ExpandMore sx={{ fontSize: "17px", fontWeight: "thin" }} />
              )}
            </ListItemButton>
            <Collapse in={thirdListopen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  style={{
                    textAlign: "center"
                  }}
                >
                  <FormGroup>
                    {uniqueApplicationStatus.map((item) => {
                      return (
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{
                                color: "#F8469A",
                                "&.Mui-checked": {
                                  color: "#F8469A"
                                }
                              }}
                              value={item}
                              onChange={(e) => {
                                // setStatus((prev) => {
                                //   const value = e.target.value;
                                //   const updatedSource = e.target.checked
                                //     ? [...prev, value]
                                //     : prev.filter((item) => item !== value);
                                props.LeadFilterFunction(
                                  e.target.value,
                                  "leadStatus",
                                  e.target.checked
                                );
                                //   return updatedSource;
                                // });
                              }}
                            />
                          }
                          label={item}
                        />
                      );
                    })}
                  </FormGroup>
                </ListItemButton>
              </List>
            </Collapse>
            <ListItemButton onClick={handleClick3}>
              <ListItemText
                primary="University"
                sx={{ textTransform: "capitalize" }}
              />
              {thirdListopen ? (
                <ExpandLess sx={{ fontSize: "17px", fontWeight: "thin" }} />
              ) : (
                <ExpandMore sx={{ fontSize: "17px", fontWeight: "thin" }} />
              )}
            </ListItemButton>
            <Collapse in={thirdListopen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  style={{
                    textAlign: "center"
                  }}
                >
                  <FormGroup>
                    {uniqueUniversity.map((item) => {
                      return (
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{
                                color: "#F8469A",
                                "&.Mui-checked": {
                                  color: "#F8469A"
                                }
                              }}
                              value={item}
                              onChange={(e) => {
                                // setStatus((prev) => {
                                //   const value = e.target.value;
                                //   const updatedSource = e.target.checked
                                //     ? [...prev, value]
                                //     : prev.filter((item) => item !== value);
                                props.LeadFilterFunction(
                                  e.target.value,
                                  "leadUniversity",
                                  e.target.checked
                                );
                                //   return updatedSource;
                                // });
                              }}
                            />
                          }
                          label={item}
                        />
                      );
                    })}
                  </FormGroup>
                </ListItemButton>
              </List>
            </Collapse>
            <ListItemButton onClick={handleClick4}>
              <ListItemText
                primary="next intake month"
                sx={{ textTransform: "capitalize" }}
              />
              {fourthListopen ? (
                <ExpandLess sx={{ fontSize: "17px", fontWeight: "thin" }} />
              ) : (
                <ExpandMore sx={{ fontSize: "17px", fontWeight: "thin" }} />
              )}
            </ListItemButton>
            <Collapse in={fourthListopen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton>
                  <Box sx={{ mt: 1 }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        views={["month"]}
                        label="Month"
                        // value={intakeMonth}
                        sx={{ width: 200 }}
                        onChange={(newValue) =>
                          handleMonthFilter(newValue, "month")
                        }
                        renderInput={(params) => (
                          <TextField {...params} fullWidth />
                        )}
                      />
                    </LocalizationProvider>
                    {/* <Typography>{error?.intakeMonth}</Typography> */}
                  </Box>
                </ListItemButton>
              </List>
            </Collapse>
            <ListItemButton onClick={handleClick8}>
              <ListItemText
                primary="next intake year"
                sx={{ textTransform: "capitalize" }}
              />
              {eightListopen ? (
                <ExpandLess sx={{ fontSize: "17px", fontWeight: "thin" }} />
              ) : (
                <ExpandMore sx={{ fontSize: "17px", fontWeight: "thin" }} />
              )}
            </ListItemButton>
            <Collapse in={eightListopen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton>
                  <Box sx={{ mt: 3 }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        views={["year"]}
                        label="Year"
                        // value={intakeYear}
                        // minDate={new Date()} // Restricts to the current year and future years
                        sx={{ width: 200 }}
                        onChange={(newValue) =>
                          handleYearFilter(newValue, "year")
                        }
                        renderInput={(params) => (
                          <TextField {...params} fullWidth />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                </ListItemButton>
              </List>
            </Collapse>
            <ListItemButton onClick={handleClick5}>
              <ListItemText
                primary="Payment Status"
                sx={{ textTransform: "capitalize" }}
              />
              {eightListopen ? (
                <ExpandLess sx={{ fontSize: "17px", fontWeight: "thin" }} />
              ) : (
                <ExpandMore sx={{ fontSize: "17px", fontWeight: "thin" }} />
              )}
            </ListItemButton>
            <Collapse in={fifthListopen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton>
                  <Box sx={{ mt: 3, display: "flex", flexDirection: "column" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            color: "#F8469A",
                            "&.Mui-checked": {
                              color: "#F8469A"
                            }
                          }}
                          value={"Completed"}
                          onChange={(e) => {
                            // setStatus((prev) => {
                            //   const value = e.target.value;
                            //   const updatedSource = e.target.checked
                            //     ? [...prev, value]
                            //     : prev.filter((item) => item !== value);
                            props.LeadFilterFunction(
                              e.target.value,
                              "paymentStatus",
                              e.target.checked
                            );
                            //   return updatedSource;
                            // });
                          }}
                        />
                      }
                      label={"Completed"}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            color: "#F8469A",
                            "&.Mui-checked": {
                              color: "#F8469A"
                            }
                          }}
                          value={"Deposit"}
                          onChange={(e) => {
                            // setStatus((prev) => {
                            //   const value = e.target.value;
                            //   const updatedSource = e.target.checked
                            //     ? [...prev, value]
                            //     : prev.filter((item) => item !== value);
                            props.LeadFilterFunction(
                              e.target.value,
                              "paymentStatus",
                              e.target.checked
                            );
                            //   return updatedSource;
                            // });
                          }}
                        />
                      }
                      label={"Deposit"}
                    />

                    {props.component == "deals" ||
                      props.component == "application" ?(
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{
                                color: "#F8469A",
                                "&.Mui-checked": {
                                  color: "#F8469A"
                                }
                              }}
                              value={"Refund Credited"}
                              onChange={(e) => {
                                // setStatus((prev) => {
                                //   const value = e.target.value;
                                //   const updatedSource = e.target.checked
                                //     ? [...prev, value]
                                //     : prev.filter((item) => item !== value);
                                props.LeadFilterFunction(
                                  e.target.value,
                                  "refund",
                                  e.target.checked
                                );
                                //   return updatedSource;
                                // });
                              }}
                            />
                          }
                          label={"Refund"}
                        />
                      ):""}
                  </Box>
                </ListItemButton>
              </List>
            </Collapse>
          </>
        )}
      </List>

      <Box
        sx={{
          display: "flex",
          gap: "10px",
          mt: "29px",
          alignItems: "center",
          "& input": {
            border: "1px solid #B8BECC",
            borderRadius: "4px",
            outline: "none",
            maxWidth: "80px",
            textAlign: "center",
            fontSize: "16px",
            lineHeight: "24px",
            padding: "5px 17px"
          }
        }}
      >
        <Typography
          sx={{
            fontSize: "14px",
            lineHeight: "19px"
          }}
        >
          Showing Entries
        </Typography>
        <input
          type="number"
          value={props.pagiantionEntry}
          onChange={(e) => props.handlePaginationEntry(e)}
        />
      </Box>
    </>
  );
}

export default LeadsFilterSearch;
